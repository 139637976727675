import React, {useEffect, useState} from 'react'
import {GridStack} from "gridstack";
import {Button} from "../ui/button";
import {GroupWidget, MultiMetricChartWidget, OldMetricChartWidget, Widget} from "./internalwidgets";
import {recursiveSave} from "./utils";
import {Group} from "./widgets/Group";
import {URLSearchParamsInit, useNavigate, useSearchParams} from "react-router-dom";
import axios from "../../utility/customAxios";
import {v4 as uuidv4} from 'uuid';
import {MdContentCopy, MdModeEdit, MdOutlineSave, MdSettings} from "react-icons/md";
import {useDebouncedCallback} from "use-debounce";
import {NavigateOptions} from "react-router/dist/lib/context";
import {Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle} from "../ui/dialog";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../ui/tooltip";
import {cn} from "../ui/lib/utils";
import {useDispatch} from 'react-redux'
import {set} from '../../store/reducers/timerange'
import {TimeRange} from "../../types/time"
import {SingleSelectDropdown} from "../Input/SingleSelectDropdown";
import {DropDownItem} from "../Input/MultiSelectorDropdown/MultiSelectorDropDown";
import {Info} from "lucide-react";
import {useToast} from "../ui/use-toast";
import {Input} from "../ui/input";
import {boolean, string} from "yup";
import {usePreserveQueryParamsNavigate} from "../ui/lib/utils";

interface DashboardProps {
    setDashboardTitle?: (title: string) => void
    dashboardTitle?: string
}

function updateDashboard(searchParams: URLSearchParams,
                         setSearchParams: (nextInit?: (URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit)), navigateOpts?: NavigateOptions) => void,
                         setInitialWidget: (value: (((prevState: (GroupWidget | undefined)) => (GroupWidget | undefined)) | GroupWidget | undefined)) => void,
                         setEditable: (value: (((prevState: boolean) => boolean) | boolean)) => void,
                         setShowNewChartInjectedDialog: (value: (((prevState: boolean) => boolean) | boolean)) => void,
                         setSelectedDefaultTimeRange: (value: (((prevState: string | undefined) => string | undefined) | string | undefined)) => void,
) {
    if (!searchParams.has("dashboardId")) {
        setSearchParams((prev) => {
            let urlSearchParams = new URLSearchParams(window.location.search);
            urlSearchParams.set("dashboardId", uuidv4())
            return urlSearchParams
        })
    }
    const injectedChartJson = searchParams.get("addChart")
    const injectedChart = injectedChartJson ? JSON.parse(injectedChartJson, dashboardJsonReviver) as MultiMetricChartWidget : undefined

    if (searchParams.get("dashboardId") !== "new") {
        axios.get(`/api/v1/dashboard?dashboardId=${searchParams.get("dashboardId")}`).then((response) => {
            const data = JSON.parse(response.data.dashboardJson, dashboardJsonReviver) as GroupWidget
            // If we have an injected chart, add it to the root group
            if (injectedChart) {
                data.children.push(injectedChart)
                setEditable(true)
                setShowNewChartInjectedDialog(true)
            }
            // Set default time range from API response
            if (response.data.defaultTimeRange) {
                setSelectedDefaultTimeRange(response.data.defaultTimeRange)
            }
            setInitialWidget(data)
            // Remove the injected chart from the URL
            setSearchParams((prev) => {
                let urlSearchParams = new URLSearchParams(window.location.search);
                urlSearchParams.delete("addChart")
                return urlSearchParams
            })
        }).catch((e) => {
            setInitialWidget({
                widgetType: "Group",
                title: "New Dashboard",
                position: {
                    "x": 0,
                    "y": 0,
                    "w": 12,
                    "h": 12
                },
                children: []
            } as GroupWidget)
        })
    }
}

export function ButtonWithTooltip({icon: Icon, tooltipText, onClick, highlightColour}: {
    icon: React.ElementType,
    tooltipText: string,
    onClick: () => void,
    highlightColour?: string
}) {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        className={cn(`flex items-center justify-center text-textmedium hover:cursor-pointer`, "hover:text-secondary", highlightColour ? `hover:text-${highlightColour}` : "")}
                        variant={"ghost"}
                        size={"icon"}
                        onClick={onClick}>
                        <Icon className={`w-4 h-4 text`}/>
                    </Button>
                </TooltipTrigger>
                <TooltipContent className={"bg-backgroundmedium border rounded text-textmedium p-1"}>
                    <p className="text-textmedium">{tooltipText}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
}


export interface RuntimeVariable {
    name: string,
    key: string,
    value: string
}

function CloneDashboardDialog(props: {
    open: boolean,
    onOpenChange: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    cloneDashboard?: (dashboardName: string) => void
}) {
    let [newDashboardName, setNewDashboardName] = React.useState<string>("")
    return <Dialog open={props.open} onOpenChange={props.onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
                <DialogTitle className="text-textlight">Clone Dashboard</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                    <div className="col-span-4">
                        <Input
                            placeholder="Enter new dashboard name"
                            value={newDashboardName}
                            onChange={(e) => setNewDashboardName(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <DialogFooter>
                <Button
                    className={"bg-primarytransparent border border-primary text-textlight hover:border-primaryhover"}
                    onClick={() => {
                        if (props.cloneDashboard) {
                            props.cloneDashboard(newDashboardName)
                        }
                    }}>
                    Clone
                </Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>;
}

function Dashboard(props: DashboardProps) {
    const [grids, setGrids] = React.useState<Map<string, GridStack>>(new Map<string, GridStack>())
    const [widgets, setWidgets] = React.useState<Map<string, Widget>>(new Map<string, Widget>())
    // This is a map from groupID to a list of variables with their runtime values
    const [runtimeVariables, setRuntimeVariables] = React.useState<Map<string, RuntimeVariable[]>>(new Map<string, RuntimeVariable[]>())
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialWidget, setInitialWidget] = React.useState<GroupWidget>()
    const [editable, setEditable] = React.useState<boolean>(false)
    const [showNewChartInjectedDialog, setShowNewChartInjectedDialog] = React.useState<boolean>(false)
    const [showSettingsDialog, setShowSettingsDialog] = React.useState<boolean>(false)
    const [selectedDefaultTimeRange, setSelectedDefaultTimeRange] = React.useState<string | undefined>()
    const [showCloneDashboardDialog, setShowCloneDashboardDialog] = React.useState<boolean>(false)
    const debouncedUpdateDasboard = useDebouncedCallback(updateDashboard, 10)
    const [gridStyle, setGridStyle] = useState({})
    const dispatch = useDispatch()
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const {toast} = useToast()
    const navigate = usePreserveQueryParamsNavigate();

    const timeRangeOptions: DropDownItem[] = [
        {displayName: "Last 15 Minutes", value: "15m"},
        {displayName: "Last 30 Minutes", value: "30m"},
        {displayName: "Last 1 Hour", value: "1h"},
        {displayName: "Last 3 Hours", value: "3h"},
        {displayName: "Last 6 Hours", value: "6h"},
        {displayName: "Last 12 Hours", value: "12h"},
        {displayName: "Last 24 Hours", value: "24h"},
        {displayName: "Last 2 Days", value: "2d"},
        {displayName: "Last 3 Days", value: "3d"},
        {displayName: "Last 7 Days", value: "7d"},
        {displayName: "Last 30 Days", value: "30d"},
        {displayName: "No Default", value: ""}
    ];

    // When the dashboard editing mode changes, update the gridstacks so that they can be edited / resized / moved
    useEffect(() => {
        grids.forEach((grid) => {
            grid.setStatic(!editable)
        })
    }, [editable])

    useEffect(() => {
        const gridStyleToSet = {
            backgroundColor: 'var(--background-dark)',
            backgroundSize: `calc(100% / 12) 128px`, // Hardcoded cell height for the grid cells.
            backgroundImage: `
      linear-gradient(to right, var(--primary-transparent) 1px, transparent 1px),
      linear-gradient(to bottom, var(--primary-transparent) 1px, transparent 1px)
    `,
        };
        if (editable) {
            setGridStyle(gridStyleToSet)
        } else {
            setGridStyle({})
        }
    }, [editable]);

    useEffect(() => {
        if (props.setDashboardTitle) {
            let title = (widgets.get("id-root") as GroupWidget)?.title
            if (title) {
                props.setDashboardTitle(title)
            }
        }
    }, [widgets])

    // Pull the configuration from the backend
    useEffect(() => {
        debouncedUpdateDasboard(searchParams, setSearchParams, setInitialWidget, setEditable, setShowNewChartInjectedDialog, setSelectedDefaultTimeRange);
    }, [searchParams.get("dashboardId")])

    // Update selected time range item when initialWidget changes
    useEffect(() => {
        // Show toast notification when time range is set and update the current time
        if (selectedDefaultTimeRange) {
            dispatch(set(new TimeRange(selectedDefaultTimeRange, undefined, undefined)))
            toast({
                className: "text-textlight",
                title: "Time Range Updated",
                description: `Dashboard time range has been set to the default dashboard time range: ${selectedDefaultTimeRange}`,
                duration: 3000, // 3 seconds
            })
        }
    }, [selectedDefaultTimeRange])

    const handleSaveSettings = () => {
        if (initialWidget) {
            // Update the widget in the backend
            axios.post(`/api/v1/dashboard`, {
                id: searchParams.get("dashboardId"),
                name: props.dashboardTitle,
                dashboardJson: JSON.stringify(initialWidget, dashboardJsonReplacer),
                defaultTimeRange: selectedDefaultTimeRange,
            }).then(() => {
                setShowSettingsDialog(false)
            })
        }
    }

    let cloneDashboardFunction = (dashboardName: string) => {
        (widgets.get("id-root") as GroupWidget).title = dashboardName
        let widget = recursiveSave("id-root", widgets, grids);
        let dashboardJson = JSON.stringify(widget, dashboardJsonReplacer);
        let newUuid = uuidv4();
        axios.post(`/api/v1/dashboard`, {
            name: dashboardName,
            id: newUuid,
            dashboardJson: dashboardJson,
            defaultTimeRange: selectedDefaultTimeRange
        }).then(() => {
            navigate(`/dashboard?dashboardId=${newUuid}`)
        }).catch((e) => {
            console.error(e);
        });
    }


    let saveButton = (
        <div className="flex gap-2">
            <ButtonWithTooltip
                icon={MdOutlineSave}
                tooltipText="Save current dashboard configuration"
                onClick={() => {
                    let widget = recursiveSave("id-root", widgets, grids);
                    let dashboardJson = JSON.stringify(widget, dashboardJsonReplacer);
                    axios.post(`/api/v1/dashboard`, {
                        name: props.dashboardTitle,
                        id: searchParams.get("dashboardId"),
                        dashboardJson: dashboardJson,
                        defaultTimeRange: selectedDefaultTimeRange
                    }).then(() => {
                        setEditable(false);
                        // We do this so that newly placed charts update their runtime variables with the correct
                        // parent group chart
                        setTimeout(() => {
                            setRuntimeVariables(prev => new Map(prev))
                        }, 1000)
                    }).catch((e) => {
                        console.error(e);
                    });
                }}
            />
            <ButtonWithTooltip
                icon={MdSettings}
                tooltipText="Dashboard Settings"
                onClick={() => setShowSettingsDialog(true)}
            />
        </div>
    );

    let editButton = (
        <ButtonWithTooltip
            icon={MdModeEdit}
            tooltipText={editable ? "Stop Editing" : "Edit Dashboard"}
            onClick={() => {
                setEditable(!editable);
            }}
            highlightColour={editable ? "red-500" : undefined}
        />
    );

    let cloneDashboardButton = (
        <ButtonWithTooltip
            icon={MdContentCopy}
            tooltipText="Clone Dashboard"
            onClick={() => {
                setShowCloneDashboardDialog(true);
            }}
        />
    );

    if (!initialWidget) {
        return <div>Loading...</div>
    }

    let settingsDialog = <Dialog open={showSettingsDialog} onOpenChange={setShowSettingsDialog}>
        <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
                <DialogTitle className="text-textlight">Dashboard Settings</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                    <div className="col-span-4 flex items-center gap-2">
                        <span className="text-textlight">Default Time Range</span>
                        <TooltipProvider>
                            <Tooltip open={tooltipOpen} onOpenChange={setTooltipOpen}>
                                <TooltipTrigger asChild>
                                    <div onMouseEnter={() => setTooltipOpen(true)}
                                         onMouseLeave={() => setTooltipOpen(false)}
                                         className={"flex flex-col justify-center"}>
                                        <Info className="h-4 w-4 text-textmedium hover:text-textlight"/>
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent className={"bg-backgroundmedium border rounded p-1"}>
                                    <p className="text-textlight">Set the default time range for this dashboard when it
                                        is first loaded</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>
                    <div className="w-max">
                        <SingleSelectDropdown
                            selectedItemTitle="Time Range"
                            selectedItem={timeRangeOptions.find(item => item.value === selectedDefaultTimeRange) || timeRangeOptions.find(item => item.value === "") || timeRangeOptions[0]}
                            possibleItems={timeRangeOptions}
                            setSelectedItem={(item) => {
                                setSelectedDefaultTimeRange(item.value)
                            }}
                        />
                    </div>
                </div>
            </div>
            <DialogFooter>
                <Button
                    className={"bg-primarytransparent border border-primary text-textlight hover:border-primaryhover"}
                    onClick={handleSaveSettings}
                >
                    Save Changes
                </Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>;

    let injectedChartDialog = <Dialog open={showNewChartInjectedDialog}>
        <DialogContent
            className={"w-1/3 text-textmedium"}
            onInteractOutside={() => setShowNewChartInjectedDialog(false)}>
            <DialogTitle>
                You added a new chart
            </DialogTitle>
            <DialogDescription>
                The chart has been added to the bottom of the dashboard. Make sure you save to keep it.
            </DialogDescription>
        </DialogContent>
    </Dialog>;

    let cloneDashboardDialog = <CloneDashboardDialog
        cloneDashboard={cloneDashboardFunction}
        open={showCloneDashboardDialog}
        onOpenChange={setShowCloneDashboardDialog}/>;

    return (
        <div className="flex flex-col grow overflow-y-auto">
            <Group
                stylesheet={gridStyle}
                editable={editable}
                editButton={editButton}
                saveButton={editable ? saveButton : undefined}
                className={"w-full h-full"}
                id={"id-root"} widget={initialWidget}
                variables={initialWidget.variables}
                runtimeVariables={runtimeVariables}
                setRuntimeVariables={setRuntimeVariables}
                grids={grids} setGrids={setGrids} widgets={widgets} setWidgets={setWidgets}
                cloneDashboardButton={editable ? cloneDashboardButton : undefined}
            />
            {settingsDialog}
            {cloneDashboardDialog}
            {injectedChartDialog}
        </div>
    )
}

// @ts-ignore
export function dashboardJsonReplacer(key, value) {
    if (value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()), // or with spread: value: [...value]
        };
    } else {
        return value;
    }
}

// @ts-ignore
export function dashboardJsonReviver(key, value) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}


export {Dashboard};
export type {GroupWidget};
