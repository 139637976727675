import {format} from "date-fns";
import {DateTimeFormat, DateTimeFormatToLocale, ISO8601} from "../pages/Settings";
import {useLocale} from "react-aria";

export enum DateTimePrecision {
    Milliseconds = ".SSS",
    Seconds = "ss",
    Minutes = "mm",
    Hours = "HH",
    Day = "dd",
    Month = "MM",
    Year = "YYYY"
}

export function getPreferredFormatString(precision: DateTimePrecision[]) {
    let preferredFormatStr = localStorage.getItem("dateTimeFormat")

    // If not defined use ISO format as default.
    if (preferredFormatStr == null) {
        preferredFormatStr = DateTimeFormat.DDMMYYYY
    }

    if (!precision.includes(DateTimePrecision.Year)) {
        preferredFormatStr = preferredFormatStr.replace("yyyy/", "")
        preferredFormatStr = preferredFormatStr.replace("/yyyy", "")
    }

    if (!precision.includes(DateTimePrecision.Month)) {
        preferredFormatStr = preferredFormatStr.replace("MM/", "")
        preferredFormatStr = preferredFormatStr.replace("/MM", "")
    }

    if (!precision.includes(DateTimePrecision.Day)) {
        preferredFormatStr = preferredFormatStr.replace("dd/", "")
        preferredFormatStr = preferredFormatStr.replace("/dd", "")
    }

    if (!precision.includes(DateTimePrecision.Hours)) {
        preferredFormatStr = preferredFormatStr.replace("HH:", "")
    }

    if (!precision.includes(DateTimePrecision.Minutes)) {
        preferredFormatStr = preferredFormatStr.replace("mm:", "")
    }

    if (!precision.includes(DateTimePrecision.Seconds)) {
        preferredFormatStr = preferredFormatStr.replace("ss", "")
    }

    if (!precision.includes(DateTimePrecision.Milliseconds)) {
        preferredFormatStr = preferredFormatStr.replace(".SSS", "")
    }

    if (preferredFormatStr.charAt(preferredFormatStr.length - 1) == ":") {
        preferredFormatStr = preferredFormatStr.slice(0, -1)
    }

    return preferredFormatStr;
}

export function displayDateTimeWithSelectedFormat(
    dateTime: Date,
    precision: DateTimePrecision[],
): string {
    let preferredFormat = getPreferredFormatString(precision);

    return format(new Date(dateTime), preferredFormat)
}

/**
 * Returns the start of a time bucket for a given timestamp
 * @param timestamp Unix timestamp in milliseconds
 * @param precision The time bucket precision (e.g., Minutes, Hours, Day)
 * @returns Unix timestamp in milliseconds for the start of the time bucket
 */
export function getStartOfBucket(timestamp: number, precision: DateTimePrecision): number {
    const msInSecond = 1000;
    const msInMinute = msInSecond * 60;
    const msInHour = msInMinute * 60;
    const msInDay = msInHour * 24;
    const msInMonth = msInDay * 30; // Approximate
    const msInYear = msInDay * 365; // Approximate

    let divisor: number;
    switch (precision) {
        case DateTimePrecision.Milliseconds:
            return timestamp;
        case DateTimePrecision.Seconds:
            divisor = msInSecond;
            break;
        case DateTimePrecision.Minutes:
            divisor = msInMinute;
            break;
        case DateTimePrecision.Hours:
            divisor = msInHour;
            break;
        case DateTimePrecision.Day:
            divisor = msInDay;
            break;
        case DateTimePrecision.Month:
            divisor = msInMonth;
            break;
        case DateTimePrecision.Year:
            divisor = msInYear;
            break;
        default:
            throw new Error(`Unsupported precision: ${precision}`);
    }

    return Math.floor(timestamp / divisor) * divisor;
}

export function getSelectedLocaleString() {
    let preferredFormat = localStorage.getItem("dateTimeFormat")

    // If not defined use EU format as default.
    if (preferredFormat == null) {
        preferredFormat = DateTimeFormat.DDMMYYYY
    }

    const localeString = DateTimeFormatToLocale.get(preferredFormat as DateTimeFormat)
    if (localeString == null) {
        return "ja-JP" // Default to ISO like one.
    }

    return localeString
}