"use client"

import {ColumnDef} from "@tanstack/react-table"
import {DataTableColumnHeader} from "../ui/datatable/datatablecolumnheader"
import {Badge} from "../ui/badge"
import {K8sPodDetails} from "./model"

export type PodData = {
    id: string
    name: string
    restarts: number
    age: {
        display: string
        ageInMs: number
    }
    status: {
        value: string
        display: React.ReactNode
    }
    containers?: React.ReactNode
    created: {
        display: string
        timestamp: number
    }
    environment?: string
    rawPod: any
    originalPodDetail: K8sPodDetails
}

export function statusBadgeColour(status: string) {
    if (status === "Running") {
        return "bg-secondarytransparent"
    } else if (status === "Waiting") {
        return "bg-amber-500"
    } else if (status === "Succeeded") {
        return "bg-primary"
    } else if (status === "Failed" || status === "Terminated") {
        return "bg-red-500"
    } else if (status === "Not Running") {
        return "bg-gray-500"
    } else if (status === "Unknown") {
        return "bg-gray-500"
    } else {
        return "bg-amber-500"
    }
}

export const columns = (onRowClick: (pod: PodData) => void, includeEnvironment: boolean): ColumnDef<PodData>[] => {
    const baseColumns: ColumnDef<PodData>[] = [
        {
            id: "name",
            accessorKey: "name",
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Name"/>
            ),
            cell: ({row}) => (
                <div className={"flex items-center justify-between"}
                     onClick={() => onRowClick(row.original)}
                >
                    {row.getValue("name")}
                    <div
                        className={"flex h-max items-center border border-primary hover:text-white hover:cursor-pointer bg-primarytransparent px-2 rounded mr-2"}
                        onClick={() => onRowClick(row.original)}>
                        View
                    </div>
                </div>
            ),
        },
        {
            id: "restarts",
            accessorKey: "restarts",
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Restarts"/>
            ),
            cell: ({row}) => (
                <div
                    className="flex justify-center cursor-pointer hover:text-primary"
                    onClick={() => onRowClick(row.original)}
                >
                    {row.getValue("restarts")}
                </div>
            ),
        },
        {
            id: "age",
            accessorFn: (row) => row.age.ageInMs,
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Age"/>
            ),
            cell: ({row}) => (
                <div
                    className="cursor-pointer hover:text-primary"
                    onClick={() => onRowClick(row.original)}
                >
                    {row.original.age.display}
                </div>
            ),
        },
        {
            id: "status",
            accessorFn: (row) => row.status.value,
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Status"/>
            ),
            cell: ({row}) => (
                <div
                    className="cursor-pointer hover:text-primary"
                    onClick={() => onRowClick(row.original)}
                >
                    <Badge className={`${statusBadgeColour(row.original.status.value)} text-white rounded`}>
                        {row.original.status.display}
                    </Badge>
                </div>
            ),
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id))
            },
        },
        {
            id: "containers",
            accessorFn: (row) => row.containers,
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Containers"/>
            ),
            cell: ({row}) => (
                <div
                    className="cursor-pointer hover:text-primary"
                    onClick={() => onRowClick(row.original)}
                >
                    {row.original.containers}
                </div>
            ),
        },
        {
            id: "created",
            accessorFn: (row) => row.created.timestamp,
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Created"/>
            ),
            cell: ({row}) => (
                <div
                    className="cursor-pointer hover:text-primary w-[240px] hidden lg:block"
                    onClick={() => onRowClick(row.original)}
                >
                    {row.original.created.display}
                </div>
            ),
        }
    ]

    if (includeEnvironment) {
        baseColumns.push({
            id: "environment",
            accessorKey: "environment",
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Environment"/>
            ),
            cell: ({row}) => (
                <div
                    className="cursor-pointer hover:text-primary w-[240px] hidden lg:block"
                    onClick={() => onRowClick(row.original)}
                >
                    {row.getValue("environment")}
                </div>
            ),
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id))
            },
        })
    }

    return baseColumns
}

export const filters = [
    {
        filteringColumn: "status",
        title: "Status"
    },
    {
        filteringColumn: "environment",
        title: "Environment"
    }
]
