import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "./ui/dialog";
import {DateTimePickerRange} from "./ui/DateTimeSelector";
import {MetoroMetricsChart, MetoroMetricsChartProps} from "../pages/MetricsTest";
import {TimeRange} from "../types/time";
import {isMulti, MultiMetoroMetricsChart, MultiMetoroMetricsChartProps} from "./Charts/MultiMetoroMetricsCharts";

interface ChartComparisonDialogProps {
    isOpen: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    chartProps: MetoroMetricsChartProps | MultiMetoroMetricsChartProps;
}

export function ChartComparisonDialog({isOpen, setOpen, chartProps}: ChartComparisonDialogProps) {
    const timeRange = useSelector(timerange.selectors.getTimeRange);
    const [timeRange1, setTimeRange1] = useState(() => TimeRange.fromTimeRange(timeRange));
    const [timeRange2, setTimeRange2] = useState(() => TimeRange.fromTimeRange(timeRange));

    // We do this to stop it from closing when the dialog is opened
    // HACK HACK HACK
    useEffect(() => {
        if (isOpen) {
            let prevOpen = setOpen
            setOpen = () => {
            }
            setTimeout(
                () => {
                    setOpen = prevOpen
                }, 100)
        }
    }, [isOpen]);

    // Create two copies of the chart props, one for each time period
    const [chart1Props, setChart1Props] = useState(() => ({
        ...chartProps,
        startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
        endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
    }));

    const [chart2Props, setChart2Props] = useState(() => ({
        ...chartProps,
        startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
        endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
    }));

    // Update chart props when time range changes
    useEffect(() => {
        const [start, end] = timeRange1.getStartEnd();
        setChart1Props(() => ({
            ...chartProps,
            startTime: Math.floor(start.getTime() / 1000),
            endTime: Math.floor(end.getTime() / 1000),
        }));

    }, [timeRange1, chartProps]);

    useEffect(() => {
        const [start, end] = timeRange2.getStartEnd();
        setChart2Props(() => ({
            ...chartProps,
            startTime: Math.floor(start.getTime() / 1000),
            endTime: Math.floor(end.getTime() / 1000),
        }));
    }, [timeRange2, chartProps]);

    return (
        <Dialog open={isOpen}>
            <DialogContent
                className="w-[70vw] h-[50vh] p-6 flex flex-col gap-4"
                onInteractOutside={() => setOpen(false)}
            >

                <DialogHeader>
                    <DialogTitle>Chart Comparison</DialogTitle>
                </DialogHeader>
                <div className="grid grid-cols-2 gap-6 h-full">
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center justify-end">
                            <DateTimePickerRange
                                timeRange={timeRange1}
                                onTimeRangeChange={
                                    (timeRange: TimeRange) => {
                                        setTimeRange1(timeRange);
                                    }
                                }/>
                        </div>
                        {
                            isMulti(chart1Props) && <MultiMetoroMetricsChart {...(chart1Props as MultiMetoroMetricsChartProps)} className={"h-full rounded border"} setTimeRange={setTimeRange1}/>
                        }
                        {
                            !isMulti(chart1Props) && <MetoroMetricsChart {...(chart1Props as MetoroMetricsChartProps)} className={"h-full rounded border"} setTimeRange={setTimeRange1}/>
                        }
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center justify-end">
                            <DateTimePickerRange
                                timeRange={timeRange2}
                                onTimeRangeChange={
                                    (timeRange: TimeRange) => {
                                        setTimeRange2(timeRange);
                                    }
                                }/>
                        </div>
                        {
                            isMulti(chart2Props) && <MultiMetoroMetricsChart {...(chart2Props as MultiMetoroMetricsChartProps)} className={"h-full rounded border"} setTimeRange={setTimeRange2}/>
                        }
                        {
                            !isMulti(chart2Props) && <MetoroMetricsChart {...(chart2Props as MetoroMetricsChartProps)} className={"h-full rounded border"} setTimeRange={setTimeRange2}/>
                        }
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
