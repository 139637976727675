import {BaseView} from "./BaseView";
import React, {useEffect} from "react";
import {Input} from "../components/ui/input";
import {SearchIcon} from "lucide-react";
import axios from "../utility/customAxios";
import {Button} from "../components/ui/button";
import {usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";

export interface DashboardSearchResult {
    id: string
    name: string
}

export interface DashboardSearchResponse {
    results: DashboardSearchResult[]
}

function DashboardSearchBar(props: {
    search: string,
    setSearch: (value: (((prevState: string) => string) | string)) => void
}) {
    const navigate = usePreserveQueryParamsNavigate()
    return <div className={"flex grow h-12 gap-4"}>
        <div
            className={"bg-backgroundmedium flex-none grow h-12 px-[7px] py-[9px] focus-within:border-primary hover:cursor-pointer hover:border-primary rounded border justify-between items-center gap-2 inline-flex"}>
            <Input
                placeholder={"Search for dashboards"}
                className={"bg-transparent focus-visible:ring-0 border-none border-0 ring-0 outline-0 text-textmedium"}
                value={props.search} onChange={(e) => {
                props.setSearch(e.target.value)
            }}/>
            <SearchIcon className={"text-textdark"}/>
        </div>
        <div className={""}>
            <Button className={"h-12 px-4 bg-secondarytransparenter border border-secondary text-textmedium"} onClick={() => {
                navigate("/dashboard")
            }
            }>Create Dashboard</Button>
        </div>
    </div>
}

function DashboardSearchResults(props: { dashboards: DashboardSearchResult[] }) {
    const navigate = usePreserveQueryParamsNavigate()

    return <div className="mt-4 min-w-0 bg-backgroundmedium border rounded min-h-0 w-full flex flex-col grow shrink overflow-y-auto">
        <div
            className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex border-b">
            <div className="h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl">Dashboard Name
            </div>
        </div>
        <div className="flex flex-col grow shrink">
            {props.dashboards.map((dashboard) => {
                return <div
                    onClick={() => {
                        navigate(`/dashboard?dashboardId=${dashboard.id}`)
                    }}
                    key={dashboard.id}
                    className="w-full h-12 px-4 py-2 border-b border-backgroundmedium hover:cursor-pointer hover:bg-backgrounddark flex justify-start items-center gap-4">
                    <div
                        className="w-full h-full font-normal font-['Inter'] leading-8 text-textmedium">{dashboard.name}</div>
                </div>
            })}
            {props.dashboards.length === 0 &&
                <div className={"flex justify-center items-center h-full text-textmedium flex-col gap-4"}>
                    <div className={"text-2xl text-textdark"}>No dashboards found</div>
                    <div className={"text-lg text-textdark"}> To create a new dashboard, use the Create Dashboard button in the top right. </div>
                </div>}
        </div>
    </div>
}

function DashboardSearchComponent() {
    const [search, setSearch] = React.useState<string>("")
    const [dashboards, setDashboards] = React.useState<DashboardSearchResult[]>([])

    useEffect(() => {
        let searchParams = ""
        if (search) {
            searchParams = `?query=${search}`
        }
        axios.get<DashboardSearchResponse>(`/api/v1/dashboardSearch${searchParams}`).then((response) => {
            setDashboards(response.data.results)
        })
    }, [search])

    return <div className={"flex flex-col grow shrink p-4"}>
        <div className={"h-max"}>
            <DashboardSearchBar search={search} setSearch={setSearch}/>
        </div>
        <div className={"flex grow shrink min-h-0"}>
            <DashboardSearchResults dashboards={dashboards}/>
        </div>
    </div>

}

const DashboardSearch = () => {
    return <BaseView title={"Dashboard Search"} disableClusterSelector={true}>
        <DashboardSearchComponent/>
    </BaseView>
}


export {
    DashboardSearch
}