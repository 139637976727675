import React from 'react';
import { Sheet, SheetContent, SheetTrigger } from "../ui/sheet";
import { Button } from "../ui/button";
import { Textarea } from "../ui/textarea";
import { ScrollArea } from "../ui/scroll-area";
import { cn } from "../ui/lib/utils";
import { Bot, ChevronDown, ChevronRight, Clock, Menu, Plus, Send, Terminal, User, XCircle } from "lucide-react";
import Markdown from 'react-markdown';
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
import { LoadingSpinner } from "../ui/customSpinner";
import useAuth from "hooks/useAuth";
import { v4 as uuidv4 } from 'uuid';
import CustomAxios from "../../utility/customAxios";
import AnimatedLogo from "../ui/AnimatedLogo";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../ui/collapsible";

interface Message {
    role: 'user' | 'assistant' | 'system' | 'tool';
    content: string;
    tool_name?: string;
    tool_call?: boolean;
    tool_call_id?: string;
    tool_call_function_name?: string;
    tool_call_function_args?: string;
    tool_call_response_id?: string;
    tool_call_response_content?: string;
}

interface ChatHistory {
    id: string;
    title: string;
    createdAt: Date;
}

interface ToolMessage {
    role: 'tool';
    content: string;
    tool_name?: string;
    tool_call?: boolean;
    tool_call_id?: string;
    tool_call_function_name?: string;
    tool_call_function_args?: string;
    tool_call_response_id?: string;
    tool_call_response_content?: string;
}

function isToolMessage(message: Message): message is ToolMessage {
    return message.role === 'tool';
}

function TypingIndicator() {
    return (
        <div className="flex space-x-2 p-1">
            <div className="w-2 h-2 rounded-full bg-primary/40 animate-bounce [animation-delay:-0.3s]"></div>
            <div className="w-2 h-2 rounded-full bg-primary/40 animate-bounce [animation-delay:-0.15s]"></div>
            <div className="w-2 h-2 rounded-full bg-primary/40 animate-bounce"></div>
        </div>
    );
}

function MarkdownRenderer({ content }: { content: string }) {
    return (
        <div className="prose prose-invert max-w-none prose-p:my-4 prose-pre:my-4 prose-headings:my-6 prose-ul:my-4 prose-ol:my-4 prose-blockquote:my-4 prose-table:my-4">
            <Markdown
                rehypePlugins={[rehypeHighlight]}
                remarkPlugins={[remarkGfm]}
                components={{
                    // Override pre and code styling
                    pre: ({ children }) => (
                        <pre className="bg-[#1D2739] rounded-lg p-4 overflow-x-auto my-4">
                            {children}
                        </pre>
                    ),
                    code: ({ children, className }) => (
                        className ? (
                            <code className={cn("text-sm", className)}>
                                {children}
                            </code>
                        ) : (
                            <code className="bg-[#1D2739] rounded px-1.5 py-0.5 text-sm">
                                {children}
                            </code>
                        )
                    ),
                    p: ({ children }) => (
                        <p className="my-4 leading-7">
                            {children}
                        </p>
                    ),
                    table: ({ children }) => (
                        <div className="my-4 overflow-x-auto">
                            <table className="border-collapse">
                                {children}
                            </table>
                        </div>
                    ),
                    th: ({ children }) => (
                        <th className="border border-[#1D2739] px-4 py-2 bg-[#131B2E]">
                            {children}
                        </th>
                    ),
                    td: ({ children }) => (
                        <td className="border border-[#1D2739] px-4 py-2">
                            {children}
                        </td>
                    ),
                }}
            >
                {content}
            </Markdown>
        </div>
    );
}

function ToolMessageRenderer({ message, isToolCall }: { message: Message, isToolCall: boolean }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isParamsOpen, setIsParamsOpen] = React.useState(false);

    if (isToolCall) {
        return (
            <div className="space-y-2">
                <div className="flex items-center gap-2 text-sm text-textmedium">
                    <Terminal className="h-4 w-4" />
                    <span>Guardian called tool: <span className="font-mono bg-[#1D2739] px-1.5 py-0.5 rounded">{message.tool_call_function_name}</span></span>
                </div>
                {message.tool_call_function_args && (
                    <Collapsible open={isParamsOpen} onOpenChange={setIsParamsOpen}>
                        <CollapsibleTrigger className="flex items-center gap-2 text-sm text-textmedium hover:text-textlight transition-colors">
                            {isParamsOpen ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
                            <span>Tool Parameters</span>
                        </CollapsibleTrigger>
                        <CollapsibleContent>
                            <div className="mt-2 bg-[#1D2739] rounded-lg p-4 text-sm font-mono text-textmedium overflow-x-auto">
                                <pre className="whitespace-pre-wrap">
                                    {JSON.stringify(JSON.parse(message.tool_call_function_args), null, 2)}
                                </pre>
                            </div>
                        </CollapsibleContent>
                    </Collapsible>
                )}
            </div>
        );
    }

    return (
        <Collapsible open={isOpen} onOpenChange={setIsOpen} className="w-full">
            <CollapsibleTrigger className="flex items-center gap-2 text-sm text-textmedium hover:text-textlight transition-colors">
                {isOpen ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
                <span>Tool Response</span>
            </CollapsibleTrigger>
            <CollapsibleContent>
                <div className="mt-2 bg-[#1D2739] rounded-lg p-4 text-sm font-mono text-textmedium overflow-x-auto">
                    <pre className="whitespace-pre-wrap">{message.tool_call_response_content || message.content}</pre>
                </div>
            </CollapsibleContent>
        </Collapsible>
    );
}

function ChatHistorySlider({ 
    isOpen, 
    onClose, 
    histories,
    activeId,
    onSelect,
    onNewChat
}: { 
    isOpen: boolean; 
    onClose: () => void;
    histories: ChatHistory[];
    activeId: string | null;
    onSelect: (history: ChatHistory) => void;
    onNewChat: () => void;
}) {
    return (
        <div 
            className={cn(
                "absolute inset-y-0 w-[260px] bg-[#0A0F1E] border-r border-[#1D2739] transition-all duration-300 ease-in-out",
                isOpen ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0 pointer-events-none"
            )}
        >
            <div className="flex flex-col h-full">
                <div className="flex items-center h-12 px-4 border-b border-[#1D2739]">
                    <span className="text-sm text-textmedium">Chat History</span>
                </div>
                <div className="p-2">
                    <Button
                        onClick={onNewChat}
                        className="w-full bg-primarytransparent hover:bg-primary/90 text-textmedium border border-primary h-9 text-sm font-normal"
                    >
                        <Plus className="h-4 w-4 mr-2" />
                        New Chat
                    </Button>
                </div>
                <ScrollArea className="flex-1">
                    <div className="py-2 space-y-1">
                        {histories.map((history) => (
                            <Button
                                key={history.id}
                                variant="ghost"
                                className={cn(
                                    "w-full justify-start px-4 py-2.5 hover:bg-[#1D2739] text-left h-auto rounded-sm",
                                    activeId === history.id ? "bg-[#1D2739]" : "bg-transparent",
                                    "group transition-colors"
                                )}
                                onClick={() => onSelect(history)}
                            >
                                <div className="flex items-center gap-3 w-full">
                                    <Clock className="h-4 w-4 text-textdark group-hover:text-textmedium flex-shrink-0" />
                                    <div className="flex flex-col min-w-0">
                                        <span className="text-sm text-textmedium group-hover:text-textlight truncate font-medium">
                                            {history.title}
                                        </span>
                                        <span className="text-xs text-textdark group-hover:text-textmedium">
                                            {history.createdAt.toLocaleDateString()}
                                        </span>
                                    </div>
                                </div>
                            </Button>
                        ))}
                    </div>
                </ScrollArea>
            </div>
        </div>
    );
}

export function GuardianChat() {
    const { user } = useAuth();
    const [isOpen, setIsOpen] = React.useState(false);
    const [messages, setMessages] = React.useState<Message[]>([]);
    const [input, setInput] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [isHistoryOpen, setIsHistoryOpen] = React.useState(false);
    const [histories, setHistories] = React.useState<ChatHistory[]>([]);
    const [activeHistoryId, setActiveHistoryId] = React.useState<string | null>(null);
    const messagesEndRef = React.useRef<HTMLDivElement>(null);

    // Load chat history when component mounts
    React.useEffect(() => {
        const loadHistory = async () => {
            try {
                const { data } = await CustomAxios.get('/api/v1/guardian/history');
                setHistories(data.map((history: any) => ({
                    ...history,
                    createdAt: new Date(history.createdAt)
                })));
            } catch (error) {
                console.error('Error loading chat history:', error);
            }
        };
        loadHistory();
    }, []);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    React.useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // Add new useEffect for scrolling when chat is opened
    React.useEffect(() => {
        if (isOpen) {
            // Add a small delay to ensure the sheet is fully opened
            setTimeout(() => {
                scrollToBottom();
            }, 100);
        }
    }, [isOpen]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!input.trim() || isLoading) return;

        const userMessage = { role: 'user' as const, content: input };
        setMessages(prev => [...prev, userMessage]);
        setInput('');
        setIsLoading(true);
        
        try {
            const { data } = await CustomAxios.post('/api/v1/guardian/chat', {
                chatId: activeHistoryId,
                message: input,
            });

            // Add all messages that come after the user's message
            const newMessages = data.messages.slice(data.messages.findIndex(
                (m: Message) => m.role === 'user' && m.content === input
            ) + 1);
            setMessages(prev => [...prev, ...newMessages]);

            // If this was a new chat (no activeHistoryId), set the chat ID from the response
            if (!activeHistoryId) {
                setActiveHistoryId(data.chatId);
            }

            // Refresh chat history after a new message
            const { data: historyData } = await CustomAxios.get('/api/v1/guardian/history');
            setHistories(historyData.map((history: any) => ({
                ...history,
                createdAt: new Date(history.createdAt)
            })));
        } catch (error) {
            console.error('Error:', error);
            setMessages(prev => [...prev, { 
                role: 'assistant', 
                content: 'Sorry, I encountered an error while processing your request.' 
            }]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleHistorySelect = async (history: ChatHistory) => {
        try {
            const { data } = await CustomAxios.get(`/api/v1/guardian/chat?id=${history.id}`);
            setMessages(data.messages);
            setActiveHistoryId(history.id);
            setIsHistoryOpen(false);
        } catch (error) {
            console.error('Error loading chat messages:', error);
        }
    };

    const handleNewChat = () => {
        setMessages([]);
        setActiveHistoryId(null);
        setIsHistoryOpen(false);
    };

    return (
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <SheetTrigger asChild>
                <Button 
                    variant="outline" 
                    size="icon"
                    className="fixed bottom-4 right-4 h-12 w-12 rounded-full bg-primary hover:bg-primary/90"
                >
                    <Bot className="h-6 w-6 text-white" />
                </Button>
            </SheetTrigger>
            <SheetContent 
                side="right" 
                className="w-[800px] p-0 bg-[#0F1729] border-l border-[#1D2739] sm:max-w-[800px] !max-w-[800px] [&>button]:hidden overflow-hidden"
            >
                <div className="flex h-full">
                    <div className="relative flex-none w-[260px] overflow-hidden">
                        <ChatHistorySlider 
                            isOpen={isHistoryOpen}
                            onClose={() => setIsHistoryOpen(false)}
                            histories={histories}
                            activeId={activeHistoryId}
                            onSelect={handleHistorySelect}
                            onNewChat={handleNewChat}
                        />
                    </div>
                    <div className={cn(
                        "flex flex-col flex-1 min-w-0 transition-[margin] duration-300 ease-in-out",
                        isHistoryOpen ? "ml-0" : "ml-[-260px]"
                    )}>
                        {/* Header */}
                        <div className="flex items-center justify-between h-12 px-4 border-b border-[#1D2739]">
                            <div className="flex items-center gap-3">
                                <div className="relative z-50">
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        className="h-7 w-7 hover:bg-[#1D2739] rounded-sm"
                                        onClick={() => setIsHistoryOpen(!isHistoryOpen)}
                                    >
                                        <Menu className="h-4 w-4 text-textmedium" />
                                    </Button>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Bot className="h-5 w-5 text-primary" />
                                    <span className="text-sm text-textlight">Guardian AI</span>
                                </div>
                            </div>
                            <Button
                                variant="ghost"
                                className="h-7 bg-primarytransparent hover:bg-primary/90 text-textmedium border border-primary text-sm font-normal rounded-sm px-3"
                                onClick={handleNewChat}
                            >
                                <Plus className="h-4 w-4 mr-2" />
                                New Chat
                            </Button>
                        </div>

                        {/* Messages */}
                        <ScrollArea className="flex-1 p-4">
                            {messages.length === 0 ? (
                                <div className="flex flex-col items-center justify-center h-full space-y-6 mt-4">
                                    <AnimatedLogo />
                                    <div className="text-center space-y-2">
                                        <h2 className="text-2xl font-semibold text-textdark">Welcome to Guardian</h2>
                                        <p className="text-textmedium">Your intelligent observability assistant.</p>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {messages.map((message, i) => {
                                        const isToolCall = Boolean(message.tool_call_id || message.tool_call_response_id);
                                        const isToolCallMessage = Boolean(message.tool_call_id);
                                        const isToolResponse = Boolean(message.tool_call_response_id);
                                        const showAvatar = message.role !== 'tool' || isToolCallMessage;
                                        
                                        return (
                                            <div
                                                key={i}
                                                className={cn(
                                                    "flex w-full py-8 px-6",
                                                    message.role === 'assistant' || (message.role === 'tool' && !isToolCallMessage) 
                                                        ? "bg-[#131B2E]" 
                                                        : "bg-[#0F1729]",
                                                    message.role === 'tool' && !isToolCallMessage ? "py-2" : ""
                                                )}
                                            >
                                                <div className="flex gap-4 max-w-[720px] mx-auto w-full">
                                                    {showAvatar && (
                                                        <div className="flex-shrink-0 w-8 h-8">
                                                            {message.role === 'assistant' || (message.role === 'tool' && isToolCallMessage) ? (
                                                                <div className="w-8 h-8 rounded bg-primary/10 flex items-center justify-center">
                                                                    <Bot className="h-5 w-5 text-primary" />
                                                                </div>
                                                            ) : (
                                                                <div className="w-8 h-8 rounded bg-textmedium/10 flex items-center justify-center">
                                                                    <User className="h-5 w-5 text-textmedium" />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    <div className={cn(
                                                        "flex flex-col gap-1 min-h-[20px] w-full",
                                                        !showAvatar ? "ml-12" : "" // Indent tool responses
                                                    )}>
                                                        {showAvatar && (
                                                            <span className="font-medium text-base text-textlight">
                                                                {message.role === 'assistant' || (message.role === 'tool' && isToolCallMessage) 
                                                                    ? 'Guardian' 
                                                                    : user?.name || 'Chris'}
                                                            </span>
                                                        )}
                                                        <div className="text-sm text-textlight">
                                                            {isToolCall ? (
                                                                <ToolMessageRenderer 
                                                                    message={message} 
                                                                    isToolCall={isToolCallMessage} 
                                                                />
                                                            ) : message.role === 'assistant' ? (
                                                                <MarkdownRenderer content={message.content} />
                                                            ) : (
                                                                <p className="whitespace-pre-wrap leading-6">{message.content}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {isLoading && (
                                        <div className="flex w-full py-8 px-6 bg-[#131B2E]">
                                            <div className="flex gap-4 max-w-[720px] mx-auto w-full">
                                                <div className="flex-shrink-0 w-8 h-8">
                                                    <div className="w-8 h-8 rounded bg-primary/10 flex items-center justify-center">
                                                        <Bot className="h-5 w-5 text-primary" />
                                                    </div>
                                                </div>
                                                <div className="flex flex-col gap-1 min-h-[20px]">
                                                    <span className="font-medium text-base text-textlight">
                                                        Guardian
                                                    </span>
                                                    <TypingIndicator />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div ref={messagesEndRef} />
                                </>
                            )}
                        </ScrollArea>

                        {/* Input */}
                        <div className="px-6 pb-6">
                            <div className="relative max-w-[720px] mx-auto">
                                <form onSubmit={handleSubmit} className="relative flex w-full bg-[#131B2E] rounded-xl shadow-[0_0_15px_rgba(0,0,0,0.1)] border border-[#1D2739] overflow-hidden">
                                    <div className="flex-1">
                                        <Textarea
                                            value={input}
                                            onChange={(e) => setInput(e.target.value)}
                                            placeholder="Ask Guardian anything..."
                                            className="min-h-[60px] max-h-[200px] w-full bg-transparent border-0 resize-none py-4 pl-4 pr-2 text-sm focus-visible:ring-0 focus-visible:ring-offset-0 text-textmedium placeholder:text-textmedium/50"
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && !e.shiftKey) {
                                                    e.preventDefault();
                                                    handleSubmit(e);
                                                }
                                            }}
                                            disabled={isLoading}
                                        />
                                    </div>
                                    <div className="flex items-stretch border-l border-[#1D2739]">
                                        <Button
                                            type="submit"
                                            className="h-auto px-4 rounded-none bg-transparent hover:bg-[#1D2739] disabled:opacity-50 transition-colors"
                                            disabled={!input.trim() || isLoading}
                                        >
                                            {isLoading ? (
                                                <LoadingSpinner className="h-4 w-4" />
                                            ) : (
                                                <Send className="h-4 w-4 text-textmedium" />
                                            )}
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </SheetContent>
        </Sheet>
    );
} 