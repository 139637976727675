import React, {createContext, useCallback, useEffect, useReducer} from 'react';

import {jwtDecode} from 'jwt-decode';
import {LOGIN, LOGOUT} from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
import {Progress} from 'components/ui/progress';
import axios from 'utility/customAxios';
import {KeyedObject} from 'types/root';
import {AuthProps, JWTContextType} from 'types/auth';
import posthog from "posthog-js";
import ReactGA from "react-ga4";
// import {useIntercom} from "react-use-intercom";

// constant
const initialState: AuthProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

const posthogIdentify = (user: any) => {
    posthog.identify(user.uuid, {
        email: user.email,
        name: user.name,
    })
}

export const JWTProvider = ({children}: { children: React.ReactElement }) => {
    // const { boot, shutdown, hide, show, update } = useIntercom();
    const [state, dispatch] = useReducer(authReducer, initialState);

    const refreshProfile = async () => {
        try {
            let serviceToken = window.localStorage.getItem('serviceToken');

            // If it's the demo server then hardcode the token for the demo user
            if (!serviceToken && window.location.hostname === "demo.us-east.metoro.io") {
                window.localStorage.setItem('serviceToken', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcklkIjoiOThlZDU1M2QtYzY4ZC00MDRhLWFhZjItNDM2ODllNWJiMGUzIiwiZW1haWwiOiJ0ZXN0QGNocmlzYmF0dGFyYmVlLmNvbSIsImV4cCI6MTgyMTI0NzIzN30.7G6alDpcZh_OThYj293Jce5rjeOBqAhOlANR_Fl5auw')
                serviceToken = window.localStorage.getItem('serviceToken');
            }

            if (serviceToken && verifyToken(serviceToken)) {
                setSession(serviceToken);
                const response = await axios.get('/api/v1/account');
                const {user} = response.data;
                // boot({name: user.name, email: user.email})
                posthogIdentify(user);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user
                    }
                });
            } else {
                dispatch({
                    type: LOGOUT
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: LOGOUT
            });
        }
    };

    const refreshProfileCallback = useCallback(refreshProfile, [dispatch]);

    useEffect(() => {
        refreshProfileCallback();
    }, [refreshProfileCallback]);

    const login = async (email: string, password: string, awsCustomerIdToken: string) => {
        const response = await axios.post('/api/v1/account/login', {email, password, awsCustomerIdToken});

        if (response.status !== 200) {
            let errorMessage = "Unknown error";
            if (response.data && response.data.error) {
                errorMessage = response.data.error;
            }
            throw new Error(`Error: ${response.status}, Message: ${errorMessage}`);
        }

        ReactGA.event({
            category: 'user',
            action: 'login',
            label: 'email'
        });

        const {token, user} = response.data;
        setSession(token);
        posthogIdentify(user);
        // boot({name: user.name, email: user.email})
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });

        // Load user settings from the backend
        await loadUserSettings();

        if (awsCustomerIdToken != "") {
            window.location.href = "/awsmarketplace-redirect"
        }
    };


    const loginGoogleSSO = async (credentialResponse: any, inviteToken: string | undefined, awsCustomerIdToken: string) => {
        const response = await axios.post(
            '/api/v1/auth/google/validate', {...credentialResponse, inviteToken: inviteToken}
        );

        if (response.status !== 200) {
            let errorMessage = "Unknown error";
            if (response.data && response.data.error) {
                errorMessage = response.data.error;
            }
            throw new Error(`Error: ${response.status}, Message: ${errorMessage}`);
        }

        const {token, user, isNewUser} = response.data;
        setSession(token);
        posthogIdentify(user);
        if (isNewUser) {
            // Fire the google analytics event for new user
            ReactGA.event({
                category: 'user',
                action: 'sign_up',
                label: 'google_sso'
            });
        }

        ReactGA.event({
            category: 'user',
            action: 'login',
            label: 'google_sso'
        });

        // boot({name: user.name, email: user.email})
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });

        // Load user settings from the backend
        await loadUserSettings();

        if (awsCustomerIdToken != "") {
            window.location.href = "/awsmarketplace-redirect"
        }
    };

    const loadUserSettings = async () => {
        const response = await axios.get('/api/v1/user/settings');
        if (response.status !== 200) {
            let errorMessage = "Unknown error";
            if (response.data && response.data.error) {
                errorMessage = response.data.error;
            }
            console.error(`Error: ${response.status}, Message: ${errorMessage}`);
        }
        localStorage.setItem("dateTimeFormat", response.data.dateTimeFormat)
        localStorage.setItem("defaultTimeRange", response.data.defaultTimeRange)
    }

    const register = async (email: string, password: string, name: string, organization: string, awsCustomerIdToken: string) => {

        const response = await axios.post('/api/v1/account/register', {
            email: email,
            password: password,
            name: name,
            organization: organization,
            awsCustomerIdToken: awsCustomerIdToken
        });

        if (response.status !== 200) {
            let errorMessage = "Unknown error";
            if (response.data && response.data.error) {
                errorMessage = response.data.error;
            }
            throw new Error(`Error: ${response.status}, Message: ${errorMessage}`);
        }

        // Fire the google analytics event for new user
        ReactGA.event({
            category: 'user',
            action: 'sign_up',
            label: 'email'
        });
    };

    const registerWithInviteLink = async (email: string, password: string, name: string, token: string) => {
        const response = await axios.post('/api/v1/account/signup', {
            email: email,
            password: password,
            name: name,
            token: token
        });
        if (response.status !== 200) {
            let errorMessage = "Unknown error";
            if (response.data && response.data.error) {
                errorMessage = response.data.error;
            }
            throw new Error(`Error: ${response.status}, Message: ${errorMessage}`);
        }

        // Fire the google analytics event for new user
        ReactGA.event({
            category: 'user',
            action: 'sign_up',
            label: 'email'
        });
    };


    const logout = () => {
        setSession(null);
        // shutdown();
        dispatch({type: LOGOUT});
    };

    const resetPassword = async (email: string) => {
    };

    const updateProfile = () => {
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Progress/>;
    }

    return <JWTContext.Provider
        value={{
            ...state,
            login,
            logout,
            register,
            registerWithInviteLink,
            resetPassword,
            updateProfile,
            refreshProfile,
            loginGoogleSSO
        }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
