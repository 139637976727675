import {MetricFunction} from "../../components/Dashboarding/widgets/MetricSelector";

interface MetricFilter {
    filters: Map<string, string[]>
    excludeFilters: Map<string, string[]>
    splits: string[],
    aggregation: string,
    metricName: string,
    functions: MetricFunction[],
    bucketSize?: number
}

interface MonitorEvaluation {
    monitorEvaluationType: MonitorEvaluationType
    monitorEvalutionPayload: MonitorEvaluationPayload
    description: string
}

type MonitorEvaluationType = EvalType.Static | EvalType.Aggregate

export enum EvalType {
    Static = "static",
    Aggregate = "aggregate",
}

export enum MissingDatapointBehavior {
    Breaching = "breaching",
    NotBreaching = "not_breaching"
}

export interface StaticMonitorEvaluationPayload {
    // Number of data points to evaluate
    evaluationWindow: number
    // Number of data points that need to meet the condition to trigger an alarm
    datapointsToAlarm: number
    // Missing data points are treated as missing data points
    missingDatapointBehavior: MissingDatapointBehavior
    // jsonPath
    jsonPath?: string
}

export interface AggregateMonitorEvaluationPayload {
    // Aggregation function to apply to the metric or trace data. Could be sum, avg, min, max, etc.
    evaluationFunction: string
    // Window size in seconds. This is the time window over which the aggregation function is applied.
    window: number
    windowUnit: WindowUnit
    // Group by splits for the aggregation function.
    evaluationSplits: string[]
    // JSON path
    jsonPath?: string
}

export type MonitorEvaluationPayload = AggregateMonitorEvaluationPayload | StaticMonitorEvaluationPayload

export enum WindowUnit {
    Minutes = "minutes",
    Hours = "hours",
    Days = "days",
}

export enum AggregationFunction {
    Sum = "sum",
    Average = "avg",
    Min = "min",
    Max = "max"
}


const monitorEventStaticThreshold = {
    monitorEvaluationType: EvalType.Static,
    description: "Each data point is evaluated against a static threshold.",
    monitorEvalutionPayload: {
        evaluationWindow: 1,
        datapointsToAlarm: 1,
        missingDatapointBehavior: MissingDatapointBehavior.NotBreaching
    }
}

const monitorEventAggregateFunction = {
    monitorEvaluationType: EvalType.Aggregate,
    description: "Result of the aggregate function on the metric selected is evaluated against a static threshold.",
    monitorEvalutionPayload: {
        evaluationFunction: AggregationFunction.Sum,
        window: 15,
        windowUnit: WindowUnit.Minutes,
        evaluationSplits: []
    }
}

const monitorEvents = [monitorEventStaticThreshold, monitorEventAggregateFunction]

interface AlarmCondition {
    condition: string,
    threshold: number,
}

interface MetricAlert {
    filters: MetricFilter,
    monitorEvaluation: MonitorEvaluation
    eventPayload: AlarmCondition
}

const defaultMetricAlert = {
    filters: {
        filters: new Map(),
        excludeFilters: new Map(),
        splits: [],
        aggregation: "",
        metricName: "",
        functions: [],
        bucketSize: 0 // Default auto bucket size
    },
    monitorEvaluation: monitorEventStaticThreshold,
    eventPayload: {
        condition: "GreaterThan",
        threshold: 0,
    }
}

const defaultKubernetesResourceAlert = {
    filters: {
        filters: new Map(),
        excludeFilters: new Map(),
        splits: [],
        aggregation: "",
        metricName: "",
        functions: [],
        bucketSize: 0 // Default auto bucket size
    },
    monitorEvaluation: monitorEventStaticThreshold,
    eventPayload: {
        condition: "GreaterThan",
        threshold: 0,
    }
}

export {
    monitorEvents,
    monitorEventStaticThreshold,
    defaultMetricAlert,
    monitorEventAggregateFunction,
    defaultKubernetesResourceAlert
}

export type {
    AlarmCondition,
    MetricFilter,
    MonitorEvaluation,
    MetricAlert,
}
