import { XIcon } from "lucide-react";
import {Dispatch, SetStateAction, forwardRef, useState, useEffect} from "react";
import {Input, InputProps} from "../../ui/input";
import {Button} from "../../ui/button";
import {Badge} from "../../ui/badge";

type InputTagsProps = InputProps & {
    label?: string;
    placeholder?: string;
    value: string[];
    setValues: Dispatch<SetStateAction<string[]>>;
};

// Taken from: https://gist.github.com/enesien/03ba5340f628c6c812b306da5fedd1a4
export const MultiSelectorInputTags = forwardRef<HTMLInputElement, InputTagsProps>(
    ({ value, setValues, ...props }, ref) => {
        const [pendingDataPoint, setPendingDataPoint] = useState("");

        const addPendingDataPoint = () => {
            if (pendingDataPoint) {
                const newDataPoints = new Set([...value, pendingDataPoint]);
                setValues(Array.from(newDataPoints));
                setPendingDataPoint("");
            }
        };

        return (
            <>
                <div className="flex h-full grow">
                    { value.length > 0 && <div
                        className="bg-backgrounddark border-r overflow-y-auto p-2 flex gap-2 items-center">
                        {value.map((item, idx) => (
                            <Badge key={idx} variant="secondary" className={"border border-border rounded bg-backgroundmedium text-textmedium"}>
                                {item}
                                <button
                                    type="button"
                                    className="w-3 ml-2"
                                    onClick={() => {
                                        setValues(value.filter((i) => i !== item));
                                    }}
                                >
                                    <XIcon className="w-4 hover:cursor-pointer hover:text-primary"/>
                                </button>
                            </Badge>
                        ))}
                        {value.length === 0 && <div className={"text-textmedium"}> {props.label ? props.label : ""} </div>}
                    </div>}
                    <Input
                        value={pendingDataPoint}
                        placeholder={props.placeholder ? props.placeholder : ""}
                        onChange={(e) => setPendingDataPoint(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                addPendingDataPoint();
                            } else if (e.key === "," || e.key === " ") {
                                e.preventDefault();
                                addPendingDataPoint();
                            }
                        }}
                        className="h-full w-max border-none items-center"
                        ref={ref}
                        {...props}
                    />
                </div>
            </>
        );
    }
);