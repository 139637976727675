import React from 'react';
import {useQuery} from '@tanstack/react-query';
import axios from '../../utility/customAxios';
import {Card} from '../ui/card';
import {Flame} from 'lucide-react';

interface WorkflowRunResult {
    uuid: string;
    workflowUuid: string;
    workflowRunUuid: string;
    organizationUuid: string;
    attributes: Record<string, any>;
    outcome: string;
    title: string;
    reason: string;
    createdTimestamp: number;
}

interface ListWorkflowRunResultsResponse {
    workflowRunResults: WorkflowRunResult[];
    total: number;
}

interface IssueTimelineProps {
    issue: {
        uuid: string;
        workflowUuid: string;
        organizationUuid: string;
        attributes: Map<string, string>;
    };
}

export function IssueTimeline({issue}: IssueTimelineProps) {
    const {data, isLoading, error} = useQuery<ListWorkflowRunResultsResponse>({
        queryKey: ['workflowRunResults', issue.uuid],
        queryFn: async () => {
            // Convert attributes to js object so it serializes
            const attributes = Object.fromEntries(issue.attributes);

            const response = await axios.post('/api/v1/workflowRunResult/list', {
                workflowUuid: issue.workflowUuid,
                attributes: attributes,
                limit: 100,
                offset: 0
            });
            return response.data;
        },
    });

    if (isLoading) {
        return <div className="text-sm">Loading timeline...</div>;
    }

    if (error) {
        return <div className="text-sm text-red-500">Error loading timeline</div>;
    }

    if (!data?.workflowRunResults?.length) {
        return <div className="text-sm text-textmedium">No workflow runs found</div>;
    }

    // Group results by workflow run UUID
    const runGroups = data.workflowRunResults.reduce((groups, result) => {
        const group = groups.get(result.workflowRunUuid) || [];
        group.push(result);
        groups.set(result.workflowRunUuid, group);
        return groups;
    }, new Map<string, WorkflowRunResult[]>());

    // Convert to array and sort by the latest result in each group
    const sortedGroups = Array.from(runGroups.entries())
        .sort((a, b) => {
            const aTime = getLatestTimestamp(a[1][0].attributes);
            const bTime = getLatestTimestamp(b[1][0].attributes);
            return bTime - aTime;
        });

    return (
        <div className="space-y-4">
            <div className="relative">
                {/* Vertical line */}
                <div className="absolute left-2.5 top-3 h-full w-0.5 bg-border" />

                {/* Timeline items */}
                <div className="space-y-6">
                    {sortedGroups.map(([runUuid, results], index) => (
                        <div key={runUuid} className="relative flex items-start gap-4">
                            {/* Flame icon */}
                            <div className={`relative z-10 flex h-6 w-6 items-center justify-center rounded-full 
                                ${index === 0 ? 'bg-red-500' : 'bg-backgroundlight border-2 border-border'}`}>
                                <Flame className={`h-4 w-4 ${index === 0 ? 'text-white' : 'text-textmedium'}`} />
                            </div>

                            {/* Content */}
                            <Card className="flex-1 p-4 bg-backgroundlight border-border">
                                <div className="space-y-2">
                                    <div className="flex items-center justify-between">
                                        <div className="text-sm font-medium text-textlight">
                                            Workflow evaluation
                                        </div>
                                        <div className="text-xs text-textmedium">
                                            {new Date(results[0].createdTimestamp * 1000).toLocaleString()}
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between text-xs">
                                        <div className={`px-2 py-1 rounded-full text-white
                                            ${hasFailedOutcome(results) ? 'bg-red-500' : 
                                              hasWarningOutcome(results) ? 'bg-yellow-500' : 'bg-green-500'}`}>
                                            {getOverallStatus(results)}
                                        </div>
                                    </div>
                                    {results.map((result, i) => (
                                        <div key={result.uuid} className={`mt-2 pt-2 ${i > 0 ? 'border-t border-border' : ''}`}>
                                            {
                                                result.title && (
                                                    <div className="text-sm font-medium text-textlight mb-2">
                                                        {result.title}
                                                    </div>
                                                )
                                            }
                                            {result.reason && (
                                                <div className="text-xs text-textmedium mb-2">
                                                    {result.reason}
                                                </div>
                                            )}
                                            {Object.entries(result.attributes).length > 0 && (
                                                <div className="grid grid-cols-2 gap-2 mt-6">
                                                    {Object.entries(result.attributes).map(([key, value]) => (
                                                        <div key={key} className="text-xs">
                                                            <span className="font-medium text-textlight">{key}:</span>{' '}
                                                            <span className="text-textmedium">{value}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

function getLatestTimestamp(attributes: Record<string, any>): number {
    // Assuming there's a timestamp in the attributes
    // You might need to adjust this based on your actual data structure
    return attributes.timestamp ? Number(attributes.timestamp) : Date.now();
}

function hasFailedOutcome(results: WorkflowRunResult[]): boolean {
    return results.some(result => result.outcome === 'fired');
}

function hasWarningOutcome(results: WorkflowRunResult[]): boolean {
    return results.some(result => result.outcome === 'warning');
}

function getOverallStatus(results: WorkflowRunResult[]): string {
    if (hasFailedOutcome(results)) return 'Fired';
    if (hasWarningOutcome(results)) return 'Warning';
    return 'Succeeded';
}
