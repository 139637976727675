import React from "react";
import {ColumnDef,} from "@tanstack/react-table";
import {useQuery} from "@tanstack/react-query";
import {DataTable} from "../ui/datatable/datatable";
import {DataTableColumnHeader} from "../ui/datatable/datatablecolumnheader";
import axios from "../../utility/customAxios";
import {format} from "date-fns";
import {Badge} from "../ui/badge";

export interface WorkflowRun {
    uuid: string;
    workflowUUID: string;
    organizationUUID: string;
    startTime: number;
    endTime: number;
    parameters: any;
}

interface WorkflowRunsTableProps {
    workflowUUID: string;
}

interface ListWorkflowRunsResponse {
    workflowRuns: WorkflowRun[];
    total: number;
}

export function WorkflowRunsTable({workflowUUID}: WorkflowRunsTableProps) {
    const {data, isLoading, error} = useQuery<ListWorkflowRunsResponse>({
        queryKey: ['workflowRuns', workflowUUID],
        queryFn: async () => {
            const response = await axios.post('/api/v1/workflowRun/list', {
                workflowUuid: workflowUUID,
                offset: 0,
                limit: 1000000
            });
            return response.data;
        },
    });

    const columns: ColumnDef<WorkflowRun>[] = [
        {
            id: 'startTime',
            header: ({column}) => <DataTableColumnHeader column={column} title="Start Time"/>,
            cell: ({row}) => {
                const timestamp = row.original.startTime as number;
                return (
                    <div>
                        {timestamp ? format(new Date(timestamp * 1000), "MMM d, yyyy HH:mm:ss") : "N/A"}
                    </div>
                );
            },
            sortingFn: (rowA, rowB) => {
                const a = rowA.original.startTime as number;
                const b = rowB.original.startTime as number;
                return a - b;
            },
            enableSorting: true,
            enableHiding: true,
        },
        {
            id: 'endTime',
            header: ({column}) => <DataTableColumnHeader column={column} title="End Time"/>,
            cell: ({row}) => {
                var endTime = row.original.endTime as number | undefined;
                if (endTime && endTime <= 0) {
                    endTime = undefined;
                }
                return (
                    <div>
                        {endTime ? format(new Date(endTime * 1000), "MMM d, yyyy HH:mm:ss") : "N/A"}
                    </div>
                );
            },
            sortingFn: (rowA, rowB) => {
                const a = rowA.original.startTime as number;
                const b = rowB.original.endTime as number;
                return a - b;
            },
            enableSorting: true,
            enableHiding: true,
        },
        {
            id: 'duration',
            header: ({column}) => <DataTableColumnHeader column={column} title="Duration"/>,
            cell: ({row}) => {
                const startTime = row.original.startTime as number;
                var endTime = row.original.endTime as number | undefined;
                if (endTime && endTime <= 0) {
                    endTime = undefined;
                }
                if (!startTime || !endTime) return <div>N/A</div>;

                const duration = endTime - startTime;
                const minutes = Math.floor(duration / 60);
                const seconds = duration % 60;
                return (
                    <div className="font-medium">
                        {minutes}m {seconds}s
                    </div>
                );
            },
            filterFn: (row, id, value) => {
                const startTime = row.original.startTime as number;
                const endTime = row.original.endTime as number;
                if (!startTime || !endTime) return false;
                const duration = endTime - startTime;
                const minutes = Math.floor(duration / 60);
                const seconds = duration % 60;
                return minutes >= value[0] && minutes <= value[1] && seconds >= value[2] && seconds <= value[3];
            },
            sortingFn: (rowA, rowB) => {
                const a = rowA.original.startTime as number;
                const b = rowB.original.endTime as number;
                return a - b;
            },
            enableSorting: true,
            enableHiding: true,
        },
        {
            id: 'status',
            header: ({column}) => <DataTableColumnHeader column={column} title="Status"/>,
            cell: ({row}) => {
                return (
                    <div>
                        <Badge
                            className={`${row.original.endTime && row.original.endTime > 0 ? "bg-green-600" : "bg-blue-500"} text-white rounded`}>
                            {row.original.endTime && row.original.endTime > 0 ? "Success" : "Running"}
                        </Badge>
                    </div>
                );
            }
        }
    ];

    if (isLoading) {
        return <div className="flex justify-center items-center h-64">Loading workflow runs...</div>;
    }

    if (error) {
        return <div className="text-red-500">Error loading workflow runs: {(error as Error).message}</div>;
    }

    return (
        <DataTable
            columns={columns}
            data={data?.workflowRuns || []}
            filters={[]}
            showPagination={true}
            tableTitle="Runs"
            isLoading={isLoading}
        />
    );
}
