import {MultiMetricChartWidget, OldMetricChartWidget, Widget} from "../internalwidgets";
import {GridStack} from "gridstack";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {cn} from "../../ui/lib/utils";
import {cloneWidget, removeWidget} from "../utils";
import {determineFullWidth, findParentGrid, getVariableResolvedValuesAtGroup} from "../utils/grid";
import {MetoroMetricsChart, MetoroMetricsChartProps} from "../../../pages/MetricsTest";
import {useSelector} from "react-redux";
import timerange from "../../../store/reducers/timerange";
import {Dialog, DialogContent} from "../../ui/dialog";
import {MetricSelectorPanel} from "./MetricSelector";
import {MdModeEdit} from "react-icons/md";
import {CopyIcon, XIcon} from "lucide-react";
import {useSearchParams} from "react-router-dom";
import {ButtonWithTooltip, RuntimeVariable} from "../Dashboard";
import {MultiMetoroMetricsChart, MultiMetoroMetricsChartProps} from "../../Charts/MultiMetoroMetricsCharts";
import {MultiMetricSelectorPanel} from "./MultiMetricSelector";

export const MetricChart = (props: {
    editable: boolean,
    id?: string,
    widget: MultiMetricChartWidget,
    grids: Map<string, GridStack>,
    setGrids: Dispatch<SetStateAction<Map<string, GridStack>>>,
    widgets: Map<string, Widget>,
    setWidgets: Dispatch<SetStateAction<Map<string, Widget>>>,
    updateParentOfMount?: (id: string) => void
    ref?: React.MutableRefObject<any>
    setRootChildren: Dispatch<SetStateAction<Widget[]>>
    runtimeVariables: Map<string, RuntimeVariable[]>
}) => {
    const [id] = React.useState<string>(props.id ?? "id-" + uuidv4())
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [searchParams, setSearchParams] = useSearchParams()
    const [environments, setEnvironments] = useState<string[]>([]);
    const [isFullWidth, setIsFullWidth] = useState<boolean>(false);
    const [chartProps, setChartProps] = React.useState<MultiMetoroMetricsChartProps>({
        startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
        endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
        metricSpecifiers: props.widget.metricSpecifiers,
        type: props.widget.type,
        title: props.widget.title,
    })

    const [renderedChartProps, setRenderedChartProps] = React.useState<MultiMetoroMetricsChartProps>(chartProps)

    const [widget, setWidget] = React.useState<Widget>(props.widget)

    const [resolvedVariables, setResolvedVariables] = React.useState<RuntimeVariable[]>(() => {
        let variableResolvedValuesAtGroup1 = getVariableResolvedValuesAtGroup(id, props.grids, props.runtimeVariables);
        return variableResolvedValuesAtGroup1
    })

    // When we update the variables, we need to calculate new resolved values
    useEffect(() => {
        let variableResolvedValuesAtGroup = getVariableResolvedValuesAtGroup(id, props.grids, props.runtimeVariables);
        setResolvedVariables(variableResolvedValuesAtGroup)
    }, [props.runtimeVariables, props.grids])


    useEffect(() => {
        let environments = searchParams.get("environment");
        if (environments !== null && environments !== "") {
            setEnvironments([environments])
        }
        if (environments === "") {
            setEnvironments([])
        }
    }, [searchParams])

    useEffect(() => {
        setRenderedChartProps(() => {
            return {
                ...chartProps,
                metricSpecifiers: chartProps.metricSpecifiers.map((metricSpecifier) => {
                    let filtersToUse = new Map(metricSpecifier.filters) ?? new Map<string, string[]>();
                    if (filtersToUse.has("environment") && filtersToUse.get("environment")!.length > 0) {
                        // Do not override as the chart has set it
                    } else {
                        if (environments.length > 0 && environments[0] !== "") {
                            let envToUse = environments.filter((env) => env !== "")
                            filtersToUse.set("environment", envToUse)
                        } else {
                            filtersToUse.delete("environment")
                        }
                    }
                    return {
                        ...metricSpecifier,
                        filters: filtersToUse
                    }
                })
            }
        })
    }, [environments, chartProps])

    useEffect(() => {
        const { element, grid } = findParentGrid(id, props.grids);
        const fullWidth = determineFullWidth(element, grid, props.widget.position ?? {});
        setIsFullWidth(fullWidth);
    }, [id, props.grids, props.widget.position, props]);

    useEffect(() => {
        props.setWidgets((prev) => {
            let newWidgets = new Map<string, Widget>(prev)
            newWidgets.set(id, props.widget)
            return newWidgets
        })

        if (props.updateParentOfMount !== undefined) {
            props.updateParentOfMount(id)
        }
    }, [])

    useEffect(() => {
        let updatedWidget = {
            ...props.widget,
            metricSpecifiers: chartProps.metricSpecifiers,
            type: chartProps.type,
            title: chartProps.title,
        } as MultiMetricChartWidget
        props.setWidgets((prev) => {
            let newWidgets = new Map<string, Widget>(prev)
            newWidgets.set(id, updatedWidget)
            return newWidgets
        })
        setWidget(updatedWidget)
    }, [chartProps])

    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

    let leftMargin = "pl-2"
    if (props.widget.position?.x === 0) {
        leftMargin = "pl-4"
    }
    let rightMargin = "pr-2"
    if (isFullWidth) {
        rightMargin = "pr-4"
    }
    let topMargin = "pt-2"
    if (props.widget.position?.y === 0) {
        topMargin = "pt-4"
    }
    let bottomMargin = "pb-2"


    return (
        <div id={id} className={cn(id, "flex flex-col grow")}>
            <div className={cn("flex grow max-w-full", leftMargin, topMargin, rightMargin, bottomMargin)}>
                <div className={cn("border flex flex-col grow h-full rounded max-w-full")}>
                    <div className={"flex border-b draggablehandle justify-between px-2 h-8 max-w-full bg-backgroundmedium"}>
                        <div
                            className={"text-textmedium flex-col text-center items-center justify-center mt-[4px] truncate"}>{chartProps.title}</div>
                        <div className={"flex gap-2"}>
                            {
                                props.editable &&
                                <div className={"flex flex-col justify-center"}>
                                    <ButtonWithTooltip
                                        icon={CopyIcon}
                                        tooltipText="Duplicate widget"
                                        onClick={() => {
                                            cloneWidget(widget, props.setRootChildren)
                                        }}
                                    />
                                </div>
                            }
                            {props.editable && (
                                <>
                                    <div className={"flex flex-col items-center justify-center"}>
                                        <ButtonWithTooltip
                                            icon={MdModeEdit}
                                            tooltipText="Edit widget"
                                            onClick={() => setIsDialogOpen(true)}
                                        />
                                    </div>
                                    <Dialog open={isDialogOpen}>
                                        <DialogContent
                                            onInteractOutside={() => setIsDialogOpen(false)}
                                            onAbort={() => setIsDialogOpen(false)}
                                            onFocusOutside={() => setIsDialogOpen(false)}
                                            className={"border-none bg-none w-[80vw] h-[80vh] m-0 p-0"}
                                        >
                                            <MultiMetricSelectorPanel
                                                variables={resolvedVariables}
                                                chartProps={chartProps}
                                                setChartProps={setChartProps}
                                                setIsOpen={setIsDialogOpen}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                    <div className={"flex flex-col items-center justify-center"}>
                                        <ButtonWithTooltip
                                            icon={XIcon}
                                            tooltipText="Delete widget"
                                            onClick={() => removeWidget(id, props.grids, props.setGrids, props.widgets, props.setWidgets)}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={"flex grow"}>
                        <div className={cn("flex grow")}>
                            <MultiMetoroMetricsChart
                                variables={resolvedVariables}
                                className={"flex grow shrink"}
                                startTime={Math.floor(timeRange.getStartEnd()[0].getTime() / 1000)}
                                endTime={Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)}
                                type={renderedChartProps.type}
                                styling={{ borderless: true }}
                                metricSpecifiers={renderedChartProps.metricSpecifiers}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}