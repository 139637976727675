import axios from 'axios'
import { jwtDecode } from 'jwt-decode';

const CustomAxios = axios.create({baseURL: process.env.REACT_APP_API_URL || '/'})

// Check if token is expired
const isTokenExpired = (token: string): boolean => {
  try {
    const decoded = jwtDecode(token);
    return (decoded.exp as number) < Date.now() / 1000;
  } catch {
    return true;
  }
};

// Add request interceptor to check token expiration before requests
CustomAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('serviceToken');
    if (token && isTokenExpired(token)) {
      // Clear auth token
      localStorage.removeItem('serviceToken');
      delete CustomAxios.defaults.headers.common.Authorization;
      
      // Redirect to login page
      window.location.href = '/login';
      // Reject the request since we're redirecting
      return Promise.reject(new Error('Token expired'));
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default CustomAxios
