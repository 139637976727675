import {Alert, AlertType} from "../../pages/AlertCreation";
import React, {Dispatch, ReactElement, SetStateAction, useEffect} from "react";
import {useSelector} from "react-redux";
import timerange from "../../store/reducers/timerange";
import {
    AggregateMonitorEvaluationPayload,
    AggregationFunction,
    EvalType,
    MetricAlert,
    MissingDatapointBehavior,
    MonitorEvaluation,
    monitorEvents,
    StaticMonitorEvaluationPayload,
    WindowUnit
} from "../../pages/alerts/MetricAlert";
import {MetoroMetricsChartProps, MetricType, Threshold, TimePeriod} from "../../pages/MetricsTest";
import {EmbedMetricSelector} from "../Dashboarding/widgets/MetricSelector";
import {TraceAlert} from "../../pages/alerts/TraceAlert";
import {useDebouncedCallback} from "use-debounce";
import {cn} from "../ui/lib/utils";
import {Tooltip, TooltipContent, TooltipTrigger} from "../ui/tooltip";
import {Input} from "../ui/input";
import {GroupByPill} from "../Filter/Pill";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuTrigger
} from "../ui/dropdown-menu";
import {ChevronDownIcon} from "@radix-ui/react-icons";
import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../ui/command";
import {DropDownItem} from "../Input/MultiSelectorDropdown/MultiSelectorDropDown";
import {SingleSelectDropdown} from "../Input/SingleSelectDropdown";
import {useForm} from "react-hook-form";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {Button} from "../ui/button";
import {Form, FormControl, FormDescription, FormField, FormItem, FormMessage} from "../ui/form";
import {formatFilterValues} from "../Filter/Filter";
import {TimeRange} from "../../types/time";
import {capitalizeFirstLetter} from "../../utility/stringUtils";
import {GetAggregateMetricEvaluation, Metric} from "../../clients/metoro/metrics";
import {ChartType} from "../Charts/MetoroChart";
import {KubernetesResourceAlert} from "../../pages/alerts/KubernetesResourceAlert";
import {EmbedMultiMetricSelector} from "../Dashboarding/widgets/MultiMetricSelector";
import {MultiMetoroMetricsChartProps} from "../Charts/MultiMetoroMetricsCharts";

const FormSchema = z.object({
    thresholdValue: z.coerce.number()
})

export enum ThresholdComparator {
    GreaterThan = "GreaterThan",
    GreaterThanOrEqual = "GreaterThanOrEqual",
    LessThanOrEqual = "LessThanOrEqual",
    LessThan = "LessThan",
}

export function getThresholdComparatorSymbol(comparator: ThresholdComparator) {
    if (comparator === ThresholdComparator.GreaterThan) {
        return ">"
    }
    if (comparator === ThresholdComparator.LessThan) {
        return "<"
    }
    if (comparator === ThresholdComparator.GreaterThanOrEqual) {
        return ">="
    }
    if (comparator === ThresholdComparator.LessThanOrEqual) {
        return "<="
    }
    return "?"
}


export function AlertMetricSelector(props: {
    alert: Alert;
    setAlert: Dispatch<SetStateAction<Alert>>
}) {
    if (props.alert.type === AlertType.Metric) {
        return <MetricSelector alert={props.alert} setAlert={props.setAlert} stepNumber={3}/>;
    }

    if (props.alert.type === AlertType.Trace) {
        return <TraceSelector alert={props.alert} setAlert={props.setAlert} stepNumber={3}/>;
    }

    if (props.alert.type === AlertType.KubernetesResource) {
        return <KubernetesResourceSelector alert={props.alert} setAlert={props.setAlert} stepNumber={3}/>;
    }

    return <></>
}

function MetricSelector(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
    stepNumber: number
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const typedMetricAlert = props.alert.alert as MetricAlert
    const [chartProps, setChartProps] = React.useState<MetoroMetricsChartProps>(initialChartProps());

    function initialChartProps(): MetoroMetricsChartProps {
        return {
            startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
            endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
            metricName: typedMetricAlert.filters.metricName != "" ? typedMetricAlert.filters.metricName : "container_net_tcp_active_connections",
            excludeFilters: typedMetricAlert.filters.excludeFilters,
            filters: typedMetricAlert.filters.filters,
            splits: typedMetricAlert.filters.splits,
            aggregation: typedMetricAlert.filters.aggregation != "" ? typedMetricAlert.filters.aggregation : "avg",
            type: ChartType.Line,
            metricType: MetricType.Metric,
            functions: typedMetricAlert.filters.functions,
            bucketSize: typedMetricAlert.filters.bucketSize ? typedMetricAlert.filters.bucketSize : 60 // Default 1 minute bucket size
        }
    }

    useEffect(() => {
        setChartProps((prev) => {
            return {
                ...prev,
                startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)
            }
        })
    }, [timeRange]);


    useEffect(() => {
        const annotationProperties = getGraphAnnotationProperties(
            typedMetricAlert.monitorEvaluation,
            timeRange, typedMetricAlert.eventPayload.threshold,
            typedMetricAlert.eventPayload.condition as ThresholdComparator
        )
        const thresholdToSet = annotationProperties.thresholdToSet
        const timePeriodToHighlight = annotationProperties.timePeriodToHighlight

        setChartProps((prev) => {
            return {
                ...prev,
                threshold: thresholdToSet,
                timePeriodHighlight: timePeriodToHighlight,
                thresholdLabel: annotationProperties.annotationLabel
            }
        })
    }, [typedMetricAlert.monitorEvaluation.monitorEvaluationType,
        typedMetricAlert.monitorEvaluation.monitorEvalutionPayload,
        typedMetricAlert.eventPayload.threshold,
        typedMetricAlert.eventPayload.condition,
        typedMetricAlert.filters.aggregation]);

    useEffect(() => {
        props.setAlert((prev) => {
            let metricAlert = prev.alert as MetricAlert
            let metricFilter = {...metricAlert.filters}
            metricFilter.metricName = chartProps.metricName
            metricFilter.aggregation = chartProps.aggregation
            metricFilter.bucketSize = chartProps.bucketSize
            metricFilter.filters = chartProps.filters ? chartProps.filters : new Map()
            metricFilter.excludeFilters = chartProps.excludeFilters ? chartProps.excludeFilters : new Map()
            metricFilter.splits = chartProps.splits ? chartProps.splits : []
            metricFilter.functions = chartProps.functions ? chartProps.functions : []
            metricAlert.filters = metricFilter
            return {...prev, alert: metricAlert}
        })
    }, [chartProps]);


    return <div className={"flex flex-col gap-8"}>
        <div className={"flex flex-col gap-4"}>
            <div className={"flex justify-start gap-2"}>
                <div
                    className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                    2
                </div>
                <div
                    className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                    Select Metric
                </div>
            </div>
            <EmbedMetricSelector chartProps={chartProps}
                                 setChartProps={setChartProps}/>
        </div>
        <EventSelector stepNumber={props.stepNumber}
                       alert={props.alert}
                       setAlert={props.setAlert}

        />
    </div>
}

function KubernetesResourceSelector(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
    stepNumber: number
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const typedKubernetesResourceAlert = props.alert.alert as KubernetesResourceAlert

    function initialChartProps(): MultiMetoroMetricsChartProps {
        return {
            startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
            endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
            metricSpecifiers: [{
                metricName: "kubernetes_resource",
                excludeFilters: typedKubernetesResourceAlert.filters.excludeFilters,
                filters: typedKubernetesResourceAlert.filters.filters,
                splits: typedKubernetesResourceAlert.filters.splits,
                aggregation: typedKubernetesResourceAlert.filters.aggregation != "" ? typedKubernetesResourceAlert.filters.aggregation : "count",
                functions: typedKubernetesResourceAlert.filters.functions,
                metricType: MetricType.Kubernetes,
                bucketSize: typedKubernetesResourceAlert.filters.bucketSize ? typedKubernetesResourceAlert.filters.bucketSize : 60, // Default 1 minute bucket size
                jsonPath: typedKubernetesResourceAlert.filters.jsonPath
            }],
            type: ChartType.Line,
        }
    }

    const [chartProps, setChartProps] = React.useState<MultiMetoroMetricsChartProps>(initialChartProps());


    useEffect(() => {
        setChartProps((prev) => {
            return {
                ...prev,
                startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)
            }
        })
    }, [timeRange]);

    useEffect(() => {
        const annotationProperties = getGraphAnnotationProperties(
            typedKubernetesResourceAlert.monitorEvaluation,
            timeRange,
            typedKubernetesResourceAlert.eventPayload.threshold,
            typedKubernetesResourceAlert.eventPayload.condition as ThresholdComparator)
        const thresholdToSet = annotationProperties.thresholdToSet
        const timePeriodToHighlight = annotationProperties.timePeriodToHighlight

        setChartProps((prev) => {
            return {
                ...prev,
                threshold: thresholdToSet,
                timePeriodHighlight: timePeriodToHighlight,
                thresholdLabel: annotationProperties.annotationLabel
            }
        })
    }, [typedKubernetesResourceAlert.monitorEvaluation.monitorEvaluationType,
        typedKubernetesResourceAlert.monitorEvaluation.monitorEvalutionPayload,
        typedKubernetesResourceAlert.eventPayload.threshold,
        typedKubernetesResourceAlert.eventPayload.condition,
        typedKubernetesResourceAlert.filters.aggregation]);

    useEffect(() => {
        props.setAlert((prev) => {
            let kubernetesAlert = prev.alert as KubernetesResourceAlert
            let kubernetesFilter = {...kubernetesAlert.filters}
            kubernetesFilter.aggregation = chartProps.metricSpecifiers[0].aggregation
            kubernetesFilter.bucketSize = chartProps.metricSpecifiers[0].bucketSize
            kubernetesFilter.filters = chartProps.metricSpecifiers[0].filters ? chartProps.metricSpecifiers[0].filters : new Map()
            kubernetesFilter.excludeFilters = chartProps.metricSpecifiers[0].excludeFilters ? chartProps.metricSpecifiers[0].excludeFilters : new Map()
            kubernetesFilter.splits = chartProps.metricSpecifiers[0].splits ? chartProps.metricSpecifiers[0].splits : []
            kubernetesFilter.functions = chartProps.metricSpecifiers[0].functions ? chartProps.metricSpecifiers[0].functions : []
            kubernetesAlert.filters = kubernetesFilter
            kubernetesFilter.jsonPath = chartProps.metricSpecifiers[0].jsonPath

            return {...prev, alert: kubernetesAlert}
        })
    }, [chartProps]);


    return <div className={"flex flex-col gap-8"}>
        <div className={"flex flex-col gap-4"}>
            <div className={"flex justify-start gap-2"}>
                <div
                    className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                    2
                </div>
                <div
                    className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                    Select Kubernetes Resource Attributes
                </div>
            </div>
            <EmbedMultiMetricSelector chartProps={chartProps}
                                      setChartProps={setChartProps}/>
        </div>
        <EventSelector stepNumber={props.stepNumber}
                       alert={props.alert}
                       setAlert={props.setAlert}
        />
    </div>
}


function TraceSelector(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
    stepNumber: number
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const typedTraceAlert = props.alert.alert as TraceAlert
    const [chartProps, setChartProps] = React.useState<MetoroMetricsChartProps>(initialChartProps());

    function initialChartProps(): MetoroMetricsChartProps {
        return {
            startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
            endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
            metricName: "container_net_tcp_active_connections",
            excludeFilters: typedTraceAlert.filters.excludeFilters,
            filters: typedTraceAlert.filters.filters,
            splits: typedTraceAlert.filters.splits,
            aggregation: typedTraceAlert.filters.aggregation != "" ? typedTraceAlert.filters.aggregation : "count",
            type: ChartType.Line,
            metricType: MetricType.Trace,
            functions: typedTraceAlert.filters.functions,
            bucketSize: typedTraceAlert.filters.bucketSize ? typedTraceAlert.filters.bucketSize : 60 // Default 1 minute bucket size
        }
    }

    useEffect(() => {
        setChartProps((prev) => {
            return {
                ...prev,
                startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)
            }
        })
    }, [timeRange]);

    useEffect(() => {
        const annotationProperties = getGraphAnnotationProperties(
            typedTraceAlert.monitorEvaluation,
            timeRange,
            typedTraceAlert.eventPayload.threshold,
            typedTraceAlert.eventPayload.condition as ThresholdComparator)
        const thresholdToSet = annotationProperties.thresholdToSet
        const timePeriodToHighlight = annotationProperties.timePeriodToHighlight

        setChartProps((prev) => {
            return {
                ...prev,
                threshold: thresholdToSet,
                timePeriodHighlight: timePeriodToHighlight,
                thresholdLabel: annotationProperties.annotationLabel
            }
        })
    }, [typedTraceAlert.monitorEvaluation.monitorEvaluationType,
        typedTraceAlert.monitorEvaluation.monitorEvalutionPayload,
        typedTraceAlert.eventPayload.threshold,
        typedTraceAlert.eventPayload.condition,
        typedTraceAlert.filters.aggregation]);

    useEffect(() => {
        props.setAlert((prev) => {
            let traceAlert = prev.alert as TraceAlert
            let traceFilter = {...traceAlert.filters}
            traceFilter.aggregation = chartProps.aggregation
            traceFilter.bucketSize = chartProps.bucketSize
            traceFilter.filters = chartProps.filters ? chartProps.filters : new Map()
            traceFilter.excludeFilters = chartProps.excludeFilters ? chartProps.excludeFilters : new Map()
            traceFilter.splits = chartProps.splits ? chartProps.splits : []
            traceFilter.functions = chartProps.functions ? chartProps.functions : []
            traceAlert.filters = traceFilter
            return {...prev, alert: traceAlert}
        })
    }, [chartProps]);


    return <div className={"flex flex-col gap-8"}>
        <div className={"flex flex-col gap-4"}>
            <div className={"flex justify-start gap-2"}>
                <div
                    className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                    2
                </div>
                <div
                    className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                    Select Trace Attributes
                </div>
            </div>
            <EmbedMetricSelector chartProps={chartProps}
                                 setChartProps={setChartProps}/>
        </div>
        <EventSelector stepNumber={props.stepNumber}
                       alert={props.alert}
                       setAlert={props.setAlert}
        />
    </div>
}

function EventSelector(props: {
    stepNumber: number
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
}) {
    const [openGroupBy, setOpenGroupBy] = React.useState(false);
    const [metricAggregateEvaluation, setMetricAggregateEvaluation] = React.useState<Metric>();
    const debouncedUpdateMetricAggregateEvaluation = useDebouncedCallback(updateMetricAggregateEvaluation, 10);
    const startEnd = useSelector(timerange.selectors.getTimeRange).getStartEnd()
    const typedAlert = props.alert.alert as MetricAlert | TraceAlert | KubernetesResourceAlert

    useEffect(() => {
        if (typedAlert.monitorEvaluation.monitorEvaluationType === EvalType.Aggregate) {
            const endTime = Math.floor(startEnd[1].getTime() / 1000)
            const payload = typedAlert.monitorEvaluation.monitorEvalutionPayload as AggregateMonitorEvaluationPayload
            let startTime = Math.floor(startEnd[0].getTime() / 1000)
            if (payload !== undefined) {
                startTime = endTime - payload.window * getWindowUnitSeconds(payload.windowUnit)
            }
            debouncedUpdateMetricAggregateEvaluation(props.alert, startTime, endTime, setMetricAggregateEvaluation);
        }
    }, [props.alert]);

    async function updateMetricAggregateEvaluation(alert: Alert,
                                                   startTime: number,
                                                   endTime: number,
                                                   setMetric: (value: (((prevState: (Metric | undefined)) => (Metric | undefined)) | Metric | undefined)) => void) {

        const typedAlert = alert.alert as MetricAlert | TraceAlert | KubernetesResourceAlert
        let filters = typedAlert.filters.filters;
        if (filters === undefined) {
            filters = new Map<string, string[]>();
        }
        let excludeFilters = typedAlert.filters.excludeFilters;
        if (excludeFilters === undefined) {
            excludeFilters = new Map<string, string[]>();
        }

        let splits = typedAlert.filters.splits;
        if (splits === undefined) {
            splits = [];
        }
        try {
            let type = MetricType.Trace
            let metricName = ""
            if (alert.type === AlertType.Metric) {
                type = MetricType.Metric
                metricName = (alert.alert as MetricAlert).filters.metricName
            }
            let jsonPath = undefined
            let aggregateParams = typedAlert.monitorEvaluation.monitorEvalutionPayload!;
            if (alert.type === AlertType.KubernetesResource) {
                type = MetricType.Kubernetes
                jsonPath = (alert.alert as KubernetesResourceAlert).filters.jsonPath
                aggregateParams.jsonPath = jsonPath
            }

            const request = {
                type: type,
                metricName: metricName,
                startTime: startTime,
                endTime: endTime,
                filters: filters,
                excludeFilters: excludeFilters,
                splits: splits,
                aggregation: typedAlert.filters.aggregation,
                isRate: false,
                functions: typedAlert.filters.functions,
                aggregateParams: aggregateParams,
                limitResults: false, // we should never limit results for aggregation. This field is ignored by get aggregation metric endpoint in the backend anyway.
            };
            const awaitedMetrics = await GetAggregateMetricEvaluation(request);
            setMetric(awaitedMetrics.metric);
        } catch (e) {
            console.error(e);
        }
    }

    return <div className={"flex items-start gap-x-32"}>
        <div className={"flex flex-col justify-between"}>
            <div className={"flex justify-start gap-2"}>
                <div
                    className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                    {props.stepNumber}
                </div>
                <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                    Select Alarm Condition
                </div>
            </div>
            <div className={"pl-2 flex flex-col gap-4"}>
                <div className={"flex text-textdark font-semibold items-baseline"}>
                    <div className={"w-[230px]"}>
                        • Select Threshold Type:
                    </div>
                    <div className={"flex gap-2 items-center"}>
                        {
                            monitorEvents.map((event, index) => {
                                return <div key={index}
                                            className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-buttonborder hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", (props.alert.alert as MetricAlert | TraceAlert).monitorEvaluation.monitorEvaluationType === event.monitorEvaluationType || monitorEvents.length === 1 ? "border bg-secondarytransparenter border-secondary" : "")}
                                            onClick={() => {
                                                let typedalert = props.alert.alert as MetricAlert | TraceAlert
                                                typedalert.monitorEvaluation = event
                                                props.setAlert({...props.alert, alert: typedalert})
                                            }}>
                                    <Tooltip delayDuration={10}>
                                        <TooltipTrigger>
                                            {capitalizeFirstLetter(event.monitorEvaluationType)}
                                        </TooltipTrigger>
                                        <TooltipContent side={"right"}
                                                        className={"ml-4 bg-backgroundlight border text-textlight rounded"}>
                                            {event.description}
                                        </TooltipContent>
                                    </Tooltip>
                                </div>
                            })
                        }
                    </div>
                </div>
                <AlarmEvaluationPropertySelector alert={props.alert} setAlert={props.setAlert}/>
                <AlarmConditionSelector alert={props.alert}
                                        setAlert={props.setAlert}
                />
            </div>
        </div>
        {typedAlert.monitorEvaluation.monitorEvaluationType === EvalType.Aggregate &&
            metricAggregateEvaluation !== undefined &&
            <AggregateEvaluationTable threshold={(props.alert.alert as MetricAlert | TraceAlert).eventPayload.threshold}
                                      thresholdComparator={(props.alert.alert as MetricAlert | TraceAlert).eventPayload.condition as ThresholdComparator}
                                      metric={metricAggregateEvaluation}/>}
    </div>
}

function AlarmConditionSelector(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>,
}) {
    const [thresholdComparator, setThresholdComparator] = React.useState((props.alert.alert as MetricAlert | TraceAlert | KubernetesResourceAlert).eventPayload.condition)
    const [thresholdValue, setThresholdValue] = React.useState((props.alert.alert as MetricAlert | TraceAlert | KubernetesResourceAlert).eventPayload.threshold)
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            thresholdValue: thresholdValue,
        },
    });

    useEffect(() => {
        props.setAlert((prev) => {
            let metricAlert = prev.alert as MetricAlert | TraceAlert
            metricAlert.eventPayload = {condition: thresholdComparator, threshold: thresholdValue}
            return {...prev, alert: metricAlert}
        })
    }, [thresholdComparator, thresholdValue]);

    function onSubmit(data: z.infer<typeof FormSchema>) {
        setThresholdValue(data.thresholdValue)
    }

    function getPlaceholderString() {
        switch (thresholdComparator) {
            case ThresholdComparator.GreaterThan:
                return "Greater than..."
            case ThresholdComparator.GreaterThanOrEqual:
                return "Greater than or equal to..."
            case ThresholdComparator.LessThanOrEqual:
                return "Lower than or equal to..."
            case ThresholdComparator.LessThan:
                return "Lower than..."
        }
    }

    return <div className={"flex flex-col gap-4"}>
        <div className={"flex items-baseline"}>
            <div className={"text-textdark font-semibold text-nowrap w-[230px]"}> • Select Threshold Comparator:
            </div>
            <div className={"flex gap-2 justify-start items-start text-center grow shrink"}>
                <Button
                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", thresholdComparator === ThresholdComparator.GreaterThan ? " bg-secondarytransparenter border-secondary" : "bg-backgroundmedium")}
                    onClick={() => {
                        setThresholdComparator(ThresholdComparator.GreaterThan)
                    }}>
                    Greater &gt;
                </Button>
                <Button
                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", thresholdComparator === ThresholdComparator.GreaterThanOrEqual ? " bg-secondarytransparenter border-secondary" : "bg-backgroundmedium")}
                    onClick={() => {
                        setThresholdComparator(ThresholdComparator.GreaterThanOrEqual)
                    }}>
                    Greater / Equal &gt;=
                </Button>
                <Button
                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", thresholdComparator === ThresholdComparator.LessThanOrEqual ? " bg-secondarytransparenter border-secondary" : "bg-backgroundmedium")}
                    onClick={() => {
                        setThresholdComparator(ThresholdComparator.LessThanOrEqual)
                    }}>
                    Lower / Equal &lt;=
                </Button>
                <Button
                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", thresholdComparator === ThresholdComparator.LessThan ? " bg-secondarytransparenter border-secondary" : "bg-backgroundmedium")}
                    onClick={() => {
                        setThresholdComparator(ThresholdComparator.LessThan)
                    }}>
                    Lower &lt;
                </Button>
            </div>
        </div>
        <div className={"flex items-baseline"}>
            <div className={"text-textdark font-semibold w-[230px]"}> • Define Threshold Value:</div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} onBlur={form.handleSubmit(onSubmit)}
                      className="w-2/3 space-y-6">
                    <FormField
                        control={form.control}
                        name="thresholdValue"
                        render={({field}) => (
                            <FormItem>
                                <FormControl>
                                    <Input type={"number"}
                                           onWheel={(e) => e.currentTarget.blur()}
                                           className={"bg-backgroundmedium border-border text-textmedium border rounded w-[514px]"}
                                           placeholder={`${getPlaceholderString()}`} {...field} />
                                </FormControl>
                                <FormDescription className={"text-textdark"}>
                                    Must be a number
                                </FormDescription>
                                <FormMessage className={"text-red-500"}/>
                            </FormItem>
                        )}
                    />
                </form>
            </Form>
        </div>
    </div>
}


interface AggragateDataPoint {
    keys: Map<string, string>
    value: number
}

function AggregateEvaluationTable(props: {
    metric: Metric,
    threshold: number,
    thresholdComparator: ThresholdComparator
}) {
    if (props.metric.timeSeries === undefined || props.metric.timeSeries.length === 0) {
        return
    }
    // @ts-ignore
    const headers: string[] = props.metric.timeSeries[0].attributes ? Array.from(new Map(Object.entries(props.metric.timeSeries[0].attributes)).keys()) : []
    // @ts-ignore
    const values: AggragateDataPoint[] = props.metric.timeSeries.map((timeSeries) => {
        return {
            keys: new Map(Object.entries(timeSeries.attributes)),
            value: timeSeries.data[0].value
        }
    })

    function isValueBreaching(value: number) {
        if (value === null) {
            return false
        }
        switch (props.thresholdComparator) {
            case ThresholdComparator.GreaterThan:
                return value > props.threshold
            case ThresholdComparator.GreaterThanOrEqual:
                return value >= props.threshold
            case ThresholdComparator.LessThanOrEqual:
                return value <= props.threshold
            case ThresholdComparator.LessThan:
                return value < props.threshold
        }
    }

    return <div
        className={cn("min-w-0 bg-backgroundmedium border rounded min-h-0 flex flex-col gap-2 max-h-[261px] overflow-y-auto max-w-[700px]", headers.length == 0 ? " w-max" : " grow shrink")}>
        <div className={"flex justify-end border-b"}>
            <div
                className={"w-full flex-none h-[32px] text-center py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex grow shrink"}>
                <div
                    className={"h-full w-[132px] flex-none justify-between text-textmedium font-semibold"}> Evaluation
                    Value
                </div>
                {headers.map((header, index) => {
                    return <div
                        className={"w-[250px] h-full flex justify-start text-textmedium font-semibold"}> {header}</div>
                })}
            </div>
        </div>
        <div
            className={"h-full max-w-full min-w-0 min-h-0 overflow-y-auto scrollMedium flex flex-col grow shrink gap-1"}>
            {values.map((value, index) => {
                return <div
                    className={cn("px-1 w-full h-full justify-start items-center flex overflow-x-clip gap-4", isValueBreaching(value.value) ? "border border-red-500 bg-red-500/10" : "")}>
                    <div
                        className={"h-full w-[128px] flex-none justify-start text-center text-textmedium"}> {value.value != null ? value.value.toFixed(2) : "no value"}
                    </div>
                    {headers.map((header, index) => {
                        return <div
                            className={cn("h-full w-max flex text-center justify-start text-textmedium truncate", headers.length <= 1 ? "w-max" : "w-[250px]")}> {formatFilterValues(header, value.keys.get(header) != undefined ? value.keys.get(header)! : "")}</div>
                    })}
                </div>
            })}
        </div>
    </div>
}

function AlarmEvaluationPropertySelector(props: { alert: Alert, setAlert: Dispatch<SetStateAction<Alert>> }) {
    const typedAlert = props.alert.alert as MetricAlert | TraceAlert
    if (typedAlert.monitorEvaluation.monitorEvaluationType === EvalType.Static) {
        return <StaticAlarmEvaluationProperties alert={props.alert} setAlert={props.setAlert}/>
    }
    if (typedAlert.monitorEvaluation.monitorEvaluationType === EvalType.Aggregate) {
        return <AggregateAlarmEvaluationProperties alert={props.alert} setAlert={props.setAlert}/>
    }
    return <></>
}

function StaticAlarmEvaluationProperties(props: { alert: Alert, setAlert: Dispatch<SetStateAction<Alert>> }) {
    const typedAlert = props.alert.alert as MetricAlert | TraceAlert;
    const typedMonitorEvaluationPayload = typedAlert.monitorEvaluation.monitorEvalutionPayload as StaticMonitorEvaluationPayload;

    useEffect(() => {
        if (typedMonitorEvaluationPayload.evaluationWindow === undefined || typedMonitorEvaluationPayload.datapointsToAlarm === undefined) {
            props.setAlert((prev) => {
                const newAlert = {...prev};
                const typedNewAlert = newAlert.alert as MetricAlert | TraceAlert;
                typedNewAlert.monitorEvaluation.monitorEvalutionPayload = {
                    ...typedNewAlert.monitorEvaluation.monitorEvalutionPayload,
                    evaluationWindow: 1,
                    datapointsToAlarm: 1,
                };
                return newAlert;
            });
        }
    }, []);

    return <div className={"flex flex-col gap-4"}>
        <div className={"flex text-textdark font-semibold items-baseline"}>
            <div className={"w-[230px]"}>
                • Select Evaluation Method:
            </div>
            <div className={"flex gap-2 items-center"}>
                <div className={"w-max text-nowrap"}>alarm when</div>
                <Input
                    className={"w-[40px] text-center h-full p-1"}
                    type="number"
                    value={(typedAlert.monitorEvaluation.monitorEvalutionPayload as StaticMonitorEvaluationPayload)?.datapointsToAlarm}
                    onWheel={(e) => e.currentTarget.blur()}
                    onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (isNaN(value)) return;

                        props.setAlert(prev => {
                            const newAlert = {...prev};
                            const typedNewAlert = newAlert.alert as MetricAlert | TraceAlert;
                            if (!typedNewAlert.monitorEvaluation.monitorEvalutionPayload) {
                                typedNewAlert.monitorEvaluation.monitorEvalutionPayload = {
                                    missingDatapointBehavior: (typedNewAlert.monitorEvaluation.monitorEvalutionPayload! as StaticMonitorEvaluationPayload).missingDatapointBehavior || MissingDatapointBehavior.NotBreaching,
                                    evaluationWindow: (typedNewAlert.monitorEvaluation.monitorEvalutionPayload! as StaticMonitorEvaluationPayload).evaluationWindow || 1,
                                    datapointsToAlarm: value
                                };
                            } else {
                                (typedNewAlert.monitorEvaluation.monitorEvalutionPayload as StaticMonitorEvaluationPayload).datapointsToAlarm = value;
                            }
                            return newAlert;
                        });
                    }}
                />
                <div className={"w-max text-nowrap"}> out of</div>
                <Input
                    className={"w-[40px] text-center h-full p-1"}
                    type="number"
                    value={(typedAlert.monitorEvaluation.monitorEvalutionPayload as StaticMonitorEvaluationPayload).evaluationWindow}
                    onWheel={(e) => e.currentTarget.blur()}
                    onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (isNaN(value)) return;

                        props.setAlert(prev => {
                            const newAlert = {...prev};
                            const typedNewAlert = newAlert.alert as MetricAlert | TraceAlert;
                            if (!typedNewAlert.monitorEvaluation.monitorEvalutionPayload) {
                                typedNewAlert.monitorEvaluation.monitorEvalutionPayload = {
                                    missingDatapointBehavior: (typedNewAlert.monitorEvaluation.monitorEvalutionPayload! as StaticMonitorEvaluationPayload).missingDatapointBehavior || MissingDatapointBehavior.NotBreaching,
                                    evaluationWindow: value,
                                    datapointsToAlarm: (typedNewAlert.monitorEvaluation.monitorEvalutionPayload! as StaticMonitorEvaluationPayload).datapointsToAlarm || 1
                                };
                            } else {
                                (typedNewAlert.monitorEvaluation.monitorEvalutionPayload as StaticMonitorEvaluationPayload).evaluationWindow = value;
                            }
                            return newAlert;
                        });
                    }}
                />
                <div className={"w-max text-nowrap"}>datapoints breach the threshold.</div>
            </div>
        </div>
        <MissingDataBehaviorSelector alert={props.alert} setAlert={props.setAlert}/>

    </div>
}

function MissingDataBehaviorSelector(props: { alert: Alert, setAlert: Dispatch<SetStateAction<Alert>> }) {
    const typedAlert = props.alert.alert as MetricAlert | TraceAlert
    const missingDatapointOption: DropDownItem[] = [
        {displayName: "breaching the threshold", value: MissingDatapointBehavior.Breaching},
        {displayName: "not breaching the threshold", value: MissingDatapointBehavior.NotBreaching}]
    const typedMonitorEvaluationPayload = typedAlert.monitorEvaluation.monitorEvalutionPayload as StaticMonitorEvaluationPayload
    const [missingDataBehavior, setMissingDataBehavior] = React.useState(missingDatapointOption.find((item) => item.value === typedMonitorEvaluationPayload.missingDatapointBehavior) || missingDatapointOption[0])

    useEffect(() => {
        props.setAlert((prev) => {
            const typedAlert = prev.alert as MetricAlert | TraceAlert;
            const typedMonitorEvaluationPayload = typedAlert.monitorEvaluation.monitorEvalutionPayload as StaticMonitorEvaluationPayload;
            typedMonitorEvaluationPayload.missingDatapointBehavior = missingDataBehavior.value as MissingDatapointBehavior;
            typedAlert.monitorEvaluation.monitorEvalutionPayload = typedMonitorEvaluationPayload;
            return {...prev, alert: typedAlert}
        })
    }, [missingDataBehavior]);


    return <div className={"flex text-textdark font-semibold items-baseline"}>
        <div className={"text-textdark font-semibold w-[230px]"}>
            • Select Missing Data Handling:
        </div>
        <div className={"h-[32px]"}>
            <SingleSelectDropdown selectedItemTitle={"Treat missing datapoint as"}
                                  possibleItems={missingDatapointOption}
                                  selectedItem={missingDataBehavior}
                                  setSelectedItem={setMissingDataBehavior}

            />
        </div>
    </div>
}


function AggregateAlarmEvaluationProperties(props: { alert: Alert, setAlert: Dispatch<SetStateAction<Alert>> }) {
    const [openGroupBy, setOpenGroupBy] = React.useState(false);

    const typedAlert = props.alert.alert as MetricAlert | TraceAlert
    const typedMonitorEvaluation = typedAlert.monitorEvaluation.monitorEvalutionPayload as AggregateMonitorEvaluationPayload
    const evaluateDropdown: string[] = []
    if (typedAlert.filters.splits !== undefined) {
        for (const split of typedAlert.filters.splits) {
            evaluateDropdown.push(split)
        }
    }

    let groupByPills: ReactElement[] = [];
    if (typedMonitorEvaluation.evaluationSplits !== undefined && typedMonitorEvaluation.evaluationSplits !== null) {
        typedMonitorEvaluation.evaluationSplits.forEach((split) => {
            groupByPills.push(<GroupByPill key={split} attributeKey={split}
                                           groupBy={typedAlert.filters.splits}
                                           setGroupBy={(groupBy) => {
                                               props.setAlert((prev) => {
                                                   const typedalert = prev.alert as MetricAlert | TraceAlert
                                                   if (typedalert.monitorEvaluation.monitorEvaluationType === EvalType.Aggregate) {
                                                       (typedalert.monitorEvaluation.monitorEvalutionPayload as AggregateMonitorEvaluationPayload).evaluationSplits = groupBy
                                                   }
                                                   return {...prev, alert: typedalert}
                                               })
                                           }}/>
            )
        })
    }

    return <div className={"flex text-textdark font-semibold items-baseline"}>
        <div className={"w-[230px]"}>
            • Select Evaluation Function:
        </div>
        <div className={"flex border rounded"}>
            <div
                className={"min-h-[48px] min-w-[73px] flex flex-col justify-center bg-backgrounddark items-center text-center text-textmedium border-r"}>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <div
                            className={"h-[32px] flex justify-center items-center text-textmedium gap-2 pl-3 pr-2 bg-none"}>
                            <div>{typedMonitorEvaluation ? typedMonitorEvaluation.evaluationFunction : AggregationFunction.Sum}</div>
                            <ChevronDownIcon className={"text-textmedium"}/>
                        </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent side={"bottom"}
                                         className="mt-2 bg-background text-textmedium rounded">
                        <DropdownMenuRadioGroup value={"aggregateFunctions"}
                                                onValueChange={(value) => {
                                                    let typedalert = props.alert.alert as MetricAlert | TraceAlert
                                                    (typedalert.monitorEvaluation.monitorEvalutionPayload as AggregateMonitorEvaluationPayload).evaluationFunction = value
                                                    props.setAlert({...props.alert, alert: typedalert})
                                                }}>
                            {Object.entries(AggregationFunction).map(([key, value], index) => {
                                return <DropdownMenuRadioItem className="hover:bg-backgroundlight"
                                                              key={key}
                                                              value={value}>{value}</DropdownMenuRadioItem>
                            })}
                        </DropdownMenuRadioGroup>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>

            <div className={"flex grow ml-2 bg-backgroundmedium"}>
                <Popover open={openGroupBy} modal={true}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <div
                            className={"flex min-w-[64px] p-2 flex-wrap gap-4 bg-backgroundmedium hover:cursor-pointer text-textdark items-center"}
                            onClick={() => setOpenGroupBy(!openGroupBy)}
                        >
                            {groupByPills.length != 0 && groupByPills}
                            {groupByPills.length == 0 && "Select"}
                        </div>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenGroupBy(false)}
                                    onEscapeKeyDown={() => setOpenGroupBy(false)}
                                    onInteractOutside={() => setOpenGroupBy(false)}
                                    className="p-0 text-textlight bg-backgroundmedium rounded max-w-max"
                    >
                        <Command>
                            <CommandInput id={"free_text_input2"} placeholder={"Group by..."}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty className={"text-textdark text-center p-2"}>No attributes
                                    are selected for this metric. Please use
                                    Group by functionality while selecting a metric
                                    above.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        evaluateDropdown.map((kv, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenGroupBy(false);
                                                props.setAlert((prev) => {
                                                    const typedalert = prev.alert as MetricAlert | TraceAlert
                                                    const typedMonitorEvaluation = typedalert.monitorEvaluation.monitorEvalutionPayload as AggregateMonitorEvaluationPayload
                                                    const newEvaluationSplits = typedMonitorEvaluation.evaluationSplits ? [...typedMonitorEvaluation.evaluationSplits] : [];
                                                    if (!newEvaluationSplits.includes(kv)) {
                                                        newEvaluationSplits.push(kv);
                                                    }
                                                    (typedalert.monitorEvaluation.monitorEvalutionPayload as AggregateMonitorEvaluationPayload).evaluationSplits = newEvaluationSplits
                                                    return {...prev, alert: typedalert}
                                                })
                                            }}>
                                                {kv}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
        </div>
        <div className={"mx-2"}>
            over
        </div>
        <div className={"flex border rounded"}>
            <div className={"flex items-baseline "}>
                <Tooltip delayDuration={30}>
                    <TooltipTrigger>
                        <Input type={"number"} min={0}
                               value={typedMonitorEvaluation.window}
                               onChange={(e) => {
                                   let typedalert = props.alert.alert as MetricAlert | TraceAlert
                                   (typedalert.monitorEvaluation.monitorEvalutionPayload as AggregateMonitorEvaluationPayload).window = parseInt(e.target.value)
                                   props.setAlert({
                                       ...props.alert,
                                       alert: typedAlert
                                   })
                               }}
                               className={"h-[48px] w-[52px] bg-backgroundmedium text-textmedium z-50 border-none"}
                        />
                    </TooltipTrigger>
                    <TooltipContent side={"top"}
                                    className={"bg-backgroundmedium border rounded px-2 py-1 font-normal text-textdark"}>Must
                        be an integer</TooltipContent>
                </Tooltip>
            </div>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <div
                        className={"h-[48px] flex justify-center items-center text-textmedium border-l bg-backgrounddark gap-2 pl-3 pr-2 bg-none border-buttonborder hover:text-textlight hover:border hover:border-secondary cursor-pointer"}>
                        <div>{formatWindowUnit(props.alert)}</div>
                        <ChevronDownIcon className={"text-textmedium"}/>
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent side={"bottom"}
                                     className="w-max bg-backgrounddark text-textmedium rounded">
                    <DropdownMenuRadioGroup value={"aggregateFunctions"}
                                            onValueChange={(value) => {
                                                let typedalert = props.alert.alert as MetricAlert | TraceAlert
                                                (typedalert.monitorEvaluation.monitorEvalutionPayload as AggregateMonitorEvaluationPayload).windowUnit = value as WindowUnit
                                                props.setAlert({
                                                    ...props.alert,
                                                    alert: typedalert
                                                })
                                            }}>
                        {Object.entries(WindowUnit).map(([key, value], index) => {
                            return <DropdownMenuRadioItem
                                className="hover:bg-backgroundlight"
                                key={key}
                                value={value}>{value}</DropdownMenuRadioItem>
                        })}
                    </DropdownMenuRadioGroup>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    </div>
}


function formatWindowUnit(alert: Alert) {
    const typedAlert = alert.alert as MetricAlert | TraceAlert
    const typedMonitorEvaluation = typedAlert.monitorEvaluation.monitorEvalutionPayload as AggregateMonitorEvaluationPayload
    if (typedMonitorEvaluation === undefined) {
        return WindowUnit.Minutes
    } else {
        if (typedMonitorEvaluation.windowUnit === undefined) {
            return WindowUnit.Minutes
        }
        return typedMonitorEvaluation.windowUnit
    }
}

export function getGraphAnnotationProperties(
    monitorEvaluation: MonitorEvaluation,
    timeRange: TimeRange,
    threshold: number,
    thresholdComparator: ThresholdComparator,
): {
    thresholdToSet: Threshold | undefined,
    timePeriodToHighlight
        :
        TimePeriod | undefined
    annotationLabel: string
} {
    let thresholdToSet: Threshold | undefined = undefined
    let timePeriodToHighlight: TimePeriod | undefined = undefined
    let annotationLabel = ""

    if (monitorEvaluation.monitorEvaluationType === EvalType.Aggregate) {
        const aggregateEvaluationPayload = monitorEvaluation.monitorEvalutionPayload as AggregateMonitorEvaluationPayload
        let timeWindow = aggregateEvaluationPayload.window
        let windowUnit = aggregateEvaluationPayload.windowUnit
        let currentEndTime = Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)
        timePeriodToHighlight = {
            start: currentEndTime - (timeWindow * getWindowUnitSeconds(windowUnit)),
            end: currentEndTime
        }
    } else if (monitorEvaluation.monitorEvaluationType === EvalType.Static) { // static threshold
        thresholdToSet = {
            value: String(threshold),
            comparator: thresholdComparator
        }
    }
    return {thresholdToSet, timePeriodToHighlight, annotationLabel}
}

export function getWindowUnitSeconds(windowUnit: WindowUnit) {
    switch (windowUnit) {
        case WindowUnit.Minutes:
            return 60
        case WindowUnit.Hours:
            return 3600
        case WindowUnit.Days:
            return 86400
    }
}