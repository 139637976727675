import React, {useState} from 'react';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '../components/ui/tabs';
import {IssuesTable} from '../components/Issues/IssuesTable';
import {WorkflowsTable} from '../components/Issues/WorkflowsTable';
import {MutedIssuesDialog} from '../components/Issues/MutedIssuesDialog';
import {BaseView} from "./BaseView";
import {Button} from '../components/ui/button';
import {BellOff} from 'lucide-react';
import {useSearchParams} from "react-router-dom";

function Issues() {
    const [showMutedIssues, setShowMutedIssues] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentTab, setCurrentTab] = React.useState<string>(searchParams.get("tab") || "issues");

    React.useEffect(() => {
        const tab = searchParams.get("tab");
        if (tab !== currentTab) {
            setCurrentTab(tab || "issues");
        }
    }, [searchParams]);

    const handleTabChange = (value: string) => {
        setSearchParams(prev => {
            prev.set("tab", value);
            return prev;
        });
    };

    return (
        <BaseView releaseStage={"Alpha"} title={"Issues"} disableClusterSelector={true} disableTimeRange={true}>
            <div className="m-4 min-h-0 flex min-w-0 bg-backgrounddark flex-col grow shrink text-textmedium">
                <Tabs 
                    value={currentTab} 
                    onValueChange={handleTabChange} 
                    className="flex flex-col min-h-0 min-w-0 grow shrink"
                >
                    <div className="flex flex-none justify-center min-h-0 min-w-0 mb-4">
                        <TabsList className="grid grid-cols-2 grid-rows-1 border">
                            <TabsTrigger value="issues" className="grow shrink text-lg">Issues</TabsTrigger>
                            <TabsTrigger value="workflows" className="grow shrink text-lg">Workflows</TabsTrigger>
                        </TabsList>
                    </div>

                    <TabsContent value="issues" className="flex grow shrink flex-col min-h-0 min-w-0 m-0">
                        <div className="flex justify-end mb-4">
                            <Button
                                variant="ghost"
                                size="sm"
                                className="text-textmedium hover:text-textlight"
                                onClick={() => setShowMutedIssues(true)}
                            >
                                <BellOff className="w-4 h-4 mr-1" />
                                View Issue Mutes
                            </Button>
                        </div>
                        <IssuesTable/>
                        <MutedIssuesDialog
                            open={showMutedIssues}
                            onOpenChange={setShowMutedIssues}
                        />
                    </TabsContent>
                    <TabsContent value="workflows" className="flex grow shrink flex-col min-h-0 min-w-0 m-0">
                        <WorkflowsTable/>
                    </TabsContent>
                </Tabs>
            </div>
        </BaseView>
    );
}

export {Issues};
