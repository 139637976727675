import {Table} from "@tanstack/react-table"
import {X, XIcon} from "lucide-react"
import {Datatablefacetedfilter} from "./datatablefacetedfilter"
import {Input} from "../input";
import {Button} from "../button";
import {DataTableViewOptions} from "./datatableviewoptions";

interface DataTableToolbarProps<TData> {
    table: Table<TData>
    textFilteringColumnName?: string
    textFilteringPlaceholder?: string
    filters: { filteringColumn: string, title: string }[]
}

export function DataTableToolbar<TData>({
                                            table,
                                            textFilteringColumnName,
                                            textFilteringPlaceholder,
                                            filters
                                        }: DataTableToolbarProps<TData>) {
    const isFiltered = table.getState().columnFilters.length > 0

    return (
        <div className="flex items-center justify-between">
            <div className="flex flex-1 items-center space-x-2">
                {textFilteringColumnName &&
                <Input
                    placeholder={textFilteringPlaceholder}
                    value={(table.getColumn(textFilteringColumnName)?.getFilterValue() as string) ?? ""}
                    onChange={(event) =>
                        table.getColumn(textFilteringColumnName)?.setFilterValue(event.target.value)
                    }
                    className="h-8 w-[150px] lg:w-[250px]"
                />}
                {filters.map(({filteringColumn, title}) => {
                    return table.getColumn(filteringColumn) &&
                        <Datatablefacetedfilter
                            column={table.getColumn(filteringColumn)!}
                            title={title}
                            options={[...table.getColumn(filteringColumn)!.getFacetedUniqueValues().entries()].map(([key, value]) => {
                                return {label: key, value: key};
                            })}
                        />

                })}
                {isFiltered && (
                    <Button
                        variant="ghost"
                        onClick={() => table.resetColumnFilters()}
                        className="h-8 px-2 lg:px-3 gap-2 border rounded border-red-500 bg-red-500/10"
                    >
                        Reset
                        <XIcon className={"w-4 h-4"}/>
                    </Button>
                )}
            </div>
        </div>
    )
}