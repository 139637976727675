import {useRoutes} from 'react-router-dom';

// project import
import CommonLayout from 'layout/CommonLayout';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import AuthGuard from 'guards/AuthGuard';
import MainLayout from 'layout/MainLayout';
import {ServiceMap} from "../pages/ServiceMap";
import HomePage from "../pages/HomePage";

export default function ThemeRoutes() {
    return useRoutes([
        {
            path: '/',
            element: <CommonLayout layout="blank"/>,
            children: [
                {
                    path: '/',
                    element: (
                        <AuthGuard>
                            <MainLayout/>
                        </AuthGuard>

                    ),
                    children: [
                        {
                            path: '/',
                            element: <HomePage/>
                        },
                    ]
                }
            ]
        },
        LoginRoutes,
        MainRoutes
    ]);
}
