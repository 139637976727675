import React, {useEffect} from 'react'
import {Card, CardContent, CardHeader, CardTitle} from '../components/ui/card'
import {
    AudioLinesIcon,
    BellIcon,
    BookIcon,
    Boxes,
    Cpu,
    Database,
    DollarSignIcon,
    GlobeIcon,
    HomeIcon,
    LineChart,
    Loader2, LogsIcon,
    Network,
    RadarIcon,
    Server,
    Settings,
    Shield,
    ShieldAlertIcon,
    Star,
    Telescope
} from 'lucide-react'
import {FaMapMarked} from "react-icons/fa";
import {TbLayoutDashboard} from "react-icons/tb";
import {IoMdSettings} from "react-icons/io";
import DateDisplay from '../components/DateDisplay'
import {BaseView} from "./BaseView"
import {ScrollArea} from '../components/ui/scroll-area'
import axios from 'utility/customAxios';
import {GetAggregateMetricEvaluation, GetLogMetrics} from "../clients/metoro/metrics";
import {WindowUnit} from "./alerts/MetricAlert";
import {RateFunctionType} from "../components/Dashboarding/widgets/MetricSelector";
import prettyBytes from "pretty-bytes";
import {shortNumericHumanizer} from "../components/ServicePanel/ServicePanel";
import {useSearchParams} from 'react-router-dom';
import {usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";

interface MetricsData {
    services_count: number
    clusters_count: number
    requests_per_second: number
    logs_per_second: number
    metrics_per_second: number
    alerts: number,
    muted_alerts: number,
    issues_by_severity: Map<string, number>,
    nodes_count: number
    cpu: {
        used: number
        total: number
    }
    memory: {
        used: number
        total: number
    }
    network_usage: {
        received: number
        sent: number
    },
    num_pods: number
}

const mockMetrics: MetricsData = {
    services_count: 12,
    clusters_count: 3,
    requests_per_second: 245.6,
    logs_per_second: 1250.8,
    metrics_per_second: 1000,
    alerts: 3,
    muted_alerts: 0,
    issues_by_severity: new Map(Object.entries({
        high: 2,
        medium: 5,
        low: 8
    })),
    nodes_count: 24,
    cpu: {
        used: 48,
        total: 96
    },
    memory: {
        used: 256,
        total: 384
    },
    network_usage: {
        received: 1024,
        sent: 2048
    },
    num_pods: 1000,
}

// Mock data for favorites
const mockFavorites = {
    services: [
        {id: 1, name: 'Authentication Service', status: 'healthy'},
        {id: 2, name: 'Payment Processing', status: 'warning'},
        {id: 3, name: 'User Management', status: 'healthy'},
        {id: 4, name: 'Order Service', status: 'healthy'},
        {id: 5, name: 'Notification Service', status: 'error'},
    ],
    dashboards: [
        {id: 1, name: 'System Overview', description: 'Key system metrics'},
        {id: 2, name: 'Service Health', description: 'Service health metrics'},
        {id: 3, name: 'Error Tracking', description: 'Error monitoring dashboard'},
        {id: 4, name: 'Performance Metrics', description: 'Performance analysis'},
    ]
}

async function getIssuesBySeverity(abortController: AbortController, environments: string[]): Promise<Map<string, number>> {
    const response = await axios.post('/api/v1/issues/list', {
        offset: 0,
        limit: 1000000,
        returnMuted: false,
        open: true
    });

    const issuesBySeverity = new Map<string, number>();

    response.data.issues.forEach((issue: any) => {
        if (issuesBySeverity.has(issue.severity)) {
            issuesBySeverity.set(issue.severity, issuesBySeverity.get(issue.severity)! + 1);
        } else {
            issuesBySeverity.set(issue.severity, 1);
        }
    });

    return issuesBySeverity;
}

async function getNumFiringAlerts(abortController: AbortController, environments: string[]): Promise<number> {
    return axios.get("/api/v1/searchAlerts").then(x => x.data.alerts.map((a: any) => a.status === "FIRING" && !a.muted ? 0 + 1 : 0 + 0).reduce((a: number, b: number) => a + b, 0));
}

async function getMutedAlerts(abortController: AbortController, environments: string[]): Promise<number> {
    return axios.get("/api/v1/searchAlerts").then(x => x.data.alerts.map((a: any) => a.muted ? 0 + 1 : 0 + 0).reduce((a: number, b: number) => a + b, 0));
}

async function getNumClusters(abortController: AbortController, environments: string[]): Promise<number> {
    return axios.get("/api/v1/environments").then(x => x.data.environments.length);
}

async function getTotalMemory(abortController: AbortController, environments: string[]): Promise<number> {
    let filters = new Map<string, string[]>();
    filters.set("Kind", ["Node"]);
    if (environments.length > 0) {
        filters.set("Environment", environments);
    }

    return await GetAggregateMetricEvaluation({
        type: "kubernetes_resource",
        metricName: "num_cores",
        startTime: Math.floor((Date.now() - 3 * 60 * 1000) / 1000),
        endTime: Math.floor((Date.now() - 2 * 60 * 1000) / 1000),
        filters: filters,
        excludeFilters: new Map<string, string[]>(),
        splits: [],
        aggregation: "sum",
        // @ts-ignore
        isRate: false,
        functions: [],
        aggregateParams: {
            evaluationFunction: "max",
            window: 1,
            windowUnit: WindowUnit.Minutes,
            evaluationSplits: [],
            jsonPath: "status.capacity.memory"
        },
        limitResults: false, // we should never limit results for aggregation. This field is ignored by get aggregation metric endpoint in the backend anyway.
    }).then(x => x.metric.timeSeries[0].data[0].value)
}

async function getTotalNetworkSent(abortController: AbortController, environments: string[]): Promise<number> {
    const filters = new Map<string, string[]>();
    if (environments.length > 0) {
        filters.set("environment", environments);
    }

    return await GetAggregateMetricEvaluation({
        type: "metric",
        metricName: "node_net_transmitted_bytes_total",
        startTime: Math.floor((Date.now() - 3 * 60 * 1000) / 1000),
        endTime: Math.floor((Date.now() - 2 * 60 * 1000) / 1000),
        filters: filters,
        excludeFilters: new Map<string, string[]>(),
        splits: [],
        aggregation: "sum",
        // @ts-ignore
        isRate: false,
        functions: [
            {
                id: "1",
                functionType: RateFunctionType.PerSecond
            }
        ],
        aggregateParams: {
            evaluationFunction: "max",
            window: 1,
            windowUnit: WindowUnit.Minutes,
            evaluationSplits: [],
        },
        limitResults: false, // we should never limit results for aggregation. This field is ignored by get aggregation metric endpoint in the backend anyway.
    }).then(x => x.metric.timeSeries[0].data[0].value)
}

async function getTotalMetrics(abortController: AbortController, environments: string[]): Promise<number> {
    return axios.post("/api/v1/metrics/rate", {
        environments: environments
    }).then(x => x.data.ratePerSecond);
}

async function getTotalNetworkReceived(abortController: AbortController, environments: string[]): Promise<number> {
    const filters = new Map<string, string[]>();
    if (environments.length > 0) {
        filters.set("environment", environments);
    }

    return await GetAggregateMetricEvaluation({
        type: "metric",
        metricName: "node_net_received_bytes_total",
        startTime: Math.floor((Date.now() - 3 * 60 * 1000) / 1000),
        endTime: Math.floor((Date.now() - 2 * 60 * 1000) / 1000),
        filters: filters,
        excludeFilters: new Map<string, string[]>(),
        splits: [],
        aggregation: "sum",
        // @ts-ignore
        isRate: false,
        functions: [
            {
                id: "1",
                functionType: RateFunctionType.PerSecond
            }
        ],
        aggregateParams: {
            evaluationFunction: "max",
            window: 1,
            windowUnit: WindowUnit.Minutes,
            evaluationSplits: [],
        },
        limitResults: false, // we should never limit results for aggregation. This field is ignored by get aggregation metric endpoint in the backend anyway.
    }).then(x => x.metric.timeSeries[0].data[0].value)
}

async function getMemoryUsage(abortController: AbortController, environments: string[]): Promise<number> {
    const filters = new Map<string, string[]>();
    if (environments.length > 0) {
        filters.set("environment", environments);
    }

    return await GetAggregateMetricEvaluation({
        type: "metric",
        metricName: "container_resources_memory_rss_bytes",
        startTime: Math.floor((Date.now() - 3 * 60 * 1000) / 1000),
        endTime: Math.floor((Date.now() - 2 * 60 * 1000) / 1000),
        filters: filters,
        excludeFilters: new Map<string, string[]>(),
        splits: [],
        aggregation: "sum",
        // @ts-ignore
        isRate: false,
        functions: [],
        aggregateParams: {
            evaluationFunction: "max",
            window: 1,
            windowUnit: WindowUnit.Minutes,
            evaluationSplits: [],
        },
        limitResults: false, // we should never limit results for aggregation. This field is ignored by get aggregation metric endpoint in the backend anyway.
    }).then(x => x.metric.timeSeries[0].data[0].value)
}

async function getCoreUsage(abortController: AbortController, environments: string[]): Promise<number> {
    const filters = new Map<string, string[]>();
    if (environments.length > 0) {
        filters.set("environment", environments);
    }

    return await GetAggregateMetricEvaluation({
        type: "metric",
        metricName: "container_resources_cpu_usage_seconds_total",
        startTime: Math.floor((Date.now() - 3 * 60 * 1000) / 1000),
        endTime: Math.floor((Date.now() - 2 * 60 * 1000) / 1000),
        filters: filters,
        excludeFilters: new Map<string, string[]>(),
        splits: [],
        aggregation: "sum",
        // @ts-ignore
        isRate: false,
        functions: [{
            id: "1",
            functionType: RateFunctionType.PerSecond
        }],
        aggregateParams: {
            evaluationFunction: "max",
            window: 1,
            windowUnit: WindowUnit.Minutes,
            evaluationSplits: [],
        },
        limitResults: false, // we should never limit results for aggregation. This field is ignored by get aggregation metric endpoint in the backend anyway.
    }).then(x => x.metric.timeSeries[0].data[0].value)
}

async function getNumCores(abortController: AbortController, environments: string[]): Promise<number> {
    let filters = new Map<string, string[]>();
    filters.set("Kind", ["Node"]);
    if (environments.length > 0) {
        filters.set("Environment", environments);
    }

    return await GetAggregateMetricEvaluation({
        type: "kubernetes_resource",
        metricName: "num_cores",
        startTime: Math.floor((Date.now() - 3 * 60 * 1000) / 1000),
        endTime: Math.floor((Date.now() - 2 * 60 * 1000) / 1000),
        filters: filters,
        excludeFilters: new Map<string, string[]>(),
        splits: [],
        aggregation: "sum",
        // @ts-ignore
        isRate: false,
        functions: [],
        aggregateParams: {
            evaluationFunction: "max",
            window: 1,
            windowUnit: WindowUnit.Minutes,
            evaluationSplits: [],
            jsonPath: "status.capacity.cpu"
        },
        limitResults: false, // we should never limit results for aggregation. This field is ignored by get aggregation metric endpoint in the backend anyway.
    }).then(x => x.metric.timeSeries[0].data[0].value);
}

async function getNumNodes(abortController: AbortController, environments: string[]): Promise<number> {
    // We want to get the number for one minute as of 3 minutes ago
    let filters = new Map<string, string[]>();
    filters.set("Kind", ["Node"]);
    if (environments.length > 0) {
        filters.set("Environment", environments);
    }

    let now = Date.now();
    return await GetAggregateMetricEvaluation(
        {
            type: "kubernetes_resource",
            metricName: "num_nodes",
            startTime: Math.floor((now - 3 * 60 * 1000) / 1000),
            endTime: Math.floor((now - 2 * 60 * 1000) / 1000),
            filters: filters,
            excludeFilters: new Map<string, string[]>(),
            splits: [],
            aggregation: "count",
            isRate: false,
            functions: [],
            aggregateParams: {
                evaluationFunction: "max",
                window: 1,
                windowUnit: WindowUnit.Minutes,
                evaluationSplits: [],
            },
            limitResults: false, // we should never limit results for aggregation. This field is ignored by get aggregation metric endpoint in the backend anyway.
        }
    ).then(x => x.metric.timeSeries[0].data[0].value)
}

async function getLogsPerSecond(abortController: AbortController, environments: string[]): Promise<number> {
    const filters = new Map<string, string[]>();
    if (environments.length > 0) {
        filters.set("environment", environments);
    }

    return await GetLogMetrics({
            startTime: Math.floor((Date.now() - 3 * 60 * 1000) / 1000),
            endTime: Math.floor((Date.now() - 2 * 60 * 1000) / 1000),
            filters: filters,
        },
        abortController
    ).then(x => x.metric.timeSeries[0].data.map(x => x.value).reduce((a: number, b: number) => a + b)) / 60;
}

async function getServicesCount(abortController: AbortController, environments: string[]): Promise<number> {
    return axios.get("/api/v1/services").then(x => x.data.services.length);
}

async function getNumPods(abortController: AbortController, environments: string[]): Promise<number> {
    let filters = new Map<string, string[]>();
    filters.set("Kind", ["Pod"]);
    if (environments.length > 0) {
        filters.set("Environment", environments);
    }

    return await GetAggregateMetricEvaluation({
        type: "kubernetes_resource",
        metricName: "num_pods",
        startTime: Math.floor((Date.now() - 5 * 60 * 1000) / 1000),
        endTime: Math.floor((Date.now() - 2 * 60 * 1000) / 1000),
        filters: filters,
        excludeFilters: new Map<string, string[]>(),
        splits: [],
        aggregation: "count",
        // @ts-ignore
        isRate: false,
        functions: [],
        aggregateParams: {
            evaluationFunction: "max",
            window: 1,
            windowUnit: WindowUnit.Minutes,
            evaluationSplits: [],
        },
        limitResults: false, // we should never limit results for aggregation. This field is ignored by get aggregation metric endpoint in the backend anyway.
    }).then(x => x.metric.timeSeries[0].data[0].value);
}

async function getRequestsPerSecond(abortController: AbortController, environments: string[]): Promise<number> {
    const filters = new Map<string, string[]>();
    if (environments.length > 0) {
        filters.set("environment", environments);
    }

    return await GetAggregateMetricEvaluation({
        type: "trace",
        metricName: "request_rate",
        startTime: Math.floor((Date.now() - 3 * 60 * 1000) / 1000),
        endTime: Math.floor((Date.now() - 2 * 60 * 1000) / 1000),
        filters: filters,
        excludeFilters: new Map<string, string[]>(),
        splits: [],
        aggregation: "count",
        isRate: true,
        functions: [],
        aggregateParams: {
            evaluationFunction: "max",
            window: 1,
            windowUnit: WindowUnit.Minutes,
            evaluationSplits: [],
        },
        limitResults: false, // we should never limit results for aggregation. This field is ignored by get aggregation metric endpoint in the backend anyway.
    }).then(x => x.metric.timeSeries[0].data[0].value / 60);
}

async function fetchMetrics(abortController: AbortController, environments: string[]): Promise<MetricsData> {
    try {
        const [
            services_count,
            clusters_count,
            nodes_count,
            requests_per_second,
            logs_per_second,
            alerts,
            mutedAlerts,
            issuesBySeverity,
            numCores,
            totalMemory,
            receivedNetwork,
            sentNetwork,
            totalMetrics,
            coreUsage,
            memoryUsage,
            numPods
        ] = await Promise.all([
            getServicesCount(abortController, environments),
            getNumClusters(abortController, environments),
            getNumNodes(abortController, environments),
            getRequestsPerSecond(abortController, environments),
            getLogsPerSecond(abortController, environments),
            getNumFiringAlerts(abortController, environments),
            getMutedAlerts(abortController, environments),
            getIssuesBySeverity(abortController, environments),
            getNumCores(abortController, environments),
            getTotalMemory(abortController, environments),
            getTotalNetworkReceived(abortController, environments),
            getTotalNetworkSent(abortController, environments),
            getTotalMetrics(abortController, environments),
            getCoreUsage(abortController, environments),
            getMemoryUsage(abortController, environments),
            getNumPods(abortController, environments),

        ])
        return {
            services_count: services_count,
            clusters_count: clusters_count,
            nodes_count: nodes_count,
            requests_per_second: requests_per_second,
            logs_per_second: logs_per_second,
            metrics_per_second: totalMetrics,
            alerts: alerts,
            muted_alerts: mutedAlerts,
            issues_by_severity: issuesBySeverity,
            cpu: {
                used: coreUsage,
                total: numCores
            },
            memory: {
                used: memoryUsage,
                total: totalMemory,
            },
            network_usage: {
                received: receivedNetwork,
                sent: sentNetwork
            },
            num_pods: numPods
        };
    } catch (error) {
        console.error('Error fetching metrics:', error);
        return mockMetrics;
    }
}

const HomePage: React.FC = () => {
    const [orgName, setOrgName] = React.useState('Metoro');
    const [metricsData, setMetricsData] = React.useState<MetricsData | undefined>();
    const [abortController, setAbortController] = React.useState(new AbortController());
    const [searchParams] = useSearchParams();
    const [environments, setEnvironments] = React.useState<string[]>((searchParams.get("environment") && searchParams.get("environment") !== "") ? [searchParams.get("environment")!] : [])
    const navigate = usePreserveQueryParamsNavigate();

    useEffect(() => {
        let newEnv = searchParams.get("environment")
        let current = environments
        if (newEnv === null) {
            return
        }
        if (newEnv === "") {
            if (current.length === 0) {
                return
            }
            setEnvironments([])
            return
        }
        if (current.includes(newEnv)) {
            return
        }
        setEnvironments([newEnv])
    }, [searchParams]);

    React.useEffect(() => {
        // Fetch organization name
        axios.get("/api/v1/account/organization").then(response => {
            setOrgName(response.data.organizationName);
        }).catch(error => {
            console.error('Error fetching organization:', error);
        });

        abortController.abort("cancelled for new request");
        let newAbortController = new AbortController();
        setAbortController(newAbortController)
        // Fetch initial metrics
        fetchMetrics(newAbortController, environments).then(setMetricsData);

        // Refresh metrics every 60 seconds
        const interval = setInterval(() => {
            abortController.abort("cancelled for new request");
            let newAbortController = new AbortController();
            setAbortController(newAbortController)
            // Fetch initial metrics
            fetchMetrics(newAbortController, environments).then(setMetricsData);
        }, 60000);

        return () => clearInterval(interval);
    }, [environments]);

    const welcomeText = 'Metrics';

    const platformMetrics = [
        {
            title: 'Clusters',
            value: metricsData?.clusters_count,
            icon: Server,
            description: 'Active Kubernetes clusters',
            onClick: () => navigate('/infrastructure')
        },
        {
            title: 'Active Alerts',
            value: metricsData?.alerts,
            icon: BellIcon,
            description: metricsData ? `${metricsData.alerts} firing alerts • ${metricsData.muted_alerts} muted alerts` : '',
            status: metricsData ? metricsData?.alerts > 0 ? 'error' : 'success' : undefined,
            onClick: () => navigate('/alerts')
        },
        {
            title: 'Open Issues',
            value: metricsData ? Array.from(metricsData.issues_by_severity.values()).reduce((a, b) => a + b, 0) : undefined,
            icon: RadarIcon,
            description: metricsData ? Array.from(metricsData.issues_by_severity.entries()).reverse().map(([severity, count]) => `${severity} · ${count}`).join(' · ') : '',
            status: metricsData ? metricsData?.issues_by_severity.get('high') && metricsData.issues_by_severity.get('high')! > 0 ? 'error' : metricsData?.issues_by_severity.get('medium') && metricsData.issues_by_severity.get('medium')! > 0 ? 'warning' : 'success' : undefined,
            onClick: () => navigate('/issues')
        },
    ]

    const clusterMetrics = [
        {
            title: 'Services',
            value: metricsData?.services_count,
            icon: Database,
            description: 'Total services monitored',
            onClick: () => navigate('/service-catalog')
        },
        {
            title: 'Requests',
            value: metricsData ? `${shortNumericHumanizer(metricsData.requests_per_second, 0)}/s` : undefined,
            icon: Network,
            description: 'Current request rate',
            onClick: () => navigate('/traces')
        },
        {
            title: 'Logs',
            value: metricsData ? `${shortNumericHumanizer(metricsData.logs_per_second, 0)}/s` : undefined,
            icon: LogsIcon,
            description: 'Current log ingestion rate',
            onClick: () => navigate('/logs')
        }, {
            title: 'Metrics',
            value: metricsData ? `${shortNumericHumanizer(metricsData.metrics_per_second, 0)}/s` : undefined,
            icon: LineChart,
            onClick: () => navigate('/metric-explorer'),
            description: 'Current metric timeseries datapoint ingestion rate',
        },
        {
            title: 'Total Nodes',
            value: metricsData?.nodes_count,
            icon: Server,
            description: 'Active nodes',
            onClick: () => navigate('/infrastructure')
        },
        {
            title: 'CPU Cores',
            value: metricsData ? `${metricsData.cpu.used.toFixed(0)} / ${metricsData.cpu.total}` : undefined,
            icon: Cpu,
            description: metricsData ? `${metricsData.cpu.used.toFixed(0)} Used · ${metricsData.cpu.total} Total` : '',
            status: metricsData ? metricsData?.cpu.used > metricsData.cpu.total * 0.8 ? 'error' : metricsData?.cpu.used > metricsData.cpu.total * 0.6 ? 'warning' : 'success' : undefined,
            onClick: () => navigate('/infrastructure')
        },
        {
            title: 'Memory',
            value: metricsData ? `${prettyBytes(metricsData.memory.used)} / ${prettyBytes(metricsData.memory.total)}` : undefined,
            icon: Database,
            description: metricsData ? `${prettyBytes(metricsData.memory.used)} Used · ${prettyBytes(metricsData.memory.total)} Total` : '',
            status: metricsData ? metricsData?.memory.used > metricsData.memory.total * 0.8 ? 'error' : metricsData?.memory.used > metricsData.memory.total * 0.6 ? 'warning' : 'success' : undefined,
            onClick: () => navigate('/infrastructure')
        },
        {
            title: 'Network Usage',
            value: metricsData ? `${prettyBytes(metricsData.network_usage.sent + metricsData.network_usage.received)}/s` : undefined,
            icon: Network,
            description: metricsData ? `Sent ${prettyBytes(metricsData.network_usage.sent)}/s · Received ${prettyBytes(metricsData.network_usage.received)}/s` : '',
            onClick: () => navigate('/infrastructure')
        }
    ]

    const navigationCards = [
        {
            title: 'Dashboards',
            icon: TbLayoutDashboard,
            description: 'View and manage dashboards',
            onClick: () => navigate('/dashboards')
        },
        {
            title: 'Alerts',
            icon: BellIcon,
            description: 'Manage and view alerts',
            onClick: () => navigate('/alerts')
        },
        {
            title: 'Issues',
            icon: RadarIcon,
            description: 'Track and resolve issues',
            onClick: () => navigate('/issues')
        },
        {
            title: 'Logs',
            icon: BookIcon,
            description: 'Search and analyze logs',
            onClick: () => navigate('/logs')
        },
        {
            title: 'Traces',
            icon: AudioLinesIcon,
            description: 'View distributed traces',
            onClick: () => navigate('/traces')
        },
        {
            title: 'Services',
            icon: GlobeIcon,
            description: 'Browse service catalog',
            onClick: () => navigate('/service-catalog')
        },
        {
            title: 'Service Map',
            icon: FaMapMarked,
            description: 'View service dependencies',
            onClick: () => navigate('/service-map')
        },
        {
            title: 'Infrastructure',
            icon: Boxes,
            description: 'Monitor infrastructure',
            onClick: () => navigate('/infrastructure')
        },
        {
            title: 'K8s Events',
            icon: ShieldAlertIcon,
            description: 'View Kubernetes events',
            onClick: () => navigate('/k8s-events')
        },
        {
            title: 'Metric Explorer',
            icon: Telescope,
            description: 'Explore and analyze metrics',
            onClick: () => navigate('/metric-explorer')
        },
        {
            title: 'Billing',
            icon: DollarSignIcon,
            description: 'View billing information',
            onClick: () => navigate('/billing')
        },
        {
            title: 'Settings',
            icon: IoMdSettings,
            description: 'Configure settings',
            onClick: () => navigate('/settings')
        }
    ]

    return (
        <BaseView title={"Home"} disableTimeRange>
            <div className="flex-1 space-y-4 p-4 md:p-8 pt-6 overflow-y-auto">
                <div className="flex items-center justify-between">
                    <h1 className="text-2xl font-bold tracking-tight">
                        Platform Metrics
                    </h1>
                    <DateDisplay/>
                </div>
                <div>
                    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
                        {platformMetrics.map((metric, index) => (
                            <Card
                                key={index}
                                onClick={metric.onClick}
                                className={`hover:border-primary hover:bg-primarytransparent hover:cursor-pointer transition-all bg-backgroundmedium ${
                                    metric.status === 'error'
                                        ? 'border-red-500 dark:border-red-400'
                                        : metric.status === 'warning'
                                            ? 'border-yellow-500 dark:border-yellow-400'
                                            : ''
                                }`}
                            >
                                <CardContent className="p-6">
                                    <div className="flex items-center justify-between space-x-4">
                                        <div className="flex items-center space-x-4">
                                            <div className="p-2 rounded-full bg-secondary/20">
                                                <metric.icon className="h-5 w-5 text-secondary" aria-hidden="true"/>
                                            </div>
                                            <div className="space-y-1">
                                                <p className="text-sm font-medium leading-none">{metric.title}</p>
                                                <p className="text-xs text-muted-foreground">{metric.description}</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            {metric.value !== undefined && (
                                                <h3 className="text-lg font-semibold tracking-tighter">{metric.value}</h3>
                                            )}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <h1 className="text-2xl font-bold tracking-tight">
                        Cluster Metrics
                    </h1>
                </div>
                <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
                    {clusterMetrics.map((metric, index) => (
                        <Card
                            key={index}
                            onClick={metric.onClick}
                            className={`hover:border-primary hover:bg-primarytransparent hover:cursor-pointer transition-all bg-backgroundmedium ${
                                metric.status === 'error'
                                    ? 'border-red-500 dark:border-red-400'
                                    : metric.status === 'warning'
                                        ? 'border-yellow-500 dark:border-yellow-400'
                                        : ''
                            }`}
                        >
                            <CardContent className="p-6">
                                <div className="flex items-center justify-between space-x-4">
                                    <div className="flex items-center space-x-4">
                                        <div className={`p-2 rounded-full ${
                                            metric.status === 'error'
                                                ? 'bg-red-100 dark:bg-red-900'
                                                : metric.status === 'warning'
                                                    ? 'bg-yellow-100 dark:bg-yellow-900'
                                                    : 'bg-secondary/20'
                                        }`}>
                                            <metric.icon className={`h-5 w-5 ${
                                                metric.status === 'error'
                                                    ? 'text-red-600 dark:text-red-400'
                                                    : metric.status === 'warning'
                                                        ? 'text-yellow-600 dark:text-yellow-400'
                                                        : 'text-secondary-foreground'
                                            }`}/>
                                        </div>
                                        <div className="space-y-1">
                                            <p className="text-sm font-medium text-muted-foreground">
                                                {metric.title}
                                            </p>
                                            <h3 className="text-2xl font-bold tracking-tighter">
                                                {metric.value !== undefined ? metric.value : (
                                                    <Loader2 className="h-6 w-6 animate-spin text-muted-foreground"/>
                                                )}
                                            </h3>
                                            {metric.description && (
                                                <p className="text-xs text-muted-foreground">
                                                    {metric.description}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>

                <div className="mt-8">
                    <h3 className="text-2xl font-bold mb-4">Platform Navigation</h3>
                    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                        {navigationCards.map((card, index) => (
                            <Card
                                key={index}
                                onClick={card.onClick}
                                className="hover:border-primary hover:bg-primarytransparent hover:cursor-pointer transition-all bg-backgroundmedium"
                            >
                                <CardContent className="p-6">
                                    <div className="flex items-center justify-between space-x-4">
                                        <div className="flex items-center space-x-4">
                                            <div className="p-2 rounded-full bg-secondary/20">
                                                {React.createElement(card.icon, {
                                                    className: "h-5 w-5 text-secondary-foreground"
                                                })}
                                            </div>
                                            <div className="space-y-1">
                                                <p className="text-sm font-medium text-muted-foreground">
                                                    {card.title}
                                                </p>
                                                <p className="text-xs text-muted-foreground">
                                                    {card.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>

                {/*/!* Favorites Section *!/*/}
                {/*<div className="grid gap-4 grid-cols-1 md:grid-cols-2">*/}
                {/*    /!* Favorite Services *!/*/}
                {/*    <Card>*/}
                {/*        <CardHeader>*/}
                {/*            <CardTitle className="text-lg font-medium flex items-center gap-2">*/}
                {/*                <Star className="h-5 w-5"/>*/}
                {/*                Favorite Services*/}
                {/*            </CardTitle>*/}
                {/*        </CardHeader>*/}
                {/*        <CardContent>*/}
                {/*            <ScrollArea className="h-[300px]">*/}
                {/*                <div className="space-y-2">*/}
                {/*                    {mockFavorites.services.map((service) => (*/}
                {/*                        <div*/}
                {/*                            key={service.id}*/}
                {/*                            className="flex items-center justify-between p-2 rounded-lg hover:bg-primarytransparent hover:cursor-pointer"*/}
                {/*                        >*/}
                {/*                            <span className="font-medium">{service.name}</span>*/}
                {/*                            <span className={`px-2 py-1 rounded-full text-xs ${*/}
                {/*                                service.status === 'healthy'*/}
                {/*                                    ? 'bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-300'*/}
                {/*                                    : service.status === 'warning'*/}
                {/*                                        ? 'bg-yellow-100 text-yellow-700 dark:bg-yellow-900 dark:text-yellow-300'*/}
                {/*                                        : 'bg-red-100 text-red-700 dark:bg-red-900 dark:text-red-300'*/}
                {/*                            }`}>*/}
                {/*                                {service.status}*/}
                {/*                            </span>*/}
                {/*                        </div>*/}
                {/*                    ))}*/}
                {/*                </div>*/}
                {/*            </ScrollArea>*/}
                {/*        </CardContent>*/}
                {/*    </Card>*/}

                {/*    /!* Favorite Dashboards *!/*/}
                {/*    <Card>*/}
                {/*        <CardHeader>*/}
                {/*            <CardTitle className="text-lg font-medium flex items-center gap-2">*/}
                {/*                <LineChart className="h-5 w-5"/>*/}
                {/*                Favorite Dashboards*/}
                {/*            </CardTitle>*/}
                {/*        </CardHeader>*/}
                {/*        <CardContent>*/}
                {/*            <ScrollArea className="h-[300px]">*/}
                {/*                <div className="space-y-2">*/}
                {/*                    {mockFavorites.dashboards.map((dashboard) => (*/}
                {/*                        <div*/}
                {/*                            key={dashboard.id}*/}
                {/*                            className="p-2 rounded-lg hover:bg-primarytransparent hover:cursor-pointer"*/}
                {/*                        >*/}
                {/*                            <div className="font-medium">{dashboard.name}</div>*/}
                {/*                            <div className="text-sm text-muted-foreground">{dashboard.description}</div>*/}
                {/*                        </div>*/}
                {/*                    ))}*/}
                {/*                </div>*/}
                {/*            </ScrollArea>*/}
                {/*        </CardContent>*/}
                {/*    </Card>*/}
                {/*</div>*/}
            </div>
        </BaseView>
    )
}

export default HomePage
