import React, {ReactElement, useEffect, useState} from "react";
import {MultiMetricChartWidget, OldMetricChartWidget} from "../Dashboarding/internalwidgets";
import {DashboardSearchResponse} from "../../pages/DashboardSearch";
import {DropDownItem, MultiSelectorDropDown} from "../Input/MultiSelectorDropdown/MultiSelectorDropDown";
import {cn, usePreserveQueryParamsNavigate} from "../ui/lib/utils";
import axios from "../../utility/customAxios";
import {Dialog, DialogContent, DialogTitle} from "../ui/dialog";
import {Button} from "../ui/button";
import {useSearchParams} from "react-router-dom";
import {Bar, Line} from "react-chartjs-2";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "../ui/dropdown-menu";
import {EllipsisIcon, InfoIcon} from "lucide-react";
import {dashboardJsonReplacer} from "../Dashboarding/Dashboard";
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import {TimeRange} from "../../types/time";
import {useDispatch, useSelector} from "react-redux";
import timerange, {set} from "../../store/reducers/timerange";
import {DateTimePrecision, getPreferredFormatString} from "../../utility/displayDateTime";
import {shortEnglishHumanizer} from "../ServicePanel/ServicePanel";


function createDashboardUrl(dashboardId: string, newMetricChartWidgetProps: MultiMetricChartWidget): string {
    return "/dashboard?dashboardId=" + dashboardId + "&addChart=" + encodeURIComponent(JSON.stringify(newMetricChartWidgetProps, dashboardJsonReplacer));
}

function AddChartToDialogComponent(props: {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    ChartWidget?: MultiMetricChartWidget,
}) {
    const [dashboards, setDashboards] = React.useState<DashboardSearchResponse | undefined>(undefined);
    const [dashboardSelected, setDashboardSelected] = React.useState<DropDownItem | undefined>();
    const navigate = usePreserveQueryParamsNavigate();

    const selectedItems = dashboardSelected ? [dashboardSelected] : []

    useEffect(() => {
        if (!props.isOpen) {
            return
        }
        axios.get<DashboardSearchResponse>("/api/v1/dashboardSearch").then((response) => {
            setDashboards(response.data)
        })
    }, [props.isOpen])

    return <Dialog open={props.isOpen} onOpenChange={props.setIsOpen}>
        <DialogContent
            className={"text-textmedium w-1/3 min-w-[400px]"}
            onInteractOutside={() =>
                props.setIsOpen(false)
            }>
            <DialogTitle>
                Add chart to dashboard
            </DialogTitle>
            Select a dashboard to add the chart to:
            <MultiSelectorDropDown selectorSingular={"Dashboard"}
                                   possibleItems={
                                       dashboards?.results.map((dashboard) => {
                                           return {
                                               displayName: dashboard.name,
                                               value: dashboard.id
                                           }
                                       }) || []
                                   }
                                   selectedItems={selectedItems}
                                   setSelectedItems={
                                       (selected) => {
                                           if (selected.length > 0) {
                                               setDashboardSelected(selected[0])
                                           } else {
                                               setDashboardSelected(undefined)
                                           }
                                       }
                                   } isSingleSelect={true}/>
            <Button
                className={"border border-primary bg-primarytransparent"}
                disabled={dashboardSelected === undefined}
                onClick={() => {
                    if (dashboardSelected === undefined) {
                        return
                    }
                    const url = createDashboardUrl(dashboardSelected.value, props.ChartWidget!)
                    // If we're currently on the dashboard, we need to refresh the page when we navigate to the new dashboard
                    if (window.location.pathname === "/dashboard") {
                        window.location.href = url
                    } else {
                        navigate(url)
                    }
                }}>
                Add to dashboard
            </Button>
        </DialogContent>
    </Dialog>
}


interface ChartStyling {
    borderless?: boolean;
}

enum ChartType {
    Bar = "bar",
    Line = "line",
}

function ChartContainer(props: {
    createAlertURL?: string,
    editDialog?: ReactElement,
    styling?: ChartStyling,
    className?: string,
    title?: string,
    children: any,
    dataUsed?: any,
    chartType?: ChartType,
    chartOptions?: any,
    hideLegend?: boolean,
    resultsLimited?: boolean,
    actualResultLen?: number,
    showAll?: boolean,
    setShowAll?: React.Dispatch<React.SetStateAction<boolean>>,
    metricChartWidget?: MultiMetricChartWidget | undefined
    setComparisonDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const [selected, setSelected] = useState(props.dataUsed !== undefined && props.dataUsed.datasets !== undefined ? props.dataUsed.datasets : []);
    const [chart, setChart] = useState(props.children);
    const [openAddToDashboardDialog, setOpenAddToDashboardDialog] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = usePreserveQueryParamsNavigate();


    useEffect(() => {
        if (props.dataUsed !== undefined && props.dataUsed.datasets !== undefined) {
            setSelected(props.dataUsed.datasets)
        }
    }, [props.dataUsed]);

    useEffect(() => {
        if (selected == null) {
            setChart(props.children)
        } else {
            const selectedData = {datasets: selected}
            switch (props.chartType) {
                case ChartType.Line:
                    setChart(<Line height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                                   data={selectedData} options={props.chartOptions}/>)
                    break;
                case ChartType.Bar:
                    setChart(<Bar height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                                  data={selectedData} options={props.chartOptions}/>)
                    break;
            }
        }

    }, [selected, props.children]);

    function getColor(currColor: string, item: any, type: string) {
        if (selected.includes(item)) {
            return currColor
        } else {
            if (type === "bg") {
                return "transparent"
            } else {
                return "rgb(30 41 59)"
            }
        }
    }

    let showLegend = props.hideLegend === undefined ? true : !props.hideLegend;
    // handle edge cases for showing legend
    if (props.dataUsed && props.dataUsed.datasets) {
        if (props.dataUsed.datasets.length === 0) {
            showLegend = false
        } else if (props.dataUsed.datasets.length === 1 && props.dataUsed.datasets[0].label == "") {
            showLegend = false
        } else if (props.dataUsed.datasets.length === 1 && props.dataUsed.datasets[0].label != "") {
            const item = props.dataUsed.datasets[0].label
            const splits = item.split("=").filter((d: string) => d !== "")
            if (splits.length < 2) {
                showLegend = false
            }
        }
    }

    let createUrlElipsis =
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <EllipsisIcon className={"hover:bg-primary rounded hover:cursor-pointer"}
                              size={16}/>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-backgroundmedium rounded">
                {props.createAlertURL && <DropdownMenuItem
                    className="text-textmedium hover:text-textlight hover:bg-backgroundlight hover:cursor-pointer">
                    <a
                        onClick={(e) => {
                            navigate(props.createAlertURL!)
                            e.preventDefault()
                        }}
                        href={props.createAlertURL}>Create alert</a>
                </DropdownMenuItem>}
                {props.metricChartWidget && <DropdownMenuItem
                    className="text-textmedium hover:text-textlight hover:bg-backgroundlight hover:cursor-pointer"
                    onClick={() => {
                        setOpenAddToDashboardDialog(true)
                    }}
                >
                    Add to dashboard
                </DropdownMenuItem>}
                {props.metricChartWidget && <DropdownMenuItem
                    className="text-textmedium hover:text-textlight hover:bg-backgroundlight hover:cursor-pointer"
                    onClick={() => {
                        setSearchParams(prev => {
                            let existing = new URLSearchParams(window.location.search)
                            existing.set("fullscreenChart", encodeURIComponent(JSON.stringify(props.metricChartWidget, dashboardJsonReplacer)))
                            return existing
                        })
                    }}
                >
                    Expand
                </DropdownMenuItem>}
                {
                    props.setComparisonDialogOpen &&
                    <DropdownMenuItem
                        className={"text-textmedium hover:text-textlight hover:bg-backgroundlight hover:cursor-pointer"}
                        onClick={() => {
                            if (props.setComparisonDialogOpen != undefined) {
                                props.setComparisonDialogOpen(true)
                            }
                        }}
                    >
                        Compare time periods
                    </DropdownMenuItem>
                }
            </DropdownMenuContent>
        </DropdownMenu>

    return (
        <div className={cn("flex flex-col justify-center min-w-0 min-h-0 grow shrink", props.className)}>
            <AddChartToDialogComponent isOpen={openAddToDashboardDialog} setIsOpen={setOpenAddToDashboardDialog}
                                       ChartWidget={props.metricChartWidget}/>
            <div className={"flex justify-center min-w-0 min-h-0 grow shrink"}>
                <div className={"flex-none flex flex-col min-w-0 min-h-0 grow shrink"}>
                    {((props.title !== undefined && props.title !== "") || (props.editDialog)) &&
                        <div
                            className={cn("min-w-0 min-h-0 h-[24px] bg-backgroundmedium border rounded-t text-sm font-medium text-textmedium leading-[14px] px-2 py-1 truncate flex justify-between draggablehandle", props.styling?.borderless ? "border-none" : "")}>
                            {
                                props.title !== undefined && props.title !== "" &&
                                <div className={"flex flex-col justify-center"}>
                                    {props.title}
                                </div>
                            }
                            {
                                props.editDialog &&
                                <div className={"flex flex-col justify-center"}>
                                    {props.editDialog}
                                </div>
                            }
                            {
                                // Settings drop down if needed
                                props.createAlertURL &&
                                createUrlElipsis
                            }
                        </div>
                    }
                    {
                        !((props.title !== undefined && props.title !== "") || (props.editDialog)) &&
                        <div className={"absolute right-4 mt-1 text-textmedium z-10 mr-1"}>
                            {createUrlElipsis}
                        </div>
                    }
                    <div
                        className={cn((props.title !== undefined && props.title !== "") ? "border-b border-l border-r rounded-b" : "border rounded", "p-1 flex flex-col min-h-0 min-w-0 grow shrink justify-center hover:cursor-crosshair bg-backgroundmedium", props.styling?.borderless ? "border-none" : "", props.className)}
                    >
                        {props.resultsLimited !== undefined && props.resultsLimited && props.actualResultLen !== undefined && props.actualResultLen !== 0 &&
                            <div className={"flex justify-end text-textdark mr-2 hover:cursor-text"}>
                                <div className={"flex items-center gap-1 mr-6"}>
                                    <InfoIcon className={"w-4 h-4"}/>
                                    {props.showAll !== undefined && !props.showAll &&
                                        <div className={"text-sm rounded"}>Showing top 50
                                            of {props.actualResultLen} results.</div>}
                                    {props.showAll !== undefined && !props.showAll && <div
                                        className={"text-sm rounded underline hover:text-primary hover:cursor-pointer"}
                                        onClick={() => {
                                            if (props.setShowAll !== undefined) props.setShowAll(true)
                                        }}>Show all</div>
                                    }
                                </div>
                            </div>}
                        <div
                            className={"p-1 flex min-h-0 min-w-0 grow shrink justify-center hover:cursor-crosshair bg-backgroundmedium border-none"}>
                            {chart}
                        </div>
                        {props.dataUsed && props.dataUsed.datasets && showLegend &&
                            <div id="legend-container"
                                 className={"max-h-[50px] overflow-y-auto ml-4 my-2 no-scrollbar"}>
                                <ul className={"justify-start items-center gap-x-2 inline-flex flex-wrap"}>
                                    {/* Sort by the biggest to smallest*/}
                                    {props.dataUsed.datasets.sort((a: any, b: any) => {
                                        let totalA = a.data.reduce((acc: number, curr: any) => {
                                            return acc + curr.y
                                        }, 0)
                                        let totalB = b.data.reduce((acc: number, curr: any) => {
                                            return acc + curr.y
                                        }, 0)
                                        return totalB - totalA
                                    }).map((item: any, index: number) => {
                                        if (item.label === "") {
                                            return null;
                                        }

                                        // const splits = item.label.split("=").filter((d: string) => d !== "")
                                        // if (splits.length < 2) {
                                        //     item.label += "[not set]"
                                        // }
                                        return (<li key={index}
                                                    className={"flex items-center hover:cursor-pointer hover:bg-backgroundlight hover:text-textlight text-textmedium"}
                                        >
                                        <span className={"border mr-1 w-[13px] h-[13px] inline-block hover:border-2"}
                                              onClick={() => {
                                                  if (selected.includes(item)) {
                                                      setSelected(selected.filter((d: any) => {
                                                          return d !== item
                                                      }))
                                                  } else {
                                                      setSelected([...selected, item])
                                                  }
                                              }}
                                              style={{
                                                  backgroundColor: getColor(item.backgroundColor, item, "bg"),
                                                  borderColor: getColor(item.borderColor, item, "border")
                                              }}></span>
                                            <div className={cn(`text-xs overflow-x-auto text-nowrap no-scrollbar`)}
                                                 onClick={() => {
                                                     if (selected.length === 1 && selected.includes(item)) {
                                                         setSelected(props.dataUsed.datasets)
                                                     } else {
                                                         setSelected([item])
                                                     }
                                                 }}
                                            >{item.label}</div>
                                        </li>)
                                    })}
                                </ul>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

export function getTimeseriesXAxisUnit(startTime: number, endTime: number) {
    let timeRange = (endTime - startTime) / 1000 // ms -> seconds

    if (timeRange < 86400) { // 24 hours
        return "" // default
    }

    if (timeRange < 86400 * 30) { // 30 days
        return "day"
    }

    if (timeRange < 86400 * 30 * 12) { // 1 year
        return "month"
    }

    return "year"
}


Chart.register(zoomPlugin, BarController, PointElement, LineElement, Legend, TimeScale, BarElement, LinearScale, CategoryScale, Title, Tooltip, ChartDataLabels, annotationPlugin, TimeSeriesScale);

function MetoroChart(props: {
    createAlertURL?: string,
    hideOnNoData?: boolean,
    editDialog?: ReactElement,
    className?: string,
    isDuration?: boolean,
    type: ChartType,
    dataToUse: any,
    title?: string,
    styling?: ChartStyling,
    annotations?: any[],
    hideLegend?: boolean,
    resultsLimited?: boolean,
    actualResultLen?: number,
    showAll?: boolean,
    setShowAll?: React.Dispatch<React.SetStateAction<boolean>>,
    metricChartWidget?: MultiMetricChartWidget,
    timeUnit?: string,
    timeUnitDisplay?: string,
    setComparisonDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>,
    setTimeRange?: React.Dispatch<React.SetStateAction<TimeRange>>,
}) {
    const dispatch = useDispatch();
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [timeUnitToUse, setTimeUnitToUse] = useState(props.timeUnit ? props.timeUnit : '');
    const dayTimeFormatterString = getPreferredFormatString([DateTimePrecision.Month, DateTimePrecision.Day, DateTimePrecision.Hours, DateTimePrecision.Minutes])

    useEffect(() => {
        if (props.timeUnit !== undefined && props.timeUnit !== "") {
            setTimeUnitToUse(props.timeUnit!)
        } else {
            setTimeUnitToUse(getTimeseriesXAxisUnit(timeRange.getStartEnd()[0].getTime(), timeRange.getStartEnd()[1].getTime()))
        }
    }, [timeRange]);


    const options =
        {
            maintainAspectRatio: false,
            color: "#C6D3E2",
            plugins: {
                annotation: {
                    annotations: props.annotations ? props.annotations : []
                },
                zoom: {
                    enabled: true,
                    mode: 'x',
                    zoom: {
                        mode: 'x',
                        onZoomComplete: function ({chart}: any) {
                            const {min, max} = chart.scales.x;
                            if (props.setTimeRange !== undefined) {
                                props.setTimeRange(new TimeRange(undefined, new Date(min), new Date(max)))
                            } else {
                                dispatch(set(new TimeRange(undefined, new Date(min), new Date(max))))
                            }
                        },
                        wheel: {
                            enabled: false
                        },
                        pinch: {
                            enabled: false
                        },
                        drag: {
                            enabled: true,
                            backgroundColor: '#ff638433',
                            borderColor: '#ff6384',
                            borderWidth: 1,
                        },
                    },
                    pan: {
                        enabled: false,
                        mode: 'x'
                    }
                },
                tooltip: {
                    intersect: false,
                    borderColor: "#334670",
                    borderWidth: 1,
                    cornerRadius: 2,
                    backgroundColor: "#1D273F",
                    titleColor: "#C6D3E2",
                    bodyColor: "#C6D3E2",
                    titleAlign: "center",
                    bodyAlign: "center",
                    displayColors: false,
                    padding: 8,
                },
                datalabels: {
                    display: false,
                },
                legend: {
                    display: false, // we use custom HTML Legends
                },
            },
            responsive: true,
            scales: {
                x: {
                    grid: {
                        display: false,
                        color: "#334670"
                    },
                    time: {
                        unit: timeUnitToUse,
                        displayFormats: {
                            minute: props.timeUnitDisplay ? props.timeUnitDisplay : 'HH:mm:ss',
                            second: props.timeUnitDisplay ? props.timeUnitDisplay : 'HH:mm:ss',
                            hour: props.timeUnitDisplay ? props.timeUnitDisplay : 'HH:mm:ss',
                            day: props.timeUnitDisplay ? props.timeUnitDisplay : dayTimeFormatterString,
                        },
                    },
                    ticks: {
                        color: "#C6D3E2",
                        autoSkip: true,
                        maxTicksLimit: 4,
                    },
                    type: 'timeseries',
                    stacked: props.type === ChartType.Bar,
                },
                y: {
                    border: {
                        display: false,
                    },
                    display: true,
                    grid: {
                        drawTicks: true,
                        color: "#334670"
                    },
                    ticks: {
                        callback: function (value: number) {
                            return props.isDuration ? shortEnglishHumanizer(value) : value;
                        },
                        color: "#C6D3E2",
                        maxTicksLimit: 5,
                        autoSkip: true,
                    },
                    stacked: props.type === ChartType.Bar,
                    beginAtZero: true,
                }
            }
        }
    let inner = <div></div>
    if (props.dataToUse === undefined || props.dataToUse.datasets.length === 0) {
        if (props.hideOnNoData) {
            return null;
        }
        inner = <div className={"flex justify-center w-full h-full"}>
            <div className={"flex flex-col justify-center"}>
                <div className={"flex justify-center text-textmedium text-sm"}>
                    No data to display
                </div>
            </div>
        </div>
    } else {
        switch (props.type) {
            case ChartType.Line:
                inner = <Line height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                    // @ts-ignore
                              data={props.dataToUse} options={options}/>
                break;
            case ChartType.Bar:
                inner = <Bar height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                    // @ts-ignore
                             data={props.dataToUse} options={options}/>
                break;
        }
    }


    return <ChartContainer
        metricChartWidget={props.metricChartWidget}
        createAlertURL={props.createAlertURL}
        editDialog={props.editDialog} styling={props.styling} className={props.className}
        showAll={props.showAll}
        setShowAll={props.setShowAll}
        resultsLimited={props.resultsLimited}
        actualResultLen={props.actualResultLen}
        dataUsed={props.dataToUse}
        chartType={props.type}
        chartOptions={options}
        hideLegend={props.hideLegend}
        title={props.title}
        setComparisonDialogOpen={props.setComparisonDialogOpen}
        children={
            inner
        }/>
}

export {MetoroChart}
export type {ChartStyling}
export {ChartType};