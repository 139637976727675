import { motion } from "framer-motion";
import logo from "../../assets/images/logo.svg";

export default function AnimatedLogo() {
  return (
    <div className="relative w-32 h-32">
      {/* Outer glow ring */}
      <motion.div
        className="absolute inset-0 rounded-full bg-primarytransparent"
        animate={{
          scale: [1, 1.1, 1],
          opacity: [0.3, 0.7, 0.3],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "linear",
        }}
      />

      {/* Main logo container */}
      <div className="relative w-full h-full">
        {/* Background circles */}
        <motion.div
          className="absolute inset-0 rounded-full bg-primary/20"
          animate={{
            scale: [1, 1.1, 1],
            opacity: [0.5, 0.2, 0.5],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "linear",
          }}
        />

        {/* Static ring */}
        <div className="absolute inset-0 rounded-full border border-primary/20" />

        {/* Logo image */}
        <motion.img
          src={logo}
          alt="Metoro Logo"
          className="absolute inset-0 w-full h-full object-contain p-6"
          style={{ transform: "none" }}
          animate={{
            scale: [1, 1.02, 1],
            opacity: [0.95, 1.0, 0.95],
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            ease: "linear",
          }}
        />

        {/* Pulse effect */}
        <motion.div
          className="absolute inset-0 rounded-full bg-primary/10"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 0, 0.3],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: "linear",
          }}
        />
      </div>
    </div>
  );
}