import {configureStore} from '@reduxjs/toolkit'
import timerange from "./timerange";
import onboarding from "./onboarding";
// @ts-ignore
import ReduxQuerySync from 'redux-query-sync'
import {TimeRange} from "../../types/time";
import {rawHistory} from "../../RootComponent";

export const store = configureStore({
    reducer: {
        timerange: timerange.reducer,
        onboarding: onboarding.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
})

// @ts-ignore
const defaultValue = [undefined, undefined];
ReduxQuerySync({
    store,
    params: {
        last: {
            // @ts-ignore
            selector: state => state.timerange.value.last,
            // @ts-ignore
            action: (value) => {
                if (value === "undefined") {
                    return {"type": "dead"}
                }
                return timerange.actions.set(new TimeRange(value, undefined, undefined))
            },
            defaultValue: '15m',
        },
        startEnd: {
            // @ts-ignore
            selector: state => [state.timerange.value.start, state.timerange.value.end],
            // @ts-ignore
            action: (value) => {
                if (value[0] === undefined || value[1] === undefined) {
                    return {"type": "dead"}
                }
                return timerange.actions.set(new TimeRange(undefined, value[0], value[1]))
            },
            defaultValue: defaultValue,

            stringToValue: (str: string) => {
                if (str === "") {
                    return defaultValue
                }
                let split = str.split(",")
                return [new Date(parseInt(split[0])), new Date(parseInt(split[1]))]
            },
            valueToString: (value: any) => {
                if (value[0] === undefined || value[1] === undefined) {
                    return ""
                }
                return value[0].getTime() + "," + value[1].getTime()
            }
        },
    },
    initialTruth: 'location',

    // Use replaceState so the browser's back/forward button will skip over these page changes.
    replaceState: false,
    history: rawHistory,
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch