"use client"

import {DataTable} from "../ui/datatable/datatable"
import {columns, filters} from "./NodeTableColumns"
import {Node} from "../../pages/Infrastructure"

interface NodeTableNewProps {
    nodes: Node[]
    onRowClick: (node: Node) => void
    isLoading?: boolean
}

export function NodeTableNew({nodes, onRowClick, isLoading}: NodeTableNewProps) {
    return (
        <div className={"w-full"}>
            <DataTable
                columns={columns(onRowClick)}
                data={nodes}
                textFilteringColumnName="name"
                textFilteringPlaceholder="Filter by hostname..."
                filters={filters}
                showPagination={true}
                tableTitle="Nodes"
                isLoading={isLoading}
            />
        </div>
    )
}
