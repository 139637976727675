"use client"

import {ColumnDef} from "@tanstack/react-table"
import {DataTableColumnHeader} from "../ui/datatable/datatablecolumnheader"
import {Progress} from "../ui/progress"
import {Tooltip, TooltipContent, TooltipTrigger} from "../ui/tooltip"
import {InfoIcon} from "lucide-react"
import hdate from "human-date"
import {V1Node} from "@kubernetes/client-node"
import yaml from "js-yaml"
import {Node} from "../../pages/Infrastructure"
import {Badge} from "../ui/badge";
import {statusBadgeColour} from "./PodsTableColumns";

function formatDate(timestamp: number): string {
    if (!timestamp) return "";
    try {
        const date = new Date(timestamp * 1000);
        return hdate.prettyPrint(date, {showTime: true});
    } catch (e) {
        console.error("Error formatting date:", e);
        return "";
    }
}

function getCPUAvailable(nodeSummary: Node): string {
    try {
        const nodeObjs = yaml.load(nodeSummary.yaml) as V1Node;
        return nodeObjs.status?.capacity?.cpu || "";
    } catch {
        return "";
    }
}

function getMemoryAvailable(nodeSummary: Node): string {
    try {
        const nodeObjs = yaml.load(nodeSummary.yaml) as V1Node;
        return nodeObjs.status?.capacity?.memory || "";
    } catch {
        return "";
    }
}

export const columns = (onRowClick: (node: Node) => void): ColumnDef<Node>[] => {
    return [
        {
            id: "name",
            accessorKey: "name",
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Hostname"/>
            ),
            cell: ({row}) => (
                <div className={"flex items-center justify-between"}
                     onClick={() => onRowClick(row.original)}>
                    <span>{row.getValue<string>("name")}</span>
                    <div
                        className={"flex h-max items-center border border-primary hover:text-white hover:cursor-pointer bg-primarytransparent px-2 rounded mr-2"}
                        onClick={(e) => {
                            e.stopPropagation();
                            onRowClick(row.original);
                        }}>
                        View
                    </div>
                </div>
            ),
        },
        {
            id: "status",
            accessorKey: "status",
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Status"/>
            ),
            cell: ({row}) => (
                <Badge className={`${statusBadgeColour(row.original.status)} text-white rounded`}>
                    {row.original.status}
                </Badge>
            ),
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id))
            },
        },
        {
            id: "environment",
            accessorKey: "environment",
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Environment"/>
            ),
            cell: ({row}) => (
                <div className={"text-textmedium"}>
                    {row.getValue<string>("environment")}
                </div>
            ),
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id))
            },
        },
        {
            id: "cpuUtil",
            accessorFn: (row) => row.nodeStats?.cpuUtil || 0,
            header: ({column}) => (
                <div className={"flex gap-2 items-center"}>
                    <Tooltip>
                        <TooltipTrigger><InfoIcon className={"w-3 h-3"}/></TooltipTrigger>
                        <TooltipContent>CPU utilization calculated from the /proc/stat linux subsystem on the
                            node.</TooltipContent>
                    </Tooltip>
                    <DataTableColumnHeader column={column} title="CPU"/>
                </div>
            ),
            cell: ({row}) => {
                const cpuUtil = row.original.nodeStats?.cpuUtil || 0;
                return (
                    <Tooltip delayDuration={30}>
                        <TooltipTrigger>
                            <Progress value={cpuUtil} className="w-[100px] border border-red"/>
                        </TooltipTrigger>
                        <TooltipContent>
                            <div className={"flex flex-col gap-2"}>
                                <div className={"flex gap-2"}>
                                    <div className={"font-semibold"}>CPU Utilisation:</div>
                                    <div>{cpuUtil.toFixed(2)}%</div>
                                </div>
                                <div className={"flex gap-2"}>
                                    <div className={"font-semibold"}>Host Capacity:</div>
                                    <div>{getCPUAvailable(row.original)} cores</div>
                                </div>
                            </div>
                        </TooltipContent>
                    </Tooltip>
                )
            },
        },
        {
            id: "memoryUtil",
            accessorFn: (row) => row.nodeStats?.memoryUtil || 0,
            header: ({column}) => (
                <div className={"flex gap-2 items-center"}>
                    <Tooltip>
                        <TooltipTrigger><InfoIcon className={"w-3 h-3"}/></TooltipTrigger>
                        <TooltipContent>Memory utilization calculated from the /proc/meminfo linux subsystem on the
                            node.</TooltipContent>
                    </Tooltip>
                    <DataTableColumnHeader column={column} title="Memory"/>
                </div>
            ),
            cell: ({row}) => {
                const memoryUtil = row.original.nodeStats?.memoryUtil || 0;
                return (
                    <Tooltip delayDuration={30}>
                        <TooltipTrigger>
                            <Progress value={memoryUtil} className="w-[100px] border border-red"/>
                        </TooltipTrigger>
                        <TooltipContent>
                            <div className={"flex flex-col gap-2"}>
                                <div className={"flex gap-2"}>
                                    <div className={"font-semibold"}>Memory Utilisation (Total - Available)/Total:</div>
                                    <div>{memoryUtil.toFixed(2)}%</div>
                                </div>
                                <div className={"flex gap-2"}>
                                    <div className={"font-semibold"}>Host Capacity:</div>
                                    <div>{getMemoryAvailable(row.original)}</div>
                                </div>
                            </div>
                        </TooltipContent>
                    </Tooltip>
                )
            },
        },
        {
            id: "created",
            accessorFn: (row) => row.creationTimestamp,
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Created"/>
            ),
            cell: ({row}) => {
                const timestamp = row.getValue<number>("created");
                return (
                    <div className={"text-textmedium"}>
                        {formatDate(timestamp)}
                    </div>
                );
            },
        },
    ]
}

export const filters = [
    {
        filteringColumn: "status",
        title: "Status"
    },
    {
        filteringColumn: "environment",
        title: "Environment"
    }
]
