import {BaseView} from "./BaseView";
import React, {useEffect} from "react";
import {Input} from "../components/ui/input";
import {BellOff, BellOffIcon, MailIcon, SearchIcon} from "lucide-react";
import axios from "../utility/customAxios";
import {Button} from "../components/ui/button";
import {AiOutlineKubernetes} from "react-icons/ai";
import {FaSlack} from "react-icons/fa";
import {usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../components/ui/tooltip";

function AlertSearchBar(props: {
    search: string,
    setSearch: (value: (((prevState: string) => string) | string)) => void
}) {
    const navigate = usePreserveQueryParamsNavigate()
    return <div className={"flex grow h-12 gap-4"}>
        <div
            className={"bg-backgroundmedium flex-none grow h-12 px-[7px] py-[9px] focus-within:border-primary hover:cursor-pointer hover:border-primary rounded border justify-between items-center gap-2 inline-flex"}>
            <Input
                placeholder={"Search for alerts"}
                className={"bg-transparent focus-visible:ring-0 border-none border-0 ring-0 outline-0 text-textmedium"}
                value={props.search} onChange={(e) => {
                props.setSearch(e.target.value)
            }}/>
            <SearchIcon className={"text-textdark"}/>
        </div>
        <div className={""}>
            <Button className={"h-12 px-4 bg-secondarytransparenter border border-secondary text-textmedium"}
                    onClick={() => {
                        navigate("/new-alert")
                    }
                    }>Create Alert</Button>
        </div>
    </div>

}

function AlertSearchResults(props: { alerts: AlertSearchResult[] }) {
    const navigate = usePreserveQueryParamsNavigate()

    return <div
        className="mt-4 min-w-0 bg-backgroundmedium border rounded min-h-0 w-full flex flex-col grow shrink overflow-y-auto">
        <div
            className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex border-b">
            <div
                className="h-full flex-none w-[300px] font-normal font-['Inter'] leading-8 text-textmedium text-xl pl-6">Name
            </div>
            <div className="h-full flex-none w-[128px] font-normal leading-8 text-textmedium text-xl">Status
            </div>
            <div className="h-full flex-none w-[128px] font-normal leading-8 text-textmedium text-xl">Type
            </div>
            <div className="h-full flex-none w-[128px] font-normal leading-8 text-textmedium text-xl">Destination
            </div>
            <div
                className="h-full flex grow shrink font-normal leading-8 text-textmedium text-xl max-[975px]:hidden">Description
            </div>
        </div>
        <div className="flex flex-col grow shrink">
            {
                props.alerts.length === 0 ?
                    <div className={"flex grow shrink flex-col gap-4 justify-center text-textdark"}>
                        <div
                            className={"flex justify-center items-center text-2xl text-center"}>No alerts found
                        </div>
                        <div className={"flex justify-center items-center text-lg"}> To create a new alert, use the
                            Create Alert button in the top right.
                        </div>
                    </div> : null
            }
            {props.alerts.map((alert) => {
                return <div
                    onClick={() => {
                        navigate(`/alert?alertId=${alert.uuid}`)
                    }}
                    key={alert.uuid}
                    className="w-full h-max px-4 py-2 border-b border-border hover:cursor-pointer hover:bg-backgrounddark flex justify-start items-center gap-4">
                    <div className={"h-full flex-none w-[300px]"}>
                        <div className={"flex items-center gap-2"}>
                            {alert.muted ?
                                <Tooltip delayDuration={10}>
                                    <TooltipTrigger>
                                        <BellOffIcon className={"w-5 h-5 text-amber-500"}/>
                                    </TooltipTrigger>
                                    <TooltipContent side={"right"} className={"font-semibold bg-backgroundlight text-textmedium text-sm"}>This alert is muted, you will not get notified when it fires. </TooltipContent>
                                </Tooltip>
                                : <div className={"w-5 h-5"}></div>}
                            <div
                                className="h-full flex flex-col justify-center font-normal text-textmedium line-clamp-2">
                                {alert.name}</div>
                        </div>
                    </div>
                    <div className={"h-full flex-none w-[128px]"}>
                        <div
                            className="h-full flex  items-center font-normal leading-8 text-white">{
                            alert.status === "FIRING" ? <div
                                    className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-red-500">Firing</div>
                                :
                                <div
                                    className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-secondaryTransparent">Ok</div>
                        }</div>
                    </div>
                    <div className={"h-full flex-none w-[128px]"}>
                        <div
                            className="h-full flex items-center font-normal leading-8 text-white">
                            {alert.type === "Kubernetes" && <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2">
                                <AiOutlineKubernetes className={"text-white h-4 w-4"}/>Kubernetes
                            </div>}
                            {alert.type === "Log" && <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 bg-blue-500 rounded">
                                Log</div>}
                            {alert.type === "Metric" && <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 bg-blue-500 rounded">
                                Metric</div>}
                            {alert.type === "Trace" && <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 bg-blue-500 rounded">
                                Trace</div>}
                        </div>
                    </div>
                    <div className={"h-full flex-none w-[130px]"}>
                        <div
                            className="h-full flex items-center font-normal leading-8 text-white">
                            <div className={"flex flex-row gap-2 items-center h-max"}>
                                {/* Always show the first destination as a badge */}
                                <div
                                    className="h-full flex-none items-center font-normal font-['Inter'] leading-8 text-white">
                                    {alert.destinations[0] === "Slack" && <div
                                        className="flex w-max justify-center items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-purple-500 gap-2">
                                        <FaSlack className={"text-white h-4 w-4"}/>Slack
                                    </div>}
                                    {alert.destinations[0] === "PagerDuty" && <div
                                        className="flex w-max justify-center items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-[#06AC38] gap-2">
                                        PagerDuty
                                    </div>}
                                    {alert.destinations[0] === "Email" && <div
                                        className="flex w-max justify-center items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-red-400 gap-2">
                                        Email
                                    </div>}
                                    {alert.destinations[0] === "Webhook" && <div
                                        className="flex w-max justify-center items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2">
                                        Webhook
                                    </div>}
                                </div>

                                {/* Show +N circle if there are additional destinations */}
                                {alert.destinations.length > 1 && (
                                    <TooltipProvider delayDuration={10}>
                                        <Tooltip>
                                            <TooltipTrigger>
                                                <div
                                                    className="flex w-[22px] h-[22px] justify-center items-center rounded bg-primarytransparent border border-primary text-textmedium text-xs font-semibold pr-[2px]">
                                                    <div
                                                        className={"flex flex-col justify-center"}>+{alert.destinations.length - 1}</div>
                                                </div>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <div className="flex flex-col gap-1">
                                                    {alert.destinations.slice(1).map((destination, index) => (
                                                        <div key={index} className="text-sm text-white">
                                                            {destination}
                                                        </div>
                                                    ))}
                                                </div>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                )}
                                {alert.destinations.length == 0 &&
                                    <div className="text-sm text-textdark">No destinations</div>}
                            </div>
                        </div>

                    </div>
                    <p className="h-full flex items-center font-normal text-textmedium line-clamp-2 max-[975px]:hidden">{alert.description}
                    </p>
                </div>
            })}
        </div>
    </div>
}

interface AlertSearchResult {
    uuid: string
    name: string
    description: string
    status: string
    type: string
    destinations: string[]
    muted: boolean
}

function AlertsComponent() {
    const [search, setSearch] = React.useState<string>("")
    const [alerts, setAlerts] = React.useState<AlertSearchResult[]>([])

    useEffect(() => {
        let searchParams = ""
        if (search) {
            searchParams = `?query=${search}`
        }
        axios.get(`/api/v1/searchAlerts${searchParams}`).then((response) => {
            setAlerts(response.data.alerts)
        })
    }, [search])

    return <div className={"flex flex-col grow shrink p-4 overflow-y-auto"}>
        <div className={"h-max"}>
            <AlertSearchBar search={search} setSearch={setSearch}/>
        </div>
        <div className={"flex grow shrink min-h-0"}>
            <AlertSearchResults alerts={alerts}/>
        </div>
    </div>

}

const Alerts = () => {
    return <BaseView title={"Alerts"} disableClusterSelector={true}>
        <AlertsComponent/>
    </BaseView>
}


export {
    Alerts
}