import React, {Dispatch, SetStateAction, useEffect} from "react";
import {Alert} from "../../pages/AlertCreation";
import axios from "../../utility/customAxios";
import {MapIcon, MapPin, PlusIcon, XIcon} from "lucide-react";
import {cn} from "../ui/lib/utils";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "components/ui/command";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger
} from "components/ui/dropdown-menu";
import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import {MultiSelectorInputTags} from "../Input/MultiSelectorInputTags/MultiSelectorInputTags";
import {FunctionType, MathExpressionFunctionType, RateFunctionType} from "../Dashboarding/widgets/MetricSelector";

export enum AlertDestinationType {
    Slack = "Slack",
    PagerDuty = "PagerDuty",
    Email = "Email",
    Webhook = "Webhook",
}

export interface SlackAlertDestination {
    channel: string
    additionalMessage?: string
}

export interface PagerDutyAlertDestination {
    serviceName: string
    serviceId: string
}

export interface EmailAlertDestination {
    emails: string[]
}

export interface WebhookAlertDestination {
    uuid: string
    name: string
}

export interface PagerDutyService {
    id: string
    name: string
}

interface WebhookIntegrationWithUUID {
    uuid: string
    name: string
    url: string
    headers: Map<string, string>
    body: string
}

export interface AlertDestination {
    type: AlertDestinationType
    alertDestination: SlackAlertDestination | PagerDutyAlertDestination | EmailAlertDestination | WebhookAlertDestination
}

export function AlertDestinationsSelector(props: {
    stepNumber: number
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
}) {
    const [openStates, setOpenStates] = React.useState<boolean[]>([]);
    const [isInitialized, setIsInitialized] = React.useState(false);

    // Initialize states for existing destinations
    useEffect(() => {
        if (props.alert.destinations && !isInitialized) {
            setOpenStates(new Array(props.alert.destinations.length).fill(false));
            setIsInitialized(true);
        }
    }, [props.alert.destinations, isInitialized]);

    // Handle state updates for new destinations
    useEffect(() => {
        if (props.alert.destinations && isInitialized) {
            const newLength = props.alert.destinations.length;
            const currentLength = openStates.length;
            
            if (newLength > currentLength) {
                // A new destination was added, set only its state to open
                setOpenStates(prev => {
                    const newStates = [...prev];
                    newStates[newLength - 1] = true;
                    return newStates;
                });
            }
        }
    }, [props.alert.destinations?.length, isInitialized]);

    const handleAddDestination = () => {
        props.setAlert((prev: Alert) => {
            const newDestination = {
                type: AlertDestinationType.Webhook,
                alertDestination: {uuid: "", name: "none"}
            };
            
            if (!prev.destinations || prev.destinations.length === 0) {
                return {
                    ...prev,
                    destinations: [newDestination]
                };
            }

            return {
                ...prev,
                destinations: [...prev.destinations, newDestination]
            };
        });
    };

    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                {props.stepNumber}
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Destination
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center grow"}>
                <div className={"flex flex-col"}>
                    {props.alert.destinations && props.alert.destinations.map((dest, index) => {
                        return <DestinationSelector 
                            key={index}
                            index={index}
                            destination={dest}
                            alert={props.alert}
                            setAlert={props.setAlert}
                            open={openStates[index]}
                            setOpen={(isOpen) => {
                                setOpenStates(prev => {
                                    const newStates = [...prev];
                                    newStates[index] = isOpen;
                                    return newStates;
                                });
                            }}
                        />
                    })}
                    <AddDestinationButton 
                        setAlert={props.setAlert} 
                        alert={props.alert}
                        onAdd={handleAddDestination}
                    />
                </div>
            </div>
        </div>
    </div>
}

function AddDestinationButton(props: { 
    alert: Alert, 
    setAlert: Dispatch<SetStateAction<Alert>>,
    onAdd: () => void 
}) {
    return <div
        className={"text-textmedium border rounded bg-backgrounddark w-max px-2 py-1 hover:cursor-pointer hover:text-white flex items-center gap-1 mt-4"}
        onClick={props.onAdd}>
        <PlusIcon className={"w-4 h-4"}/> Add Destination
    </div>
}

function DestinationSelector(props: {
    index: number,
    destination: AlertDestination,
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>,
    open: boolean,
    setOpen: (isOpen: boolean) => void
}) {
    const [slackIntegrationExists, setSlackIntegrationExists] = React.useState(false)
    const [slackShareToken, setSlackShareToken] = React.useState("")
    const [slackChannels, setSlackChannels] = React.useState<string[]>([])
    const [pagerDutyServices, setPagerDutyServices] = React.useState<PagerDutyService[]>([])
    const [webhooks, setWebhooks] = React.useState<WebhookIntegrationWithUUID[]>([])

    useEffect(() => {
        if (!slackIntegrationExists) {
            return
        }
        axios.get("/api/v1/slack/channels").then((response) => {
            setSlackChannels(response.data.channels)
        }).catch((e) => {
            console.error(e)
        })
    }, [slackIntegrationExists])

    useEffect(() => {
        axios.get("/api/v1/integrationExists?type=slack").then((response) => {
            setSlackIntegrationExists(response.data.exists)
        }).catch((e) => {
            console.error(e)
        })
    }, [])

    useEffect(() => {
        if (slackIntegrationExists) return
        axios.get("/api/v1/slack/shareToken").then((response) => {
            setSlackShareToken(response.data.token)
        }).catch((e) => {
            console.error(e)
        })
    }, [slackIntegrationExists])

    useEffect(() => {
        axios.get("/api/v1/pagerDuty/selectedServices").then((response) => {
            setPagerDutyServices(response.data.services)
        }).catch((e) => {
            console.error(e)
        })
    }, [])

    useEffect(() => {
        axios.get("/api/v1/integrations?type=webhook").then((response) => {
            setWebhooks(response.data)
        }).catch((e) => {
            console.error(e)
        })
    }, [])

    let host = window.location.host;

    return <div className={"flex flex-col w-max"}>
        {props.index != 0 && <div className={"bg-border h-4 w-[2px] ml-2"}></div>}
        <div className={"border rounded group flex"}>
            <DropdownMenu open={props.open} onOpenChange={props.setOpen}>
                <DropdownMenuTrigger asChild>
                    <div
                        className={"flex  border border-secondary bg-secondarytransparenter hover:cursor-pointer hover:text-white"}>
                        <div
                            className={"text-textmedium flex items-center px-2"}>{props.destination.type as AlertDestinationType}</div>
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end"
                                     className="w-[280px] mt-3 bg-background text-textmedium rounded">
                    <DropdownMenuGroup>
                        {Object.entries(AlertDestinationType).map((destType, destValue) => {
                            return <DropdownMenuSub>
                                <DropdownMenuItem
                                    className={"hover:bg-backgroundlight"}
                                    onClick={() => {
                                        props.setAlert((prev: Alert) => {
                                            const prevDestinations = [...prev.destinations];
                                            const currDestinationIndex = prevDestinations.findIndex(
                                                dest => dest === props.destination
                                            );
                                            if (currDestinationIndex === -1) {
                                                return prev;
                                            }
                                            // Replace the destination at the same index to maintain order
                                            prevDestinations[currDestinationIndex] = {
                                                type: destType[1],
                                                alertDestination: {channel: "none"}
                                            };
                                            return {
                                                ...prev,
                                                destinations: prevDestinations
                                            }
                                        })
                                    }}
                                >{destType[1]}</DropdownMenuItem>
                            </DropdownMenuSub>
                        })}
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
            <div className={"w-max border-l"}>
                {props.destination.type === AlertDestinationType.Slack && <SlackDestinationPicker
                    slackChannels={slackChannels}
                    destination={props.destination}
                    alert={props.alert}
                    setAlert={props.setAlert}
                    slackShareToken={slackShareToken}
                />}
                {props.destination.type === AlertDestinationType.PagerDuty && <PagerDutyServicePicker
                    destination={props.destination}
                    alert={props.alert}
                    setAlert={props.setAlert}
                    pagerDutyServices={pagerDutyServices}
                />}
                {props.destination.type === AlertDestinationType.Email && <EmailDestinationPicker
                    destination={props.destination}
                    alert={props.alert}
                    setAlert={props.setAlert}
                />}
                {props.destination.type === AlertDestinationType.Webhook && <WebhookDestinationPicker
                    destination={props.destination}
                    alert={props.alert}
                    setAlert={props.setAlert}
                    webhooks={webhooks}
                />}
            </div>
            <div className={"group-hover:flex group-hover:items-center"}>
                <div
                    onClick={() => {
                        props.setAlert((prev: Alert) => {
                            const updatedDestinations = prev.destinations.filter(
                                dest => dest !== props.destination
                            );
                            return {
                                ...prev,
                                destinations: updatedDestinations
                            }
                        })
                    }}
                    className="flex items-center h-full hover:cursor-pointer hover:text-primary border-l rounded-r text-textmedium">
                    <XIcon/>
                </div>
            </div>
        </div>
    </div>
}


function SlackDestinationPicker(props: {
    destination: AlertDestination,
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>,
    slackChannels: string[],
    slackShareToken: string
}) {

    let host = window.location.host;

    return <div className={"flex h-full items-center justify-center text-textmedium"}>
        <div
            className={"flex items-center h-full border-r bg-backgrounddark px-2"}>
            Notify channel
        </div>
        <Popover>
            <PopoverTrigger asChild
                            className={"h-full flex grow items-center hover:bg-backgroundlight hover:cursor-pointer "}>
                <div className={"flex flex-wrap gap-4 p-2 grow"}>
                    <div className={"text-textmedium"}>
                        {(props.destination.alertDestination as SlackAlertDestination).channel}
                    </div>
                </div>
            </PopoverTrigger>
            <PopoverContent side={"bottom"} avoidCollisions={true}
                            className="p-0 text-textlight bg-backgroundmedium ml-16 w-[40vw]"
            >
                <Command>
                    <CommandInput id={"free_text_input2"}
                                  className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                    <CommandList className={"text-textlight"}>
                        <CommandEmpty className={"m-16 text-center text-textdark"}>
                            <div className={"mb-4"}>No Slack channels found.</div>
                            <div> Please go to Settings &gt; Integrations to add Slack integration to
                                Metoro. Alternatively click the button below to add Slack integration.
                            </div>
                            <div className={"mt-4 h-full w-full flex items-center justify-center text-textmedium"}>
                                <div>
                                    <a href={"https://slack.com/oauth/v2/authorize?client_id=5445777126257.5568817569717&scope=channels:read,chat:write,chat:write.public&user_scope=&redirect_uri=https://" + host + "/slack-redirect&state=" + props.slackShareToken}><img
                                        alt="Add to Slack" height="40" width="139"
                                        src="https://platform.slack-edge.com/img/add_to_slack.png"
                                        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"/></a>
                                </div>
                            </div>
                        </CommandEmpty>
                        <CommandGroup>
                            {
                                props.slackChannels.map((channel, index) => {
                                    return <CommandItem
                                        className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                        key={index} onSelect={() => {
                                        props.setAlert((prev: Alert) => {
                                            let prevDestinations = prev.destinations;
                                            let currDestinationIndex = prevDestinations.findIndex((value, index) => value !== undefined && value.type == props.destination.type);
                                            if (currDestinationIndex == -1) {
                                                return prev;
                                            }
                                            if (prevDestinations[currDestinationIndex] !== undefined) {
                                                prevDestinations[currDestinationIndex].alertDestination = {
                                                    channel: channel
                                                }
                                            }
                                            return {
                                                ...prev,
                                                destinations: prevDestinations
                                            }
                                        })
                                    }}>
                                        {channel}
                                    </CommandItem>
                                })
                            }
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    </div>
}


function PagerDutyServicePicker(props: {
    destination: AlertDestination
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>,
    pagerDutyServices: PagerDutyService[]
}) {
    const [open, setOpen] = React.useState(false)

    return <div className={"flex h-full items-center justify-center text-textmedium"}>
        <div
            className={"h-full text-textmedium flex flex-col justify-center border-r bg-backgrounddark p-2 text-nowrap"}>
            Trigger PagerDuty service
        </div>
        <Popover open={open}>
            <PopoverTrigger asChild className={"h-full flex items-center grow hover:bg-backgroundlight"}>
                <div className={"flex flex-wrap gap-4 p-2 hover:cursor-pointer grow"}
                     onClick={() => setOpen(true)}>
                    <div className={"text-textmedium"}>
                        {(props.destination.alertDestination as PagerDutyAlertDestination).serviceName ? (props.destination.alertDestination as PagerDutyAlertDestination).serviceName : "none"}
                    </div>
                </div>
            </PopoverTrigger>
            <PopoverContent side={"bottom"}
                            avoidCollisions={true}
                            onBlur={() => setOpen(false)}
                            onKeyDown={(e) => {
                                if (e.key === "Escape") {
                                    setOpen(false)
                                }
                            }}
                            className="p-0 text-textlight bg-backgroundmedium w-[50vw] rounded"
            >
                <Command>
                    <CommandInput id={"free_text_input2"}
                                  placeholder={"Search for PagerDuty Service..."}
                                  className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                    <CommandList className={"text-textlight"}>
                        <CommandEmpty className={"m-16 text-center text-textdark"}>
                            <div className={"mb-4"}>
                                No PagerDuty Services found.
                            </div>
                            <div>
                                Please go to Settings &gt; Integrations to add PagerDuty Services to Metoro.
                            </div>
                        </CommandEmpty>
                        <CommandGroup>
                            {
                                props.pagerDutyServices.map((service, index) => {
                                    return <CommandItem
                                        className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                        key={index} onSelect={() => {
                                        props.setAlert((prev: Alert) => {
                                            let prevDestinations = prev.destinations;
                                            let currDestinationIndex = prevDestinations.findIndex((value, index) => value !== undefined && value.type == props.destination.type);
                                            if (currDestinationIndex == -1) {
                                                return prev;
                                            }
                                            if (prevDestinations[currDestinationIndex] !== undefined) {
                                                prevDestinations[currDestinationIndex].alertDestination = {
                                                    serviceName: service.name,
                                                    serviceId: service.id
                                                }
                                            }
                                            return {
                                                ...prev,
                                                destinations: prevDestinations
                                            }
                                        })
                                        setOpen(false)
                                    }}>
                                        {service.name}
                                    </CommandItem>
                                })
                            }
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    </div>
}

function EmailDestinationPicker(props: {
    destination: AlertDestination,
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
}) {
    const [emails, setEmails] = React.useState<string[]>((props.destination.alertDestination as EmailAlertDestination).emails || [])

    useEffect(() => {
        props.setAlert((prev) => {
            let prevDestinations = prev.destinations;
            let currDestinationIndex = prevDestinations.findIndex((value, index) => value !== undefined && value.type == props.destination.type);
            if (currDestinationIndex == -1) {
                return prev;
            }
            if (prevDestinations[currDestinationIndex] !== undefined) {
                prevDestinations[currDestinationIndex].alertDestination = {
                    emails: emails
                }
            }
            return {
                ...prev,
                destinations: prevDestinations
            }
        })
    }, [emails]);

    return <div className={"flex h-full items-center justify-center text-textmedium"}>
        <div
            className={"flex items-center h-full border-r bg-backgrounddark  p-2"}>
            Send email to
        </div>
        <MultiSelectorInputTags value={emails} setValues={setEmails} placeholder={"Enter email(s)"}/>
    </div>
}

function WebhookDestinationPicker(props: {
    destination: AlertDestination,
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>,
    webhooks: WebhookIntegrationWithUUID[]
}) {
    const [open, setOpen] = React.useState(false)
    return <div className={"flex h-full items-center justify-center text-textmedium"}>
        <div
            className={"flex items-center h-full border-r bg-backgrounddark px-2"}>
            Call webhook
        </div>
        <Popover open={open}>
            <PopoverTrigger asChild
                            className={"h-full flex grow items-center hover:cursor-pointer hover:bg-backgroundlight "}>
                <div className={"flex flex-wrap gap-4 p-2  grow"}
                     onClick={() => setOpen(true)}>
                    <div className={"text-textmedium"}>
                        {(props.destination.alertDestination as WebhookAlertDestination).name ? (props.destination.alertDestination as WebhookAlertDestination).name : "none"}
                    </div>
                </div>
            </PopoverTrigger>
            <PopoverContent side={"bottom"}
                            avoidCollisions={true}
                            onBlur={() => setOpen(false)}
                            onKeyDown={(e) => {
                                if (e.key === "Escape") {
                                    setOpen(false)
                                }
                            }}
                            className="p-0 text-textlight bg-backgroundmedium w-[50vw] rounded"
            >
                <Command>
                    <CommandInput id={"webhook_search_input"}
                                  placeholder={"Search for webhook..."}
                                  className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                    <CommandList className={"text-textlight"}>
                        <CommandEmpty className={"m-16 text-center text-textdark"}>
                            <div className={"mb-4"}>
                                No webhooks found.
                            </div>
                            <div>
                                Please go to Settings &gt; Integrations to add webhooks to Metoro.
                            </div>
                        </CommandEmpty>
                        <CommandGroup>
                            {props.webhooks && props.webhooks.map((webhook, index) => (
                                <CommandItem
                                    className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                    key={webhook.uuid}
                                    onSelect={() => {
                                        props.setAlert((prev: Alert) => {
                                            let prevDestinations = [...prev.destinations];
                                            let currDestinationIndex = prevDestinations.findIndex(
                                                dest => dest === props.destination
                                            );
                                            if (currDestinationIndex === -1) {
                                                return prev;
                                            }
                                            prevDestinations[currDestinationIndex] = {
                                                ...prevDestinations[currDestinationIndex],
                                                alertDestination: {
                                                    uuid: webhook.uuid,
                                                    name: webhook.name
                                                }
                                            };
                                            return {
                                                ...prev,
                                                destinations: prevDestinations
                                            }
                                        })
                                        setOpen(false)
                                    }}>
                                    {webhook.name}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    </div>
}

const DestinationTypeToText: Map<AlertDestinationType, string> = new Map<AlertDestinationType, string>(
    [
        [AlertDestinationType.Slack, "Notify channel"],
        [AlertDestinationType.PagerDuty, "Trigger PagerDuty service"],
        [AlertDestinationType.Email, "Send email(s)"],
        [AlertDestinationType.Webhook, "Call webhook"]
    ]
);
