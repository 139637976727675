import {GroupWidget, MarkdownWidget, MultiMetricChartWidget, OldMetricChartWidget, Widget} from "../internalwidgets";
import {GridStack} from "gridstack";
import React, {Dispatch, SetStateAction} from "react";
import {v4 as uuidv4} from "uuid";
import {Group} from "./Group";
import {MetricChart} from "./Chart";
import {MarkdownNote} from "./MarkdownNote";
import {RuntimeVariable} from "../Dashboard";

export function WidgetDiv(props: {
    stylesheet?: React.CSSProperties,
    editable: boolean,
    widget: Widget,
    parentGridStack?: GridStack
    grids: Map<string, GridStack>,
    setGrids: Dispatch<SetStateAction<Map<string, GridStack>>>,
    widgets: Map<string, Widget>,
    setWidgets: Dispatch<SetStateAction<Map<string, Widget>>>,
    setRootChildren: Dispatch<SetStateAction<Widget[]>>
    runtimeVariables: Map<string, RuntimeVariable[]>
    setRuntimeVariables: Dispatch<SetStateAction<Map<string, RuntimeVariable[]>>> // For edit
}) {
    let id = "id-" + uuidv4()
    if (props.widget.widgetType === 'Group') {
        let groupWidget = props.widget as GroupWidget
        let group = <Group
            runtimeVariables={props.runtimeVariables}
            setRuntimeVariables={props.setRuntimeVariables}
            stylesheet={props.stylesheet}
            setRootChildren={props.setRootChildren}
            editable={props.editable}
            id={id}
            variables={groupWidget.variables}
            updateParentOfMount={
                (id) => {
                    props.parentGridStack?.makeWidget(id, {
                        x: props.widget.position?.x,
                        y: props.widget.position?.y,
                        w: props.widget.position?.w,
                        h: props.widget.position?.h,
                        id: id
                    })
                }} widget={props.widget as GroupWidget} grids={props.grids}
            setGrids={props.setGrids}
            widgets={props.widgets} setWidgets={props.setWidgets}/>;
        return group
    }
    if (props.widget.widgetType === 'MetricChart') {
        let multiMetricChartWidget: MultiMetricChartWidget
        if (props.widget["aggregation"] !== undefined) {
            // This is an old chart so we need to convert from OldMetricChartWidget to MultiMetricChartWidget
            let oldMetricChartWidget = props.widget as OldMetricChartWidget
            multiMetricChartWidget = {
                widgetType: oldMetricChartWidget.widgetType,
                position: oldMetricChartWidget.position,
                title: oldMetricChartWidget.title,
                type: oldMetricChartWidget.type,
                metricSpecifiers: [{
                    metricName: oldMetricChartWidget.metricName,
                    filters: oldMetricChartWidget.filters,
                    excludeFilters: oldMetricChartWidget.excludeFilters,
                    splits: oldMetricChartWidget.splits,
                    aggregation: oldMetricChartWidget.aggregation,
                    functions: oldMetricChartWidget.functions,
                    metricType: oldMetricChartWidget.metricType
                }]
            }
        } else {
            multiMetricChartWidget = props.widget as MultiMetricChartWidget
        }

        return <MetricChart
            runtimeVariables={props.runtimeVariables}
            setRootChildren={props.setRootChildren}
            editable={props.editable} id={id} widget={multiMetricChartWidget}
            grids={props.grids} setGrids={props.setGrids}
            widgets={props.widgets} setWidgets={props.setWidgets} updateParentOfMount={(id) => {
            props.parentGridStack?.makeWidget(id, {
                x: multiMetricChartWidget.position?.x,
                y: multiMetricChartWidget.position?.y,
                w: multiMetricChartWidget.position?.w,
                h: multiMetricChartWidget.position?.h,
                id: id
            })
        }}/>
    }

    if (props.widget.widgetType === 'Markdown') {
        return <MarkdownNote
            setRootChildren={props.setRootChildren}
            editable={props.editable} id={id} widget={props.widget as MarkdownWidget}
            grids={props.grids} setGrids={props.setGrids}
            widgets={props.widgets} setWidgets={props.setWidgets} updateParentOfMount={(id) => {
            props.parentGridStack?.makeWidget(id, {
                x: props.widget.position?.x,
                y: props.widget.position?.y,
                w: props.widget.position?.w,
                h: props.widget.position?.h,
                id: id
            })
        }}/>
    }

    throw new Error("Unknown widget type")
}