import {BaseView} from "./BaseView";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../components/ui/tabs";
import React, {Dispatch, SetStateAction, useContext, useEffect} from "react";
import axios from "../utility/customAxios";
import {Button} from "../components/ui/button";
import {cn} from "../components/ui/lib/utils";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from "../components/ui/dialog";
import {Input} from "../components/ui/input";
import {Checkbox} from "../components/ui/checkbox";
import {capitalize} from "@mui/material";
import AuthContext from "../contexts/JWTContext";
import {Tooltip, TooltipContent, TooltipTrigger} from "../components/ui/tooltip";
import {KnownService} from "./AlertCreation";
import {InfoIcon, MoreVertical, Webhook, XIcon} from "lucide-react";
import slackLogo from "../assets/images/slackLogo.png"
import pagerDuty from "../assets/images/pagerDuty.png"
import {PagerDutyServices} from "./PagerDutyRedirect";
import {OnboardingFlow} from "./Onboarding";
import SyntaxHighlighter from "react-syntax-highlighter";
import {customTheme} from "../components/K8sInfo/YamlViewer";
import {
    DropDownItem,
    dropDownItemArrayToStringArray,
    MultiSelectorDropDown,
    stringArrayToDropDownItemArray
} from "../components/Input/MultiSelectorDropdown/MultiSelectorDropDown";
import {SingleSelectDropdown} from "../components/Input/SingleSelectDropdown";
import {LoadingSpinner} from "../components/ui/customSpinner";
import {toast} from 'components/ui/use-toast';
import {Separator} from "../components/ui/separator";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger,} from "../components/ui/dropdown-menu"
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../components/ui/select";
import {PagerDutyService} from "../components/Alert/AlertDestination";

function Integrations() {
    return <div className={"m-4"}>
        <h2 className={"text-lg font-bold"}>Integrations</h2>
        <SlackIntegration/>
        <PagerDutyIntegration/>
        <WebhookIntegration/>
    </div>
}

function SlackIntegration() {
    const [slackIntegrationExists, setSlackIntegrationExists] = React.useState(true);
    const [slackShareToken, setSlackShareToken] = React.useState("")

    let host = window.location.host

    useEffect(() => {
        if (slackIntegrationExists) return
        axios.get("/api/v1/slack/shareToken").then((response) => {
            setSlackShareToken(response.data.token)
        }).catch((e) => {
            console.error(e)
        })
    }, [slackIntegrationExists])


    useEffect(() => {
        axios.get("/api/v1/integrationExists?type=slack").then((response) => {
            setSlackIntegrationExists(response.data.exists)
        }).catch((e) => {
            console.error(e)
        })
    }, []);

    return (

        <div className={"mt-4 p-4 bg-backgroundlight border rounded"}>
            <div className={"flex"}>
                <div className={"flex-grow"}>
                    <div className={"flex col"}>
                        <img src={slackLogo} className={"w-8 h-6"}/>
                        <h3 className={"text-lg font-bold"}>Slack</h3>
                    </div>
                    <p className={"text-sm text-textdark"}>Integrate with Slack to allow Metoro to post to slack rooms
                        when alerts
                        fire.</p>
                </div>
                <div className={"h-full"}>
                    {
                        !slackIntegrationExists &&
                        <div className={"justify-center h-full"}>
                            <a href={"https://slack.com/oauth/v2/authorize?client_id=5445777126257.5568817569717&scope=channels:read,chat:write,chat:write.public&user_scope=&redirect_uri=https://" + host + "/slack-redirect&state=" + slackShareToken}
                               className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}
                            >Add to slack</a>
                        </div>
                    }
                    {
                        slackIntegrationExists &&
                        <Button
                            onClick={() => {
                                axios.post("/api/v1/integration/remove?type=slack").then(() => {
                                    setSlackIntegrationExists(false)
                                }).catch((e) => {
                                    console.error(e)
                                })
                            }}
                            className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Disconnect</Button>
                    }
                </div>
            </div>
        </div>
    );
}

function PagerDutyIntegration() {
    const [pagerDutyIntegrationExists, setPagerDutyIntegrationExists] = React.useState(true);
    const [pagerDutyShareToken, setPagerDutyShareToken] = React.useState("")
    const [disconnect, setDisconnect] = React.useState(false)
    const [isEditing, setIsEditing] = React.useState(false)
    const [openViewServices, setOpenViewServices] = React.useState(false)
    const [selectedServices, setSelectedServices] = React.useState<PagerDutyService[]>([])
    const [allServices, setAllServices] = React.useState<PagerDutyService[]>([])
    const [popoverOpen, setPopoverOpen] = React.useState(false)
    const [isSaved, setIsSaved] = React.useState(false)

    let host = window.location.host

    useEffect(() => {
        if (!isEditing) {
            return
        }
        axios.get("/api/v1/pagerDuty/services").then((response) => {
            setAllServices(response.data.services)
        }).catch((e) => {
            console.error(e)
        })
    }, [isEditing]);

    useEffect(() => {
        if (!isSaved) {
            return
        }
        if (selectedServices.length > 0) {
            axios.post("/api/v1/pagerDuty/selectedServices",
                {services: selectedServices}
            ).then((response) => {
                // Redirect to the dashboard
                setOpenViewServices(false)
                setIsSaved(false)
                setIsEditing(false)
                setPopoverOpen(false)
            }).catch((e) => {
                console.error(e)
            })
        }
    }, [isSaved]);

    useEffect(() => {
        if (pagerDutyIntegrationExists) return
        axios.get("/api/v1/pagerDuty/shareToken").then((response) => {
            setPagerDutyShareToken(response.data.token)
        }).catch((e) => {
            console.error(e)
        })
    }, [pagerDutyIntegrationExists])


    useEffect(() => {
        axios.get("/api/v1/integrationExists?type=pagerDuty").then((response) => {
            setPagerDutyIntegrationExists(response.data.exists)
        }).catch((e) => {
            console.error(e)
        })
    }, []);

    useEffect(() => {
        axios.get("/api/v1/pagerDuty/selectedServices")
            .then((response) => {
                setSelectedServices(response.data.services)
            }).catch((e) => {
            console.error(e)
        })
    }, []);

    let protocol = window.location.protocol
    let pagerDutyClientId = "4f78f15d-8395-4302-9706-50e177dd75e7" //for production and dev.
    if (host.includes("localhost")) {
        pagerDutyClientId = "f583ce7e-d07b-4a24-95e9-b2c26a06a725"
    }

    return (
        <div className={"mt-4 p-4 bg-backgroundlight border rounded"}>
            <div className={"flex"}>
                <div className={"flex-grow"}>
                    <div className={"flex col"}>
                        <img src={pagerDuty} className={"w-7 h-7 mr-1"}/>
                        <h3 className={"text-lg font-bold"}>PagerDuty</h3>
                    </div>
                    <p className={"text-sm text-textdark"}>Integrate with PagerDuty to receive pages for Metoro
                        alerts.</p>
                </div>
                <div className={"h-full"}>
                    {
                        !pagerDutyIntegrationExists &&
                        <a href={"https://identity.pagerduty.com/oauth/authorize?client_id=" + pagerDutyClientId + "&response_type=code&scope=write&redirect_uri=" + protocol + "//" + host + "/pagerduty-redirect&state=" + pagerDutyShareToken}
                           className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Connect
                            to PagerDuty</a>
                    }
                    {
                        pagerDutyIntegrationExists &&
                        <div className={"flex gap-2 justify-center items-center"}>
                            <Dialog open={openViewServices}>
                                <DialogTrigger asChild>
                                    <Button
                                        onClick={() => setOpenViewServices(true)}
                                        className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded hover:border-primaryhover"}>
                                        View Services
                                    </Button>
                                </DialogTrigger>
                                <DialogContent className="sm:max-w-[825px] bg-background"
                                               onInteractOutside={() => setOpenViewServices(false)}
                                               onKeyDown={(e) => {
                                                   if (e.key === "Escape") {
                                                       setOpenViewServices(false)
                                                   }
                                               }}>
                                    <DialogHeader>
                                        <div className={"flex justify-between items-center mb-4"}>
                                            <DialogTitle
                                                className={"text-textmedium font-normal text-left items-center"}>
                                                Integrated PagerDuty Services
                                            </DialogTitle>
                                            <XIcon
                                                className={"text-textdark hover:cursor-pointer hover:text-primary justify-end"}
                                                onClick={() => setOpenViewServices(false)}/>
                                        </div>
                                    </DialogHeader>
                                    <div className={"flex justify-between items-center"}>
                                        <DialogDescription className={"text-textdark"}>
                                            These are the PagerDuty services Metoro can send pages to.
                                        </DialogDescription>
                                        {!isEditing && <Button type="submit"
                                                               className={"flex flex-col bg-primarytransparent h-full border hover:border-primaryhover border-primary text-white justify-center items-center rounded"}
                                                               onClick={() => {
                                                                   setIsEditing(true)
                                                               }}>Add Service</Button>}
                                        {isEditing && <Button type="submit"
                                                              className={"flex flex-col bg-primarytransparent h-full border hover:border-primaryhover border-primary text-white justify-center items-center rounded"}
                                                              onClick={() => {
                                                                  setIsSaved(true)
                                                                  setIsEditing(false)
                                                              }}>Save</Button>}
                                    </div>
                                    <div
                                        className="min-w-0 bg-backgroundmedium border rounded min-h-0 w-full flex flex-col grow shrink overflow-y-auto">
                                        <div
                                            className="w-full h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex border-b">
                                            <div
                                                className="h-full w-[100px] font-normal leading-8 text-textmedium text-xl">ID
                                            </div>
                                            <div
                                                className="h-full w-[100px] font-normal leading-8 text-textmedium text-xl">Name
                                            </div>
                                        </div>
                                        {selectedServices.map((service, index) => {
                                            return <div className={"flex justify-end mr-4"}>
                                                <div key={index}
                                                     className="w-full px-4 py-2 border-b border-backgroundmedium hover:bg-backgrounddark flex justify-start items-center gap-4">
                                                    <div
                                                        className="h-full w-[100px] font-normal leading-8 text-textmedium">{service.id}</div>
                                                    <div
                                                        className="h-full w-[100px] font-normal leading-8 text-textmedium">{service.name}</div>
                                                </div>
                                                <div className={"flex items-center justify-center"}>
                                                    <Button onClick={() => {
                                                        setIsEditing(true)
                                                        setSelectedServices((prev) => {
                                                            return prev.filter((value) => value.id !== service.id)
                                                        })
                                                    }}
                                                            className="flex flex-col bg-red-500/20 border border-red-500 text-white justify-center items-center rounded">Remove
                                                    </Button>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    {isEditing && <PagerDutyServices allServices={allServices} open={popoverOpen}
                                                                     setOpen={setPopoverOpen}
                                                                     selectedServices={selectedServices}
                                                                     setSelectedServices={setSelectedServices}/>}
                                </DialogContent>
                            </Dialog>
                            <Dialog open={disconnect}>
                                <DialogTrigger asChild>
                                    <Button onClick={() => setDisconnect(true)}
                                            className={"bg-red-500/20 h-full border border-red-500 text-white flex flex-col justify-center p-2 rounded"}>Disconnect</Button>
                                </DialogTrigger>
                                <DialogContent className="sm:max-w-[825px] bg-background"
                                               onBlur={() => setDisconnect(false)}>
                                    <DialogHeader>

                                        <div className={"flex justify-between items-center mb-4"}>
                                            <DialogTitle className={"text-textmedium font-normal text-center"}>
                                                Are you sure you want to delete PagerDuty integration?
                                            </DialogTitle>
                                            <XIcon
                                                className={"text-textdark hover:cursor-pointer hover:text-primary justify-end"}
                                                onClick={() => setDisconnect(false)}/>
                                        </div>
                                    </DialogHeader>
                                    <DialogDescription className={"text-textdark"}>
                                        You will need to re-add the integration to receive pages for Metoro alerts. If
                                        you
                                        have any alerts that are configured to send pages to PagerDuty, they will no
                                        longer be sent.
                                    </DialogDescription>
                                    <Button type="submit"
                                            className={"flex flex-col bg-red-500/20 h-full border border-red-500 text-textlight justify-end rounded"}
                                            onClick={() => {
                                                axios.post("/api/v1/integration/remove?type=pagerDuty").then(() => {
                                                    setPagerDutyIntegrationExists(false)
                                                    setDisconnect(false)
                                                }).catch((e) => {
                                                    console.error(e)
                                                })
                                            }}>Delete Integration</Button>
                                </DialogContent>
                            </Dialog>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

interface Exclusion {
    id: string
    type: string
    exclusion: string
    serviceName: string
    isGlobal: boolean
}


interface NamespaceFilterArgs {
    namespaces: string[]
    regex: string
}

interface ServiceFilterArgs {
    services: string[]
    regex: string
}

enum FilterType {
    Namespace = "namespace",
    Service = "service"
}

enum IncludeOrExclude {
    Include = "include",
    Exclude = "exclude"
}

// A filter is defined by the following
interface Filter {
    id: string
    // Empty means all environments
    environments: string[]
    // Whether the exclusion is inclusive or exclusive
    inclusionType: IncludeOrExclude
    // The type of exclusion
    filterType: FilterType
    // The args if the exclusion type is a namespace
    namespaceFilterArgs: NamespaceFilterArgs | undefined
    // The args if the exclusion type is a service
    serviceFilterArgs: ServiceFilterArgs | undefined
}

function NewLogFilterModal(
    props: {
        openAddModal: boolean,
        setOpenAddModal: (open: boolean) => void,
        setLogFilters: (logFilters: Filter[]) => void,
        setTriggerReload: () => void
    }
) {
    const [openServiceFilter, setOpenServiceFilter] = React.useState(false)
    const [services, setServices] = React.useState<KnownService[]>([])
    const [namespaces, setNamespaces] = React.useState<string[]>([])

    const [logFilter, setLogFilter] = React.useState<Filter>({
        id: "",
        environments: [],
        inclusionType: IncludeOrExclude.Exclude,
        filterType: FilterType.Service,
        namespaceFilterArgs: undefined,
        serviceFilterArgs: {
            services: [],
            regex: ".*"
        },
    })

    // Services
    const [filters, setFilters] = React.useState<Filter[]>([])

    const [environments, setEnvironments] = React.useState<DropDownItem[]>([])
    useEffect(() => {
        axios.get("/api/v1/environments").then((response) => {
            setEnvironments(stringArrayToDropDownItemArray(response.data.environments))
        }).catch((e) => {
            console.error(e)
        })
    }, [])


    useEffect(() => {
        // Fetch environments
        axios.post("/api/v1/knownServices", {
            environments: logFilter.environments
        }).then((response) => {
            setServices(response.data.services)
        }).catch((e) => {
            console.error(e)
        })
    }, [logFilter.environments])


    // Get the namespaces
    useEffect(() => {
        let url = "/api/v1/namespaces"
        if (logFilter.environments.length > 0) {
            url = url + "?environments=" + logFilter.environments.join(",")
        }
        axios.get(url).then((response) => {
            setNamespaces(response.data.namespaces)
        }).catch((e) => {
            console.error(e)
        })
    }, [logFilter.environments])


    return (
        <Dialog open={props.openAddModal}>
            <DialogContent className={"w-1/2"} onInteractOutside={() => props.setOpenAddModal(false)}>
                <div className={"flex flex-col gap-4 text-textmedium"}>
                    <h3 className={"text-lg font-bold"}>Add a log filter</h3>
                    {/* Button group for inclusion and exclusion*/}
                    <p>
                        1. Select whether you want to include or exclude log lines that match the filter pattern.
                    </p>
                    <div className={"flex gap-4"}>
                        <Button
                            className={
                                cn("border", logFilter.inclusionType === IncludeOrExclude.Exclude ? "bg-secondarytransparenter border-secondary" : "border-primary bg-primarytransparent",)
                            }
                            onClick={() => setLogFilter((prev) => {
                                prev.inclusionType = IncludeOrExclude.Exclude
                                return {...prev}
                            })}>
                            Exclude</Button>
                        <Button
                            className={
                                cn("border", logFilter.inclusionType === IncludeOrExclude.Include ? "bg-secondarytransparenter border-secondary" : "border-primary bg-primarytransparent",)
                            }
                            onClick={() => setLogFilter((prev) => {
                                prev.inclusionType = IncludeOrExclude.Include
                                return {...prev}
                            })}>
                            Include</Button>
                    </div>
                    <p>
                        2. Select the environments that you want this filter to apply to:
                    </p>
                    <MultiSelectorDropDown
                        selectorSingular={"Environment"}
                        possibleItems={environments}
                        selectedItems={stringArrayToDropDownItemArray(logFilter.environments)}
                        setSelectedItems={(selectedItems) => {
                            setLogFilter((prev) => {
                                prev.environments = dropDownItemArrayToStringArray(selectedItems)
                                return {...prev}
                            })
                        }}
                    />
                    <p>
                        3. Select whether you want to match all services in a set of namespaces or a specific set of
                        services.
                    </p>
                    <div className={"flex gap-4"}>
                        <Button
                            className={
                                cn("border", logFilter.filterType === FilterType.Service ? "bg-secondarytransparenter border-secondary" : "border-primary bg-primarytransparent",)
                            }
                            onClick={() => setLogFilter((prev) => {
                                if (prev.filterType === FilterType.Service) {
                                    return prev
                                }

                                prev.filterType = FilterType.Service
                                prev.serviceFilterArgs = {
                                    services: [],
                                    regex: prev.namespaceFilterArgs?.regex || ".*"
                                }
                                prev.namespaceFilterArgs = undefined
                                return {...prev}
                            })}>
                            Services</Button>
                        <Button
                            className={
                                cn("border", logFilter.filterType === FilterType.Namespace ? "bg-secondarytransparenter border-secondary" : "border-primary bg-primarytransparent",)
                            }
                            onClick={() => setLogFilter((prev) => {
                                if (prev.filterType === FilterType.Namespace) {
                                    return prev
                                }
                                prev.filterType = FilterType.Namespace
                                prev.namespaceFilterArgs = {
                                    namespaces: [],
                                    regex: prev.serviceFilterArgs?.regex || ".*"
                                }
                                prev.serviceFilterArgs = undefined
                                return {...prev}
                            })}>
                            Namespaces</Button>
                    </div>
                    <div className={"text-textmedium"}>
                        <div>
                            4. Select the {logFilter.filterType === FilterType.Service ? "services" : "namespaces"} that
                            you want this exclusion pattern to be applied to:
                        </div>
                    </div>
                    {
                        logFilter.filterType === FilterType.Service &&
                        <MultiSelectorDropDown
                            selectorSingular={"Service"}
                            possibleItems={
                                services.map((service) => {
                                    return {
                                        displayName: service.displayName,
                                        value: service.serviceName
                                    }
                                })
                            }
                            selectedItems={stringArrayToDropDownItemArray(logFilter.serviceFilterArgs?.services || [])}
                            setSelectedItems={(selectedItems) => {
                                setLogFilter((prev) => {
                                    prev.serviceFilterArgs!.services = dropDownItemArrayToStringArray(selectedItems)
                                    return {...prev}
                                })
                            }}
                        />
                    }
                    {
                        logFilter.filterType === FilterType.Namespace &&
                        <MultiSelectorDropDown
                            selectorSingular={"Namespace"}
                            possibleItems={stringArrayToDropDownItemArray(namespaces)}
                            selectedItems={stringArrayToDropDownItemArray(logFilter.namespaceFilterArgs?.namespaces || [])}
                            setSelectedItems={(selectedItems) => {
                                setLogFilter((prev) => {
                                    prev.namespaceFilterArgs!.namespaces = dropDownItemArrayToStringArray(selectedItems)
                                    return {...prev}
                                })
                            }}
                        />
                    }

                    <p className={"text-sm"}>5. Enter the exclusion pattern that will be used to exclude
                        matching log lines from ingestion: </p>
                    <Input
                        value={logFilter.filterType == FilterType.Namespace ? logFilter.namespaceFilterArgs?.regex : logFilter.serviceFilterArgs?.regex}
                        onChange={(e) => {
                            if (logFilter.filterType == FilterType.Namespace) {
                                setLogFilter((prev) => {
                                    prev.namespaceFilterArgs!.regex = e.target.value
                                    return {...prev}
                                })
                            }
                            if (logFilter.filterType == FilterType.Service) {
                                setLogFilter((prev) => {
                                    prev.serviceFilterArgs!.regex = e.target.value
                                    return {...prev}
                                })
                            }
                        }}
                        type={"text"} placeholder={"Exclusion regex pattern"}
                        className={"border border-primary rounded p-2"}/>
                    <div className={"flex justify-end gap-4"}>
                        <Button
                            onClick={() => props.setOpenAddModal(false)}
                            className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Cancel</Button>
                        <Button
                            onClick={() => {
                                axios.post("/api/v1/logFilter", {
                                    ...logFilter,
                                }).then(() => {
                                    props.setTriggerReload()
                                    props.setOpenAddModal(false)
                                }).catch((e) => {
                                    console.error(e)
                                })

                            }}
                            className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>Save</Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}


function LogSettings() {
    const [logFilters, setLogFilters] = React.useState<Filter[]>([]);
    const context = useContext(AuthContext);

    // Delete modal
    const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean[]>([])

    const [triggerReload, setTriggerReload] = React.useState(false)

    const [openAddModal, setOpenAddModal] = React.useState(false)

    function getServiceDisplayName(path: string): string {
        // Extract the service name from the path
        const segments = path.split('/');
        const serviceName = segments[segments.length - 1];

        // Capitalize the first letter of each word if there are hyphens, otherwise capitalize the single word
        const formattedServiceName = serviceName.includes('-')
            ? serviceName.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('-')
            : serviceName.charAt(0).toUpperCase() + serviceName.slice(1);

        return formattedServiceName;
    }

    useEffect(() => {
        axios.get("/api/v1/logFilters").then((response) => {
            setLogFilters(response.data.logFilters)
        }).catch((e) => {
            console.error(e)
        })
    }, [triggerReload])

    return (
        <div className={"m-4"}>
            <div className={"flex justify-between"}>
                <h2 className={"text-lg font-bold"}>Filters</h2>
                <div className={"flex flex-col justify-center"}>
                    <NewLogFilterModal
                        openAddModal={openAddModal}
                        setOpenAddModal={setOpenAddModal}
                        setLogFilters={setLogFilters}
                        setTriggerReload={() => {
                            setTriggerReload(prev => !prev)
                        }}
                    />
                    <Button
                        onClick={() => setOpenAddModal(true)}
                        className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Add
                        Filter</Button>
                </div>
            </div>
            {logFilters !== undefined && logFilters.length > 0 &&
                <div className={"border-l border-r border-t mt-4 rounded-t bg-backgroundlight"}>
                    <div
                        className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex grow shrink">
                        <div
                            className={`w-[152px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Type
                        </div>
                        <div
                            className={`w-[360px] h-full  font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Environments
                        </div>
                        <div
                            className={`w-[180px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Class
                        </div>
                        <div
                            className={`w-[360px] h-full  font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Entities
                        </div>
                        <div
                            className={`flex grow shrink h-full  font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Regex
                        </div>
                    </div>
                    <div className={"bg-backgroundlight"}>
                        {
                            logFilters.map((filter, index) => {
                                return <div
                                    className={cn(`w-full flex-none h-[48px] px-4 py-2 border-t justify-start items-start gap-4 flex grow shrink`, index === logFilters.length - 1 ? "border-b" : "")}>
                                    <div
                                        className={`w-[152px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-md`}>{filter.inclusionType || "Unknown"}
                                    </div>
                                    <div
                                        className={`w-[360px] h-full  font-normal font-['Inter'] leading-8 text-textmedium text-md truncate`}>
                                        {filter.environments.length > 0 ? filter.environments.join(", ") : "All"}
                                    </div>
                                    <div
                                        className={`w-[180px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-md`}>
                                        {filter.filterType}
                                    </div>
                                    <div
                                        className={`w-[360px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-md`}>{filter.filterType === FilterType.Service ? (filter.serviceFilterArgs != undefined && filter.serviceFilterArgs?.services.length > 0 ? filter.serviceFilterArgs?.services.join(",") : "All") : (filter.namespaceFilterArgs !== undefined && filter.namespaceFilterArgs.namespaces.length > 0 ? filter.namespaceFilterArgs?.namespaces.join(", ") : "All")}
                                    </div>
                                    <div className={"flex grow shrink"}>
                                        {filter.filterType === FilterType.Service ? filter.serviceFilterArgs?.regex : filter.namespaceFilterArgs?.regex}
                                    </div>
                                    <div className={"flex flex-col justify-center"}>
                                        <Dialog open={openDeleteModal[index]}>
                                            <DialogContent className={"w-1/3"}>
                                                <div className={"flex flex-col gap-4 text-textmedium"}>
                                                    <h3 className={"text-lg font-bold"}>Remove filter</h3>
                                                    <div className={"text-textmedium"}>Are you sure you want to
                                                        remove filter ?
                                                    </div>
                                                    <div className={"flex justify-end gap-4"}>
                                                        <Button
                                                            onClick={() => setOpenDeleteModal(prev => {
                                                                prev[index] = false
                                                                return [...prev]
                                                            })}
                                                            className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Cancel</Button>
                                                        <Button
                                                            onClick={() => {
                                                                axios.delete("/api/v1/logFilter?id=" + filter.id).then(() => {
                                                                    setTriggerReload(prev => !prev)
                                                                }).catch(e => console.error(e))
                                                                setOpenDeleteModal(prev => {
                                                                    prev[index] = false
                                                                    return [...prev]
                                                                })
                                                            }}
                                                            className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>Remove</Button>
                                                    </div>
                                                </div>
                                            </DialogContent>
                                        </Dialog>
                                        <Button
                                            onClick={() => {
                                                setOpenDeleteModal(prev => {
                                                    prev[index] = true
                                                    return [...prev]
                                                })
                                            }}
                                            className={"bg-primarytransparent h-[32px] border border-primary text-white flex flex-col justify-center p-2 rounded"}>Remove</Button>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>}
            {logFilters.length <= 0 && <div
                className="w-full flex-none mt-4 p-4 rounded bg-backgroundlight border flex grow shrink">
                Add a log filter to prevent Metoro from exporting logs that match specific conditions.
            </div>}
        </div>
    );
}


interface User {
    name: string
    email: string
    isAdmin: boolean
    status: string
}


//
function Users() {
    const context = useContext(AuthContext);
    const [users, setUsers] = React.useState<User[]>([])

    // Invite modal
    const [openInviteModal, setOpenInviteModal] = React.useState(false)
    const [inviteEmail, setInviteEmail] = React.useState("")
    const [isNewUserAdmin, setIsNewUserAdmin] = React.useState(false)
    const [inviteLink, setInviteLink] = React.useState("")
    const [showCopyTick, setShowCopyTick] = React.useState(false)
    const [selectedInviteeEmail, setSelectedInviteeEmail] = React.useState("")

    // Reset Password modal
    const [openResetPasswordModal, setOpenResetPasswordModal] = React.useState(false)
    const [resetPasswordLink, setResetPasswordLink] = React.useState("")
    const [selectedUserEmail, setSelectedUserEmail] = React.useState("")
    const [currentUser, setCurrentUser] = React.useState<User | null>(null)

    // View Invite Link modal
    const [openViewLinkModal, setOpenViewLinkModal] = React.useState(false)
    const [viewInviteLink, setViewInviteLink] = React.useState("")

    const [triggerReload, setTriggerReload] = React.useState(false)
    // Delete modal
    const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean[]>([])
    const [userToDelete, setUserToDelete] = React.useState<User | null>(null)

    useEffect(() => {
        axios.get("/api/v1/account/organization").then((response) => {
            setUsers(response.data.organizationMembers)
            setOpenDeleteModal(new Array(response.data.organizationMembers.length).fill(false))
        }).catch((e) => {
            console.error(e)
        })
    }, [triggerReload])

    useEffect(() => {
        if (!context?.user?.email || !users?.length) return;
        const userEmail = context.user.email;
        const foundUser = users.find((user) => user.email === userEmail);
        if (foundUser) {
            setCurrentUser(foundUser);
        }
    }, [users, context?.user?.email]);

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setShowCopyTick(true)
                setTimeout(() => {
                    setShowCopyTick(false)
                }, 2000)
            })
            .catch((err) => console.error('Failed to copy:', err));
    };

    const handleViewInviteLink = async (email: string) => {
        try {
            const response = await axios.post(`/api/v1/account/organization/invite/link`, {
                email: email,
            });
            setViewInviteLink(response.data.inviteLink);
            setOpenViewLinkModal(true);
        } catch (error) {
            console.error('Failed to get invite link:', error);
        }
    };

    const handleResetPassword = async (email: string) => {
        try {
            const response = await axios.post(`/api/v1/account/reset-password-token`, {
                user_email: email
            });
            setResetPasswordLink(response.data.reset_link);
            setSelectedUserEmail(email);
            setOpenResetPasswordModal(true);
        } catch (error) {
            console.error('Failed to generate reset password link:', error);
        }
    };

    const handleDeleteUser = async (user: User) => {
        try {
            await axios.delete(`/api/v1/user?email=${encodeURIComponent(user.email)}`);
            setTriggerReload(prev => !prev);
            setUserToDelete(null);
        } catch (error) {
            console.error('Failed to delete user:', error);
        }
    };

    return (
        <div className={"m-4"}>
            <div className={"flex justify-between"}>
                <h2 className={"text-lg font-bold"}>Users</h2>
                <div className={"flex flex-col justify-center"}>
                    {/* Existing Invite Modal */}
                    <Dialog open={openInviteModal}>
                        <DialogContent className={"w-1/3"} onInteractOutside={() => {
                            setOpenInviteModal(false)
                            setInviteLink("")
                            setShowCopyTick(false)
                        }}>
                            <div className={"flex flex-col gap-4 text-textmedium"}>
                                {!inviteLink ? (
                                    <>
                                        <h3 className={"text-lg font-bold"}>Invite a user</h3>
                                        <div className={"text-textmedium"}>Enter the email of the user you want to
                                            invite.
                                        </div>
                                        <Input
                                            value={inviteEmail}
                                            onChange={(e) => setInviteEmail(e.target.value)}
                                            type={"email"} placeholder={"Email"}
                                            className={"border border-primary rounded p-2"}/>
                                        <div className={"flex gap-4"}>
                                            Should this user be an admin?
                                            <div className={"flex flex-col justify-center"}>
                                                <Checkbox
                                                    className={"rounded"}
                                                    checked={isNewUserAdmin}
                                                    onCheckedChange={(checked) => setIsNewUserAdmin(prev => !prev)}/>
                                            </div>
                                        </div>
                                        <div className={"flex justify-end gap-4"}>
                                            <Button
                                                onClick={() => setOpenInviteModal(false)}
                                                className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Cancel</Button>
                                            <Button
                                                onClick={() => {
                                                    axios.post("/api/v1/account/organization/invite", {
                                                        newUserEmail: inviteEmail,
                                                        isAdmin: isNewUserAdmin
                                                    }).then(response => {
                                                        setInviteLink(response.data.inviteLink)
                                                        setTimeout(() => {
                                                            setTriggerReload(prev => !prev)
                                                        }, 1000)
                                                    }).catch(e => console.error(e))
                                                }}
                                                className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>Invite</Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <h3 className={"text-lg font-bold"}>Invitation sent!</h3>
                                        <div className={"text-textmedium"}> We've emailed the invite to
                                            the user. You can also share this sign-up link with them:
                                        </div>
                                        <div className={"flex gap-2"}>
                                            <Input
                                                value={inviteLink}
                                                readOnly
                                                className={"border border-primary rounded p-2 flex-grow"}
                                            />
                                            <Button
                                                onClick={() => copyToClipboard(inviteLink)}
                                                className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded min-w-[80px]"}>
                                                {showCopyTick ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5"
                                                         viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                              clipRule="evenodd"/>
                                                    </svg>
                                                ) : (
                                                    "Copy"
                                                )}
                                            </Button>
                                        </div>
                                        <div className={"flex justify-end"}>
                                            <Button
                                                onClick={() => {
                                                    setOpenInviteModal(false)
                                                    setInviteLink("")
                                                    setInviteEmail("")
                                                    setIsNewUserAdmin(false)
                                                    setShowCopyTick(false)
                                                }}
                                                className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>
                                                Close
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </DialogContent>
                    </Dialog>

                    {/* View Invite Link Modal */}
                    <Dialog open={openViewLinkModal}>
                        <DialogContent className={"w-1/3"} onInteractOutside={() => {
                            setOpenViewLinkModal(false)
                            setViewInviteLink("")
                            setShowCopyTick(false)
                        }}>
                            <div className={"flex flex-col gap-4 text-textmedium"}>
                                <h3 className={"text-lg font-bold"}>Invite Link</h3>
                                <div className={"text-textmedium"}>Share this sign-up link with the user:</div>
                                <div className={"flex gap-2"}>
                                    <Input
                                        value={viewInviteLink}
                                        readOnly
                                        className={"border border-primary rounded p-2 flex-grow"}
                                    />
                                    <Button
                                        onClick={() => copyToClipboard(viewInviteLink)}
                                        className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded min-w-[80px]"}>
                                        {showCopyTick ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5"
                                                 viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd"
                                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                      clipRule="evenodd"/>
                                            </svg>
                                        ) : (
                                            "Copy"
                                        )}
                                    </Button>
                                </div>
                                <div className={"flex justify-end"}>
                                    <Button
                                        onClick={() => {
                                            setOpenViewLinkModal(false)
                                            setViewInviteLink("")
                                            setShowCopyTick(false)
                                        }}
                                        className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>
                                        Close
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>

                    {/* Reset Password Modal */}
                    <Dialog open={openResetPasswordModal}>
                        <DialogContent className={"w-1/3"} onInteractOutside={() => {
                            setOpenResetPasswordModal(false)
                            setResetPasswordLink("")
                            setShowCopyTick(false)
                        }}>
                            <div className={"flex flex-col gap-4 text-textmedium"}>
                                <h3 className={"text-lg font-bold"}>Reset Password Link</h3>
                                <div className={"text-textmedium"}>Share this reset password link
                                    with {selectedUserEmail}:
                                </div>
                                <div className={"flex gap-2"}>
                                    <Input
                                        value={resetPasswordLink}
                                        readOnly
                                        className={"border border-primary rounded p-2 flex-grow"}
                                    />
                                    <Button
                                        onClick={() => copyToClipboard(resetPasswordLink)}
                                        className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded min-w-[80px]"}>
                                        {showCopyTick ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5"
                                                 viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd"
                                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                      clipRule="evenodd"/>
                                            </svg>
                                        ) : (
                                            "Copy"
                                        )}
                                    </Button>
                                </div>
                                <div className={"flex justify-end"}>
                                    <Button
                                        onClick={() => {
                                            setOpenResetPasswordModal(false)
                                            setResetPasswordLink("")
                                            setShowCopyTick(false)
                                        }}
                                        className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>
                                        Close
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>

                    {/* Delete User Confirmation Dialog */}
                    <Dialog open={!!userToDelete}>
                        <DialogContent className={"w-1/3"}>
                            <div className={"flex flex-col gap-4 text-textmedium"}>
                                <h3 className={"text-lg font-bold"}>Delete User</h3>
                                <div>Are you sure you want to delete this user?</div>
                                <div
                                    className={"text-base font-semibold text-textdark bg-backgroundlight px-2 py-1 rounded w-full"}>
                                    {userToDelete?.email}
                                </div>
                                <div className={"flex justify-end gap-2"}>
                                    <Button className={"text-textmedium bg-red-500/10 border border-red-500"}
                                            onClick={() => setUserToDelete(null)}>
                                        Cancel
                                    </Button>
                                    <Button className={"text-textmedium bg-primarytransparent border border-primary"}
                                            onClick={() => handleDeleteUser(userToDelete!)}>
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Tooltip>
                        <TooltipTrigger>
                            <Button
                                onClick={() => setOpenInviteModal(true)}
                                disabled={currentUser === null || !currentUser.isAdmin}
                                className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Invite</Button>
                        </TooltipTrigger>
                        <TooltipContent side={"left"}>
                            {currentUser === null || !currentUser.isAdmin ? "Only admins can invite users" : "Invite a new user to join your organization"}
                        </TooltipContent>
                    </Tooltip>

                </div>
            </div>
            <div className={"border-l border-r border-t mt-4 rounded-t bg-backgroundlight overflow-x-hidden"}>
                <div
                    className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex grow shrink">
                    <div
                        className={`w-[152px]  flex-none h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>User
                    </div>
                    <div
                        className={`w-[360px] flex-none h-full  font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>Email
                    </div>
                    <div
                        className={`font-normal flex-none w-[160px] font-['Inter'] leading-8 text-textmedium text-xl`}>Role
                    </div>
                    <div
                        className={`font-normal flex-none w-[140px] font-['Inter'] leading-8 text-textmedium text-xl`}>Status
                    </div>
                </div>
                <div className={"bg-backgroundlight"}>
                    {
                        users.map((user, index) => {
                            user.status = capitalize(user.status)
                            let indicatorColor = user.status === "Active" ? "bg-green-600" : "bg-blue-500"
                            return <div
                                className={cn(`w-full flex-none h-[48px] px-4 py-2 border-t justify-center items-center gap-4 flex grow shrink`, index === users.length - 1 ? "border-b" : "")}>
                                <div
                                    className={`w-[152px] flex-none h-full font-normal font-['Inter'] leading-8 text-textmedium text-md`}>{user.name || "Unknown"}
                                </div>
                                <div
                                    className={`w-[360px] flex-none h-full font-normal font-['Inter'] leading-8 text-textmedium text-md truncate`}>
                                    {user.email}
                                </div>
                                <div
                                    className={`w-[160px] flex-none h-full font-normal font-['Inter'] leading-8 text-textmedium text-md`}>
                                    {user.isAdmin ? "Admin" : "User"}
                                </div>
                                <div
                                    className={`w-[140px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-md`}>
                                    <div className={"flex gap-2"}>
                                        <div className={`w-2 h-2 rounded-full mt-3 ${indicatorColor}`}></div>
                                        {user.status}
                                    </div>
                                </div>
                                <div className={"flex flex-col grow justify-center items-end hover:text-primary"}>
                                    {currentUser !== null && currentUser?.isAdmin && <DropdownMenu>
                                        <DropdownMenuTrigger>
                                            <MoreVertical className="h-5 w-5"/>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent
                                            className={"text-textmedium bg-backgrounddark rounded hover:cursor p-0"}>
                                            {user.status === "Pending" && (
                                                <DropdownMenuItem onClick={() => handleViewInviteLink(user.email)}
                                                                  className={"hover:text-textlight hover:bg-backgroundlight w-full"}>
                                                    View Invite Link
                                                </DropdownMenuItem>
                                            )}
                                            {currentUser?.isAdmin && user.email !== currentUser.email && user.status !== "Pending" && (
                                                <DropdownMenuItem onClick={() => handleResetPassword(user.email)}
                                                                  className={"hover:text-textlight hover:bg-backgroundlight w-full"}>
                                                    Reset Password
                                                </DropdownMenuItem>
                                            )}
                                            <DropdownMenuItem
                                                onClick={() => {
                                                    if (user.email !== currentUser?.email) {
                                                        setUserToDelete(user)
                                                    }
                                                }}
                                                disabled={user.email === currentUser?.email}
                                                className={cn(
                                                    "hover:text-textlight hover:bg-backgroundlight w-full",
                                                    user.email === currentUser?.email && "opacity-50 cursor-not-allowed hover:bg-transparent hover:text-textmedium"
                                                )}>
                                                Remove User
                                            </DropdownMenuItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu>}
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    );
}

interface UserSettings {
    dateTimeFormat: DateTimeFormat
    defaultTimeRange?: string
}

// Maybe ensure that display values are like this but actual value set is the formatstr that format will accept.
export enum DateTimeFormat {
    YYYYMMDD = "yyyy/MM/dd HH:mm:ss.SSS", // iso8601, if the user has not specified, this is the default value.
    MMDDYYYY = "MM/dd/yyyy HH:mm:ss.SSS", // common in US
    DDMMYYYY = "dd/MM/yyyy HH:mm:ss.SSS",  // common in EU
}

const DateTimeFormatToDisplayValues = new Map<DateTimeFormat, string>(
    [
        [DateTimeFormat.YYYYMMDD, "yyyy/MM/DD"],
        [DateTimeFormat.MMDDYYYY, "MM/DD/yyyy"],
        [DateTimeFormat.DDMMYYYY, "DD/MM/yyyy"]
    ]
);
export const ISO8601 = "iso8601"
export const DateTimeFormatToLocale = new Map<DateTimeFormat, string>(
    [
        [DateTimeFormat.YYYYMMDD, "ja-JP"], // en-SE is the closest locale to ISO8601
        [DateTimeFormat.MMDDYYYY, "en-US"],
        [DateTimeFormat.DDMMYYYY, "en-GB"]
    ]
);

function UserSettings() {
    const [settings, setSettings] = React.useState<UserSettings>()
    useEffect(() => {
        axios.get("/api/v1/user/settings").then((response) => {
            setSettings(response.data)
            localStorage.setItem("dateTimeFormat", response.data.dateTimeFormat)
            localStorage.setItem("defaultTimeRange", response.data.defaultTimeRange)
        }).catch((e) => {
            console.error(e)
        })
    }, [])

    return <div className={"m-4"}>
        {settings === undefined && <LoadingSpinner className={"absolute top-1/2 left-1/2"}/>}
        {settings !== undefined && setSettings &&
            <div className={"mt-4 flex flex-col gap-4"}>
                <DateSetting setSettings={setSettings} settings={settings}/>
                <Separator/>
                <DefaultTimeRangeSetting setSettings={setSettings} settings={settings}/>
                <Separator/>
                <ChangePassword/>
                <Separator/>
                <ShowServiceToken/>
            </div>
        }
    </div>
}

function ShowServiceToken() {
    const [showServiceToken, setShowServiceToken] = React.useState(false)
    const [showCopyTick, setShowCopyTick] = React.useState(false)
    let serviceToken = window.localStorage.getItem('serviceToken');
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setShowCopyTick(true)
                setTimeout(() => {
                    setShowCopyTick(false)
                }, 2000)
            })
            .catch((err) => console.error('Failed to copy:', err));
    };

    if (serviceToken == null) {
        return <></>
    }

    return <div className={"p-4"}>
        <div className={"flex flex-col gap-2"}>
            <h3 className={"text-lg font-semibold"}>Service Token</h3>
            <div className={"text-textmedium text-wrap mb-4"}>
                <div>This token allows you to make direct requests to the Metoro API.</div>
                <div>Do not share this token with anyone. It is the same as sharing your password.</div>
                <div className={"flex items-center gap-2 mt-4"}>
                    <div className={"flex flex-grow gap-2"}>
                        <Input
                            type={showServiceToken ? "text" : "password"}
                            value={serviceToken}
                            readOnly
                            className={"pr-20 py-2 w-[490px]"}
                        />
                        <Button
                            onClick={() => setShowServiceToken(!showServiceToken)}
                            className={"rounded border border-primary bg-primarytransparent"}
                        >
                            {showServiceToken ? "Hide" : "Show"}
                        </Button>
                        <Button
                            onClick={() => copyToClipboard(serviceToken)}
                            className={"rounded border border-primary bg-primarytransparent"}
                        >
                            {showCopyTick ? "Copied!" : "Copy"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


function DefaultTimeRangeSetting(props: {
    settings: UserSettings,
    setSettings: Dispatch<SetStateAction<UserSettings | undefined>>
}) {
    const [isLoading, setIsLoading] = React.useState(false);

    const timeRangeOptions = [
        {value: "not-set", label: "Not set"},
        {value: "5m", label: "Last 5 Minutes"},
        {value: "15m", label: "Last 15 Minutes"},
        {value: "30m", label: "Last 30 Minutes"},
        {value: "1h", label: "Last 1 Hour"},
        {value: "3h", label: "Last 3 Hours"},
        {value: "6h", label: "Last 6 Hours"},
        {value: "12h", label: "Last 12 Hours"},
        {value: "24h", label: "Last 24 Hours"},
        {value: "2d", label: "Last 2 Days"},
        {value: "7d", label: "Last 7 Days"},
        {value: "30d", label: "Last 30 Days"}
    ];

    const handleTimeRangeChange = async (value: string) => {
        setIsLoading(true);
        try {
            await axios.post('/api/v1/user/settings', {
                dateTimeFormat: props.settings.dateTimeFormat,
                defaultTimeRange: value
            });
            props.setSettings({
                ...props.settings,
                defaultTimeRange: value
            });
            toast({
                title: "Success",
                description: "Default time range updated successfully",
            });
            localStorage.setItem("defaultTimeRange", value)
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update default time range",
                variant: "destructive"
            });
        }
        setIsLoading(false);
    };

    return (
        <div className={"ml-4 text-textmedium"}>
            <div className={"text-lg font-bold mb-4"}>Default Time Range
            </div>
            <div className={"text-sm text-muted-foreground mb-4"}>
                Select the default time range for all views
            </div>
            <Select
                value={props.settings.defaultTimeRange}
                onValueChange={handleTimeRangeChange}
                disabled={isLoading}
            >
                <SelectTrigger className="w-[200px]">
                    <SelectValue placeholder="Select time range"/>
                </SelectTrigger>
                <SelectContent className={"bg-backgroundmedium"}>
                    {timeRangeOptions.map((option) => (
                        <SelectItem className={"text-textmedium"} key={option.value} value={option.value}>
                            {option.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    );
}

function DateSetting(props: {
    settings: UserSettings,
    setSettings: Dispatch<SetStateAction<UserSettings | undefined>>
}) {
    const [isSaved, setIsSaved] = React.useState(false)

    let possibleDateFormats: DropDownItem[] = [];
    DateTimeFormatToDisplayValues.forEach((displayVal, val) => {
        possibleDateFormats.push({displayName: displayVal, value: val} as DropDownItem)
    });

    function saveSettings() {
        if (props.settings?.dateTimeFormat === undefined) {
            return
        }
        axios.post("/api/v1/user/settings", {dateTimeFormat: props.settings?.dateTimeFormat}).then((response) => {
            localStorage.setItem("dateTimeFormat", props.settings.dateTimeFormat)
            setIsSaved(true)
            setTimeout(() => {
                setIsSaved(false)
            }, 2000) // Show tick for 2 seconds
        }).catch((e) => {
            console.error(e)
        })
    }

    const [selected, setSelected] = React.useState<DropDownItem>({
        displayName: DateTimeFormatToDisplayValues.get(props.settings.dateTimeFormat as DateTimeFormat),
        value: props.settings.dateTimeFormat
    } as DropDownItem);

    useEffect(() => {
        props.setSettings(prev => {
            return {...prev, dateTimeFormat: selected.value as DateTimeFormat}
        })
    }, [selected]);

    return <div className={"flex flex-col gap-4 items-start p-4 rounded overflow-x-hidden"}>
        <div className={"font-semibold text-lg"}> Date Format</div>
        <div className={"text-textmedium text-wrap"}>Metoro will show all dates in the selected format. By
            default, all
            the dates are shown DD/MM/yyyy.
        </div>
        <SingleSelectDropdown selectedItemTitle={"Date Format"} possibleItems={possibleDateFormats}
                              setSelectedItem={setSelected} selectedItem={selected}/>
        <Button
            className={"w-[650px] border border-primary hover:cursor-pointer bg-primarytransparent rounded text-textmedium hover:text-textlight"}
            onClick={() => saveSettings()}>
            {isSaved ? (
                <span className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                        </span>
            ) : ('Save')}
        </Button>
    </div>
}

function ChangePassword() {
    const [currentPassword, setCurrentPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            toast({
                className: "text-textlight",
                variant: "destructive",
                title: "Passwords don't match",
                description: "Please ensure both passwords are identical.",
            });
            return;
        }

        if (newPassword.length < 8) {
            toast({
                className: "text-textlight",
                variant: "destructive",
                title: "Password too short",
                description: "Password must be at least 8 characters long.",
            });
            return;
        }

        setIsLoading(true);

        try {
            const response = await axios.post('/api/v1/account/change-password', {
                current_password: currentPassword,
                new_password: newPassword,
            });

            if (response.status !== 200) {
                throw new Error('Failed to change password');
            }

            toast({
                className: "text-textlight",
                title: "Password Changed Successfully",
                description: "Your password has been updated.",
            });

            // Clear the form
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            toast({
                className: "text-textlight",
                variant: "destructive",
                title: "Error",
                description: "Failed to change password. Please check your current password and try again.",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="p-4">
            <div className={"font-semibold text-lg mb-4"}>Change Password</div>
            <div className={"text-textmedium text-wrap mb-4"}>
                <div>If you are not using SSO, you can change your password here.</div>
                <div>Your password must be at least 8 characters long.</div>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="space-y-2">
                    <Input
                        id="current-password"
                        type="password"
                        placeholder="Current Password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                        className="w-[650px]"
                    />
                </div>
                <div className="space-y-2">
                    <Input
                        id="new-password"
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        className="w-[650px]"
                    />
                </div>
                <div className="space-y-2">
                    <Input
                        id="confirm-password"
                        type="password"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className="w-[650px]"
                    />
                </div>
                <Button type="submit" disabled={isLoading}
                        className={"text-textmedium bg-primarytransparent border border-primary hover:text-textlight rounded"}>
                    {isLoading ? "Changing Password..." : "Change Password"}
                </Button>
            </form>
        </div>
    );
}

function ClusterSettings() {
    const [clusters, setClusters] = React.useState<string[]>([]);
    const [openAddClusterModal, setOpenAddClusterModal] = React.useState(false)
    const [newClusterName, setNewClusterName] = React.useState("")
    const [triggerReload, setTriggerReload] = React.useState(false)
    const [openDeleteClusterModal, setOpenDeleteClusterModal] = React.useState<boolean[]>([])

    useEffect(() => {
        // Call backend to get the clusters
        axios.get("/api/v1/environments").then((response) => {
            setClusters(response.data.environments)
        }).catch((e) => {
            console.error(e)
        })
    }, [triggerReload]);

    const deletionCommands = `
    $ helm uninstall -n metoro metoro-exporter
    `
    return <div className={"m-4 grow shrink"}>
        <div className={"flex justify-between"}>
            <h2 className={"text-lg font-bold"}>Your Clusters</h2>
            <div className={"flex flex-col justify-center"}>
                <Dialog open={openAddClusterModal}>
                    <DialogContent className={"w-3/4"}
                                   onInteractOutside={() => setOpenAddClusterModal(false)}
                                   onEscapeKeyDown={() => setOpenAddClusterModal(false)}
                        // onBlur={() => setOpenAddClusterModal(false)}
                    >
                        <div
                            className={"blur-none flex min-w-0 min-h-0 grow shrink justify-center"}>
                            <OnboardingFlow addCluster={true}/>
                        </div>
                    </DialogContent>
                </Dialog>

                <Button
                    onClick={() => setOpenAddClusterModal(true)}
                    className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Add
                    Cluster</Button>
            </div>
        </div>
        <div className={"border-l border-r border-t mt-4 rounded-t bg-backgroundlight"}>
            <div
                className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex grow shrink">
                <div
                    className={`w-[152px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>Cluster
                    Name
                </div>
            </div>
            <div className={"bg-backgroundlight"}>
                {
                    clusters.map((cluster, index) => {
                        return <div
                            className={cn(`w-full flex-none h-[48px] px-4 py-2 border-t justify-between items-start gap-4 flex grow shrink`, index === clusters.length - 1 ? "border-b" : "")}>
                            <div
                                className={`w-[152px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-md`}>{cluster || "Unknown"}
                            </div>
                            <div className={"flex flex-col justify-center"}>
                                <Dialog open={openDeleteClusterModal[index]}>
                                    <DialogContent className={"w-max"}
                                    >
                                        <div className={"flex flex-col gap-4 text-textmedium"}>
                                            <h3 className={"text-lg font-bold"}>Remove cluster</h3>
                                            <p className={"text-sm"}>If you would like to remove this cluster, please
                                                run the following commands to uninstall Metoro from your cluster: </p>
                                            <div className={"border flex min-h-0 min-w-0 grow shrink rounded"}>

                                                <SyntaxHighlighter
                                                    language="bash" customStyle={{width: "100%"}}
                                                    // @ts-ignore
                                                    style={customTheme}>
                                                    {deletionCommands}
                                                </SyntaxHighlighter>
                                            </div>

                                            <div className={"flex justify-end gap-4"}>
                                                <Button
                                                    onClick={() => setOpenDeleteClusterModal(prev => {
                                                        prev[index] = false
                                                        return [...prev]
                                                    })}
                                                    className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Cancel</Button>
                                                <Button
                                                    onClick={() => {
                                                        axios.delete("/api/v1/environment?envName=" + cluster).then(() => {
                                                            setTriggerReload(prev => !prev)
                                                        }).catch(e => console.error(e))
                                                        setOpenDeleteClusterModal(prev => {
                                                            prev[index] = false
                                                            return [...prev]
                                                        })
                                                    }}
                                                    className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>Remove</Button>
                                            </div>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                                <Button
                                    onClick={() => {
                                        setOpenDeleteClusterModal(prev => {
                                            prev[index] = true
                                            return [...prev]
                                        })
                                    }}
                                    className={"bg-primarytransparent h-[32px] border border-primary text-white flex flex-col justify-center p-2 rounded"}>Remove
                                </Button>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
        <div className="flex-none mt-4">
            <a
                href="https://github.com/metoro-io/metoro-helm-charts"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary/80 text-sm flex items-center gap-2"
            >
                View Metoro Helm Charts
                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                </svg>
            </a>
        </div>
    </div>
}

interface WebhookIntegrationWithUUID {
    uuid: string;
    name: string;
    url: string;
    headers?: {
        [key: string
            ]:
            string
    };
    body?: string;
    method: string;
}

function WebhookIntegration() {
    const [webhooks, setWebhooks] = React.useState<WebhookIntegrationWithUUID[]>([]);
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const [openListModal, setOpenListModal] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        loadWebhooks();
    }, []);

    const loadWebhooks = () => {
        setLoading(true);
        axios.get("/api/v1/integrations?type=webhook")
            .then((response) => {
                setWebhooks(response.data);
            })
            .catch((e) => {
                console.error(e);
                toast({
                    title: "Error",
                    className: "text-textlight",
                    description: "Failed to load webhook integrations",
                    variant: "destructive",
                });
            })
            .finally(() => setLoading(false));
    };

    const deleteWebhook = (uuid: string) => {
        axios.delete(`/api/v1/integration/webhook?uuid=${encodeURIComponent(uuid)}`)
            .then(() => {
                loadWebhooks();
                toast({
                    title: "Success",
                    className: "text-textlight",
                    description: "Webhook deleted successfully",
                });
            })
            .catch((e) => {
                console.error(e);
                toast({
                    title: "Error",
                    className: "text-textlight",
                    description: e.response?.data?.error || "Failed to delete webhook",
                    variant: "destructive",
                });
            });
    };

    return (
        <div className={"mt-4 p-4 bg-backgroundlight border rounded text-textmedium"}>
            <div className={"flex"}>
                <div className={"flex-grow"}>
                    <div className={"flex gap-1"}>
                        <Webhook className={"text-primary"}/>
                        <h3 className={"text-lg font-bold"}>Webhooks</h3>
                    </div>
                    <p className={"text-sm text-textdark"}>
                        Create webhook integrations to send alert notifications to external services.
                    </p>
                </div>
                <div className="space-x-2">
                    <Button
                        onClick={() => setOpenListModal(true)}
                        className={"bg-primarytransparent border border-primary text-textlight hover:text-white"}
                    >
                        View Webhooks
                    </Button>
                    <Button
                        onClick={() => setOpenAddModal(true)}
                        className={"bg-primarytransparent border border-primary text-textlight hover:text-white"}
                    >
                        Add Webhook
                    </Button>
                </div>
            </div>

            {/* Add Webhook Dialog */}
            <Dialog open={openAddModal} onOpenChange={setOpenAddModal}>
                <DialogContent className={"w-1/2"}>
                    <DialogHeader>
                        <DialogTitle className={"text-textmedium"}>Add Webhook Integration</DialogTitle>
                        <DialogDescription className={"text-textdark"}>
                            Configure a webhook to receive alert notifications
                        </DialogDescription>
                    </DialogHeader>
                    <WebhookForm onSuccess={() => {
                        setOpenAddModal(false);
                        loadWebhooks();
                    }}/>
                </DialogContent>
            </Dialog>

            {/* List Webhooks Dialog */}
            <Dialog open={openListModal} onOpenChange={setOpenListModal}>
                <DialogContent className={"w-2/3 max-h-[66vh] overflow-y-auto"}>
                    <DialogHeader>
                        <DialogTitle className={"text-textmedium"}>Webhook Integrations</DialogTitle>
                        <DialogDescription className={"text-textdark"}>
                            Manage your webhook integrations
                        </DialogDescription>
                    </DialogHeader>
                    {loading ? (
                        <div className="flex justify-center p-4">
                            <LoadingSpinner/>
                        </div>
                    ) : (
                        <div className="mt-4 space-y-4 min-h-0">
                            {webhooks.length === 0 ? (
                                <p className="text-center text-sm text-textdark">No webhooks configured</p>
                            ) : (
                                <div className="space-y-4 h-full overflow-y-auto">
                                    {webhooks.map((webhook) => (
                                        <div key={webhook.uuid} className="p-4 border rounded bg-backgroundlight">
                                            <div className="flex items-start justify-between">
                                                <div>
                                                    <h4 className="font-medium text-textmedium">{webhook.name}</h4>
                                                    <p className="text-sm text-textdark mt-1">{webhook.url}</p>
                                                    {webhook.headers && Object.keys(webhook.headers).length > 0 && (
                                                        <div className="mt-2">
                                                            <p className="text-sm font-medium text-textmedium">Headers:</p>
                                                            <div className="mt-1 space-y-1">
                                                                {Object.entries(webhook.headers).map(([key, value]) => (
                                                                    <p key={key} className="text-sm text-textdark">
                                                                        {key}: {value}
                                                                    </p>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {webhook.body && (
                                                        <div className="mt-2">
                                                            <p className="text-sm font-medium text-textmedium">Body
                                                                Template:</p>
                                                            <p className="text-sm text-textdark mt-1 font-mono bg-backgrounddark p-2 rounded">
                                                                {webhook.body}
                                                            </p>
                                                        </div>
                                                    )}
                                                    <p className="text-sm mt-1 font-medium text-textmedium">Method:</p>
                                                    <p className="text-sm text-textdark">{webhook.method}</p>
                                                </div>
                                                <Button
                                                    variant="ghost"
                                                    size="sm"
                                                    onClick={() => deleteWebhook(webhook.uuid)}
                                                    className="border border-red-500 text-textlight hover:text-white bg-red-500/10"
                                                >
                                                    Delete
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
}

function WebhookForm({
                         onSuccess
                     }: {
    onSuccess: () => void
}) {
    const [name, setName] = React.useState("");
    const [url, setUrl] = React.useState("");
    const [method, setMethod] = React.useState("POST");
    const [headers, setHeaders] = React.useState<{ key: string; value: string }[]>([
        {key: "Content-Type", value: "application/json"}
    ]);
    const [body, setBody] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const httpMethods = ["GET", "POST", "PUT", "PATCH", "DELETE"];

    // Update headers when method changes
    const handleMethodChange = (newMethod: string) => {
        setMethod(newMethod);
        if (newMethod === "POST" || newMethod === "PUT") {
            // Add Content-Type header if it doesn't exist
            if (!headers.some(h => h.key === "Content-Type")) {
                setHeaders([{key: "Content-Type", value: "application/json"}, ...headers]);
            }
        } else {
            // Remove Content-Type header for other methods
            setHeaders(headers.filter(h => h.key !== "Content-Type"));
        }
    };

    const addHeader = () => {
        setHeaders([...headers, {key: "", value: ""}]);
    };

    const updateHeader = (index: number, field: "key" | "value", value: string) => {
        const newHeaders = [...headers];
        newHeaders[index][field] = value;
        setHeaders(newHeaders);
    };

    const removeHeader = (index: number) => {
        // Don't allow removing the Content-Type header for POST/PUT methods
        if (index === 0 && headers[0].key === "Content-Type" && (method === "POST" || method === "PUT")) {
            return;
        }
        const newHeaders = [...headers];
        newHeaders.splice(index, 1);
        setHeaders(newHeaders);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        const headerMap = headers.reduce((acc, {key, value}) => {
            if (key && value) {
                acc[key] = value;
            }
            return acc;
        }, {} as { [key: string]: string });

        const webhookData = {
            name,
            url,
            method,
            headers: headerMap,
            body,
        };

        axios.post("/api/v1/integrations", {
            type: "webhook",
            integrationCredentialsJson: JSON.stringify(webhookData),
        })
            .then(() => {
                toast({
                    className: "text-textlight",
                    title: "Success",
                    description: "Webhook integration added successfully",
                });
                onSuccess();
            })
            .catch((e) => {
                console.error(e);
                toast({
                    className: "text-textlight",
                    title: "Error",
                    description: "Failed to add webhook integration",
                    variant: "destructive",
                });
            })
            .finally(() => setLoading(false));
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <label className="text-sm font-medium text-textdark">Name</label>
                <Input
                    className={"h-[36px]"}
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="My Webhook"
                />
            </div>
            <div>
                <div className="flex items-center gap-2">
                    <label className="text-sm font-medium text-textdark">URL</label>
                    <Tooltip>
                        <TooltipTrigger>
                            <InfoIcon className="h-4 w-4 text-textdark hover:cursor-pointer hover:text-primary"/>
                        </TooltipTrigger>
                        <TooltipContent className="w-96 p-4 text-sm text-textmedium">
                            <div className="space-y-2">
                                <p>You can use template variables in your URL for dynamic values:</p>
                                <ul className="list-disc pl-4 space-y-1">
                                    <li><code>$alert_name</code> - Name of the alert</li>
                                    <li><code>$alert_description</code> - Description of the alert</li>
                                    <li><code>$environment</code> - Environment context</li>
                                    <li><code>$service</code> - Service associated with the alert</li>
                                    <li><code>$fired_at</code> - Unix Timestamp when alert was fired</li>
                                    <li><code>$resolved_at</code> - Unix Timestamp when alert was resolved</li>
                                    <li><code>$breaching_datapoint_value</code> - Value that triggered the alert</li>
                                    <li><code>$breaching_datapoint_time</code> - Unix Timestamp of the breaching value
                                    </li>
                                    <li><code>$metric_name</code> - Name of the metric</li>
                                </ul>
                                <p className="text-xs mt-2">Example: <code>https://api.example.com/webhook?alert=$alert_name&value=$breaching_datapoint_value</code>
                                </p>
                            </div>
                        </TooltipContent>
                    </Tooltip>
                </div>
                <Input
                    className={"h-[36px]"}
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="https://api.example.com/webhook?alert=$alert_name"
                />
            </div>
            <div>
                <label className="text-sm font-medium text-textdark">HTTP Method</label>
                <Select value={method} onValueChange={handleMethodChange}>
                    <SelectTrigger className="w-full border-border text-textmedium rounded">
                        <SelectValue placeholder="Select HTTP method"/>
                    </SelectTrigger>
                    <SelectContent className={"bg-background text-textmedium"}>
                        {httpMethods.map((m) => (
                            <SelectItem key={m} value={m}
                                        className={"hover:border hover:border-secondary hover:bg-secondarytransparenter hover:cursor-pointer"}>
                                {m}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <div>
                <label className="text-sm font-medium text-textdark">Headers</label>
                <div className={"flex flex-col gap-1"}>
                    {headers.map((header, index) => (
                        <div key={index} className="flex gap-2">
                            <Input
                                disabled={header.key === "Content-Type" && (method === "POST" || method === "PUT")}
                                className={"h-[36px]"}
                                value={header.key}
                                onChange={(e) => updateHeader(index, "key", e.target.value)}
                                placeholder="Header name"
                            />
                            <Input
                                className={"h-[36px]"}
                                value={header.value}
                                onChange={(e) => updateHeader(index, "value", e.target.value)}
                                placeholder="Header value"
                            />
                            <Button
                                type="button"
                                variant="ghost"
                                size="sm"
                                onClick={() => removeHeader(index)}
                            >
                                <XIcon className="h-4 w-4 text-textdark hover:cursor-pointer hover:text-primary"/>
                            </Button>
                        </div>
                    ))}
                </div>
                <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    onClick={addHeader}
                    className="mt-2 text-textmedium bg-primarytransparent border border-primary hover:text-textlight rounded"
                >
                    Add Header
                </Button>
            </div>
            <div>
                <div className="flex items-center gap-2">
                    <label className="text-sm font-medium text-textdark">Body Template (Optional)</label>
                    <Tooltip>
                        <TooltipTrigger>
                            <InfoIcon className="h-4 w-4 text-textdark hover:cursor-pointer hover:text-primary"/>
                        </TooltipTrigger>
                        <TooltipContent className="w-96 p-4 text-sm text-textmedium">
                            <div className="space-y-2">
                                <p>You can use the following template variables in your webhook body:</p>
                                <ul className="list-disc pl-4 space-y-1">
                                    <li><code>$alert_name</code> - Name of the alert</li>
                                    <li><code>$alert_description</code> - Description of the alert</li>
                                    <li><code>$environment</code> - Environment context</li>
                                    <li><code>$service</code> - Service associated with the alert</li>
                                    <li><code>$fired_at</code> - Unix Timestamp when alert was fired</li>
                                    <li><code>$resolved_at</code> - Unix Timestamp when alert was resolved</li>
                                    <li><code>$breaching_datapoint_value</code> - Value that triggered the alert</li>
                                    <li><code>$breaching_datapoint_time</code> - Unix Timestamp of the breaching value
                                    </li>
                                    <li><code>$metric_name</code> - Name of the metric</li>
                                </ul>
                                <div className="mt-2 space-y-2">
                                    <p className="font-medium">Examples:</p>
                                    <div className="space-y-1 text-xs">
                                        <p>JSON format:</p>
                                        <code
                                            className="block p-1 bg-backgrounddark rounded">{"{ \"alert\": \"$alert_name\", \"value\": \"$breaching_datapoint_value\" }"}</code>
                                        <p>Plain text format:</p>
                                        <code className="block p-1 bg-backgrounddark rounded">Alert $alert_name was
                                            triggered with value $breaching_datapoint_value</code>
                                        <p>XML format:</p>
                                        <code
                                            className="block p-1 bg-backgrounddark rounded">{"<alert><name>$alert_name</name><value>$breaching_datapoint_value</value></alert>"}</code>
                                    </div>
                                </div>
                            </div>
                        </TooltipContent>
                    </Tooltip>
                </div>
                <textarea
                    className="w-full p-2 border rounded bg-backgrounddark text-textmedium"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    placeholder="Enter your custom template (e.g., Alert $alert_name was triggered with value $breaching_datapoint_value)"
                    rows={4}
                />
            </div>
            <Button type="submit" disabled={loading}
                    className={"text-textmedium bg-primarytransparent border border-primary hover:text-textlight rounded"}>
                {loading ? "Adding..." : "Add Webhook"}
            </Button>
        </form>
    );
}

function Settings() {
    return (
        <BaseView title={"Settings"} disableClusterSelector={true} disableTimeRange>
            <div className={"m-4 border bg-backgroundmedium flex-col grow shrink text-textmedium overflow-y-auto"}>
                <Tabs defaultValue="usersettings" className="grow shrink">
                    <TabsList className={"grow shrink flex p-1 border-b"}>
                        <TabsTrigger className={"grow shrink"} value="usersettings">User Settings</TabsTrigger>
                        <TabsTrigger className={"grow shrink"} value="users">Users</TabsTrigger>
                        <TabsTrigger className={"grow shrink"} value="integrations">Integrations</TabsTrigger>
                        <TabsTrigger className={"grow shrink"} value="logSettings">Log Settings</TabsTrigger>
                        <TabsTrigger className={"grow shrink"} value="clusterSettings">Cluster Settings</TabsTrigger>
                    </TabsList>
                    <TabsContent value="usersettings">
                        <UserSettings/>
                    </TabsContent>
                    <TabsContent value="users">
                        <Users/>
                    </TabsContent>
                    <TabsContent value="integrations">
                        <Integrations/>
                    </TabsContent>
                    <TabsContent value="logSettings">
                        <LogSettings/>
                    </TabsContent>
                    <TabsContent value="clusterSettings">
                        <ClusterSettings/>
                    </TabsContent>
                </Tabs>
            </div>
        </BaseView>
    )
        ;
}


export {
    Settings
}