"use client"

import * as React from "react"
import {useEffect} from "react"
import {
    ColumnDef,
    ColumnFiltersState,
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState
} from "@tanstack/react-table"

import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "../table"

import {DataTablePagination} from "./datatablepagination"
import {DataTableToolbar} from "./datatabletoolbar";
import {DataTableViewOptions} from "./datatableviewoptions";
import {LoadingSpinner} from "../customSpinner";

interface DataTableProps<TData> {
    columns: ColumnDef<TData>[]
    data: TData[]
    tableTitle?: string
    textFilteringColumnName?: string
    textFilteringPlaceholder?: string
    filters: { filteringColumn: string, title: string, defaultValues?: string[] }[]
    showPagination?: boolean
    isLoading?: boolean
    setFilters?: (filters: any[]) => void
}

export function DataTable<TData>({
                                             columns,
                                             data,
                                             textFilteringColumnName,
                                             textFilteringPlaceholder,
                                             filters,
                                             showPagination = true,
                                             tableTitle,
                                             isLoading = false,
                                             setFilters,
                                         }: DataTableProps<TData>) {
    const [rowSelection, setRowSelection] = React.useState({})
    const [columnVisibility, setColumnVisibility] =
        React.useState<VisibilityState>(() => {
            const visibility: VisibilityState = {}
            columns.forEach((column) => {
                // @ts-ignore
                if (column.show !== undefined && column.show === false) {
                    visibility[column.id!] = false
                }
            })
            return visibility
        })

    useEffect(() => {
        // set hidden columns which have show: false
        const visibility: VisibilityState = {}
        columns.forEach((column) => {
            // @ts-ignore
            if (column.show !== undefined && column.show === false) {
                visibility[column.id!] = false
            }
        })
        setColumnVisibility(visibility)
    }, [columns]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        filters.filter(x => x.defaultValues).map(x => ({id: x.filteringColumn, value: x.defaultValues!}))
    )
    const [sorting, setSorting] = React.useState<SortingState>(
        // @ts-ignore
        columns.filter(x => x.sort !== undefined).map(x => ({id: x.id, desc: x.sort === "desc"}))
    )
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    useEffect(() => {
        table.setPageSize(10)
    }, [table])

    return (
        <div className="space-y-4 bg-backgroundmedium text-textmedium border rounded">
            <div className={"pt-4 px-4 flex space-x-4 items-center"}>
                {tableTitle && <div className={"font-semibold text-lg"}>{tableTitle}</div>}
                <DataTableToolbar table={table} textFilteringColumnName={textFilteringColumnName}
                                  textFilteringPlaceholder={textFilteringPlaceholder} filters={filters}/>
                <div className={"w-full flex justify-items-end"}>
                    <DataTableViewOptions table={table}/>
                </div>
            </div>
            <div className="min-h-[430px] overflow-auto">
                <Table>
                    <TableHeader className={"bg-backgrounddark rounded border-t"}>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead
                                            className={"px-4"}
                                            key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-full text-center"
                                >
                                    <div className="relative top-1/2 left-1/2">
                                        <LoadingSpinner/>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ) : table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell className={"px-4"}
                                                   key={cell.id}>

                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    No results found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            {showPagination &&
                <div className={"pb-2 px-2"}>
                    <DataTablePagination table={table}/>
                </div>
            }
        </div>)
}
