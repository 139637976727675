import React, {useEffect, useState} from "react";
import {Radio, RadioGroup} from '@headlessui/react'


import {AxiosResponse} from "axios";

import axios from "utility/customAxios";
import {BaseView} from "./BaseView";
import {cn} from "../components/ui/lib/utils";
import {CheckIcon} from "@radix-ui/react-icons";
import {Button} from "../components/ui/button";
import {PlanChangeDialog} from "../components/PlanChangeDialog";
import {CalendarClock} from "lucide-react";
import {AlertDescription, AlertTitle} from "../components/ui/alert";
import {MetricToChartData} from "./MetricsTest";
import {CardDescription, CardHeader, CardTitle} from "../components/ui/card";
import {Dialog, DialogContent, DialogTitle} from "../components/ui/dialog";
import {ButtonGroup} from "@mui/material";
import {Metric} from "../clients/metoro/metrics";
import {ChartType, MetoroChart} from "../components/Charts/MetoroChart";

export interface Product {
    id: string;
    name: string;
}

export interface ProductResponse {
    products: Product[];
}


export interface CurrentProductResponse {
    currentProductName: string;
    currentProductId: string;
    currentProductTrialEnd: number;
    nextBillingCycleProductId: string;
    nextBillingCycleProductName: string;
    nextBillingCycleDate: string;
}

export interface BillingResponse {
    billingRedirectURL: string;
}

export interface CostsResponse {
    currentCost: Cost;
    projectedCost: Cost;
}

export interface Cost {
    value: number;
    currency: string;
}

export interface GetUsageResponse {
    currentCost: Cost;
    projectedCost: Cost;
    usage: Metric;
    billingCycle: BillingCycle;
}

export interface BillingCycle {
    startDate: number;
    endDate: number;
}

export function getBillingFromApi() {
    return axios.get('/api/v1/billing') as Promise<AxiosResponse<BillingResponse>>
}

export function getCurrentProductFromApi() {
    return axios.get('/api/v1/billing/current-product') as Promise<AxiosResponse<CurrentProductResponse>>
}

export function getProductDataFromApi() {
    return axios.get('/api/v1/billing/products') as Promise<AxiosResponse<ProductResponse>>
}

export function getUsageDataFromApi() {
    return axios.get('/api/v1/billing/usage') as Promise<AxiosResponse<GetUsageResponse>>
}

export function convertProductToPlan(product: Product): Tier {
    return {
        id: product.id,
        name: product.name,
    }
}

export interface Tier {
    id: string;
    name: string;
}

export interface PlanComparisonProps {
    // Assumed to be in order of increasing price
    plans: Tier[];
    onChoosePlan: (plan: Tier) => Promise<void>;
    canClick: boolean;
    currentPlanId?: string;
    nextPlanId?: string;
    nextBillingCycleDate?: string;
}


export interface CheckoutResponse {
    downgradeScheduled?: boolean;
    cancelledDowngrade?: boolean;
    upgradeRedirectURL?: string;
}

export interface CheckoutRequest {
    productId: string;
}

export function checkout(productId: string) {
    return axios.post('/api/v1/checkout', {productId} as CheckoutRequest) as Promise<AxiosResponse<CheckoutResponse>>
}


const frequencies = [
    {value: 'month', label: 'Month'},
]
const tiers = [
    {
        name: 'Hobby',
        id: 'tier-hobby',
        href: 'https://us-east.metoro.io',
        featured: false,
        price: {month: '$0'},
        mainFeatures: ['1 Cluster', '1 User', '2 Nodes'],
    },
    {
        name: 'Scale',
        id: 'tier-scale',
        href: 'https://us-east.metoro.io/pricing',
        featured: true,
        price: {month: '$20'},
        mainFeatures: [
            'Unlimited clusters',
            'Unlimited users',
            'Unlimited nodes',
            'Support via slack',
        ],
    },
    {
        name: 'Enterprise',
        id: 'tier-enterprise',
        href: 'mailto:enterprise@metoro.io',
        featured: false,
        price: {month: 'Contact us'},
        mainFeatures: [
            'Unlimited clusters',
            'Unlimited users',
            'Unlimited nodes',
            '24/7 white glove support and onboarding',
            'Custom SLAs',
            'On-premises deployments',
        ],
    },
]


interface AwsMarketplaceDesiredBillingLocationProps {
    closeOverallDialog: () => void
}

function AwsMarketplaceDesiredBillingLocation({closeOverallDialog}: AwsMarketplaceDesiredBillingLocationProps) {
    const [openDialog, setOpenDialog] = useState(false);
    const [hasConnectedAwsMarketplaceAccount, setHasConnectedAwsMarketplaceAccount] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        axios.get('/api/v1/billing/isAwsMarketPlaceLinked').then(response => {
            setHasConnectedAwsMarketplaceAccount(response.data.awsMarketPlaceLinked);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    if (hasConnectedAwsMarketplaceAccount) {
        return <div className={"flex flex-col"}>
            <div className={"font-bold"}>
                Are you sure you want to switch to AWS Marketplace billing?
            </div>
            <div className={"mt-2"}>
                After clicking the button below, if you have accrued usage in the current billing cycle, it will
                immediately be charged to your card on file with stripe.
                Your billing method will then be switched to AWS Marketplace billing and you will start to see Metoro
                charges on your AWS bill.
            </div>
            <Button
                onClick={() => {
                    setOpenDialog(true);
                }}
                className={"bg-primarytransparent border-primary border text-textlight mt-4"}>
                Switch to AWS Marketplace billing
            </Button>
            <Dialog open={openDialog}>
                <DialogContent
                    className={"w-1/2 max-h-[50%] text-textmedium overflow-y-auto"}
                    onInteractOutside={() => setOpenDialog(false)}>
                    <DialogTitle>
                        Confirm billing location change
                    </DialogTitle>
                    Are you sure you want to switch to AWS Marketplace billing? You will be immediately charged for any
                    accrued usage in the current billing cycle on stripe.
                    <div className={"flex gap-x-2"}>
                        <Button
                            onClick={() => {
                                setOpenDialog(false);
                            }}
                            className={"bg-redtransparenter border border-red-500 text-textlight"}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                axios.post('/api/v1/billing/method', {
                                    billingMethod: "awsmarketplace"
                                }).then(() => {
                                    setOpenDialog(false);
                                    closeOverallDialog();
                                }).catch(err => {
                                    console.log(err);
                                });
                            }}
                            className={"bg-primarytransparent border border-primary text-textlight"}>
                            Confirm
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    }

    // Return instructions on how to connect an AWS Marketplace account
    return <div className={"flex flex-col gap-y-2"}>
        <div>
            It looks like you haven't connected your AWS Marketplace account yet.
        </div>
        <div className={"mb-2"}>
            To connect your AWS Marketplace account, follow these steps:
        </div>
        <div>
            1. Head to the Metoro AWS Marketplace listing <a className={"text-primary"}
            href={"https://aws.amazon.com/marketplace/pp/prodview-4v43fz25vi6ug"}>here</a>.
        </div>
        <div>
            2. Click on the "View purchase options" button in the top right.
        </div>
        <div>
            3. Click on the subscribe button.
        </div>
        <div>
            4. Click on set up your account.
        </div>
        <div>
            5. After you have followed the steps in Metoro, head back to this page and click the "Update your billing
            location" button.
        </div>

    </div>
}

interface StripeDesiredBillingLocationProps {
    closeOverallDialog: () => void
}

function StripeDesiredBillingLocation({closeOverallDialog}: StripeDesiredBillingLocationProps) {
    return <div className={"flex flex-col gap-y-2"}>
        <div className={"flex flex-col gap-y-2"}>
            To move to stripe billing, click the button below. We will stop sending usage reports to AWS Marketplace.
            <div>
                At the end of your AWS Marketplace billing cycle, you will be charged for any usage you have accrued up
                until now. You will then be billed through stripe for all future usage.
            </div>
            <div>
                This means that this month you will be charged twice, once through AWS Marketplace and once through
                stripe. The total amount will be the same, just split between the two billing methods.
            </div>
        </div>
        <Button
            onClick={() => {
                axios.post('/api/v1/billing/method', {
                    billingMethod: "stripe"
                }).then(() => {
                    closeOverallDialog();
                }).catch(err => {
                    console.log(err);
                });
            }}
            className={"bg-primarytransparent border-primary border text-textlight"}>
            Switch to Stripe billing
        </Button>
    </div>
}

function UpdateBillingLocationDialog(props: { openDialog: boolean, setOpenDialog: (open: boolean) => void }) {
    const [currentBillingLocation, setCurrentBillingLocation] = useState<string | undefined>(undefined);
    const [desiredBillingLocation, setDesiredBillingLocation] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!props.openDialog) {
            return;
        }
        axios.get('/api/v1/billing/method').then(response => {
            setCurrentBillingLocation(response.data.billingMethod);
        }).catch(err => {
            console.log(err);
        });
    }, [props.openDialog]);

    const closeDialog = () => {
        setDesiredBillingLocation(undefined);
        props.setOpenDialog(false);
    }

    return <Dialog open={props.openDialog}>
        <DialogContent
            className={"w-1/2 max-h-[50%] text-textmedium overflow-y-auto"}
            onInteractOutside={closeDialog}>
            <DialogTitle>
                Select your payment location
            </DialogTitle>
            <div className={"text-sm"}>
                You are currently paying through <b>{currentBillingLocation === "stripe" ? "Stripe" : "AWS Marketplace"}</b>.
            </div>

            Pick from the options below to update your payment location.
            <ButtonGroup className={"gap-x-2"}>
                <Button
                    className={cn((currentBillingLocation === "stripe" ? "bg-secondarytransparenter border-secondary cursor-not-allowed" : "bg-primarytransparent border-primary"), "border")}
                    onClick={() => {
                        if (currentBillingLocation === "stripe") {
                            setDesiredBillingLocation(undefined);
                            return;
                        }
                        setDesiredBillingLocation("stripe");
                    }}>Stripe</Button>
                <Button
                    className={cn((currentBillingLocation === "awsmarketplace" ? "bg-secondarytransparenter border-secondary" : "bg-primarytransparent border-primary"), "border")}
                    onClick={() => {
                        if (currentBillingLocation === "awsmarketplace") {
                            setDesiredBillingLocation(undefined);
                            return;
                        }
                        setDesiredBillingLocation("awsmarketplace");
                    }}>AWS Marketplace</Button>
            </ButtonGroup>
            {desiredBillingLocation === "awsmarketplace" &&
                <AwsMarketplaceDesiredBillingLocation closeOverallDialog={closeDialog}/>}
            {desiredBillingLocation === "stripe" && <StripeDesiredBillingLocation
                closeOverallDialog={closeDialog}
            />}
        </DialogContent>
    </Dialog>
}

const Billing = () => {
    const [plans, setPlans] = useState<Tier[]>();
    const [currentProductResponse, setCurrentProductResponse] = useState<CurrentProductResponse>();
    const [billingRedirectURL, setBillingRedirectURL] = useState<string>();
    const [hasAccessToBilling, setHasAccessToBilling] = useState<boolean | undefined>(undefined);
    const [frequency, setFrequency] = useState(frequencies[0])
    const [openDialogs, setOpenDialogs] = React.useState<boolean[]>(tiers.map(p => false));
    const [updateBillingLocationPopupOpen, setUpdateBillingLocationPopupOpen] = useState(false);
    const [isStripeBilling, setIsStripeBilling] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        setTimeout(() => {
        axios.get('/api/v1/billing/method').then(response => {
            setIsStripeBilling(response.data.billingMethod === "stripe");
        }).catch(err => {
            console.log(err);
        })}, 2000)
    }, [updateBillingLocationPopupOpen]);

    async function updateDetails() {
        await getProductDataFromApi().then(response => {
            let plansToUse = response.data.products.map((p: Product) => convertProductToPlan(p));
            setPlans(plansToUse)
        }).catch(err => {
            console.log(err);
        });
        await getCurrentProductFromApi().then(response => {
            setCurrentProductResponse(response.data);
        }).catch(err => {
            console.log(err);
        });
        await getBillingFromApi().then(async response => {
                setBillingRedirectURL(response.data.billingRedirectURL);
                setHasAccessToBilling(true);
            }
        ).catch(err => {
            if (err.response.status === 403 || err.response.status === 401) {
                setHasAccessToBilling(false);
                return;
            }
            console.log(err);
        });
    }

    useEffect(() => {
        updateDetails();
    }, []);

    let onChoosePlan = async (tier: Tier) => {
        if (tier.name === "Enterprise") {
            window.location.href = "mailto:sales@metoro.io";
        }
        let planId = "";
        for (let plan of plans!) {
            if (plan.name === tier.name) {
                planId = plan.id;
            }
        }

        await checkout(planId).then(response => {
            if (response.data.upgradeRedirectURL) {
                window.location.href = response.data.upgradeRedirectURL;
            }
        }).then(updateDetails).catch(err => {
            console.log(err);
        });
    }

    return <BaseView disableTimeRange title={"Billing"} disableClusterSelector={true}>
        <div className={"justify-center w-full items-center overflow-y-scroll"}>
            {currentProductResponse && (currentProductResponse.currentProductTrialEnd > (Date.now() / 1000)) &&
                <div className={"flex w-full items-center justify-center mb-12"}>
                    <div
                        className={"py-4 px-4 my-4 w-1/2 text-textlight rounded border-purple-400 bg-purple-400/10 border"}>
                        <div className={"flex flex-col gap-4"}>
                            <div className={"flex items-center gap-4"}>
                                <CalendarClock className={"w-8 h-8 text-purple-400"}/>
                                <AlertTitle className={"font-semibold text-lg"}>You are currently on a free trial for
                                    the {currentProductResponse.currentProductName} Plan! </AlertTitle>
                            </div>
                            <AlertDescription>
                                <div>You can use Metoro for free until your trial ends
                                    on {new Date(currentProductResponse?.currentProductTrialEnd * 1000).toLocaleDateString()}.
                                    {currentProductResponse.nextBillingCycleProductName && currentProductResponse.currentProductName !== currentProductResponse.nextBillingCycleProductName ?
                                        ` After your trial ends, you will be switched to
                                            the ${currentProductResponse?.nextBillingCycleProductName} plan.` : ""}
                                </div>
                            </AlertDescription>
                        </div>
                    </div>
                </div>
            }
            <div className="flow-root h-max mb-14">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="relative z-10">
                        <div className={"flex flex-col items-center justify-center"}>
                            <div id={"pricing"}
                                 className={cn("inline text-center font-display text-3xl tracking-tight text-textlight mb-6 ", currentProductResponse !== undefined && currentProductResponse!.currentProductTrialEnd > (Date.now() / 1000) ? "" : "mt-24")}>
                                {"Make a change to your plan"}
                            </div>
                            <div className={"flex gap-x-2"}>
                                <Button onClick={() => {
                                    setUpdateBillingLocationPopupOpen(true);
                                }}
                                        className={"bg-primarytransparent border border-primary text-textlight hover:border-primaryhover hover:text-white"}>
                                    Update your billing location
                                </Button>
                                {isStripeBilling &&
                                <Button onClick={async () => {
                                    if (billingRedirectURL && hasAccessToBilling) {
                                        window.location.href = billingRedirectURL!;
                                    }
                                }}
                                        className={"bg-primarytransparent border border-primary text-textlight hover:border-primaryhover hover:text-white"}>
                                    Update your billing details
                                </Button>}
                            </div>
                        </div>
                        <UpdateBillingLocationDialog
                            openDialog={updateBillingLocationPopupOpen}
                            setOpenDialog={setUpdateBillingLocationPopupOpen}
                        />
                        {frequencies.length > 1 &&
                            <div className="mt-16 flex justify-center">
                                <fieldset aria-label="Payment frequency">
                                    <RadioGroup
                                        value={frequency}
                                        onChange={setFrequency}
                                        className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-textlight"
                                    >
                                        {frequencies.map((option) => (
                                            <Radio
                                                key={option.value}
                                                value={option}
                                                className={({checked}) =>
                                                    cn(checked ? 'bg-indigo-500' : '', 'cursor-pointer rounded-full px-2.5 py-1')
                                                }
                                            >
                                                {option.label}
                                            </Radio>
                                        ))}
                                    </RadioGroup>
                                </fieldset>
                            </div>
                        }
                    </div>
                    <div
                        className="relative mx-auto mt-10 grid max-w-sm grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-3">
                        <svg
                            viewBox="0 0 604 512"
                            aria-hidden="true"
                            className=" hidden lg:block absolute -bottom-48 left-1/2 h-[40rem] -translate-x-1/2 translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] lg:-top-32 lg:bottom-auto lg:translate-y-0"
                        >
                            <ellipse cx={302} cy={256} fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)"
                                     rx={302}
                                     ry={256}/>
                            <defs>
                                <radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
                                    <stop stopColor="#38bdf8"/>
                                    <stop offset={2} stopColor="#38bdf8"/>
                                </radialGradient>
                            </defs>
                        </svg>
                        <div
                            className="hidden lg:absolute lg:inset-x-px lg:top-4 lg:bottom-4 lg:block lg:rounded-t lg:bg-backgrounddark lg:ring-1 lg:ring-primary"
                            aria-hidden="true"
                        />
                        {tiers.map((tier, index) => (
                            <div
                                key={tier.id}
                                className={cn(
                                    tier.featured
                                        ? 'z-10 bg-backgrounddark border border-primary shadow-xl'
                                        : ' ring-1 ring-white/10 lg:bg-transparent lg:pb-14 lg:ring-0',
                                    currentProductResponse?.currentProductName === tier.name ? "border-secondary" : currentProductResponse?.nextBillingCycleProductName === tier.name ? "border-orange-600" : '',
                                    'relative rounded'
                                )}
                            >
                                <div className="p-8 lg:pt-12 xl:p-10 xl:pt-14">
                                    <h3
                                        id={tier.id}
                                        className={cn(
                                            tier.featured ? 'text-textmedium' : 'text-textmedium',
                                            'text-sm font-semibold leading-6'
                                        )}
                                    >
                                        {tier.name}
                                    </h3>
                                    <div
                                        className="flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
                                        <div className="mt-2 flex items-center gap-x-4">
                                            <p
                                                className={cn(
                                                    tier.featured ? 'text-textmedium' : 'text-textmedium',
                                                    'text-4xl font-bold tracking-tight'
                                                )}
                                            >
                                                {
                                                    // @ts-ignore
                                                    tier.price[frequency.value]
                                                }
                                            </p>
                                            {
                                                // @ts-ignore
                                                tier.price[frequency.value] !== 'Contact us' &&
                                                <div className="text-sm leading-5">
                                                    <p className={tier.featured ? 'text-textmedium' : 'text-textmedium'}>USD</p>
                                                    <p
                                                        className={tier.featured ? 'text-textmedium' : 'text-textmedium'}
                                                    >{`/ node / ${frequency.value}`}</p>
                                                </div>}
                                        </div>
                                        <button
                                            onClick={() => {
                                                if (!isStripeBilling) {
                                                    return;
                                                }

                                                if (tier.name === currentProductResponse?.nextBillingCycleProductName) {
                                                    return;
                                                }
                                                if (tier.name === "Enterprise") {
                                                    window.location.href = "mailto:enterprise@metoro.io";
                                                    return
                                                }
                                                let newOpenDialogs = [...openDialogs];
                                                newOpenDialogs[index] = true;
                                                setOpenDialogs(newOpenDialogs);
                                            }}
                                            aria-describedby={tier.id}
                                            className={cn(
                                                tier.featured
                                                    ? 'rounded bg-secondarytransparenter border border-secondary shadow-sm hover:bg-secondarytransparent focus-visible:outline-indigo-600'
                                                    : 'rounded bg-primarytransparent border-primary border hover:bg-primary focus-visible:outline-white',
                                                'rounded py-2 px-3 text-center text-sm font-semibold leading-6 text-textlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
                                                currentProductResponse?.nextBillingCycleProductName === tier.name ? 'bg-gray-500 text-textmedium border-gray-700 hover:cursor-default hover:bg-gray-500' : '', !isStripeBilling ? 'bg-gray-500 text-textmedium border-gray-700 hover:cursor-default hover:bg-gray-500' : '',
                                            )}
                                        >
                                            {
                                                // @ts-ignore
                                                !isStripeBilling ? "Managed externally" : tier.name === currentProductResponse?.currentProductName && currentProductResponse.nextBillingCycleProductName === currentProductResponse.currentProductName ? "Current plan" : tier.name === currentProductResponse?.nextBillingCycleProductName ? "Changing to plan on " + (new Date(currentProductResponse?.currentProductTrialEnd * 1000).toLocaleDateString()) : tier.price[frequency.value] === 'Contact us' ? 'Contact us' : 'Change to plan'}
                                        </button>
                                    </div>
                                    <div className="mt-8 flow-root sm:mt-10">
                                        <ul
                                            role="list"
                                            className={cn(
                                                tier.featured
                                                    ? 'divide-white/5 border-gray-900/5 text-gray-600'
                                                    : 'divide-white/5 border-white/5 text-textlight',
                                                '-my-2 divide-y border-t text-sm leading-6 lg:border-t-0'
                                            )}
                                        >
                                            {tier.mainFeatures.map((mainFeature) => (
                                                <li key={mainFeature}
                                                    className="flex gap-x-3 py-2 text-textmedium">
                                                    <CheckIcon
                                                        className={cn(
                                                            tier.featured ? 'text-secondary' : 'text-textmedium',
                                                            'h-6 w-5 flex-none'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {mainFeature}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <PlanChangeDialog openDialog={openDialogs.at(index)!}
                                                      onConfirm={async () => {
                                                          // @ts-ignore
                                                          await onChoosePlan(tier).then(() => {
                                                              setOpenDialogs(new Array(tiers.length).fill(false));
                                                          })
                                                      }} onCancel={async () => {
                                        setOpenDialogs(new Array(tiers.length).fill(false));
                                    }}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <UsageGraphs isStripeBilling={isStripeBilling}/>
        </div>
    </BaseView>
}

const UsageGraphs = (props: {isStripeBilling? : boolean}) => {
    const [usageData, setUsageData] = useState<Metric>();
    const [currentCost, setCurrentCost] = useState<Cost>();
    const [projectedCost, setProjectedCost] = useState<Cost>();
    const [currentBillingCycle, setCurrentBillingCycle] = useState<BillingCycle>()

    useEffect(() => {
        getUsageDataFromApi().then((data) => {
            setUsageData(data.data.usage);
            setCurrentCost(data.data.currentCost);
            setProjectedCost(data.data.projectedCost);
            setCurrentBillingCycle(data.data.billingCycle);
        }).catch(err => {
            console.log(err);
        });
    }, []);
    let startDate = ""
    let endDate = ""
    if (currentBillingCycle !== undefined) {
        startDate = new Date(currentBillingCycle?.startDate * 1000).toLocaleDateString();
        endDate = new Date(currentBillingCycle?.endDate * 1000).toLocaleDateString();
    }
    return <div className={"w-full flex justify-center mt-28 mb-12"}>
        <div className={"flex flex-none flex-col w-full gap-8 mx-auto max-w-7xl px-6 lg:px-8"}>
            <div className="text-center font-display text-3xl tracking-tight text-textlight">
                Usage
            </div>
            <div className={"flex gap-4"}>
                <div className={"relative flex grow shrink"}>
                    <MetoroChart className={"border rounded"}
                                 styling={{borderless: true}}
                                 title={"Node Minutes per day"}
                                 dataToUse={MetricToChartData(usageData, false, undefined, undefined)}
                                 type={ChartType.Bar} timeUnit={"day"} timeUnitDisplay={"dd/MM"}></MetoroChart>
                </div>
                <div className={"flex flex-none flex-col gap-4 text-textmedium w-[300px]"}>
                    <CardHeader className="pb-2">
                        <CardDescription>Billing Cycle</CardDescription>
                        <CardTitle className="text-xl ">{startDate + " - " + endDate}</CardTitle>
                    </CardHeader>
                    <CardHeader className="pb-2 ">
                        <CardDescription>Month To Date</CardDescription>
                        {
                            props.isStripeBilling ? <CardTitle className="text-4xl">${Number(currentCost?.value).toFixed(2)}</CardTitle> : <CardTitle className="text-4xl">Managed externally</CardTitle>
                        }
                    </CardHeader>
                    <CardHeader className="pb-2 ">
                        <CardDescription>Projected End Of Month</CardDescription>
                        {
                            props.isStripeBilling ? <CardTitle className="text-4xl">${Number(projectedCost?.value).toFixed(2)}</CardTitle> : <CardTitle className="text-4xl">Managed externally</CardTitle>
                        }
                    </CardHeader>
                </div>
            </div>
        </div>
    </div>
}

export default Billing;