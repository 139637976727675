import React, {useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {Badge} from '../ui/badge';
import {Button} from '../ui/button';
import {Pause, Play, Settings} from 'lucide-react';
import {DataTable} from '../ui/datatable/datatable';
import {ColumnDef} from '@tanstack/react-table';
import {DataTableColumnHeader} from '../ui/datatable/datatablecolumnheader';
import axios from '../../utility/customAxios';
import {WorkflowDetailsDrawerButton} from './WorkflowDetailsDrawer';

export interface Workflow {
    uuid: string;
    name: string;
    description: string;
    workflowType: string;
    parameters: any;
    parametersSchema: any;
    enabled: boolean;
    createdAt: string;
    updatedAt: string;
}

interface ListWorkflowsResponse {
    workflows: Workflow[];
    total: number;
}

function getWorkflowTypeColor(type: string) {
    switch (type.toLowerCase()) {
        case "rightsizing":
            return "bg-purple-500";
        case "alert_monitor":
            return "bg-red-500";
        case "sync_metrics":
            return "bg-blue-500";
        default:
            return "bg-primary";
    }
}

function getStatusColor(enabled: boolean) {
    return enabled ? "bg-green-500" : "bg-yellow-600";
}

const filters: any = [
    {
        filteringColumn: 'workflowType',
        title: 'Type',
        options: [
            {
                label: 'Rightsizing',
                value: 'rightsizing',
            },
            {
                label: 'Alert Monitor',
                value: 'alert_monitor',
            },
            {
                label: 'Sync Metrics',
                value: 'sync_metrics',
            },
        ],
    },
    {
        filteringColumn: 'enabled',
        title: 'Status',
        options: [
            {
                label: 'Enabled',
                value: 'true',
            },
            {
                label: 'Disabled',
                value: 'false',
            },
        ],
    },
];

export function WorkflowsTable() {
    const [pageIndex, setPageIndex] = useState(0);
    const [sorting, setSorting] = useState<any>([]);
    const [columnFilters, setColumnFilters] = useState<any>([]);

    const {data, isLoading, error, refetch} = useQuery<ListWorkflowsResponse>({
        queryKey: ['workflows', pageIndex, sorting, columnFilters],
        queryFn: async () => {
            const response = await axios.post('/api/v1/workflows/list', {
                limit: 10000,
            });
            return response.data;
        },
    });

    const columns: ColumnDef<Workflow>[] = [
        {
            accessorKey: 'name',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Name"/>
            ),
            cell: ({row}) => (
                <div className="flex space-x-2">
                    <span className="max-w-[500px] truncate font-medium">
                        {row.getValue('name')}
                    </span>
                </div>
            ),
        },
        {
            accessorKey: 'description',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Description"/>
            ),
            cell: ({row}) => (
                <div className="flex space-x-2">
                    <span className="max-w-[500px] truncate text-muted-foreground">
                        {row.getValue('description')}
                    </span>
                </div>
            ),
        },
        {
            accessorKey: 'workflowType',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Type"/>
            ),
            cell: ({row}) => {
                const type = row.getValue('workflowType') as string;
                return (
                    <Badge
                        className={`${getWorkflowTypeColor(type)} text-white rounded flex items-center gap-1 max-w-max`}>
                        {type}
                    </Badge>
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
        },
        {
            accessorKey: 'enabled',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Status"/>
            ),
            cell: ({row}) => {
                const enabled = row.getValue('enabled') as boolean;
                return (
                    <Badge
                        className={`${getStatusColor(enabled)} text-white rounded flex items-center gap-1 max-w-max`}>
                        {enabled ? 'Enabled' : 'Disabled'}
                    </Badge>
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(String(row.getValue(id)));
            },
        },
        {
            accessorKey: 'updatedAt',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Last Updated"/>
            ),
            cell: ({row}) => {
                const date = new Date(row.getValue('updatedAt'));
                return date.toLocaleString();
            },
        },
        {
            id: 'actions',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Actions"/>
            ),
            cell: ({row}) => {
                const workflow = row.original;
                return (
                    <div className="flex items-center gap-2">
                        <WorkflowDetailsDrawerButton workflow={workflow} onWorkflowUpdate={refetch} />
                    </div>
                );
            },
        },
    ];

    if (isLoading) {
        return <div className="flex justify-center items-center h-64">Loading workflows...</div>;
    }

    if (error) {
        return <div className="flex justify-center items-center h-64">Error loading workflows</div>;
    }

    return (
        <DataTable<Workflow>
            columns={columns}
            data={data?.workflows || []}
            filters={filters}
            tableTitle={"Workflows"}
            textFilteringColumnName="name"
            textFilteringPlaceholder="Filter by name..."
            isLoading={isLoading}
        />
    );
}
