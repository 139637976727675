import {LoadingSpinner} from "../components/ui/customSpinner";
import {BaseView} from "./BaseView";
import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import {cn, useDebounce, usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import {useDebouncedCallback} from "use-debounce";
import {FeedSearch} from "./Traces";
import axios from "../utility/customAxios";
import {Attribute} from "../types/telemetry";
import {TimeRange} from "../types/time";
import {AxiosPromise} from "axios";
import {plainToInstance} from "class-transformer";
import {FilterPanel, TelemetryLevelFilters} from "../components/Filter/Filter";
import {Drawer} from "vaul";
import {TimelineViewer} from "./Logs";
import LogMessageContainer from "../components/SidePanel/Log/LogMessageContainer";
import {ServerIcon} from "lucide-react";
import TagContainer from "../components/SidePanel/Tags/TagContainer";
import {DateTimePrecision, displayDateTimeWithSelectedFormat} from "../utility/displayDateTime";
import {GetLogMetricsResponse} from "../clients/metoro/metrics";

export interface K8sEvent {
    environment: string
    latestTimestamp: number
    uuid: string
    version: string
    eventType: string
    resourceName: string
    serviceName: string
    namespace: string
    reason: string
    message: string
    involvedObjectName: string
    involvedObjectNamespace: string
    involvedObjectKind: string
    reportingInstance: string
    reportingComponent: string
    involvedObjectUUID: string
    involvedObjectVersion: string
    count: number
}

interface K8sEvents {
    k8sEvents: K8sEvent[]
    filterAttributes: Map<string, Attribute[]>
}

class K8sEventsResponse {
    constructor(k8sEvents: K8sEvent[]
    ) {
        this.k8sEvents = k8sEvents;
    }

    k8sEvents: K8sEvent[]
}


interface K8sEventsProps {
    noBaseView?: boolean
    justTraceFeed?: boolean
    service?: string
    filter?: Map<string, string[]>
    removeService?: boolean
    sidePanelSize?: string
}

async function getK8sEvents(setIsLoadingK8sEvents: (value: (((prevState: boolean) => boolean) | boolean)) => void,
                            debouncedFilter: Map<string, string[]>,
                            timeRange: TimeRange,
                            debouncedExcludeFilter: Map<string, string[]>,
                            debouncedRegexes: string[],
                            debouncedExcludeRegexes: string[],
                            prevMaxTime: number | undefined,
                            debouncedAscending: boolean,
                            environments: string[],
                            setK8sEvents: (value: (((prevState: K8sEvents) => K8sEvents) | K8sEvents)) => void,
                            getK8sEventsAbortController: AbortController,
                            setGetK8sEventsAbortController: (value: (((prevState: AbortController) => AbortController) | AbortController)) => void
) {
    try {
        getK8sEventsAbortController.abort();
        const newAbortController = new AbortController();
        setGetK8sEventsAbortController(newAbortController);
        setIsLoadingK8sEvents(true)
        const filters = Object.fromEntries(debouncedFilter);
        const startEnd = timeRange.getStartEnd();
        let excludeFilters = Object.fromEntries(debouncedExcludeFilter);
        const d: AxiosPromise<K8sEventsResponse> = axios.post("/api/v1/k8s/events", {
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "filters": filters,
                "excludeFilters": excludeFilters,
                "regexes": debouncedRegexes,
                "excludeRegexes": debouncedExcludeRegexes,
                "prevEndTime": prevMaxTime,
                "ascending": debouncedAscending,
                "environments": environments[0] === "" ? [] : environments
            },
            {signal: newAbortController.signal}
        )
        const awaited = (await d).data;
        let awaitedK8sEvents = plainToInstance(K8sEventsResponse, awaited)
        setK8sEvents(prevState => {
            if (prevMaxTime === undefined) {
                return {...prevState, k8sEvents: awaitedK8sEvents.k8sEvents}
            } else {
                return {...prevState, k8sEvents: [...prevState.k8sEvents, ...awaitedK8sEvents.k8sEvents]}
            }
        })
        setIsLoadingK8sEvents(false)
    } catch (e) {
        // @ts-ignore
        if (e.code === "ERR_CANCELED") {
            return;
        }
        console.error(e);
        setIsLoadingK8sEvents(false)
    }
}

async function updateK8sEventsAttributesSummary(
    setIsLoadingFilters: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    filter: Map<string, string[]>,
    excludeFilter: Map<string, string[]>,
    timeRange: TimeRange,
    regexes: string[],
    excludeRegexes: string[],
    environments: string[],
    setK8sEvents: (value: (((prevState: K8sEvents) => K8sEvents) | K8sEvents)) => void,
    abortController: AbortController,
    setAbortController: (value: (((prevState: AbortController) => AbortController) | AbortController)) => void,
    openFilters: Map<string, boolean>
) {
    try {
        abortController.abort();
        const newAbortController = new AbortController();
        setAbortController(newAbortController);
        const newFilters = new Map<string, string[]>(filter);
        const filters = Object.fromEntries(newFilters)
        const excludeFilters = Object.fromEntries(excludeFilter);
        const startEnd = timeRange.getStartEnd();
        for (let attribute of openFilters.keys()) {
            if (openFilters.get(attribute) !== true) {
                continue;
            }
            axios.post("/api/v1/k8s/events/summaryIndividualAttribute", {
                    "startTime": Math.floor(startEnd[0].getTime() / 1000),
                    "endTime": Math.floor(startEnd[1].getTime() / 1000),
                    // Removed because we want to keep everything for now
                    "excludeFilters": excludeFilters,
                    // Removed because we want to keep everything for now
                    "filters": filters,
                    // Removed because we want to keep everything for now
                    "regexes": regexes,
                    // Removed because we want to keep everything for now
                    "excludeRegexes": excludeRegexes,
                    "environments": environments[0] === "" ? [] : environments,
                    "attribute": attribute
                },
                {signal: newAbortController.signal}
            ).then((response) => {
                let attributes = response.data.attribute;
                setK8sEvents(prevState => {
                    let prevMap = prevState.filterAttributes;
                    prevMap.set(attribute, attributes);
                    return {...prevState, filterAttributes: prevMap}
                })
            })
        }
    } catch (e) {
        // @ts-ignore
        if (e.code === "ERR_CANCELED") {
            return;
        }
        setIsLoadingFilters(false)
        console.error(e);
    }
}

async function updateK8sEventsMetrics(
    setIsLoadingTimeline: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    timeRange: TimeRange,
    filter: Map<string, string[]>,
    excludeFilter: Map<string, string[]>,
    regexes: string[],
    excludeRegexes: string[],
    environments: string[],
    setEventTimeMetrics: (value: (((prevState: (GetLogMetricsResponse | undefined)) => (GetLogMetricsResponse | undefined)) | GetLogMetricsResponse | undefined)) => void,
    abortController: AbortController,
    setAbortController: (value: (((prevState: AbortController) => AbortController) | AbortController)) => void
) {
    try {
        abortController.abort();
        const newAbortController = new AbortController();
        setAbortController(newAbortController);
        setIsLoadingTimeline(true)
        const startEnd = timeRange.getStartEnd();
        const filters = Object.fromEntries(filter)
        const excludeFilters = Object.fromEntries(excludeFilter);
        axios.post("/api/v1/k8s/events/metrics", {
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                // Removed because we want to keep everything for now
                "excludeFilters": excludeFilters,
                // Removed because we want to keep everything for now
                "filters": filters,
                // Removed because we want to keep everything for now
                "regexes": regexes,
                // Removed because we want to keep everything for now
                "excludeRegexes": excludeRegexes,
                // Added so we can get colourful metric bars for each eventtype.
                "splits": ["EventType"],
                "environments": environments[0] === "" ? [] : environments,
            },
            {signal: newAbortController.signal}
        ).then((response) => {
            setEventTimeMetrics(response.data)
            setIsLoadingTimeline(false)
        })
    } catch (e) {
        // @ts-ignore
        if (e.code === "ERR_CANCELED") {
            return;
        }
        console.error(e);
        setIsLoadingTimeline(false)
    }
}

export function K8sEvents(props: K8sEventsProps) {
    const [isLoadingTimeline, setIsLoadingTimeline] = useState<boolean>(true);
    const [isLoadingK8sEvents, setIsLoadingK8sEvents] = useState<boolean>(true)
    const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const timeRange = useSelector(timerange.selectors.getTimeRange)

    const [k8sEvents, setK8sEvents] = useState<K8sEvents>({k8sEvents: [], filterAttributes: new Map()});
    const [prevMaxTime, setPrevMaxTime] = useState<number>();
    const [k8sEventsTimeMetrics, setK8sEventsTimeMetrics] = useState<GetLogMetricsResponse>();
    const [ascending, setAscending] = useState<boolean>(false);
    const debouncedAscending = useDebounce(ascending, 100);

    const [filter, setFilter] = useState<Map<string, string[]>>(() => {
        const filter = new Map<string, string[]>();
        const search = searchParams.get("filter");
        if (search !== null) {
            const filterObject = JSON.parse(search);
            for (const [key, value] of Object.entries(filterObject)) {
                filter.set(key, value as string[])
            }
        }
        if (props.service !== undefined) {
            filter.set("service.name", [props.service])
        }
        return filter;
    });
    const [excludeFilter, setExcludeFilter] = useState<Map<string, string[]>>(
        () => {
            const excludeFilter = new Map<string, string[]>();
            const search = searchParams.get("excludeFilter");
            if (search !== null) {
                const excludeFilterObject = JSON.parse(search);
                for (const [key, value] of Object.entries(excludeFilterObject)) {
                    excludeFilter.set(key, value as string[])
                }
            }
            return excludeFilter;
        }
    );
    const [environments, setEnvironments] = useState<string[]>([]);

    const debouncedGetK8sEvents = useDebouncedCallback(getK8sEvents, 10);
    const [getK8sEventsAbortController, setGetK8sEventsAbortController] = useState(new AbortController());
    const debouncedUpdateK8sEventsSummary = useDebouncedCallback(updateK8sEventsAttributesSummary, 10);
    const [updateK8sEventsSummaryAbortController, setUpdateK8sEventsSummaryAbortController] = useState(new AbortController());
    const debouncedUpdateK8sEventsMetrics = useDebouncedCallback(updateK8sEventsMetrics, 10);
    const [updateK8sEventsMetricsAbortController, setUpdateK8sEventsMetricsAbortController] = useState(new AbortController());
    const [regexes, setRegexes] = useState<string[]>(
        () => {
            const regexes = searchParams.get("regexes") || "";
            if (regexes !== "") {
                return JSON.parse(regexes)
            }
            return []
        }
    );
    const [excludeRegexes, setExcludeRegexes] = useState<string[]>(
        () => {
            const excludeRegexes = searchParams.get("excludeRegexes") || "";
            if (excludeRegexes !== "") {
                return JSON.parse(excludeRegexes)
            }
            return []
        }
    );


    useEffect(() => {
        axios.get("/api/v1/k8s/events/summaryAttributes").then((response) => {
            let attributes = response.data.attributes;
            setK8sEvents(prevState => {
                let newMap = new Map<string, Attribute[]>(prevState.filterAttributes);
                for (let attribute of attributes) {
                    if (!newMap.has(attribute)) {
                        newMap.set(attribute, [])
                    }
                }
                return {...prevState, filterAttributes: newMap}
            })
        })
    }, [])

    // OpenFilters is used to know what we need to know what values we need to pull when something updates
    const [openFilters, setOpenFilters] = useState<Map<string, boolean>>(() => {
        let o = new Map<string, boolean>();
        o.set("EventType", true)
        o.set("Reason", true)
        o.set("service.name", true)
        return o;
    });
    // Request refresh is used when a filter is opened so we need to refresh the values
    const [requestRefresh, setRequestRefresh] = useState<Map<string, boolean>>(new Map());

    useEffect(() => {
        let environments = searchParams.get("environment");
        if (environments !== null) {
            setEnvironments([environments])
        }
    }, [searchParams])


    useEffect(() => {
        let filterJson = searchParams.get("regexes") || "";
        if (filterJson !== "") {
            setRegexes(JSON.parse(filterJson))
        }
        filterJson = searchParams.get("excludeRegexes") || "";
        if (filterJson !== "") {
            setExcludeRegexes(JSON.parse(filterJson))
        }
    }, [searchParams])

    useEffect(() => {
        let filterJson = searchParams.get("excludeFilter") || "";
        if (filterJson !== "") {
            const excludeFilterMap = new Map<string, string[]>();
            const excludeFilterObject = JSON.parse(filterJson);
            for (const [key, value] of Object.entries(excludeFilterObject)) {
                excludeFilterMap.set(key, value as string[])
            }
            setExcludeFilter(excludeFilterMap)
        }
        filterJson = searchParams.get("filter") || "";
        if (filterJson !== "") {
            const filterMap = new Map<string, string[]>();
            const filterObject = JSON.parse(filterJson);
            for (const [key, value] of Object.entries(filterObject)) {
                filterMap.set(key, value as string[])
            }
            setFilter(filterMap)
        }
    }, [searchParams, props.service])

    useEffect(() => {
        const ascending = searchParams.get("ascending");
        if (ascending !== null) {
            setAscending(ascending === "true")
        }
    }, [searchParams])

    useEffect(() => {
        if (ascending) {
            setPrevMaxTime(undefined)
        }
    }, [debouncedAscending])

    // Reset prevMaxTime when filter changes so that we can fetch new traces
    useEffect(() => {
        setPrevMaxTime(undefined)
    }, [filter, excludeFilter, regexes, excludeRegexes, timeRange, environments]);


    const scrolledToBottomHandler = () => {
        if (k8sEvents == undefined || k8sEvents.k8sEvents == undefined || k8sEvents.k8sEvents.length === 0) {
            return;
        }
        setPrevMaxTime(k8sEvents.k8sEvents[k8sEvents.k8sEvents.length - 1].latestTimestamp + (ascending ? 1 : -1))
    }

    useEffect(() => {
        // Remove all of the filters, regexes and exclude regexes when from the search params when the service is removed
        return () => {
            if (window.location.pathname === "/service") {
                setSearchParams(prev => {
                    prev.delete("filter")
                    prev.delete("excludeFilter")
                    prev.delete("regexes")
                    prev.delete("excludeRegexes")
                    return prev
                })
            }
        }
    }, [])

    useEffect(() => {
        // Get Traces
        debouncedGetK8sEvents(setIsLoadingK8sEvents, filter, timeRange, excludeFilter, regexes, excludeRegexes, prevMaxTime, debouncedAscending, environments, setK8sEvents, getK8sEventsAbortController, setGetK8sEventsAbortController);
    }, [prevMaxTime, debouncedAscending, filter, excludeFilter, regexes, excludeRegexes, timeRange, environments]);

    useEffect(() => {
        debouncedUpdateK8sEventsSummary(setIsLoadingFilters, filter, excludeFilter, timeRange, regexes, excludeRegexes, environments, setK8sEvents, updateK8sEventsSummaryAbortController, setUpdateK8sEventsSummaryAbortController, openFilters);
    }, [filter, excludeFilter, regexes, excludeRegexes, timeRange, environments]);


    // If we request a refresh, we need to update the k8s summary for that attribute
    useEffect(() => {
        if (requestRefresh.size === 0) {
            return;
        }
        let requestedFilters = new Map<string, boolean>();
        requestRefresh.forEach((value, key) => {
            requestedFilters.set(key, value)
        })
        setRequestRefresh(prev => {
            let newMap = new Map<string, boolean>(prev);
            requestedFilters.forEach((value, key) => {
                newMap.delete(key)
            })
            return newMap
        })
        debouncedUpdateK8sEventsSummary(setIsLoadingFilters, filter, excludeFilter, timeRange, regexes, excludeRegexes, environments, setK8sEvents, updateK8sEventsSummaryAbortController, setUpdateK8sEventsSummaryAbortController, requestedFilters);
    }, [requestRefresh]);


    useEffect(() => {
        debouncedUpdateK8sEventsMetrics(setIsLoadingTimeline, timeRange, filter, excludeFilter, regexes, excludeRegexes, environments, setK8sEventsTimeMetrics, updateK8sEventsMetricsAbortController, setUpdateK8sEventsMetricsAbortController);
    }, [filter, excludeFilter, regexes, excludeRegexes, timeRange, environments]);

    const customEventColours = new Map<Record<string, string>, string[]>(
        [
            [
                {key: "EventType", value: "Warning",},
                ["#ff9f40", "#ff9f4033"]
            ],
            [
                {key: "EventType", value: "Normal",},
                ["#36a2eb", "#36a2eb33"]
            ],
        ])

    const innerElement = <div className={"flex flex-col grow shrink min-h-0 min-w-0"}>
        <div className={"flex flex-none relative"}>
            {isLoadingTimeline && <LoadingSpinner className={`absolute top-1/2 left-1/2 z-40"}`}/>}
            <TimelineViewer metric={k8sEventsTimeMetrics} title={"Number of events"}
                            customCategoryColour={customEventColours}/>
        </div>
        <MainK8sEventsView
            dropDownFunction={(attribute: string) => {
                let excludeFilters = Object.fromEntries(excludeFilter);
                let filters = Object.fromEntries(filter);
                return axios.post("/api/v1/k8s/events/summaryIndividualAttribute", {
                    "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                    "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                    // Removed because we want to keep everything for now
                    "excludeFilters": excludeFilters,
                    // Removed because we want to keep everything for now
                    "filters": filters,
                    // Removed because we want to keep everything for now
                    "regexes": regexes,
                    // Removed because we want to keep everything for now
                    "excludeRegexes": excludeRegexes,
                    "environments": environments[0] === "" ? [] : environments,
                    "attribute": attribute
                }).then((response) => {
                    return response.data.attribute;
                })
            }}
            requestRefresh={setRequestRefresh}
            setIsOpened={setOpenFilters}
            ascending={ascending}
            setAscending={(ascended: boolean) => {
                setSearchParams(prev => {
                    let prevParams = new URLSearchParams(window.location.search)
                    prevParams.set("ascending", ascended.toString())
                    return prevParams
                })
            }}
            isLoadingK8sEvents={isLoadingK8sEvents} isLoadingFilter={isLoadingFilters}
            setFilter={(filter: Map<string, string[]>) => setSearchParams(prev => {
                let existing = new URLSearchParams(window.location.search)
                existing.set("filter", JSON.stringify(Object.fromEntries(filter)))
                return existing
            })}
            filter={filter}
            k8sEvents={k8sEvents}
            scrolledToBottomHandler={scrolledToBottomHandler}
            sidePanelSize={props.sidePanelSize}
            excludeFilter={excludeFilter}
            setExcludeFilter={(filter: Map<string, string[]>) => setSearchParams(prev => {
                let existing = new URLSearchParams(window.location.search)
                existing.set("excludeFilter", JSON.stringify(Object.fromEntries(filter)))
                return existing
            })} regexes={regexes}
            setRegexes={(regexes: string[]) => setSearchParams(prev => {
                let existing = new URLSearchParams(window.location.search)
                existing.set("regexes", JSON.stringify(regexes))
                return existing
            })}
            excludeRegexes={excludeRegexes}
            setExcludeRegexes={(regexes: string[]) => setSearchParams(prev => {
                let existing = new URLSearchParams(window.location.search)
                existing.set("excludeRegexes", JSON.stringify(regexes))
                return existing
            })}
        />
    </div>

    return (props.noBaseView ?
        innerElement
        : (
            <BaseView title={"K8s Events"}>
                <div className={"p-4 flex flex-col grow shrink bg-backgrounddark min-w-0 min-h-0"}>
                    {innerElement}
                </div>
            </BaseView>
        ));
}


function getIndicatorColorForEventType(eventType: string): string {
    let indicatorColor = "bg-gray-500";
    if (eventType === "Normal") {
        indicatorColor = "bg-blue-500";
    }
    if (eventType === "Warning") {
        indicatorColor = "bg-orange-500";
    }
    return indicatorColor;
}

function MainK8sEventsView(props: {
    ascending: boolean,
    setAscending
        :
        (ascended: boolean) => void
    isLoadingK8sEvents?: boolean,
    isLoadingFilter?: boolean,
    k8sEvents: K8sEvents,
    setFilter: (filter: Map<string, string[]>) => void,
    filter: Map<string, string[]>,
    scrolledToBottomHandler: (() => void)
    sidePanelSize?: string
    excludeFilter: Map<string, string[]>
    setExcludeFilter: (filter: Map<string, string[]>) => void
    regexes: string[]
    setRegexes: (regexes: string[]) => void
    excludeRegexes: string[]
    setExcludeRegexes: (regexes: string[]) => void
    requestRefresh?: React.Dispatch<React.SetStateAction<Map<string, boolean>>>
    setIsOpened?: React.Dispatch<React.SetStateAction<Map<string, boolean>>>
    dropDownFunction?: (attribute: string) => Promise<Attribute[]>
}) {
    const [shrinkFilter, setShrinkFilter] = useState<boolean>(false);
    const filteredAttributesWithoutEventType = new Map<string, Attribute[]>();
    for (let [key, value] of props.k8sEvents.filterAttributes) {
        if (key !== "EventType") {
            filteredAttributesWithoutEventType.set(key, value)
        }
    }
    return <div className={"w-full min-w-0 min-h-0 flex justify-between grow shrink"}>
        <div className={"flex flex-none relative"}>
            {!shrinkFilter && props.isLoadingFilter && <LoadingSpinner className={`absolute top-1/2 left-1/2 z-40"}`}/>}
            <FilterPanel
                initiallyOpenFilterKeys={["service.name", "Reason"]}
                attributes={filteredAttributesWithoutEventType} setFilter={props.setFilter}
                filter={props.filter}
                telemetryFiltersComponent={
                    <TelemetryLevelFilters filter={props.filter} setFilter={props.setFilter}
                                           attributes={props.k8sEvents.filterAttributes}
                                           excludeFilter={props.excludeFilter}
                                           setExcludeFilter={props.setExcludeFilter}
                                           indicatorColourFunction={getIndicatorColorForEventType}
                                           levelAttributeKey={"EventType"}
                    />
                }
                filteringCriteria={""}
                setExcludeFilter={props.setExcludeFilter}
                excludeFilter={props.excludeFilter}
                setFilterShrank={setShrinkFilter}
                requestRefresh={props.requestRefresh}
                setIsOpened={props.setIsOpened}
            />
        </div>
        <div className={"flex grow shrink min-w-0 min-h-0 relative"}>
            {props.isLoadingK8sEvents && <LoadingSpinner className={`absolute top-1/2 left-1/2 z-40"}`}/>}
            <div className={"pl-4 min-w-0 min-h-0 grow shrink w-full flex flex-col"}>
                <FeedSearch
                    dropDownFunction={props.dropDownFunction}
                    filterAttributes={Array.from(props.k8sEvents.filterAttributes.keys())}
                    filter={props.filter} setFilter={props.setFilter}
                    excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
                    regexes={props.regexes} setRegexes={props.setRegexes}
                    excludeRegexes={props.excludeRegexes} setExcludeRegexes={props.setExcludeRegexes}
                />
                <K8sEventsFeed
                    ascending={props.ascending}
                    setAscending={props.setAscending}
                    regex={props.regexes !== undefined && props.regexes.length > 0 ? props.regexes[0] : ""}
                    k8sEvents={props.k8sEvents.k8sEvents}
                    scrolledToBottomHandler={props.scrolledToBottomHandler}
                    sidePanelSize={props.sidePanelSize}
                    isLoadingK8sEvents={props.isLoadingK8sEvents}
                    filter={props.filter}
                    setFilter={props.setFilter}
                    excludeFilter={props.excludeFilter}
                    setExcludeFilter={props.setExcludeFilter}
                    isFilterShown={!shrinkFilter}
                />
            </div>
        </div>
    </div>
}

function K8sEventsFeed(props: {
    ascending: boolean,
    setAscending: (ascended: boolean) => void
    removeService?: boolean,
    regex: string,
    k8sEvents: K8sEvent[],
    scrolledToBottomHandler: () => void
    sidePanelSize?: string
    isLoadingK8sEvents?: boolean
    filter: Map<string, string[]>
    setFilter: (filter: Map<string, string[]>) => void
    excludeFilter: Map<string, string[]>
    setExcludeFilter: (filter: Map<string, string[]>) => void
    showExpand?: boolean
    isFilterShown: boolean
}) {
    const navigate = usePreserveQueryParamsNavigate();
    const handleScroll = (e: any) => {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1
        if (bottom) {
            props.scrolledToBottomHandler();
        }
    }

    return <div
        className="max-w-full grow mt-4 min-w-0 bg-backgroundmedium border rounded min-h-0 w-full shrink flex flex-col">
        <div className={"flex justify-end"}>
            <div
                className="w-full h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 inline-flex truncate">
                <div
                    className={`flex-none w-[152px] h-full flex justify-between font-normal leading-8 text-textmedium text-xl`}>
                    <div>Time</div>
                    <div onClick={() => {
                        if (props.setAscending) {
                            props.setAscending(!props.ascending)
                        }
                    }} className="hover:cursor-pointer text-sm flex flex-col justify-center pr-4">
                        {props.ascending ? "▲" : "▼"}
                    </div>
                </div>
                <div
                    className={"w-[200px] flex-none font-normal leading-8 text-textmedium text-xl"}>
                    ServiceName
                </div>
                {!props.removeService &&
                    <div
                        className="w-[200px] flex-none font-normal leading-8 text-textmedium text-xl ">Reason
                    </div>}
                <div
                    className={"w-[290px] flex-none font-normal leading-8 text-textmedium text-xl max-[1460px]:hidden "}>InvolvedObject
                </div>
                <div
                    className="w-[100px] flex-none items-center font-normal leading-8 text-textmedium text-xl ">Count
                </div>
                <div
                    className={"flex grow shrink font-normal leading-8 text-textmedium text-xl "}>Message
                </div>


            </div>
            {/*{props.showExpand &&*/}
            {/*    <Maximize2Icon*/}
            {/*        className={"w-4 h-4 text-textdark border-l border-b rounded-bl hover:text-primary hover:cursor-pointer"}*/}
            {/*        onClick={() => {*/}
            {/*            let existing = new URLSearchParams(window.location.search)*/}
            {/*            const currentServiceName = existing.get("service")*/}
            {/*            const filterBy = {"server.service.name": [currentServiceName]}*/}
            {/*            navigate(`/traces?filter=${JSON.stringify(filterBy)}`)*/}
            {/*        }}/>*/}
            {/*}*/}
        </div>
        {
            (props.isLoadingK8sEvents !== undefined && !props.isLoadingK8sEvents) && props.k8sEvents.length === 0 &&
            <div className="border-t flex-grow flex items-center justify-center">
                <div className="text-textdark text-lg">No K8s events found. Try selecting a different time period.</div>
            </div>
        }
        <div
            className="max-w-full max-h-full min-w-0 min-h-0 overflow-y-auto shrink scrollMedium border-t rounded-b overflow-x-clip"
            onScroll={handleScroll}>
            {
                props.k8sEvents.map((event, index) => {
                    return <K8sEventsView removeService={props.removeService} regex={props.regex} key={index}
                                          k8sEvent={event}
                                          sidePanelSize={props.sidePanelSize}
                                          filter={props.filter} setFilter={props.setFilter}
                                          excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
                                          isFilterShown={props.isFilterShown}
                    />
                })
            }
        </div>
    </div>
}

function K8sEventsView(props: {
    removeService?: boolean,
    regex: string,
    k8sEvent: K8sEvent,
    sidePanelSize?: string
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void
    isFilterShown: boolean
}) {
    let indicatorColor = "bg-gray-500";
    if (props.k8sEvent.eventType !== undefined) {
        if (props.k8sEvent.eventType == "Normal") {
            indicatorColor = "bg-primary"
        } else {
            indicatorColor = "bg-amber-500"
        }
    }

    let sidePanelSize = "w-2/3";
    if (props.sidePanelSize !== undefined) {
        sidePanelSize = props.sidePanelSize;
    }

    let match = new RegExp("");
    try {
        match = new RegExp(props.regex);
    } catch (e) {
        console.error(e)
    }

    return <Drawer.Root direction="right">
        <Drawer.Trigger asChild>
            <div
                className="w-full max-w-full h-8 px-2 justify-start items-center gap-4 flex hover:bg-backgroundlight overflow-x-clip">
                <div
                    className="w-full h-8 px-2 justify-start items-center gap-4 flex hover:bg-backgroundlight overflow-x-clip hover:cursor-pointer">
                    <div className="flex-none h-8 w-[152px] flex justify-start items-center space-x-2">
                        <div className={cn(indicatorColor, " flex-none w-[4px] h-6 left-0 top-[4px] rounded")}/>
                        <div
                            className="flex items-center h-[24px] w-full top-0 text-textmedium text-sm font-medium  leading-[16px] truncate">
                            {
                                displayDateTimeWithSelectedFormat(new Date(props.k8sEvent.latestTimestamp), [DateTimePrecision.Month, DateTimePrecision.Day, DateTimePrecision.Hours, DateTimePrecision.Minutes, DateTimePrecision.Seconds, DateTimePrecision.Milliseconds])
                            }
                        </div>
                    </div>
                    <div
                        className={"flex-none flex items-center self-center w-[200px] h-[24px] top-0 text-textmedium text-sm font-medium leading-[16px] truncate"}>
                        {
                            props.k8sEvent.serviceName
                        }
                    </div>
                    <div
                        className={"flex-none flex items-center w-[200px] h-[24px] top-0 text-textmedium text-sm font-medium leading-[16px] truncate"}>
                        {props.k8sEvent.reason}
                        {/*{<Badge*/}
                        {/*    className={"rounded " + indicatorColor}>{props.k8sEvent.reason}</Badge>}*/}
                    </div>
                    <div
                        className={"flex-none flex w-[300px] items-center h-[24px] top-0 text-textmedium text-sm font-medium leading-[16px] max-[1460px]:hidden truncate"}>
                        {
                            props.k8sEvent.involvedObjectName
                        }
                    </div>
                    <div
                        className="flex-none flex w-[100px] items-center h-[24px] top-0 text-textmedium text-sm font-medium leading-[16px] truncate">
                        {
                            props.k8sEvent.count
                        }
                    </div>
                    <div
                        dangerouslySetInnerHTML={{__html: props.regex !== "" ? props.k8sEvent.message.replace(match, '<mark class="highlight">$&</mark>') : props.k8sEvent.message}}
                        className={"flex grow shrink items-center text-textmedium text-sm font-medium leading-[16px] overflow-x-clip overflow-y-clip truncate"}></div>
                </div>
            </div>
        </Drawer.Trigger>
        <Drawer.Portal>
            <Drawer.Content
                className={`select-text flex flex-col rounded-t-[10px] h-full w-1/2 mt-12 fixed bottom-0 right-0`}>
                <K8sEventPanelContent
                    k8sEvent={props.k8sEvent}
                    filter={props.filter}
                    setFilter={props.setFilter}
                    excludeFilter={props.excludeFilter}
                    setExcludeFilter={props.setExcludeFilter}
                />
            </Drawer.Content>
        </Drawer.Portal>
    </Drawer.Root>
}


function K8sEventPanelContent(props: {
    k8sEvent: K8sEvent,
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void
}) {
    const tableEntries = {
        "EventType": props.k8sEvent.eventType,
        "ResourceName": props.k8sEvent.resourceName,
        "service.name": props.k8sEvent.serviceName,
        "Namespace": props.k8sEvent.namespace,
        "Reason": props.k8sEvent.reason,
        "Message": props.k8sEvent.message,
        "InvolvedObjectName": props.k8sEvent.involvedObjectName,
        "InvolvedObjectNamespace": props.k8sEvent.involvedObjectNamespace,
        "InvolvedObjectKind": props.k8sEvent.involvedObjectKind,
        "InvolvedObjectUUID": props.k8sEvent.involvedObjectUUID,
        "InvolvedObjectVersion": props.k8sEvent.involvedObjectVersion,
        "ReportingComponent": props.k8sEvent.reportingComponent,
        "ReportingInstance": props.k8sEvent.reportingInstance,
        "Count": props.k8sEvent.count + ""
    }


    return <div data-vaul-no-drag
                className="border-l h-full w-full p-4 bg-backgrounddark shadow flex-col justify-start items-center gap-8 inline-flex overflow-y-auto">
        <div className="flex-col w-full justify-start items-start gap-4 flex">
            <LogMessageContainer
                overrideColourBand={props.k8sEvent.eventType === "Normal" ? "bg-blue-500" : "bg-amber-500"}
                hideExcludeSearchButtons={true}
                regexes={[]}
                excludeRegexes={[]}
                logLevel={props.k8sEvent.reason} logMessage={props.k8sEvent.message}
                setRegexes={() => {
                }}
                setExcludeRegexes={() => {
                }}
                datetime={props.k8sEvent.latestTimestamp}
            />
            <div className={"flex flex-col gap-4"}>
                <TagContainer
                    title={"Event Details"}
                    attributes={tableEntries}
                    icon={<ServerIcon className={"text-textdark"}/>}
                    filter={props.filter}
                    setFilter={props.setFilter}
                    excludeFilter={props.excludeFilter}
                    setExcludeFilter={props.setExcludeFilter}
                />
            </div>
        </div>
    </div>
}