import moment from 'moment'
import React, { useEffect, useState } from 'react'

const DateDisplay: React.FC = () => {
    const [date, setDate] = useState('')

    /**
     * On component render sets the date state to current date and time
     */
    useEffect(() => {
        const interval = setInterval(() => {
            setDate(moment().format('ddd MMM D, HH:mm:ss'))
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    return (
        <div className="text-lg font-semibold text-foreground">
            {date}
        </div>
    )
}

export default DateDisplay
