import {BaseView} from "./BaseView";
import React, {Dispatch, ReactElement, SetStateAction, useEffect, useState} from "react";
import {cn, usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import {Tooltip, TooltipContent, TooltipTrigger} from "../components/ui/tooltip";
import {defaultKubernetesAlert, KubernetesAlert, kubernetesEvents} from "./alerts/KubernetesAlert";
import {GroupByPill, Pill} from "../components/Filter/Pill";
import {Popover, PopoverContent, PopoverTrigger} from "../components/ui/popover";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../components/ui/command";
import axios from "../utility/customAxios";
import {useSearchParams} from "react-router-dom";
import {Textarea} from "../components/ui/textarea";
import {Input} from "../components/ui/input";
import {defaultLogAlert, LogAlert, LogEventRegexMatch, LogEventRegexMatchPayload, logEvents} from "./alerts/LogAlert";
import {Button} from "../components/ui/button";
import {
    MetricFunction,
} from "../components/Dashboarding/widgets/MetricSelector";
import {
    AggregationFunction, defaultKubernetesResourceAlert,
    defaultMetricAlert,
    EvalType,
    MetricAlert, MissingDatapointBehavior,
    WindowUnit
} from "./alerts/MetricAlert";
import {defaultTraceAlert, TraceAlert} from "./alerts/TraceAlert";
import {adjustThreshold} from "./alerts/utils";
import {plainToClassFromExist} from "class-transformer";
import {
    AlertDestination,
    AlertDestinationType,
    AlertDestinationsSelector,
    EmailAlertDestination,
    PagerDutyAlertDestination,
    SlackAlertDestination,
    WebhookAlertDestination
} from "../components/Alert/AlertDestination";
import {AlertMetricSelector, ThresholdComparator} from "../components/Alert/AlertMetricSelector";
import {capitalizeFirstLetter} from "../utility/stringUtils";
import {KubernetesResourceAlert} from "./alerts/KubernetesResourceAlert";

const nanoToMilliSeconds = 1_000_000

export enum AlertType {
    Kubernetes = "Kubernetes",
    Log = "Log",
    Metric = "Metric",
    Trace = "Trace",
    KubernetesResource = "KubernetesResource"
}

export interface Alert {
    uuid: string,
    type: AlertType,
    name: string,
    description: string,
    alert: KubernetesAlert | MetricAlert | LogAlert | TraceAlert | KubernetesResourceAlert,
    destinations: AlertDestination[]
}

function AlertTypeSelector(
    props: {
        alert: Alert,
        setAlert: Dispatch<SetStateAction<Alert>>
    }) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                1
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Alert Type
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center"}>
                {
                    Object.values(AlertType).map((type, index) => {
                        return <div key={index}
                                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-buttonborder hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", props.alert.type === type ? "border bg-secondarytransparenter border-secondary" : "")}
                                    onClick={() => {
                                        if (type === AlertType.Metric) {
                                            props.setAlert((prev) => {
                                                return {...prev, alert: defaultMetricAlert}
                                            })
                                        }

                                        if (type === AlertType.Kubernetes) {
                                            props.setAlert((prev) => {
                                                return {...prev, alert: defaultKubernetesAlert}
                                            })
                                        }

                                        if (type === AlertType.Log) {
                                            props.setAlert((prev) => {
                                                return {...prev, alert: defaultLogAlert}

                                            })
                                        }

                                        if (type === AlertType.Trace) {
                                            props.setAlert((prev) => {
                                                return {...prev, alert: defaultTraceAlert}
                                            })
                                        }
                                        if (type === AlertType.KubernetesResource) {
                                            props.setAlert((prev) => {
                                                return {...prev, alert: defaultKubernetesResourceAlert}
                                            })
                                        }
                                        props.setAlert((prev) => {
                                            return {...prev, type: type as AlertType}
                                        })
                                    }}>
                            {capitalizeFirstLetter(type)}
                        </div>
                    })
                }
            </div>
        </div>
    </div>;
}


function LogEventSelector(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
}) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                2
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Event
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center"}>
                {
                    logEvents.map((event, index) => {
                        return <div key={index}
                                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-buttonborder hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", (props.alert.alert as KubernetesAlert).monitorEvent === event ? "border bg-secondarytransparenter border-secondary" : "")}
                                    onClick={() => {
                                        let logAlert = (props.alert.alert as LogAlert)
                                        logAlert.monitorEvent = event
                                        logAlert.eventPayload = {regex: ""}
                                        props.setAlert({...props.alert, alert: logAlert})
                                    }}>
                            <Tooltip delayDuration={10}>
                                <TooltipTrigger>
                                    {capitalizeFirstLetter(event.name)}
                                </TooltipTrigger>
                                <TooltipContent side={"right"}
                                                className={"ml-4 bg-backgroundlight border text-textlight rounded"}>
                                    {event.description}
                                </TooltipContent>
                            </Tooltip>
                        </div>
                    })
                }
            </div>
        </div>
        {
            (props.alert.alert as LogAlert).monitorEvent === LogEventRegexMatch &&
            <div className={"flex flex-col justify-start text-textmedium"}>
                <div>
                    The alert will fire when the regex matches the log body.
                    The regex is an <a target={"_blank"} className={"text-textlight underline"}
                                       href={"https://github.com/google/re2/wiki/Syntax"}>re2</a> regex.
                </div>
                <div className={"mt-4 flex gap-2 grow"}>
                    <Input
                        value={((props.alert.alert as LogAlert).eventPayload as LogEventRegexMatchPayload).regex}
                        onChange={(e) => {
                            props.setAlert((prev) => {
                                let logAlert = prev.alert as LogAlert
                                let regexPayload = logAlert.eventPayload as LogEventRegexMatchPayload
                                regexPayload.regex = e.target.value
                                logAlert.eventPayload = regexPayload
                                return {...prev, alert: logAlert}
                            })
                        }}
                        className={"h-[32px] flex justify-center border-border items-center text-textmedium border gap-2 p-2 rounded bg-none hover:text-textlight hover:border-primary cursor-pointer px-4"}
                        placeholder={"re2 regex to match against"}
                    />
                </div>
            </div>
        }
    </div>;
}

function AlertEventSelector(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
}) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                2
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Event
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center"}>
                {
                    kubernetesEvents.map((event, index) => {
                        return <div key={index}
                                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-buttonborder hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", (props.alert.alert as KubernetesAlert).monitorEvent === event ? "border bg-secondarytransparenter border-secondary" : "")}
                                    onClick={() => {
                                        let k8sAlert = (props.alert.alert as KubernetesAlert)
                                        k8sAlert.monitorEvent = event
                                        props.setAlert({...props.alert, alert: k8sAlert})
                                    }}>
                            <Tooltip delayDuration={10}>
                                <TooltipTrigger>
                                    {capitalizeFirstLetter(event.name)}
                                </TooltipTrigger>
                                <TooltipContent side={"right"}
                                                className={"ml-4 bg-backgroundlight border text-textlight rounded"}>
                                    {event.description}
                                </TooltipContent>
                            </Tooltip>
                        </div>
                    })
                }
            </div>
        </div>
    </div>;
}

function initialFilterMap(clusters: string[], services: string[]): Map<string, string[]> {
    let filterMap = new Map<string, string[]>()
    filterMap.set("cluster", clusters)
    filterMap.set("service", services)
    return filterMap
}

export interface KnownService {
    displayName: string
    serviceName: string
    requestsPerSecond: number
    logsPerSecond: number
}


function AlertFilterSelector(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
}) {
    let k8sAlert = (props.alert.alert as KubernetesAlert)

    const [filters, setFilters] = React.useState<Map<string, string[]>>(initialFilterMap(k8sAlert.filters.clusters, k8sAlert.filters.services))
    const [openClusterFilter, setOpenClusterFilter] = React.useState(false)
    const [openServiceFilter, setOpenServiceFilter] = React.useState(false)
    const [environments, setEnvironments] = React.useState<string[]>([])
    const [services, setServices] = React.useState<KnownService[]>([])
    const [selectedEnvironments, setSelectedEnvironments] = React.useState<string[]>([])

    useEffect(() => {
        // Fetch environments
        axios.get("/api/v1/environments").then((response) => {
            setEnvironments(response.data.environments)
        }).catch((e) => {
            console.error(e)
        })
    }, [])

    useEffect(() => {
        // Fetch environments
        axios.post("/api/v1/knownServices", {
            environments: selectedEnvironments
        }).then((response) => {
            setServices(response.data.services)
        }).catch((e) => {
            console.error(e)
        })
    }, [selectedEnvironments])


    useEffect(() => {
        let k8sAlert = (props.alert.alert as KubernetesAlert)
        k8sAlert.filters.clusters = filters.get("cluster") ?? []
        k8sAlert.filters.services = filters.get("service") ?? []
        props.setAlert({...props.alert, alert: k8sAlert})
    }, [filters])

    useEffect(() => {
        if (!filters.has("cluster")) {
            setSelectedEnvironments([])
        }
        filters.get("cluster")?.forEach((cluster) => {
            setSelectedEnvironments(filters.get("cluster") ?? [])
        })
    }, [filters]);

    let clusterPills: ReactElement[] = [];
    filters.get("cluster")?.forEach((cluster) => {
        clusterPills.push(<Pill key={cluster} attributeKey={"cluster"} attributeValue={cluster}
                                filter={filters}
                                setFilter={setFilters}
                                additionalRemoveAction={() => {
                                    setFilters((prev: Map<string, string[]>) => {
                                        const newFilter = new Map(prev);
                                        if (newFilter.has("service")) {
                                            newFilter.delete("service")
                                        }
                                        return newFilter
                                    })
                                }}
        />)
    })

    let servicePills: ReactElement[] = [];
    filters.get("service")?.forEach((service) => {
        servicePills.push(<Pill key={service} attributeKey={"service"} attributeValue={service}
                                filter={filters}
                                setFilter={setFilters}/>)
    })

    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                3
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Filters
            </div>
        </div>
        <div className={"text-textmedium"}>
            <div>
                The alert will fire for:
            </div>
            <div>
                <ul className={"list-disc list-inside"}>
                    <li>
                        {
                            k8sAlert.filters.clusters.length === 0 ? "All clusters" : "Only the following clusters: " + k8sAlert.filters.clusters.join(", ")
                        }
                    </li>
                    <li>
                        {
                            k8sAlert.filters.services.length === 0 ? "All services in each cluster" : "Only the following services: " + k8sAlert.filters.services.join(", ")
                        }
                    </li>
                </ul>
            </div>
        </div>
        <div className={"flex flex-col justify-start gap-4"}>
            <div className={"flex flex-wrap border bg-backgroundmedium rounded"}>
                <div className={"text-textmedium flex flex-col justify-center border-r bg-backgrounddark p-2"}>
                    Clusters
                </div>
                <Popover open={openClusterFilter}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <div onClick={() =>
                            setOpenClusterFilter(true)
                        } className={"flex flex-wrap gap-4 p-2 hover:cursor-pointer grow"}>
                            {
                                clusterPills.length === 0 &&
                                <div className={"text-textmedium"}>
                                    All Clusters
                                </div>
                            }
                            {clusterPills}
                        </div>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenClusterFilter(false)}
                                    onEscapeKeyDown={() => setOpenClusterFilter(false)}
                                    onInteractOutside={() => setOpenClusterFilter(false)}
                                    className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
                    >
                        <Command>
                            <CommandInput id={"free_text_input2"}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty>No clusters found.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        environments.filter(env => !filters.get("cluster")?.includes(env)).map((kv, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenClusterFilter(false);

                                                setFilters((prev: Map<string, string[]>) => {
                                                    const newFilter = new Map(prev);
                                                    if (newFilter.has("cluster")) {
                                                        newFilter.get("cluster")?.push(kv);
                                                    } else {
                                                        newFilter.set("cluster", [kv]);
                                                    }
                                                    if (newFilter.has("service")) {
                                                        newFilter.delete("service")
                                                    }
                                                    return newFilter;
                                                })
                                            }}>
                                                {kv}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
            <div className={"flex flex-wrap border bg-backgroundmedium rounded"}>
                <div className={"text-textmedium border-r bg-backgrounddark p-2"}>
                    Services
                </div>
                <Popover open={openServiceFilter}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <div onClick={() =>
                            setOpenServiceFilter(true)
                        } className={"flex flex-wrap gap-4 p-2 hover:cursor-pointer grow"}>
                            {
                                servicePills.length === 0 &&
                                <div className={"text-textmedium"}>
                                    All Services
                                </div>
                            }
                            {servicePills}
                        </div>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenServiceFilter(false)}
                                    onEscapeKeyDown={() => setOpenServiceFilter(false)}
                                    onInteractOutside={() => setOpenServiceFilter(false)}
                                    className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
                    >
                        <Command>
                            <CommandInput id={"free_text_input2"}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty>No services found.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        services.filter(service => !filters.get("service")?.includes(service.displayName)).map((kv, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenServiceFilter(false);

                                                setFilters((prev: Map<string, string[]>) => {
                                                    const newFilter = new Map(prev);
                                                    if (newFilter.has("service")) {
                                                        newFilter.get("service")?.push(kv.serviceName);
                                                    } else {
                                                        newFilter.set("service", [kv.serviceName]);
                                                    }
                                                    return newFilter;
                                                })
                                            }}>
                                                {kv.displayName}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
        </div>
    </div>
}


interface ApiServerAlert {
    status?: string
    muted: boolean
    uuid: string
    name: string
    description: string
    type: AlertType
    destinations: {
        type: AlertDestinationType
        slackDestination?: SlackAlertDestination
        pagerDutyDestination?: PagerDutyAlertDestination
        emailDestination?: EmailAlertDestination
        webhookDestination?: WebhookAlertDestination
    }[],
    metricAlert?: {
        filters: {
            metricName: string,
            filters: Map<string, string[]>,
            excludeFilters: Map<string, string[]>,
            splits: string[],
            aggregation: string,
            bucketSize: number,
            functions: MetricFunction[]
        },
        monitorEvaluation: {
            description: string,
            monitorEvaluationType: EvalType,
            monitorEvalutionPayload: {
                // Following fields are for evaluation type aggregation.
                evaluationFunction: AggregationFunction
                window: number,
                windowUnit: WindowUnit,
                evaluationSplits: string[]
                // Following fields are for evaluation type static.
                evaluationWindow: number,
                datapointsToAlarm: number,
                missingDatapointBehavior: MissingDatapointBehavior
            }
        },
        alarmCondition: {
            condition: ThresholdComparator,
            threshold: string
        },
    },
    traceAlert?: {
        filters: {
            filters: Map<string, string[]>,
            excludeFilters: Map<string, string[]>,
            splits: string[],
            aggregation: string,
            bucketSize: number,
            functions: MetricFunction[]
        },
        monitorEvaluation: {
            description: string,
            monitorEvaluationType: EvalType,
            monitorEvalutionPayload: {
                // Following fields are for evaluation type aggregation.
                evaluationFunction: AggregationFunction
                window: number,
                windowUnit: WindowUnit,
                evaluationSplits: string[]
                // Following fields are for evaluation type static.
                evaluationWindow: number,
                datapointsToAlarm: number,
                missingDatapointBehavior: MissingDatapointBehavior
            }
        },
        alarmCondition: {
            condition: ThresholdComparator,
            threshold: string
        },
    },
    kubernetesResourceAlert?: {
        filters: {
            filters: Map<string, string[]>,
            excludeFilters: Map<string, string[]>,
            splits: string[],
            aggregation: string,
            bucketSize: number,
            functions: MetricFunction[]
            jsonPath: string
        },
        monitorEvaluation: {
            description: string,
            monitorEvaluationType: EvalType,
            monitorEvalutionPayload: {
                // Following fields are for evaluation type aggregation.
                evaluationFunction: AggregationFunction
                window: number,
                windowUnit: WindowUnit,
                evaluationSplits: string[]
                // Following fields are for evaluation type static.
                evaluationWindow: number,
                datapointsToAlarm: number,
                missingDatapointBehavior: MissingDatapointBehavior
            }
        },
        alarmCondition: {
            condition: ThresholdComparator,
            threshold: string
        },
    },
    kubernetesAlert?: {
        filters: {
            services: string[],
            clusters: string[]
        },
        monitorEvent: string,
        scope: {
            service: boolean,
            environment: boolean
        }
    },
    logAlert?: {
        filters: {
            services: string[],
            clusters: string[]
        },
        monitorEvent: string,
        logAlertRegexPayload?: LogEventRegexMatchPayload
    }
}

function ConvertToApiServerAlert(alert: Alert): any {
    let destinations = alert.destinations.map(dest => {
        let destination = {}

        if (dest.type === AlertDestinationType.Slack) {
            destination = {
                type: dest.type,
                slackDestination: {
                    channel: (dest.alertDestination as SlackAlertDestination).channel,
                    additionalMessage: (dest.alertDestination as SlackAlertDestination).additionalMessage
                }
            }
        }
        if (dest.type === AlertDestinationType.PagerDuty) {
            destination = {
                type: dest.type,
                pagerDutyDestination: {
                    serviceName: (dest.alertDestination as PagerDutyAlertDestination).serviceName,
                    serviceId: (dest.alertDestination as PagerDutyAlertDestination).serviceId
                }
            }
        }
        if (dest.type === AlertDestinationType.Email) {
            destination = {
                type: dest.type,
                emailDestination: {
                    emails: (dest.alertDestination as EmailAlertDestination).emails
                }
            }
        }
        if (dest.type === AlertDestinationType.Webhook) {
            destination = {
                type: dest.type,
                webhookDestination: {
                    uuid: (dest.alertDestination as WebhookAlertDestination).uuid,
                    name: (dest.alertDestination as WebhookAlertDestination).name
                }
            }
        }
        return destination
    })

    if (alert.type === AlertType.Kubernetes) {
        return {
            alert: {
                uuid: alert.uuid,
                name: alert.name,
                description: alert.description,
                type: alert.type,
                destinations: destinations,
                metricAlert: null,
                kubernetesAlert: {
                    filters: (alert.alert as KubernetesAlert).filters,
                    monitorEvent: (alert.alert as KubernetesAlert).monitorEvent.id,
                    scope: (alert.alert as KubernetesAlert).scope
                }
            }
        }
    }
    if (alert.type === AlertType.Log) {
        return {
            alert: {
                uuid: alert.uuid,
                name: alert.name,
                description: alert.description,
                type: alert.type,
                destinations: destinations,
                metricAlert: null,
                logAlert: {
                    filters: (alert.alert as LogAlert).filters,
                    monitorEvent: (alert.alert as LogAlert).monitorEvent.id,
                    logAlertRegexPayload: (alert.alert as LogAlert).monitorEvent.id === LogEventRegexMatch.id ? (alert.alert as LogAlert).eventPayload : null
                }
            }
        }
    }
    if (alert.type === AlertType.Metric) {
        const toSend = {
            alert: {
                uuid: alert.uuid,
                name: alert.name,
                description: alert.description,
                type: alert.type,
                destinations: destinations,
                metricAlert: {
                    filters: {
                        metricName: (alert.alert as MetricAlert).filters.metricName,
                        filters: Object.fromEntries((alert.alert as MetricAlert).filters.filters),
                        excludeFilters: Object.fromEntries((alert.alert as MetricAlert).filters.excludeFilters || {}),
                        splits: (alert.alert as MetricAlert).filters.splits,
                        aggregation: (alert.alert as MetricAlert).filters.aggregation,
                        bucketSize: (alert.alert as MetricAlert).filters.bucketSize,
                        functions: (alert.alert as MetricAlert).filters.functions
                    },
                    monitorEvaluation: (alert.alert as MetricAlert).monitorEvaluation,
                    alarmCondition: (alert.alert as MetricAlert).eventPayload
                }
            }
        }

        return toSend
    }
    if (alert.type === AlertType.KubernetesResource) {
        const toSend = {
            alert: {
                uuid: alert.uuid,
                name: alert.name,
                description: alert.description,
                type: alert.type,
                destinations: destinations,
                kubernetesResourceAlert: {
                    filters: {
                        filters: Object.fromEntries((alert.alert as KubernetesResourceAlert).filters.filters),
                        excludeFilters: Object.fromEntries((alert.alert as KubernetesResourceAlert).filters.excludeFilters || {}),
                        splits: (alert.alert as KubernetesResourceAlert).filters.splits,
                        aggregation: (alert.alert as KubernetesResourceAlert).filters.aggregation,
                        bucketSize: (alert.alert as KubernetesResourceAlert).filters.bucketSize,
                        functions: (alert.alert as KubernetesResourceAlert).filters.functions,
                        jsonPath: (alert.alert as KubernetesResourceAlert).filters.jsonPath
                    },
                    monitorEvaluation: (alert.alert as KubernetesResourceAlert).monitorEvaluation,
                    alarmCondition: (alert.alert as KubernetesResourceAlert).eventPayload
                }
            }
        }
        return toSend
    }

    if (alert.type === AlertType.Trace) {
        const aggregation = (alert.alert as TraceAlert).filters.aggregation
        const isTime = ["p50", "p90", "p95", "p99"].includes(aggregation.toLowerCase())
        const thresholdAdjustedForTime = isTime ? (alert.alert as TraceAlert).eventPayload.threshold * nanoToMilliSeconds : (alert.alert as TraceAlert).eventPayload.threshold
        const toSend = {
            alert: {
                uuid: alert.uuid,
                name: alert.name,
                description: alert.description,
                type: alert.type,
                destinations: destinations,
                traceAlert: {
                    filters: {
                        metricName: "",
                        filters: Object.fromEntries((alert.alert as TraceAlert).filters.filters),
                        excludeFilters: Object.fromEntries((alert.alert as TraceAlert).filters.excludeFilters || {}),
                        splits: (alert.alert as TraceAlert).filters.splits,
                        aggregation: (alert.alert as TraceAlert).filters.aggregation,
                        bucketSize: (alert.alert as TraceAlert).filters.bucketSize,
                        functions: (alert.alert as TraceAlert).filters.functions
                    },
                    monitorEvaluation: (alert.alert as TraceAlert).monitorEvaluation,
                    alarmCondition: {
                        condition: (alert.alert as TraceAlert).eventPayload.condition,
                        threshold: thresholdAdjustedForTime
                    }
                }
            }
        }
        return toSend
    }
}

function AlertNameSelector(props: {
    stepNumber: number,
    alert: Alert,
    setAlert: (value: (((prevState: Alert) => Alert) | Alert)) => void
}) {
    const [localName, setLocalName] = useState(props.alert.name);
    const [localDescription, setLocalDescription] = useState(props.alert.description);

    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                {props.stepNumber}
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Name alert
            </div>
        </div>
        <div className={"flex justify-start text-textmedium"}>
            <div className={"flex gap-2 grow"}>
                <Input
                    value={localName}
                    onChange={(e) => setLocalName(e.target.value)}
                    onBlur={() => {
                        props.setAlert((prev) => ({...prev, name: localName}));
                    }}
                    className={"h-[32px] flex justify-center border-border items-center text-textmedium border gap-2 p-2 rounded bg-none hover:text-textlight hover:border-primary cursor-pointer px-4"}
                    placeholder={"Alert Name"}
                />
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 grow"}>
                <Textarea
                    value={localDescription}
                    onChange={(e) => setLocalDescription(e.target.value)}
                    onBlur={() => {
                        props.setAlert((prev) => ({...prev, description: localDescription}));
                    }}
                    className={"h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-border hover:text-textlight hover:border cursor-pointer px-4"}
                    placeholder={"Alert Description"}
                />
            </div>
        </div>
    </div>
}

function deserializeAlert(alert: any): Alert {
    let alertInner = {} as KubernetesAlert | LogAlert | MetricAlert | TraceAlert | KubernetesResourceAlert
    if (alert.kubernetesAlert !== null && alert.kubernetesAlert !== undefined) {
        alertInner = {
            filters: alert.kubernetesAlert.filters,
            scope: alert.kubernetesAlert.scope,
            monitorEvent: kubernetesEvents.find((event) => event.id === alert.kubernetesAlert.monitorEvent) || kubernetesEvents[0]
        } as KubernetesAlert
    }
    if (alert.logAlert !== null && alert.logAlert !== undefined) {
        alertInner = {
            filters: alert.logAlert.filters,
            monitorEvent: logEvents.find((event) => event.id === alert.logAlert.monitorEvent) || logEvents[0],
            eventPayload: alert.logAlert.logAlertRegexPayload
        } as LogAlert
    }

    if (alert.metricAlert !== null && alert.metricAlert !== undefined) {
        let filterMap = new Map<string, string[]>()
        plainToClassFromExist(filterMap, alert.metricAlert.filters.filters)
        let excludeFilterMap = new Map<string, string[]>()
        plainToClassFromExist(excludeFilterMap, alert.metricAlert.filters.excludeFilters || {})

        alertInner = {
            filters: {
                filters: filterMap,
                metricName: alert.metricAlert.filters.metricName,
                excludeFilters: excludeFilterMap,
                splits: alert.metricAlert.filters.splits,
                aggregation: alert.metricAlert.filters.aggregation,
                bucketSize: alert.metricAlert.filters.bucketSize,
                functions: alert.metricAlert.filters.functions
            },
            monitorEvaluation: alert.metricAlert.monitorEvaluation,
            eventPayload: alert.metricAlert.alarmCondition
        } as MetricAlert
    }
    if (alert.traceAlert !== null && alert.traceAlert !== undefined) {
        let filterMap = new Map<string, string[]>()
        plainToClassFromExist(filterMap, alert.traceAlert.filters.filters)
        let excludeFilterMap = new Map<string, string[]>()
        plainToClassFromExist(excludeFilterMap, alert.traceAlert.filters.excludeFilters || {})
        alertInner = {
            filters: {
                filters: filterMap,
                excludeFilters: excludeFilterMap,
                splits: alert.traceAlert.filters.splits,
                aggregation: alert.traceAlert.filters.aggregation,
                bucketSize: alert.traceAlert.filters.bucketSize,
                functions: alert.traceAlert.filters.functions
            },
            monitorEvaluation: alert.traceAlert.monitorEvaluation,
            eventPayload: {
                condition: alert.traceAlert.alarmCondition.condition,
                threshold: Number(adjustThreshold(alert))
            }
        } as TraceAlert
    }

    if (alert.kubernetesResourceAlert !== null && alert.kubernetesResourceAlert !== undefined) {
        let filterMap = new Map<string, string[]>()
        plainToClassFromExist(filterMap, alert.kubernetesResourceAlert.filters.filters)
        let excludeFilterMap = new Map<string, string[]>()
        plainToClassFromExist(excludeFilterMap, alert.kubernetesResourceAlert.filters.excludeFilters || {})
        alertInner = {
            filters: {
                filters: filterMap,
                excludeFilters: excludeFilterMap,
                splits: alert.kubernetesResourceAlert.filters.splits,
                aggregation: alert.kubernetesResourceAlert.filters.aggregation,
                bucketSize: alert.kubernetesResourceAlert.filters.bucketSize,
                functions: alert.kubernetesResourceAlert.filters.functions,
                jsonPath: alert.kubernetesResourceAlert.filters.jsonPath
            },
            monitorEvaluation: alert.kubernetesResourceAlert.monitorEvaluation,
            eventPayload: alert.kubernetesResourceAlert.alarmCondition
        } as KubernetesResourceAlert
    }

    let alertDestinations = [] as Array<AlertDestination>

    alertDestinations = alert.destinations.map((destination: any) => {
        let alertDestination = {} as SlackAlertDestination | PagerDutyAlertDestination | EmailAlertDestination | WebhookAlertDestination

        if (destination.type === AlertDestinationType.Slack && destination.slackDestination) {
            alertDestination = {
                channel: destination.slackDestination.channel,
                additionalMessage: destination.slackDestination.additionalMessage
            } as SlackAlertDestination
        } else if (destination.type === AlertDestinationType.PagerDuty && destination.pagerDutyDestination) {
            alertDestination = {
                serviceName: destination.pagerDutyDestination.serviceName,
                serviceId: destination.pagerDutyDestination.serviceId
            } as PagerDutyAlertDestination
        } else if (destination.type === AlertDestinationType.Email && destination.emailDestination) {
            alertDestination = {
                emails: destination.emailDestination.emails
            } as EmailAlertDestination
        } else if (destination.type === AlertDestinationType.Webhook && destination.webhookDestination) {
            alertDestination = {
                uuid: destination.webhookDestination.uuid,
                name: destination.webhookDestination.name
            } as WebhookAlertDestination
        }

        return {
            type: destination.type,
            alertDestination: alertDestination
        }
    })

    return {
        uuid: alert.uuid,
        type: alert.type,
        name: alert.name,
        description: alert.description,
        alert: alertInner,
        destinations: alertDestinations
    }
}

function AlertInner() {
    const navigate = usePreserveQueryParamsNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [alert, setAlert] = React.useState<Alert>({
        uuid: "",
        type: AlertType.Kubernetes,
        name: "",
        description: "",
        alert: {
            filters: {
                services: [],
                clusters: []
            },
            scope: {
                service: false,
                environment: false
            },
            monitorEvent: kubernetesEvents[0]
        },
        destinations: []
    })

    // Load the alertJson from the url param if it exists
    useEffect(() => {
        let alertJson = searchParams.get("alertJson")
        if (alertJson === null) {
            return
        }
        let alert = JSON.parse(alertJson)
        let useableAlert = deserializeAlert(alert);
        setAlert(useableAlert)
    }, [])

    useEffect(() => {
        // Load the alert from the url id if one is set
        let alertId = searchParams.get("alertId")
        if (alertId === null) {
            return
        }
        axios.get("/api/v1/alert?alertId=" + alertId).then((response) => {
            let alert = response.data
            let useableAlert = deserializeAlert(alert);
            setAlert(useableAlert)
        }).catch((e) => {
            console.error(e)
        })
    }, [searchParams.get("alertId")])

    if (searchParams.get("alertId") !== null && alert.uuid === "") {
        return <div className={"p-4 flex grow shrink"}>
            <div
                className={"flex flex-col grow shrink border bg-backgroundmedium p-4 gap-8 overflow-y-auto"}>
                <div
                    className={"flex justify-center text-textdark"}>
                    Loading...
                </div>
            </div>
        </div>
    }

    return <div className={"p-4 flex grow shrink"}>
        <div
            className={"flex flex-col grow shrink border bg-backgroundmedium p-4 gap-8 overflow-y-auto"}>
            <div className={"flex justify-between"}>
                <AlertTypeSelector alert={alert}
                                   setAlert={setAlert}/>
                <Button
                    className={"bg-primarytransparent border border-primary rounded p-4 text-textmedium hover:border-primaryhover"}
                    onClick={() => {
                        navigate(`/alerts`)
                    }}>Cancel</Button>
            </div>
            {
                alert.type === AlertType.Kubernetes &&
                <div className={"flex flex-col gap-8"}>
                    <AlertEventSelector alert={alert}
                                        setAlert={setAlert}/>
                    <AlertFilterSelector alert={alert}
                                         setAlert={setAlert}/>
                </div>
            }
            {
                alert.type === AlertType.Log &&
                <div className={"flex flex-col gap-8"}>
                    <LogEventSelector alert={alert}
                                      setAlert={setAlert}/>
                    <AlertFilterSelector alert={alert}
                                         setAlert={setAlert}/>
                </div>
            }
            <AlertMetricSelector alert={alert} setAlert={setAlert}/>
            <AlertDestinationsSelector alert={alert}
                                       setAlert={setAlert}
                                       stepNumber={4}/>
            <AlertNameSelector alert={alert} setAlert={setAlert}
                               stepNumber={5}/>
            <div className={"flex justify-center"}>
                <div
                    onClick={() => {
                        axios.post("/api/v1/alert", ConvertToApiServerAlert(alert)).then((response) => {
                            // Navigate to the alert page
                            let id = response.data.id;
                            navigate("/alert?alertId=" + id)
                        }).catch((e) => {
                            console.error(e)
                        })
                    }}
                    className={"bg-primarytransparent flex grow text-center items-center justify-center border-primary border text-textlight p-2 rounded hover:border-primaryhover hover:text-textlight cursor-pointer"}>
                    Save
                </div>
            </div>
        </div>
    </div>
}

function AlertCreation() {
    return <BaseView title={"Create an Alert"}
                     disableClusterSelector={true}>
        <AlertInner/>
    </BaseView>
}

export {
    AlertCreation, AlertInner
}

export type {
    ApiServerAlert
}