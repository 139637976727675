import React from 'react';
import {Dialog, DialogContent, DialogHeader, DialogTitle} from '../ui/dialog';
import {Button} from '../ui/button';
import {TrashIcon} from 'lucide-react';
import {useMutation, useQuery} from '@tanstack/react-query';
import axios from '../../utility/customAxios';
import {DataTable} from '../ui/datatable/datatable';
import {ColumnDef} from '@tanstack/react-table';
import {DataTableColumnHeader} from '../ui/datatable/datatablecolumnheader';

interface MutedIssue {
    uuid: string;
    workflowUuid: string;
    organizationUuid: string;
    attributes: Record<string, any>;
    createdTimestamp: number;
}

interface ListMutedIssuesResponse {
    mutedIssues: MutedIssue[];
}

interface MutedIssuesDialogProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
}

export function MutedIssuesDialog({open, onOpenChange}: MutedIssuesDialogProps) {
    const {data, isLoading, error, refetch} = useQuery<ListMutedIssuesResponse>({
        queryKey: ['mutedIssues'],
        queryFn: async () => {
            const response = await axios.get('/api/v1/issues/mutes');
            return response.data;
        },
        enabled: open, // Only fetch when dialog is open
    });

    const unmuteMutation = useMutation({
        mutationFn: async (uuid: string) => {
            await axios.put('/api/v1/issues/unmute', {
                uuid: uuid
            });
        },
        onSuccess: () => {
            refetch();
        },
    });

    const filters: any = [
        {
            filteringColumn: 'workflow',
            title: 'Workflow',
        },
    ];


    const columns: ColumnDef<MutedIssue>[] = [
        {
            id: 'workflow',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Workflow"/>
            ),
            accessorKey: 'workflowUuid',
            cell: ({row}) => (
                <div className="text-xs text-textmedium">
                    {row.original.workflowUuid}
                </div>
            ),
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id))
            },
        },
        {
            id: 'attributes',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Attributes"/>
            ),
            cell: ({row}) => (
                <div className="grid grid-cols-2 gap-2">
                    {Object.entries(row.original.attributes).map(([key, value]) => (
                        <div key={key} className="text-xs">
                            <span className="font-medium text-textlight">{key}:</span>{' '}
                            <span className="text-textmedium">{value}</span>
                        </div>
                    ))}
                </div>
            ),
        },
        {
            id: 'createdAt',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Created At"/>
            ),
            accessorKey: 'createdTimestamp',
            cell: ({row}) => (
                <div className="text-xs text-textmedium">
                    {new Date(Math.floor(row.original.createdTimestamp * 1000)).toLocaleString()}
                </div>
            ),
        },
        {
            id: 'actions',
            header: () => <div className="text-right">Actions</div>,
            cell: ({row}) => (
                <div className="text-right">
                    <Button
                        variant="ghost"
                        size="sm"
                        className="text-red-500 hover:text-red-600"
                        onClick={() => unmuteMutation.mutate(row.original.uuid)}
                    >
                        <TrashIcon className="w-4 h-4" />
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="bg-backgrounddark text-textmedium max-w-4xl">
                <DialogHeader>
                    <DialogTitle>Issue Mute Rules</DialogTitle>
                </DialogHeader>
                <div className="space-y-4">
                    {isLoading && (
                        <div className="text-sm">Loading mute rules...</div>
                    )}
                    {error && (
                        <div className="text-sm text-red-500">Error loading mute rules</div>
                    )}
                    {!data?.mutedIssues?.length && (
                        <div className="text-sm text-textmedium">No mute rules configured</div>
                    )}
                    {data?.mutedIssues && (
                        <DataTable
                            showPagination={true}
                            columns={columns}
                            data={data.mutedIssues}
                            filters={filters}
                        />
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}
