import React, {ReactElement, useEffect, useState} from "react";
import {Maximize2Icon, SearchIcon, XIcon} from "lucide-react";
import {format} from 'date-fns'
import {cn} from "components/ui/lib/utils";
import axios from "../utility/customAxios";
import {plainToInstance} from "class-transformer";
import {FilterPanel, FilterSelector, TelemetryLevelFilters} from "components/Filter/Filter";
import {InputPill, Pill} from "components/Filter/Pill";
import {Attribute} from "types/telemetry";
import {getLogLeveLIndicatorColor} from "components/SidePanel/Log/utils";
import LogPanelContent from "components/SidePanel/Log/LogDetails";
import {Drawer} from "vaul";
import {BaseView} from "./BaseView";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import {AxiosPromise} from "axios";
import {MetricToChartData} from "./MetricsTest";
import {LoadingSpinner} from "../components/ui/customSpinner";
import {useSearchParams} from "react-router-dom";
import {useDebouncedCallback} from "use-debounce";
import {TimeRange} from "../types/time";
import {DateTimePrecision, displayDateTimeWithSelectedFormat} from "../utility/displayDateTime";
import {GetLogMetrics, GetLogMetricsResponse} from "../clients/metoro/metrics";
import {ChartType, MetoroChart} from "../components/Charts/MetoroChart";

export interface Log {
    // The time that the log line was emitted in milliseconds since the epoch
    time: number,
    // The severity of the log line
    severity: string,
    // The log message
    message: string
    // The attributes of the log line
    logAttributes: Map<string, string>
    // The attributes of the resource that emitted the log line
    resourceAttributes: Map<string, string>
    // Service name
    serviceName: string
}


export interface Logs {
    logs: Log[]
    filterAttributes: Map<string, Attribute[]>
}

const customLogColours = new Map<Record<string, string>, string[]>(
    [[
        {key: "log_level", value: "error",},
        ["#ff6384", "#ff638433"]
    ],
        [
            {key: "log_level", value: "warning",},
            ["#ff9f40", "#ff9f4033"]
        ],
        [
            {key: "log_level", value: "info",},
            ["#36a2eb", "#36a2eb33"]
        ],
        [
            {key: "log_level", value: "unknown",},
            ["rgb(107, 114, 128)", "rgba(201, 203, 207, 0.2)"]
        ],

    ])

export function TimelineViewer(props: {
    metric?: GetLogMetricsResponse,
    title: string,
    customCategoryColour?: Map<Record<string, string>, string[]>
}) {
    let metric = undefined;
    if (props.metric) {
        metric = props.metric?.metric
    }

    return <div className={"flex-none w-full h-[180px] bg-backgroundmedium mb-4"}>
        <MetoroChart
            hideLegend={true}
            className={"h-full"} title={props.title} type={ChartType.Bar}
            dataToUse={MetricToChartData(metric, false, undefined, undefined, props.customCategoryColour ? props.customCategoryColour : customLogColours)}/>
    </div>
}


function LogLevelFilters(props: {
    filter: Map<string, string[]>,
    attributes: Map<string, Attribute[]>,
    setFilter: (filter: Map<string, string[]>) => void
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void
}) {
    const levels = props.attributes.get("log_level");
    if (!levels) {
        return null;
    }
    const levelFilters: ReactElement[] = [];
    for (const level of levels) {
        const levelName = level.value;
        const numLogs = level.volume;
        const indicatorColor = getLogLeveLIndicatorColor(levelName);
        levelFilters.push(<FilterSelector filterFilter={""} indicatorColor={indicatorColor} key={levelName}
                                          filterKey={"log_level"}
                                          filterValue={levelName}
                                          volume={numLogs} filter={props.filter} setFilter={props.setFilter}
                                          setExcludeFilter={props.setExcludeFilter}
                                          excludeFilter={props.excludeFilter}
        />)
    }

    return <div className={"py-2"}>
        {levelFilters}
    </div>
}

function LogSearch(props: {
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    setFreeTextFilter: (filter: string) => void
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void
    regexes: string[]
    setRegexes: (regexes: string[]) => void
    excludeRegexes: string[]
    setExcludeRegexes: (regexes: string[]) => void
    logAttributes?: Map<string, Attribute[]>
    dropDownFunction?: (key: string) => Promise<Attribute[]>
}) {
    let pills: ReactElement[] = [];
    const entries = Array.from(props.filter.entries());
    for (let i = 0; i < entries.length; i++) {
        const [key, values] = entries[i]
        if (values.length === 0) {
            continue;
        }
        const valuesString = values.join(" || ")
        pills.push(<Pill
            dropDownFunction={props.dropDownFunction}
            key={i.toString()} attributeKey={key} attributeValue={valuesString} filter={props.filter}
            setFilter={props.setFilter} excludeFilter={props.excludeFilter}
            setExcludeFilter={props.setExcludeFilter}
            isEditable={true}
            regexes={props.regexes}
            setRegexes={props.setRegexes}
            excludeRegexes={props.excludeRegexes}
            setExcludeRegexes={props.setExcludeRegexes}
        />)
    }

    const excludeEntries = Array.from(props.excludeFilter.entries());
    for (let i = 0; i < excludeEntries.length; i++) {
        const [key, values] = excludeEntries[i]
        if (values.length === 0) {
            continue;
        }
        const valuesString = values.join(" || ")
        pills.push(<Pill
            dropDownFunction={props.dropDownFunction}
            key={i.toString()} attributeKey={key} attributeValue={valuesString}
            filter={props.excludeFilter}
            setFilter={props.setFilter} isExclude={true} excludeFilter={props.excludeFilter}
            setExcludeFilter={props.setExcludeFilter}
            isEditable={true}
            regexes={props.regexes}
            setRegexes={props.setRegexes}
            excludeRegexes={props.excludeRegexes}
            setExcludeRegexes={props.setExcludeRegexes}
        />)
    }

// Create a pill for each exclude regex
    for (let i = 0; i < props.excludeRegexes.length; i++) {
        const regex = props.excludeRegexes[i];
        pills.push(<Pill key={i.toString()} attributeKey={"regex_match"} attributeValue={regex}
                         filter={props.excludeFilter}
                         setFilter={props.setFilter} excludeFilter={props.excludeFilter}
                         setExcludeFilter={props.setExcludeFilter}
                         isEditable={true}
                         regexes={props.regexes}
                         setRegexes={props.setRegexes}
                         excludeRegexes={props.excludeRegexes}
                         setExcludeRegexes={props.setExcludeRegexes}
                         isRegex={true}
                         isExclude={true}
        />)
    }

// Create a pill for each regex
    for (let i = 0; i < props.regexes.length; i++) {
        const regex = props.regexes[i];
        pills.push(<Pill key={i.toString()} attributeKey={"regex_match"} attributeValue={regex} filter={props.filter}
                         setFilter={props.setFilter} excludeFilter={props.excludeFilter}
                         setExcludeFilter={props.setExcludeFilter}
                         isEditable={true}
                         regexes={props.regexes}
                         setRegexes={props.setRegexes}
                         excludeRegexes={props.excludeRegexes}
                         setExcludeRegexes={props.setExcludeRegexes}
                         isRegex={true}
        />)
    }


    return <div
        className={"bg-backgroundmedium flex-none w-full hover:cursor-pointer hover:border-primary rounded border items-center gap-x-2 flex"}
    >

        <div className="w-6 h-6 pl-2 relative hover:cursor-pointer hover:text-primary text-border">
            <SearchIcon/>
        </div>
        <div
            className="grow max-w-full shrink px-[7px] py-[9px] justify-start gap-2 flex flex-wrap">
            {
                pills
            }
            <div className={"flex grow shrink"}>
                <InputPill
                    inputKeys={props.logAttributes ? Array.from(props.logAttributes.keys()) : []}
                    placeholderText={"Search log text..."}
                    filter={props.filter} setFilter={props.setFilter}
                    excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
                    regexes={props.regexes} setRegexes={props.setRegexes}
                />
            </div>
        </div>
        <div onClick={
            () => {
                const input = document.getElementById("free_text_input") as HTMLInputElement;
                if (input) {
                    input.value = "";
                }
                props.setFreeTextFilter("");
                props.setFilter(new Map());
                props.setExcludeFilter(new Map());
                props.setRegexes([]);
                props.setExcludeRegexes([]);
            }
        } className="w-6 h-6 right-2 absolute  text-textmedium hover:bg-primary rounded hover:cursor-pointer">
            <XIcon/>
        </div>
    </div>
}

function LogView(props: {
    removeService?: boolean,
    regex: string,
    log: Log
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void
    regexes: string[]
    setRegexes: (regexes: string[]) => void
    excludeRegexes: string[]
    setExcludeRegexes: (regexes: string[]) => void
    isFilterShown: boolean
}) {
    let indicatorColor = getLogLeveLIndicatorColor(props.log.severity);
    let match = new RegExp("");
    try {
        match = new RegExp(props.regex);
    } catch (e) {
        console.error(e)
    }
    return <Drawer.Root direction="right">
        <Drawer.Trigger asChild>
            <div id="clickableLogLine"
                 className="w-full h-8 px-2 justify-start items-center gap-4 flex hover:bg-backgroundlight overflow-x-clip hover:cursor-pointer"
            >
                <div className="flex-none h-8 w-[160px] flex justify-start items-center space-x-2">
                    <div className={cn(indicatorColor, " flex-none w-[4px] h-6 left-0 top-[4px] rounded")}/>
                    <div
                        className="flex-none h-[16px] w-full top-0 text-textmedium text-sm font-medium  leading-[16px] truncate">
                        {
                            displayDateTimeWithSelectedFormat(new Date(props.log.time), [DateTimePrecision.Month, DateTimePrecision.Day, DateTimePrecision.Hours, DateTimePrecision.Minutes, DateTimePrecision.Seconds, DateTimePrecision.Milliseconds])
                        }
                    </div>
                </div>
                {!props.removeService && <div
                    className={"flex-none w-[160px] h-[16px] top-0 text-textmedium text-sm font-medium leading-[16px] truncate " + getBreakpointAttributesForLogs(props.isFilterShown, "service")}>
                    {
                        props.log.serviceName
                    }
                </div>}
                <div
                    dangerouslySetInnerHTML={{__html: props.regex !== "" ? props.log.message.replace(match, '<mark class="highlight">$&</mark>') : props.log.message}}
                    className={"text-textmedium text-sm font-medium leading-[16px] overflow-x-clip overflow-y-clip truncate " + getBreakpointAttributesForLogs(props.isFilterShown, "message")}></div>
            </div>
        </Drawer.Trigger>
        <Drawer.Portal>
            <Drawer.Content
                className="select-text flex border-l flex-col h-full w-1/2 mt-24 fixed bottom-0 right-0">
                <LogPanelContent
                    log={props.log}
                    filter={props.filter}
                    setFilter={props.setFilter}
                    excludeFilter={props.excludeFilter}
                    setExcludeFilter={props.setExcludeFilter}
                    regexes={props.regexes}
                    setRegexes={props.setRegexes}
                    excludeRegexes={props.excludeRegexes}
                    setExcludeRegexes={props.setExcludeRegexes}
                />
            </Drawer.Content>
        </Drawer.Portal>
    </Drawer.Root>

}


function getBreakpointAttributesForLogs(isFilterShown: boolean, componentName: string) {
    if (isFilterShown) {
        switch (componentName) {
            case "service":
                return " max-[900px]:hidden"
        }
    } else {
        switch (componentName) {
            case "service":
                return " max-[620px]:hidden"
        }
    }
}

function LogFeed(props: {
    ascending?: boolean,
    setAscending?: (ascending: boolean) => void,
    logs: Log[],
    scrolledToBottomHandler: () => void,
    removeService?: boolean,
    smallerTitleEnabled?: boolean
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void
    regexes: string[]
    setRegexes: (regexes: string[]) => void
    excludeRegexes: string[]
    setExcludeRegexes: (regexes: string[]) => void
    showExpand?: boolean
    expandOnClick?: () => void,
    isFilterShown: boolean
}) {
    const handleScroll = (e: any) => {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1
        if (bottom) {
            props.scrolledToBottomHandler();
        }
    }
    let overrideTextFont = "text-xl"
    if (props.smallerTitleEnabled !== undefined && props.smallerTitleEnabled) {
        overrideTextFont = "text-sm font-semibold"
    } else {
        overrideTextFont = "text-xl"
    }

    return <div className="mt-4 min-w-0 bg-backgroundmedium border rounded min-h-0 w-full flex flex-col grow shrink">
        <div className={"flex justify-end"}>
            <div
                className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex grow shrink">
                <div
                    className={`w-[152px] h-full flex justify-between font-normal font-['Inter'] leading-8 text-textmedium ${overrideTextFont}`}>
                    <div>Time</div>
                    <div onClick={() => {
                        if (props.setAscending) {
                            props.setAscending(!props.ascending)
                        }
                    }} className="hover:cursor-pointer text-sm flex flex-col justify-center pr-4">
                        {props.ascending ? "▲" : "▼"}
                    </div>
                </div>
                {!props.removeService &&
                    <div
                        className={`w-[160px] h-full  font-normal font-['Inter'] leading-8 text-textmedium ${overrideTextFont} ${getBreakpointAttributesForLogs(props.isFilterShown, "service")}`}>Service
                    </div>}
                <div
                    className={`font-normal font-['Inter'] leading-8 text-textmedium truncate ${overrideTextFont} ${getBreakpointAttributesForLogs(props.isFilterShown, "message")}`}>Message
                </div>
            </div>
            {props.showExpand && props.expandOnClick &&
                <Maximize2Icon
                    className={"w-4 h-4 text-textdark border-l border-b rounded-bl hover:text-primary hover:cursor-pointer"}
                    onClick={props.expandOnClick}/>
            }
        </div>
        <div
            className="h-full max-w-full min-w-0 min-h-0 overflow-y-auto scrollMedium border-t rounded-b flex flex-col grow shrink"
            onScroll={handleScroll}>
            {
                props.logs.length === 0 &&
                <div className="h-full w-full flex justify-center items-center">
                    <div className="text-textmedium text-lg">No logs found</div>
                </div>
            }
            {
                props.logs.map((log, index) => {
                    return <LogView
                        regex={props.regexes !== undefined && props.regexes.length > 0 ? props.regexes[0] : ""}
                        removeService={props.removeService}
                        key={index}
                        log={log}
                        filter={props.filter}
                        setFilter={props.setFilter}
                        excludeFilter={props.excludeFilter}
                        setExcludeFilter={props.setExcludeFilter}
                        regexes={props.regexes}
                        setRegexes={props.setRegexes}
                        excludeRegexes={props.excludeRegexes}
                        setExcludeRegexes={props.setExcludeRegexes}
                        isFilterShown={props.isFilterShown}
                    />
                })
            }
        </div>
    </div>
}

function LogPanel(props: {
    ascending: boolean,
    setAscending: (ascending: boolean) => void,
    logs: Logs,
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    scrolledToBottomHandler: (() => void),
    setFreeTextFilter: (filter: string) => void
    excludeFilter: Map<string, string[]>
    setExcludeFilter: (filter: Map<string, string[]>) => void
    regexes: string[]
    setRegexes: (regexes: string[]) => void
    excludeRegexes: string[]
    setExcludeRegexes: (regexes: string[]) => void
    isFilterShown: boolean
    dropDownFunction?: (key: string) => Promise<Attribute[]>
}) {
    return <div className={"pl-4 min-w-0 min-h-0 grow shrink w-full flex flex-col"}>
        <LogSearch
            dropDownFunction={props.dropDownFunction}
            logAttributes={props.logs.filterAttributes}
            setFreeTextFilter={props.setFreeTextFilter}
            filter={props.filter} setFilter={props.setFilter}
            excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
            regexes={props.regexes} setRegexes={props.setRegexes}
            excludeRegexes={props.excludeRegexes} setExcludeRegexes={props.setExcludeRegexes}
        />
        <LogFeed
            ascending={props.ascending} setAscending={props.setAscending}
            logs={props.logs.logs}
            scrolledToBottomHandler={props.scrolledToBottomHandler}
            filter={props.filter} setFilter={props.setFilter}
            excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
            regexes={props.regexes} setRegexes={props.setRegexes}
            excludeRegexes={props.excludeRegexes} setExcludeRegexes={props.setExcludeRegexes}
            isFilterShown={props.isFilterShown}
        />
    </div>
}

function MainLogsView(props: {
    ascending: boolean,
    setAscending: (ascending: boolean) => void,
    isLogsLoading?: boolean,
    isFilterAttributesLoading?: boolean,
    logs: Logs,
    setFilter: (filter: Map<string, string[]>) => void,
    filter: Map<string, string[]>,
    scrolledToBottomHandler: (() => void)
    setFreeTextFilter: (filter: string) => void
    setExcludeFilter: (filter: Map<string, string[]>) => void
    excludeFilter: Map<string, string[]>
    regexes: string[]
    setRegexes: (regexes: string[]) => void
    excludeRegexes: string[]
    setExcludeRegexes: (regexes: string[]) => void
    setIsOpened?: React.Dispatch<React.SetStateAction<Map<string, boolean>>>
    dropDownFunction?: (key: string) => Promise<Attribute[]>
}) {
    const [shrinkFilter, setShrinkFilter] = useState<boolean>(false);

    return <div className={"w-full min-w-0 min-h-0 flex justify-between grow shrink"}>
        <div className={"flex flex-none relative"}>
            {!shrinkFilter && props.isFilterAttributesLoading &&
                <LoadingSpinner className={`absolute top-1/2 left-1/2 z-40"}`}/>}
            <FilterPanel
                initiallyOpenFilterKeys={["service.name"]}
                attributes={props.logs.filterAttributes} setFilter={props.setFilter} filter={props.filter}
                telemetryFiltersComponent={
                    <TelemetryLevelFilters filter={props.filter} setFilter={props.setFilter}
                                           attributes={props.logs.filterAttributes}
                                           excludeFilter={props.excludeFilter}
                                           setExcludeFilter={props.setExcludeFilter}
                                           levelAttributeKey={"log_level"}
                                           indicatorColourFunction={getLogLeveLIndicatorColor}
                    />}
                filteringCriteria={"log_level"}
                excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
                setFilterShrank={setShrinkFilter}
                setIsOpened={props.setIsOpened}
            />
        </div>
        <div className={"flex grow shrink min-w-0 min-h-0 relative"}>
            {props.isLogsLoading && <LoadingSpinner className={`absolute top-1/2 left-1/2 z-40"}`}/>}
            <LogPanel
                dropDownFunction={props.dropDownFunction}
                ascending={props.ascending}
                setAscending={props.setAscending}
                setFreeTextFilter={props.setFreeTextFilter}
                filter={props.filter} setFilter={props.setFilter}
                logs={props.logs}
                scrolledToBottomHandler={props.scrolledToBottomHandler}
                excludeFilter={props.excludeFilter}
                setExcludeFilter={props.setExcludeFilter}
                regexes={props.regexes}
                setRegexes={props.setRegexes}
                excludeRegexes={props.excludeRegexes}
                setExcludeRegexes={props.setExcludeRegexes}
                isFilterShown={!shrinkFilter}
            />
        </div>
    </div>
}

class LogsResponse {
    constructor(logs: Log[]) {
        this.logs = logs;
    }

    logs: Log[]
}


interface LogSummaryResponse {
    attributes: Map<string, Attribute[]>
}

interface LogsProps {
    smallerTitleEnabled?: boolean
    justLogsFeed?: boolean
    removeService?: boolean
    expandOnClick?: () => void
    service?: string
    noBaseView?: boolean
}

async function updateLogMetrics(props: LogsProps, environments: string[], timeRange: TimeRange, filter: Map<string, string[]>, excludeFilter: Map<string, string[]>, regexes: string[], excludeRegexes: string[], setLogTimeMetrics: (value: (((prevState: (GetLogMetricsResponse | undefined)) => (GetLogMetricsResponse | undefined)) | GetLogMetricsResponse | undefined)) => void, setIsTimelineLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void,
                                updateLogMetricsAbortController: AbortController | undefined,
                                setLogTimeMetricsAbortController: (value: (((prevState: (AbortController | undefined)) => (AbortController | undefined)) | AbortController | undefined)) => void
) {
    if (props.justLogsFeed) {
        // In the case where it's just the trace feed, we don't need the metrics
        return;
    }
    if (updateLogMetricsAbortController) {
        updateLogMetricsAbortController.abort();
    }
    const abortController = new AbortController();
    setLogTimeMetricsAbortController(abortController);
    setIsTimelineLoading(true)
    const startEnd = timeRange.getStartEnd();
    await GetLogMetrics({
            filters: filter,
            startTime: Math.floor(startEnd[0].getTime() / 1000),
            excludeFilters: excludeFilter,
            endTime: Math.floor(startEnd[1].getTime() / 1000),
            regexes: regexes,
            excludeRegexes: excludeRegexes,
            environments: environments[0] === "" ? [] : environments,
            splits: ["log_level"],
        }, abortController
    ).then((response) => {
        setLogTimeMetrics(response)
        setIsTimelineLoading(false)
    }).catch((e) => {
        if (e.code === "ERR_CANCELED") {
            return;
        }
        console.error(e);
        setIsTimelineLoading(false)
    })
}

async function updateLogs(setIsLogsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, filter: Map<string, string[]>, timeRange: TimeRange, prevMaxTime: number | undefined, excludeFilter: Map<string, string[]>, regexes: string[], excludeRegexes: string[], ascending: boolean, environments: string[], setLogs: (value: (((prevState: Logs) => Logs) | Logs)) => void,
                          abortController: AbortController | undefined, setAbortController: (value: (((prevState: (AbortController | undefined)) => (AbortController | undefined)) | AbortController | undefined)) => void
) {
    try {
        setIsLogsLoading(true)
        const filters = Object.fromEntries(filter);
        const startEnd = timeRange.getStartEnd();
        let prevEndTime = prevMaxTime;
        const excludeFilterToUse = Object.fromEntries(excludeFilter);
        if (abortController) {
            abortController.abort();
        }
        const abort = new AbortController();
        setAbortController(abort);
        const d: AxiosPromise<LogsResponse> = axios.post("/api/v1/logs", {
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "filters": filters,
                "excludeFilters": excludeFilterToUse,
                "regexes": regexes,
                "excludeRegexes": excludeRegexes,
                "prevEndTime": prevEndTime,
                "ascending": ascending,
                "environments": environments[0] === "" ? [] : environments
            }, {
                signal: abort.signal
            }
        )
        const awaited = (await d).data;
        let awaitedLogs = plainToInstance(LogsResponse, awaited)
        setLogs(prevState => {
            if (prevMaxTime === undefined) {
                return {...prevState, logs: awaitedLogs.logs}
            } else {
                return {...prevState, logs: [...prevState.logs, ...awaitedLogs.logs]}
            }
        })
        setIsLogsLoading(false)
    } catch (e) {
        // @ts-ignore
        if (e.code === "ERR_CANCELED") {
            return;
        }
        console.error(e);
        setIsLogsLoading(false)

    }
}

async function updateLogSummary(setIsFilterAttributesLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, filter: Map<string, string[]>, excludeFilter: Map<string, string[]>, timeRange: TimeRange, regexes: string[], excludeRegexes: string[], environments: string[], setLogs: (value: (((prevState: Logs) => Logs) | Logs)) => void,
                                abortController: AbortController | undefined, setAbortController: (value: (((prevState: (AbortController | undefined)) => (AbortController | undefined)) | AbortController | undefined)) => void,
                                openFilters: Map<string, boolean>
) {
    try {
        abortController?.abort();
        const newAbortController = new AbortController();
        setAbortController(newAbortController);
        const filters = Object.fromEntries(filter)
        const excludeFilters = Object.fromEntries(excludeFilter);
        const startEnd = timeRange.getStartEnd();
        for (let attribute of openFilters.keys()) {
            if (openFilters.get(attribute) !== true) {
                continue;
            }
            axios.post("/api/v1/logsSummaryIndividualAttribute", {
                    "startTime": Math.floor(startEnd[0].getTime() / 1000),
                    "endTime": Math.floor(startEnd[1].getTime() / 1000),
                    // Removed because we want to keep everything for now
                    "excludeFilters": excludeFilters,
                    // Removed because we want to keep everything for now
                    "filters": filters,
                    // Removed because we want to keep everything for now
                    "regexes": regexes,
                    // Removed because we want to keep everything for now
                    "excludeRegexes": excludeRegexes,
                    "environments": environments[0] === "" ? [] : environments,
                    "attribute": attribute
                },
                {signal: newAbortController.signal}
            ).then((response) => {
                let attributes = response.data.attribute;
                setLogs(prevState => {
                    let prevMap = prevState.filterAttributes;
                    prevMap.set(attribute, attributes);
                    return {...prevState, filterAttributes: prevMap}
                })
            })
        }
    } catch (e) {
        // @ts-ignore
        if (e.code === "ERR_CANCELED") {
            return;
        }
        console.error(e);
        setIsFilterAttributesLoading(false)
    }
}

const Logs = (props: LogsProps) => {
    const [logs, setLogs] = useState<Logs>({logs: [], filterAttributes: new Map()});
    const [prevMaxTime, setPrevMaxTime] = useState<number>();
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [logTimeMetrics, setLogTimeMetrics] = useState<GetLogMetricsResponse>();
    const [isTimelineLoading, setIsTimelineLoading] = useState<boolean>(true);
    const [isLogsLoading, setIsLogsLoading] = useState<boolean>(true);
    const [isFilterAttributesLoading, setIsFilterAttributesLoading] = useState<boolean>(false);
    const [ascending, setAscending] = useState<boolean>(false);
    const [environments, setEnvironments] = useState<string[]>([]);
    const debouncedUpdateLogMetrics = useDebouncedCallback(updateLogMetrics, 10);
    const [updateLogMetricsAbortController, setUpdateLogMetricsAbortController] = useState<AbortController | undefined>(undefined);
    const debouncedUpdateLogs = useDebouncedCallback(updateLogs, 10);
    const [updateLogsAbortController, setUpdateLogsAbortController] = useState<AbortController | undefined>(undefined);
    const debouncedUpdateLogSummary = useDebouncedCallback(updateLogSummary, 10);
    const [logsSummaryAbortController, setLogsSummaryAbortController] = useState<AbortController | undefined>(undefined);


    // OpenFilters is used to know what we need to know what values we need to pull when something updates
    const [openFilters, setOpenFilters] = useState<Map<string, boolean>>(() => {
        let o = new Map<string, boolean>();
        o.set("log_level", true)
        o.set("service.name", true)
        return o;
    });

    // Get all the trace attributes to filter by
    useEffect(() => {
        axios.get("/api/v1/logsSummaryAttributes").then((response) => {
            let attributes = response.data.attributes;
            setLogs(prevState => {
                let newMap = new Map<string, Attribute[]>(prevState.filterAttributes);
                for (let attribute of attributes) {
                    if (!newMap.has(attribute)) {
                        newMap.set(attribute, [])
                    }
                }
                return {...prevState, filterAttributes: newMap}
            })
        })
    }, [])

    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState<Map<string, string[]>>(() => {
        const filter = new Map<string, string[]>();
        const search = searchParams.get("filter");
        if (search !== null) {
            const filterObject = JSON.parse(search);
            for (const [key, value] of Object.entries(filterObject)) {
                filter.set(key, value as string[])
            }
        }
        if (props.service !== undefined) {
            filter.set("service.name", [props.service])
        }
        if (filter.get("ServiceName") !== undefined) {
            filter.delete("ServiceName")
        }
        return filter;
    });

    useEffect(() => {
        if (props.service !== undefined) {
            filter.set("service.name", [props.service])
        }
    }, [props.service]);

    const [excludeFilter, setExcludeFilter] = useState<Map<string, string[]>>(
        () => {
            const excludeFilter = new Map<string, string[]>();
            const search = searchParams.get("excludeFilter");
            if (search !== null) {
                const excludeFilterObject = JSON.parse(search);
                for (const [key, value] of Object.entries(excludeFilterObject)) {
                    excludeFilter.set(key, value as string[])
                }
            }
            return excludeFilter;
        }
    );
    const [regexes, setRegexes] = useState<string[]>(
        () => {
            const regexes = searchParams.get("regexes") || "";
            if (regexes !== "") {
                return JSON.parse(regexes)
            }
            return []
        }
    );
    const [excludeRegexes, setExcludeRegexes] = useState<string[]>(
        () => {
            const excludeRegexes = searchParams.get("excludeRegexes") || "";
            if (excludeRegexes !== "") {
                return JSON.parse(excludeRegexes)
            }
            return []
        }
    );

    useEffect(() => {
        let filterJson = searchParams.get("regexes") || "";
        if (filterJson !== "") {
            setRegexes(JSON.parse(filterJson))
        }
        filterJson = searchParams.get("excludeRegexes") || "";
        if (filterJson !== "") {
            setExcludeRegexes(JSON.parse(filterJson))
        }
    }, [searchParams])

    useEffect(() => {
        let environments = searchParams.get("environment");
        if (environments !== null) {
            setEnvironments([environments])
        }
    }, [searchParams])

    useEffect(() => {
        let filterJson = searchParams.get("excludeFilter") || "";
        if (filterJson !== "") {
            const excludeFilterMap = new Map<string, string[]>();
            const excludeFilterObject = JSON.parse(filterJson);
            for (const [key, value] of Object.entries(excludeFilterObject)) {
                excludeFilterMap.set(key, value as string[])
            }
            setExcludeFilter(excludeFilterMap)
        }
        filterJson = searchParams.get("filter") || "";
        if (filterJson !== "") {
            const filterMap = new Map<string, string[]>();
            const filterObject = JSON.parse(filterJson);
            for (const [key, value] of Object.entries(filterObject)) {
                filterMap.set(key, value as string[])
            }
            setFilter(filterMap)
        }

    }, [searchParams])


    // Ascending query param
    useEffect(() => {
        const ascending = searchParams.get("ascending");
        if (ascending !== null) {
            setAscending(ascending === "true")
        }
    }, [searchParams])

    useEffect(() => {
        // Remove all of the filters, regexes and exclude regexes when from the search params when the service is removed
        return () => {
            if (window.location.pathname === "/service") {
                setSearchParams(prev => {
                    prev.delete("filter")
                    prev.delete("excludeFilter")
                    prev.delete("regexes")
                    prev.delete("excludeRegexes")
                    return prev
                })
            }
        }
    }, [])


    useEffect(() => {
        debouncedUpdateLogMetrics(props, environments, timeRange, filter, excludeFilter, regexes, excludeRegexes, setLogTimeMetrics, setIsTimelineLoading, updateLogMetricsAbortController, setUpdateLogMetricsAbortController);
    }, [filter, excludeFilter, regexes, excludeRegexes, timeRange, environments]);


    // Reset prevMaxTime when filter changes so that we can fetch new logs
    useEffect(() => {
        setPrevMaxTime(undefined)
    }, [filter, excludeFilter, regexes, excludeFilter, environments]);


    const scrolledToBottomHandler = () => {
        if (logs.logs.length === 0) {
            return
        }
        let prevMaxTime = logs.logs[logs.logs.length - 1].time + 1;
        setPrevMaxTime(prevMaxTime)
    }

    // Let's reset the prevMaxTime when the ascending order changes so that we can fetch new logs
    useEffect(() => {
        setPrevMaxTime(undefined)
    }, [ascending])

    useEffect(() => {
        // Get logs
        debouncedUpdateLogs(setIsLogsLoading, filter, timeRange, prevMaxTime, excludeFilter, regexes, excludeRegexes, ascending, environments, setLogs, updateLogsAbortController, setUpdateLogsAbortController);
    }, [prevMaxTime, excludeFilter, filter, regexes, excludeRegexes, timeRange, ascending, environments]);

    useEffect(() => {
        if (props.justLogsFeed) {
            // In the case where it's just the trace feed, we don't need the metrics
            return;
        }
        debouncedUpdateLogSummary(setIsFilterAttributesLoading, filter, excludeFilter, timeRange, regexes, excludeRegexes, environments, setLogs, logsSummaryAbortController, setLogsSummaryAbortController, openFilters);
    }, [filter, excludeFilter, regexes, excludeRegexes, timeRange, environments, openFilters]);

    if (props.justLogsFeed) {
        return <LogFeed
            filter={filter} setFilter={setFilter}
            excludeFilter={excludeFilter} setExcludeFilter={setExcludeFilter}
            ascending={ascending} setAscending={(ascending: boolean) => {
            setSearchParams(prev => {
                let existing = new URLSearchParams(window.location.search)
                existing.set("ascending", ascending.toString())
                return existing
            })
        }} removeService={props.removeService}
            logs={logs.logs}
            scrolledToBottomHandler={scrolledToBottomHandler}
            smallerTitleEnabled={props.smallerTitleEnabled}
            regexes={regexes}
            setRegexes={setRegexes}
            excludeRegexes={excludeRegexes}
            setExcludeRegexes={setExcludeRegexes}
            showExpand={true}
            expandOnClick={props.expandOnClick}
            isFilterShown={true}
        />
    }

    let logsInner = <div className={"flex flex-col grow shrink min-h-0 min-w-0"}>
        <div className={"flex flex-none relative"}>
            {isTimelineLoading && <LoadingSpinner className={`absolute top-1/2 left-1/2 z-40"}`}/>}
            <TimelineViewer metric={logTimeMetrics} title={"Number of logs"}/>
        </div>
        <MainLogsView
            dropDownFunction={
                (key: string) => {
                    let filters = Object.fromEntries(filter);
                    let excludeFilters = Object.fromEntries(excludeFilter);
                    return axios.post("/api/v1/logsSummaryIndividualAttribute", {
                        "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                        "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                        // Removed because we want to keep everything for now
                        "excludeFilters": excludeFilters,
                        // Removed because we want to keep everything for now
                        "filters": filters,
                        // Removed because we want to keep everything for now
                        "regexes": regexes,
                        // Removed because we want to keep everything for now
                        "excludeRegexes": excludeRegexes,
                        "environments": environments[0] === "" ? [] : environments,
                        "attribute": key
                    }).then((response) => {
                        return response.data.attribute;
                    })
                }
            }
            ascending={ascending}
            setIsOpened={setOpenFilters}
            setAscending={(ascending: boolean) => setSearchParams(prev => {
                let existing = new URLSearchParams(window.location.search)
                existing.set("ascending", ascending.toString())
                return existing
            })}
            isLogsLoading={isLogsLoading} isFilterAttributesLoading={isFilterAttributesLoading}
            setFreeTextFilter={(filter: string) => setSearchParams(prev => {
                    let existing = new URLSearchParams(window.location.search)
                    existing.set("search", filter)
                    return existing
                }
            )}
            setFilter={(filter: Map<string, string[]>) => setSearchParams(prev => {
                let existing = new URLSearchParams(window.location.search)
                existing.set("filter", JSON.stringify(Object.fromEntries(filter)))
                return existing
            })}
            filter={filter}
            setExcludeFilter={(filter: Map<string, string[]>) => setSearchParams(prev => {
                let existing = new URLSearchParams(window.location.search)
                existing.set("excludeFilter", JSON.stringify(Object.fromEntries(filter)))
                return existing
            })}
            excludeFilter={excludeFilter}
            regexes={regexes}
            setRegexes={(regexes: string[]) => setSearchParams(prev => {
                let existing = new URLSearchParams(window.location.search)
                existing.set("regexes", JSON.stringify(regexes))
                return existing
            })}
            excludeRegexes={excludeRegexes}
            setExcludeRegexes={(regexes: string[]) => setSearchParams(prev => {
                let existing = new URLSearchParams(window.location.search)
                existing.set("excludeRegexes", JSON.stringify(regexes))
                return existing
            })}
            logs={logs}
            scrolledToBottomHandler={scrolledToBottomHandler}/>
    </div>;
    if (props.noBaseView) {
        return logsInner;
    }
    return (
        <BaseView title={"Logs"}>
            <div className={"p-4 flex flex-col grow shrink bg-backgrounddark min-w-0 min-h-0"}>
                {logsInner}
            </div>
        </BaseView>
    )
}

export default Logs;