import React, {Dispatch, SetStateAction} from "react";
import {GroupWidget, OldMetricChartWidget, MarkdownWidget, Widget} from "./internalwidgets";
import {addWidget} from "./utils";
import {FaLayerGroup} from "react-icons/fa";
import {Button} from "../ui/button";
import {FcComboChart} from "react-icons/fc";
import {MetricType} from "../../pages/MetricsTest";
import {MdEditNote} from "react-icons/md";

const markdownPlaceholderText = `### Hi
This is a **markdown** note.
`;
const widgets = [
    {
        name: "Group",
        widgetGenerationFunction: (setChildren: Dispatch<SetStateAction<Widget[]>>) => {
            let newGroup = {
                widgetType: "Group",
                title: "New Group",
                position: {
                    x: undefined,
                    y: undefined,
                    w: 6,
                    h: 4
                },
                children: []
            } as GroupWidget
            addWidget(newGroup, setChildren)
        },
        Icon: (props: {className?: string}) => <FaLayerGroup className={props.className}/>
    },
    {
        name: "Chart",
        widgetGenerationFunction: (setChildren: Dispatch<SetStateAction<Widget[]>>) => {
            let newWidget = {
                metricName: "container_net_tcp_active_connections",
                aggregation: "avg",
                type: "line",
                widgetType: "MetricChart",
                title: "Active TCP Connections",
                position: {
                    x: undefined,
                    y: undefined,
                    w: 6,
                    h: 2
                },
                metricType: MetricType.Metric
            } as OldMetricChartWidget
            addWidget(newWidget, setChildren)
        },
        Icon: (props: {className?: string}) => <FcComboChart className={props.className} />
    },
    {
        name: "Markdown Note",
        widgetGenerationFunction: (setChildren: Dispatch<SetStateAction<Widget[]>>) => {
            let newGroup = {
                widgetType: "Markdown",
                content: markdownPlaceholderText,
                position: {
                    x: undefined,
                    y: undefined,
                    w: 6,
                    h: 2
                },
                children: []
            } as MarkdownWidget
            addWidget(newGroup, setChildren)
        },
        Icon: (props: {className?: string}) => <MdEditNote className={props.className}/>
    },
]

interface AddNewWidgetDrawerProps {
    setChildren: Dispatch<SetStateAction<Widget[]>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const AddNewWidgetDrawer = (props: AddNewWidgetDrawerProps) => {
    return (
        <div>
            <div className={"text-textlight text-2xl font-semibold leading-loose truncate justify-left px-4 border-b"}>
                Add a widget
            </div>
            <div className={"flex columns-2 gap-4 p-4 flex-wrap"}>
                {widgets.map((widget, index) => {
                    return (
                        <div key={index} className={"flex w-full flex-col items-center justify-center"}>
                            <Button className={"w-full h-max bg-primarytransparent border border-primary text-textmedium"} onClick={() => {
                                widget.widgetGenerationFunction(props.setChildren)
                                props.setIsOpen(false)
                            }}>
                                <div className={"flex flex-col w-full gap-2"}>
                                    <div className={"flex justify-center"}>
                                    <widget.Icon className={"size-1/2 justify-center"}/>
                                    </div>
                                    <div className={"text-lg"}>{widget.name}</div>
                                </div>
                            </Button>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}