import React from 'react';
import {Dialog, DialogContent, DialogHeader, DialogTitle} from '../ui/dialog';
import {Button} from '../ui/button';
import {Checkbox} from '../ui/checkbox';
import {useMutation} from '@tanstack/react-query';
import axios from '../../utility/customAxios';

interface MuteIssueDialogProps {
    issue: {
        uuid: string;
        workflowUuid: string;
        organizationUuid: string;
        attributes: Map<string, string>;
    };
    open: boolean;
    onOpenChange: (open: boolean) => void;
}

export function MuteIssueDialog({issue, open, onOpenChange}: MuteIssueDialogProps) {
    const [selectedAttributes, setSelectedAttributes] = React.useState<Set<string>>(new Set());

    const muteMutation = useMutation({
        mutationFn: async () => {
            const selectedAttributesObj = Object.fromEntries(
                Array.from(selectedAttributes).map(key => [key, issue.attributes.get(key)])
            );

            await axios.put('/api/v1/issues/mute', {
                workflowUuid: issue.workflowUuid,
                attributes: selectedAttributesObj,
            });
        },
        onSuccess: () => {
            onOpenChange(false);
        },
    });

    const handleAttributeToggle = (key: string) => {
        const newSelected = new Set(selectedAttributes);
        if (newSelected.has(key)) {
            newSelected.delete(key);
        } else {
            newSelected.add(key);
        }
        setSelectedAttributes(newSelected);
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="bg-backgrounddark text-textmedium w-[70%]">
                <DialogHeader>
                    <DialogTitle>Mute Similar Issues</DialogTitle>
                </DialogHeader>
                <div className="space-y-4">
                    <p className="text-sm">
                        Select which attributes to match when muting similar issues. Any future issues with matching attributes
                        will be automatically muted. Mutes match any issue from the same workflow as long as the mute attributes match.
                        For example if you have a rightsizing mute for an environment, all issues in that environment will be muted.
                    </p>
                    <div className="space-y-2">
                        {Array.from(issue.attributes.entries()).map(([key, value]) => (
                            <div key={key} className="flex items-center space-x-2">
                                <Checkbox
                                    id={key}
                                    checked={selectedAttributes.has(key)}
                                    onCheckedChange={() => handleAttributeToggle(key)}
                                />
                                <label htmlFor={key} className="text-sm">
                                    <span className="font-medium">{key}:</span> {value}
                                </label>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-end space-x-2">
                        <Button variant="outline" onClick={() => onOpenChange(false)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => muteMutation.mutate()}
                            disabled={selectedAttributes.size === 0 || muteMutation.isPending}
                        >
                            Mute Issues
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
